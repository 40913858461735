"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "veevaHandle"
  }, [_c("stickyHeader"), !_vm.isVeevaAuth && _vm.veevaInfo.status == 1 ? _c("div", {
    staticClass: "authInfos authPendding"
  }, [_vm._v(" 已收到您的认证资料，预计在1-2周内完成处理，请耐心等待 ")]) : _vm._e(), !_vm.isVeevaAuth && _vm.veevaInfo.status == 3 ? _c("div", {
    staticClass: "authInfos authError"
  }, [_vm._v(" 很遗憾！您没有通过医疗卫生专业人士身份认证，您可以修改个人信息并补充认证资料后，重新申请认证 ")]) : _vm._e(), !_vm.isVeevaAuth && (_vm.veevaInfo.status == 1 || _vm.veevaInfo.status == 3) ? _c("div", {
    staticClass: "authInfosSeat",
    class: _vm.veevaInfo.status == 1 ? "authInfosSeatSmall" : "authInfosSeatBig"
  }) : _vm._e(), _c("div", [_c("VeevaStep", {
    attrs: {
      step: 2
    }
  }), _c("VeevauploadInfo", {
    ref: "VeevauploadInfo"
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;