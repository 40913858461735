"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  props: {
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    },
    /* 
      展示形式
        words： 文字提示
        slot  插槽
    */
    showType: {
      type: String,
      default: 'words'
    },
    /* 提示文字 */
    tipWords: {
      type: String,
      default: '请传入提示文字'
    },
    /* 数据 */
    dataBase: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showPop: false,
      statusArr: [{
        word: '未开始',
        color: '#fd9a64',
        bgcolor: '253,154,100'
      }, {
        word: '进行中',
        color: '#80cf1a',
        bgcolor: '128,207,26'
      }, {
        word: '回放中',
        color: '#00b6ff',
        bgcolor: '0,182,255'
      }, {
        word: '已结束',
        color: '#00617f',
        bgcolor: '0,97,127'
      }]
    };
  },
  watch: {},
  filter: {},
  created() {},
  mounted() {},
  computed: {},
  methods: {
    showFormatTime(im) {
      const start_time = this.$SPLITDATE(im.start_time, 'yyyy-mm-dd h:m');
      const end_time = this.$SPLITDATE(im.end_time, 'yyyy-mm-dd h:m');
      const start_time_arr = start_time.split(' ');
      const end_time_arr = end_time.split(' ');
      if (start_time_arr[0] == end_time_arr[0]) {
        return start_time + ' - ' + end_time_arr[1];
      }
      return start_time + ' - ' + end_time;
    },
    open() {
      this.showPop = true;
    },
    close() {
      this.popFullClose();
    },
    maskHandle() {
      if (this.mask) {
        this.popFullClose();
      }
    },
    popFullClose() {
      this.showPop = false;
      this.$emit('cancle');
    }
  }
};