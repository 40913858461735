"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container-register",
    on: {
      touchmove: function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c("transition", {
    attrs: {
      name: "popFull",
      appear: ""
    }
  }, [_vm.showPop ? _c("div", {
    staticClass: "popFull",
    class: {
      grayBg: _vm.grayFlag
    },
    on: {
      click: _vm.maskHandle
    }
  }, [_c("div", {
    staticClass: "popFullInner",
    on: {
      click: function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c("img", {
    staticClass: "popClose",
    attrs: {
      src: require("img/common/popClose.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.cancleRegister.apply(null, arguments);
      }
    }
  }), _c("div", {
    staticClass: "popFullInnerContent popFullInnerContent-slot"
  }, [_c("div", {
    staticClass: "registerSuccess"
  }, [_c("div", {
    staticClass: "registerSuccessTip"
  }, [_vm.days > 0 ? _c("span", [_vm._v("您的免认证学习权益还剩" + _vm._s(_vm.days) + "天，前往认证即可解锁永久权益")]) : _c("span", [_vm._v("还差一步！本内容仅供医疗卫生专业人士学习交流使用，前往认证即可立即解锁免费学习")])]), _c("div", {
    staticClass: "registerSuccessHandle flex align-center justify-between"
  }, [_c("div", {
    staticClass: "registerSuccessHandleDelay flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.cancleRegister.apply(null, arguments);
      }
    }
  }, [_vm._v(" 稍后认证 ")]), _c("div", {
    staticClass: "registerSuccessHandleNow flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.toRegister.apply(null, arguments);
      }
    }
  }, [_vm._v(" 立即前往 ")])])])])])]) : _vm._e()])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;