"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ai-img-container",
    style: {
      width: _vm.w,
      height: _vm.wrapHeight
    }
  }, [_c("div", {
    staticClass: "icon"
  }, [_vm.loading && _vm.showLoading ? _c("van-icon", {
    attrs: {
      name: "photo",
      size: "30",
      color: _vm.color
    }
  }) : _vm._e(), _vm.error && _vm.showError ? _c("van-icon", {
    attrs: {
      name: "photo-fail",
      size: "30",
      color: _vm.color
    }
  }) : _vm._e()], 1), _c("van-image", {
    class: {
      opacity: !_vm.loading && !_vm.error
    },
    attrs: {
      src: _vm.img,
      width: _vm.w,
      height: _vm.h,
      radius: _vm.r,
      "show-loading": false,
      "show-error": false
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;