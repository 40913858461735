"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/toast/style");
var _toast = _interopRequireDefault(require("vant/es/toast"));
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _vue = _interopRequireDefault(require("vue"));
var _axios = _interopRequireDefault(require("axios"));
var _store = _interopRequireDefault(require("@/store"));
var _index = _interopRequireDefault(require("@/router/index.js"));
var _qs = _interopRequireDefault(require("qs"));
var _config = require("@/config");
const queue = []; // 请求队列

const http = _axios.default.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded' // need qs
    // 'Content-Type': 'application/json; charset=utf-8',
    // 'Content-Type': 'multipart/form-data', // need form data
  },
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000
});

/* 请求拦截器 */
http.interceptors.request.use(config => {
  // console.log(' --- config --- ', config)
  // 根据环境自动替换 /api -> /api-dev
  if (_config.deploy.service === 'k8s' && _config.env === 'dev' && !config.custom) {
    config.url = config.url.replace('/api/', '/api-dev/');
  }
  if (config.method == "put" || config.method == "PUT") {
    config.headers['Content-Type'] = "application/json; charset=utf-8";
  }
  if (config.isJson) {
    config.headers['Content-Type'] = "application/json; charset=utf-8";
  }
  /**
     * custom 说明：
     * @params {any} custom 是否开启自定义，默认为 false
     *
     * 传入 1 | true 结果为完全自定义
     * 调用时需要传入完整 url 地址，在请求第三方接口时使用
     *
     * 传入字符，表示取配置的域名进行拼接（对应 config.baseUrl）
     * 适合多域名情况，传入 config 中定义的 key, 比如 'baidu'，运行的结果就会取 config 下对应的 url
     */
  if (!config.custom) {
    config.url = `${_config.baseUrl['base']}${config.url}`; // 默认拼接
  } else {
    // 仅判断 typeof === string 不够健壮，但实现了业务逻辑，后续可优化
    if (typeof config.custom === 'string') {
      config.url = `${_config.baseUrl[config.custom]}${config.url}`; // 多域名
    } else {
      config.url = `${config.url}`; // 完全自定义
    }
  }
  if (isQueue(config.url)) return Promise.reject(new Error('重复请求！已被拦截')); // 拦截重复
  // if (config.loading) Toast.loading({ message: '加载中...' }) // 开启 loading
  // 文件上传
  if (config.upload) {
    config.headers['Content-Type'] = 'multipart/form-data';
    config.onUploadProgress = progressEvent => {
      let persent = progressEvent.loaded / progressEvent.total * 100 | 0; //上传进度百分比
      config === null || config === void 0 || config.callBack(String(persent) + '%');
    };
  }
  // 设置 token
  if (_config.deploy.oauth === 'look-star') {
    const token = _store.default.getters.wechatInfo && _store.default.getters.wechatInfo.token;
    config.headers['Authorization'] = `Bearer ${token}`; // 设置 token // '123' //
  }
  if (config.openid) {
    const openid = _store.default.getters.openid || localStorage.getItem("openid");
    if (config.method === 'get') {
      if (config.params && typeof config.params === 'object') {
        config.params.openid = openid;
      } else {
        config.params = {
          openid
        };
      }
    } else {
      if (config.data && typeof config.data === 'object') {
        config.data.openid = openid;
      } else {
        config.data = {
          openid
        };
      }
    }
  }

  // 转换参数（application/x-www-form-urlencoded 的请求需要转换参数）
  if (config.headers['Content-Type'].startsWith('application/x-www-form-urlencoded')) config.data = _qs.default.stringify(config.data);
  queue.push(config.url); // 添加队列
  return config;
}, error => {
  handleError(error);
  return Promise.reject(error);
});

/* 响应拦截器 */
http.interceptors.response.use(res => {
  // console.log(' --- res --- ', res)
  // 移除队列
  _toast.default.clear();

  // console.log(res,'==> res,=');
  removeQueue(res.config.url);
  const resData = res.data;
  if (res.config.origin) {
    // console.log('origin 钩子', res)
    return Promise.resolve(resData);
  } else {
    // console.log('normal 钩子', res)
    const {
      code,
      msg
    } = resData;
    const {
      data
    } = resData;
    // console.log('信息提示：', code, data, msg)
    if (code && code === 1000) {
      // 1000 请求成功
      return Promise.resolve(data);
    } else {
      const whiteList = [_config.middlePlatform.baseUrl, '/api/monitor', '/api/js-sdk', '/api/platform'];
      const whiteRes = whiteList.some(api => res.config.url.indexOf(api) > -1);
      if (resData !== null && resData !== void 0 && resData.result && resData !== null && resData !== void 0 && resData.score) {
        if (Number(resData === null || resData === void 0 ? void 0 : resData.score) > 0) {
          setTimeout(() => {
            _store.default.commit('SET_INTEGRAL', {
              show: true,
              integral: resData.score
            });
          }, 500);
        }
      }
      if ((resData === null || resData === void 0 ? void 0 : resData.errcode) == "50001") {
        /* if (window.location.href.indexOf("ishomerecommend=true") != -1) {
          alert("首页推来的")
          store.commit("SET_SHOWAUTHPOP", true)
            return Promise.resolve(resData)
        } */

        _store.default.commit("SET_SHOWAUTHPOP", true);
        return Promise.resolve({
          hasErrCode: "50001"
        });
      }
      if (whiteRes) {
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("数据获取失败：" + res.errmsg)
          return Promise.resolve({});
        }

        // 中台接口不返回 code
        // 中台接口只返回 res.data
        return Promise.resolve(res.data);
      } else {
        // 请求失败
        handleError(msg);
        return Promise.reject(msg);
      }
    }
  }
}, error => {
  _toast.default.clear();
  console.log('请求错误: ' + error); // for debug
  // 特殊情况
  const exp = String(error).indexOf('401') !== -1;
  if (exp) {
    localStorage.clear();
    _store.default.dispatch('clearWechatInfo');
    window.location.href = window.location.href;
    return Promise.reject(error);
  }
  handleError(error);
  return Promise.reject(error);
});

/** 是否在队列 */
function isQueue(url) {
  queue.includes(url);
}

/** 移除请求 */
function removeQueue(url) {
  const index = queue.findIndex(item => item === url);
  queue.splice(index, 1);
}
function handleError(err) {
  setTimeout(t => {
    (0, _toast.default)(err.message || err || '未捕获的错误');
  }, 30);
}
var _default = exports.default = http;