"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _picLook = _interopRequireDefault(require("@/components/customPopview/picLook"));
var _default = exports.default = {
  components: {
    picLook: _picLook.default
  },
  props: {
    dataBase: {
      type: Object,
      default: () => {}
    },
    list: {
      type: [Object, Array],
      default: () => {}
    }
  },
  data() {
    return {
      isBeforeSendMsg: false,
      base64QRCode: ""
    };
  },
  created() {},
  mounted() {
    console.log("dataBase.link", this.dataBase.link);
    this.$nextTick(() => {
      this.getBase64QRCode();
    });
    this.isBeforeSendMsg = this.isSendMsg();
  },
  methods: {
    scalePicHandle() {
      this.$refs.scalePic.open();
    },
    getBase64QRCode() {
      this.base64QRCode = this.$refs.QRCode.qrious.canvas.toDataURL();
    },
    isSendMsg() {
      return this.list.some(item => item.action_type == 1 && item.content_type == 3);
    }
  }
};