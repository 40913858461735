"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "veevaAuth",
    on: {
      touchmove: function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c("transition", {
    attrs: {
      name: "popFull",
      appear: ""
    }
  }, [_vm.show ? _c("div", {
    staticClass: "popFull",
    on: {
      click: _vm.maskHandle
    }
  }, [_c("div", {
    staticClass: "veevaAuthBox"
  }, [_c("div", {
    staticClass: "veevaAuthBoxTop"
  }, [!_vm.veevaPopInfo.send_msg ? _c("div", {
    staticClass: "noSendMsg"
  }, [_c("div", {
    staticClass: "veevaAuthBoxTopLine veevaAuthBoxTopLineTop"
  }, [_vm._v("您已获得")]), _c("div", {
    staticClass: "veevaAuthBoxTopLine"
  }, [_vm._v("医疗卫生专业人士认证")])]) : _c("div", {
    staticClass: "sendMsg"
  }, [_c("div", {
    staticClass: "veevaAuthBoxTopLine veevaAuthBoxTopLineTop"
  }, [_vm._v("还差一步就可以参加拜耳学术")]), _c("div", {
    staticClass: "veevaAuthBoxTopLine"
  }, [_vm._v("会议了，快来扫码绑定吧！")])]), _c("img", {
    staticClass: "veevaPopIcon",
    attrs: {
      src: require("img/home/veevaPopIcon.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "veevaAuthBoxTopClose",
    on: {
      click: _vm.popFullClose
    }
  }, [_c("svg", {
    staticClass: "icon",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      t: "1693908739866",
      viewBox: "0 0 1024 1024",
      version: "1.1",
      xmlns: "http://www.w3.org/2000/svg",
      "p-id": "4232",
      width: "200",
      height: "200"
    }
  }, [_c("path", {
    attrs: {
      d: "M556.8 512L832 236.8c12.8-12.8 12.8-32 0-44.8-12.8-12.8-32-12.8-44.8 0L512 467.2l-275.2-277.333333c-12.8-12.8-32-12.8-44.8 0-12.8 12.8-12.8 32 0 44.8l275.2 277.333333-277.333333 275.2c-12.8 12.8-12.8 32 0 44.8 6.4 6.4 14.933333 8.533333 23.466666 8.533333s17.066667-2.133333 23.466667-8.533333L512 556.8 787.2 832c6.4 6.4 14.933333 8.533333 23.466667 8.533333s17.066667-2.133333 23.466666-8.533333c12.8-12.8 12.8-32 0-44.8L556.8 512z",
      fill: "#ffffff",
      "p-id": "4233"
    }
  })])])]), _c("div", {
    staticClass: "veevaAuthBoxBottom"
  }, [!_vm.veevaPopInfo.send_msg ? _c("div", {
    staticClass: "veevaAuthBoxBottomWords"
  }, [_vm._v("还差一步就可以参加拜耳学术会议了， 快来扫码绑定吧！")]) : _vm._e(), _c("div", {
    staticClass: "veevaAuthBoxBottomCode"
  }, [_c("img", {
    staticClass: "veevaAuthBoxBottomCodeImg",
    attrs: {
      src: _vm.base64QRCode,
      alt: ""
    }
  })])]), _c("div", {
    staticClass: "btnGroup"
  }, [_c("div", {
    staticClass: "veevaPassContentListRightBtn",
    on: {
      click: _vm.popFullClose
    }
  }, [_vm._v("稍后绑定")]), _c("div", {
    staticClass: "veevaPassContentListLeftBtn",
    on: {
      click: _vm.lookBindStepPath
    }
  }, [_vm._v("查看绑定步骤")])])])]) : _vm._e()]), _c("div", {
    staticClass: "outScreen"
  }, [_c("qriously", {
    ref: "QRCode",
    attrs: {
      value: _vm.veevaPopInfo.link,
      foreground: "#000000",
      size: 200,
      backgroundAlpha: 0
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;