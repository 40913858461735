"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default
  },
  data() {
    return {
      questionList: []
    };
  },
  created() {
    this.$store.commit("SET_LOADINGPOP", true);
    this.getList();
    this.isAddScore();
    this.initWeChatShare();
  },
  mounted() {
    setTimeout(() => {}, 500);
  },
  methods: {
    async initWeChatShare() {
      this.weChatShare.title = `拜耳影像学苑 | 我的问卷`;
      this.weChatShare.desc = `拜耳影像学苑 | 我的问卷`;
      this.weChatShare.imgUrl = `${this.$publicPath}/shareIcon.png`;
      this.initWechat();
    },
    async getList() {
      let res = await this.$api.getQuestionList({
        current: 1,
        pageSize: 999999
      });
      this.questionList = res.data;
      this.$store.commit("SET_LOADINGPOP", false);
    },
    async isAddScore() {
      try {
        var _res$ids;
        let res = await this.$api.isAddScore({
          action_type: "QUESTION_ANSWER_COMPLETE"
        });
        if (res.result && res !== null && res !== void 0 && (_res$ids = res.ids) !== null && _res$ids !== void 0 && _res$ids.length) {
          var _res$ids2;
          await this.$api.putAddScore({
            ids: res === null || res === void 0 || (_res$ids2 = res.ids) === null || _res$ids2 === void 0 ? void 0 : _res$ids2.join(",")
          });
        }
      } catch (error) {}
    }
  }
});