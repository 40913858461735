"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/popup/style");
var _popup = _interopRequireDefault(require("vant/es/popup"));
var _index = require("@/utils/index.js");
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _list = _interopRequireDefault(require("@/components/course/list.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    vanPopup: _popup.default,
    listItem: _list.default
  },
  data() {
    return {
      showPowerBoxTip: false,
      current: 1,
      pageSize: 10,
      faverList: [],
      isReachBottom: false,
      loadFinal: false,
      noThenData: false
    };
  },
  watch: {},
  filter: {},
  created() {
    this.initData();
    this.initReachBottom();
  },
  destroyed() {
    window.removeEventListener("reachBottom", this.reachBottomHandle);
  },
  mounted() {},
  computed: {},
  methods: {
    /* 分页 start */
    initReachBottom() {
      window.addEventListener("reachBottom", this.reachBottomHandle);
    },
    reachBottomHandle(evt) {
      const self = this;
      if (evt.detail.path != "/personal/collection") {
        return;
      }
      if (self.isReachBottom) {
        return;
      }
      self.isReachBottom = true;
      this.initData();
    },
    /* 分页 end */
    async initData() {
      this.loadFinal = false;
      try {
        let params = {
          current: this.current,
          pageSize: this.pageSize
        };
        let res = await this.$api.getMyFaver(params);
        this.isReachBottom = false;
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getMyFaver请求失败：" + res.errmsg)
          return;
        }
        const finalData = (0, _index.limitData)({
          interfaceData: res,
          baseData: this.faverList,
          limit: params
        });
        this.faverList = finalData.baseData;
        finalData.currentAdd && this.current++ && (this.noThenData = false);
        !finalData.currentAdd && (this.noThenData = true);
        this.loadFinal = true;
      } catch (error) {
        console.log(error, '==>> error');
      }
    }
  }
});