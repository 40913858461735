"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  props: {
    data: null,
    close: Function,
    callback: Function,
    confirm: Function
  },
  data() {
    return {};
  },
  created() {
    this.init();
  },
  mounted() {},
  computed: {},
  filters: {},
  methods: {}
};