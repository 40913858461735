"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _aiLoadingVue = _interopRequireDefault(require("./ai-loading-vue"));
const AiLoading = _vue.default.extend(_aiLoadingVue.default);
const instance = new AiLoading().$mount();
_aiLoadingVue.default.install = {
  show(option) {
    /* document.body.appendChild(instance.$el)
    Vue.nextTick(() => {
      if (option) {
        const { type, color, text, styles } = option
        if (text) instance.text = text
        if (type) instance.type = type
        if (color) instance.color = color
        if (styles) instance.styles = styles
      }
      instance.show = true
    }) */
  },
  hide() {
    // instance.show = false
    // document.body.removeChild(instance.$el)
  }
};
var _default = exports.default = _aiLoadingVue.default;