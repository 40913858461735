"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _default = exports.default = [{
  path: '/activity/detailenter',
  name: 'detailBefore',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/activity/detailBefore'))),
  meta: {
    title: '详情',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜活动详情',
      desc: '活动详情'
    },
    checkUser: true,
    hideApproval: true
  }
}, {
  path: '/activity/activeShowOncePoster',
  name: 'activeShowOncePoster',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/activity/activeShowOncePoster'))),
  meta: {
    title: '活动',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜活动',
      desc: '活动'
    },
    checkUser: true,
    hideApproval: true
  }
}, {
  path: '/activity/activeShowOnceForm',
  name: 'activeShowOnceForm',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/activity/activeShowOnceForm'))),
  meta: {
    title: '活动',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜活动',
      desc: '活动'
    },
    checkUser: true,
    hideApproval: true
  }
}, {
  path: '/activity/detail',
  name: 'detail',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/activity/detail'))),
  meta: {
    title: '所有内容',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜活动所有内容',
      desc: '活动所有内容'
    },
    reachBottom: true,
    checkUser: true,
    hideApproval: true
  }
}, {
  path: '/activity/secondList',
  name: 'secondList',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/activity/secondList'))),
  meta: {
    title: '模块列表',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜热门活动',
      desc: ''
    },
    checkUser: true,
    reachBottom: true
  }
}, {
  path: '/activity/richlist',
  name: 'activityRichList',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/activity/richText/list'))),
  meta: {
    title: '所有内容',
    keepAlive: true,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜活动所有内容',
      desc: '活动所有内容'
    },
    reachBottom: true,
    hideApproval: true,
    checkUser: true
  }
}, {
  path: '/activity/richdetail',
  name: 'activityRichDetail',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/activity/richText/detail'))),
  meta: {
    title: '所有内容',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜活动所有内容',
      desc: '活动所有内容'
    },
    reachBottom: true,
    hideApproval: true,
    checkUser: true
  }
},
// 年度账单h5 task retrospect
{
  path: '/annual/task',
  name: 'task',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/annual/task'))),
  meta: {
    title: '开启全新「影像」人生',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '开启全新「影像」人生',
      desc: '开启全新「影像」人生'
    },
    reachBottom: true,
    hideApproval: true,
    checkUser: false
  }
}, {
  path: '/annual/retrospect',
  name: 'retrospect',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/annual/retrospect'))),
  meta: {
    title: '开启全新「影像」人生',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '开启全新「影像」人生',
      desc: '开启全新「影像」人生'
    },
    reachBottom: true,
    hideApproval: true,
    checkUser: true
  }
}];