"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _vueCookies = _interopRequireDefault(require("vue-cookies"));
var _aui = _interopRequireDefault(require("aui"));
var _api = _interopRequireDefault(require("api"));
var _config = require("@/config");
var _mixins = _interopRequireDefault(require("@/mixins"));
var _vconsole = _interopRequireDefault(require("vconsole"));
const install = Vue => {
  /* cookies */
  Vue.use(_vueCookies.default);

  /* 自定义组件（后续移至 npm 包管理） */
  Vue.use(_aui.default);

  /* 全局 API */
  Vue.use(_api.default);

  /* 域名地址 */
  Vue.prototype.$domain = _config.baseUrl.base;
  Vue.prototype.$publicPath = `${_config.baseUrl.base}/${_config.deploy.path}`;
  if (!_config.deploy.path) Vue.prototype.$publicPath = `${_config.baseUrl.base}`;

  /* 全局混入 */
  window.$initPage = _mixins.default;

  /* 自定义计算单位 */
  Vue.prototype.$rem = val => `${val / 8.28}vw`;

  /* 生成session_num */
  Vue.prototype.$GetSessionNum = () => {
    var str = "abcdefghijklmnopqrstuvwxyz0123456789";
    var tmp = [];
    var random;
    for (var i = 0; i < 8; i++) {
      random = Math.floor(Math.random() * str.length);
      if (tmp.indexOf(str[random]) === -1) {
        tmp.push(str[random]);
      } else {
        i--;
      }
    }
    return String(Date.now()) + tmp.join("");
  };

  /* 调试插件 */
  if (_config.debug) new _vconsole.default();
};
var _default = exports.default = {
  install
};