"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = _interopRequireDefault(require("utils/http"));
var _default = exports.default = {
  /* 学术助手/联系我们 */
  getAssistantsInfo(params) {
    return (0, _http.default)({
      url: `/api/platform/assistants/info`,
      method: 'get'
    });
  },
  getShowPopDaysInfo(params) {
    return (0, _http.default)({
      url: `/api/platform/veeva/info`,
      method: 'get',
      params
    });
  },
  /* 课程分类
    type
    1 ： 课程/病例
    0 全部
  */
  getCourseCate(type) {
    return (0, _http.default)({
      url: `/api/platform/category/tree/${type}`,
      method: 'get'
    });
  },
  /* 表单-评论-点赞/取消点赞 */
  setFormZanLog(params) {
    return (0, _http.default)({
      url: `/api/platform/form/form-question-answer-zan-log`,
      method: 'post',
      data: params
    });
  },
  /* 表单-评论-log */
  getOtherComments(params) {
    return (0, _http.default)({
      url: `/api/platform/form/form-question-answer-comment`,
      method: 'get',
      params
    });
  },
  /* 收集积分-行为 */
  setScoreLog(params) {
    return (0, _http.default)({
      url: `/api/platform/score-log`,
      method: 'post',
      data: params
    });
  },
  /* 单独传本次学习时长 */
  setLearnTimeLog(params) {
    return (0, _http.default)({
      url: `/api/platform/learnlog/create`,
      method: 'post',
      data: params
    });
  },
  /* 监测是否被加积分 */
  isAddScore(params) {
    return (0, _http.default)({
      url: `/api/platform/score-log/scorelog`,
      method: 'get',
      params
    });
  },
  /* 通知后台已弹出加积分的行为 传单个【已暂停使用】 */
  /* putAddScore(id,params) {
    return http({
      url: `/api/platform/score-log/scorelog/${id}`,
      method: 'put',
      data: params
    })
  }, */
  /* 通知后台已弹出加积分的行为 传多id个【使用中】 */
  putAddScore(params) {
    return (0, _http.default)({
      url: `/api/platform/score-log/up-tip-status`,
      method: 'post',
      data: params
    });
  },
  /* 判断是否关注服务号 */
  isWatchService(openid) {
    return (0, _http.default)({
      url: `/api/platform/token?openid=${openid}`,
      method: 'get'
    });
  },
  /* 是否有未弹窗的证书、勋章（个人中心小红点） */
  claimStatus() {
    return (0, _http.default)({
      url: `/api/platform/content/claim/status`,
      method: 'get'
    });
  },
  /* 通过省份查询医院 */
  getHospital(params) {
    return (0, _http.default)({
      url: `/api/platform/hospital/get-hospital`,
      method: 'get',
      params
    });
  },
  /* 首页-发放会议学术能量 */
  getmeetingview() {
    return (0, _http.default)({
      url: `/api/platform/score-log/meetingview`,
      method: 'post',
      data: {
        action_type: "MEETING_VIEW"
      }
    });
  },
  /* 提升-airep状态监测 */
  memberAiRep() {
    return (0, _http.default)({
      url: `/api/platform/member/ai-rep/check`,
      method: 'get'
    });
  },
  imageupload(params) {
    return (0, _http.default)({
      url: `/api/platform/imageupload`,
      method: 'post',
      upload: 1,
      data: params
    });
  },
  imageuploadBase64(params, callBack) {
    return (0, _http.default)({
      url: `/api/platform/imageupload-base64`,
      method: 'post',
      upload: 1,
      data: params,
      callBack: callBack
    });
  }
};