"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  props: {
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    },
    /* 
      展示形式
        words： 文字提示
        slot  插槽
    */
    showType: {
      type: String,
      default: "words"
    },
    /* 提示文字 */
    tipWords: {
      type: String,
      default: "请传入提示文字"
    }
  },
  data() {
    return {
      showPop: false,
      outCallBack: null,
      content: `<div class="item card-rows">
                    <h4>   隐私声明</h4>

                    <p>
                        本[企业公众号]（以下称“公众号”）由[拜耳医药有限公司]提供（以下称“我们”）。
                    </p>
                    <p>
                        拜耳深知个人数据对您而言十分重要，并将尽最大努力保护您的个人数据。我们致力于维护您对我们的信任。我们将本着合法、正当、必要和诚信的原则，严格遵守适用的中国法律法规和标准。我们将明确告知您我们收集和使用您的个人数据的规则、目的、方法和范围并获取您相应的同意。
                        <br />
                        <br />
                        以下我们将向您提供有关您在使用我们的公众号时，我们如何处理您的个人数据方面的信息。
                    </p>
                    <p>
                        具体内容包括：
                    </p>
                    <p style="text-indent:2em">
                        一、	我们如何收集和使用信息
                    </p>
                    <p style="text-indent:2em">
                        二、我们如何对外提供信息
                    </p>
                    <p style="text-indent:2em">
                        三、我们如何保护和存储个人信息
                    </p>
                    <p style="text-indent:2em">
                        四、我们如何处理未成年人的个人信息
                    </p>
                    <p style="text-indent:2em">
                        五、有关您所享有的个人信息权利
                    </p>
                    <p style="text-indent:2em">
                        六、无需征得您同意处理个人信息的情形
                    </p>
                    <p style="text-indent:2em">
                        六、无需征得您同意处理个人信息的情形
                    </p>
                    <p style="text-indent:2em">
                        七、联系方式
                    </p>
                    <p style="text-indent:2em">
                        八、隐私声明的修订
                    </p>
                    <p class="t2" style="color:#365f91">
                        一、我们如何收集和使用信息
                    </p>
                    <p>
                        在您使用公众号过程中，我们会基于如下不同的目的，遵循最小必要原则，收集您在使用服务时或为了使用服务，您主动提供或者因为使用服务而产生的信息：
                    </p>
                    <p class="t2" style="color:#365f91">
                        （一）您使用公众号时我们自动记录的信息
                    </p>
                    <p>
                        为了能够向您提供我们的公众号服务，每次在您使用我们公众号时，我们会自动收集您的以下数据：
                    </p>
                    <p style="text-indent:2em">
                        •	IP 地址
                    </p>
                    <p style="text-indent:2em">
                        •	访问的日期和时间
                    </p>
                    <p style="text-indent:2em">
                        •	操作系统的信息
                    </p>
                    <p style="text-indent:2em">
                        •	访问状态/HTTP状态代码
                    </p>
                    <p style="text-indent:2em">
                        •	语言设置、公众号版本
                    </p>
                    <p style="text-indent:2em">
                        •	登录信息（用户名和密码）
                    </p>
                    <p>
                        另外，为了保护我们的合法利益并遵守中国有关网络日志存储方面的法律法规（根据法规要求，日志将至少保存180天），我们将在限定的时间内存储该数据以便能够在1）发生未经授权访问或企图访问我们的服务器或2）相关政府部门要求时能够确认该数据。 一旦存储期限届满，我们会立即删除与您相关的个人信息。
                    </p>
                    <p class="t2" style="color:#365f91">
                        （二）访问我们的企业公众号
                    </p>
                    <p>
                        当您访问我们的企业公众号时，公众号会收集您在使用服务时主动提供或因为使用服务而产生的信息：
                    </p>
                    <p style="text-indent:2em">
                        •	您的微信昵称和头像
                    </p>
                    <p style="text-indent:2em">
                        •	您与企业公众号的互动（例如：浏览、评论和点赞等）
                    </p>
                    <p>
                        此外，企业公众平台也将收集为提供企业公众号服务所必需的信息（请参照腾讯企业微信保护指引：
                        <a href="https://work.weixin.qq.com/privacy">https://work.weixin.qq.com/privacy</a>）
                    </p>


                    <p class="t2" style="color:#365f91">
                        二、我们如何对外提供信息
                    </p>
                    <p class="t2" style="color:#365f91">
                        （一）向跨境传输和第三方分享
                    </p>

                    <p>
                        我们所收集的所有信息将会存储在位于中国境内的服务器，不会传输到境外。也不会分享给第三方公司或服务商。
                    </p>
                    <p class="t2" style="color:#365f91">
                        （二）转移
                    </p>
                    <p>
                        在发生合并、分立、解散、收购、资产转让、资产重组或破产清算等情形时，如涉及到向第三方转移您的个人信息，我们会向您告知接收方的名称或者姓名和联系方式，并要求新的持有您个人信息的公司、组织继续受本政策的约束。
                    </p>
                    <p class="t2" style="color:#365f91">
                        （三）公开披露
                    </p>
                    <p>
                        我们不会对外公开披露您的个人信息；如必须公开披露时，我们会向您告知公开披露的目的、披露信息的类型及可能涉及的敏感个人信息，并征得您的单独同意，法律法规另有规定的除外。
                    </p>
                    <p class="t2" style="color:#365f91">
                        三、我们如何保护和存储您的个人数据
                    </p>
                    <p>
                        我们已按照法律、行政法规要求，采取必要措施（包括但不限于：制定内部管理制和操作规程、分类管理个人信息、合理确定个人信息处理的操作权限，并定期对从业人员进行安全教育和培训采取加密、去标识化等安全技术措施、个人信息安全事件应急预案等）保护您的个人数据并防止未经授权访问、公开披露、使用、篡改、损毁或丢失数据，并定期开展合规审计、按照规定开展个人信息安全影响评估等。此外，您的信息将会存储于代表本公司并对公司负责的数据处理器或服务器上。
                        <br />
                        我们将采取一切合理措施确保我们不收集无关的个人数据。所收集的个人信息将根据公司合理的商业服务目的之所必须和法律法规或监管规定的期限保存您的个人数据，到期后，除非获得您的另行同意或适用法律的允许，我们将会删除或匿名化处理所收集的个人信息。
                    </p>
                    <p class="t2" style="color:#365f91">
                        四、我们如何处理未成年人的个人数据
                    </p>
                    <p>
                        我们非常重视对未成年人的个人信息保护。根据相关法律法规的规定，若您是不满十四周岁的未成年人，建议您的父母或监护人仔细阅读本政策并在征得您的父母或监护人的同意后提交您的个人信息，同时建议您在您的父母或监护人的指导下使用我们的服务。如您的父母或监护人不同意您提交您的个人信息或不同意您使用本公众号相关服务，请您立即终止提交信息或终止使用我们的服务，并尽快将此情况通知我们，以便我们采取有效的措施。
                        <br />若您是不满十四周岁的未成年人，对在取得未成年人的父母或其他监护人的同意后收集的您的个人数据，除非法律允许、您的父母同意、或出于保护您所需要，我们将不会使用或披露您的个人数据。若您是不满十四周岁的未成年人，如果我们获知在未取得您的父母或其他监护人的同意的情况下收集了您的个人数据，我们将尽快删除您的个人数据。
                        <br />若您是未成年人的父母或监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过本政策第七条所述的联系方式与我们联系。
                    </p>
                    <p class="t2" style="color:#365f91">
                        五、有关您所享有的权利方面的信息
                    </p>
                    <p>
                        1. 根据《中华人民共和国个人信息保护法》等相关适用的数据隐私法律规定，原则上您享有以下权利：
                    </p>
                    <p style="text-indent:2em">•	对我们存储的您的个人数据享有的知情权；</p>
                    <p style="text-indent:2em">•	要求查询、复制和转移您个人信息的权利：</p>
                    <p style="text-indent:2em">•	要求更正、补充或限制处理您个人数据的权利：</p>
                    <p style="text-indent:2em">•	在以下情况要求删除您个人数据的权利：</p>
                    <p style="text-indent:4em"> o	如果我们处理您的个人数据违反了法律；</p>
                    <p style="text-indent:4em"> o	如果我们未经您同意收集和使用您的个人数据；</p>
                    <p style="text-indent:4em"> o	如果我们处理您的个人数据违反您与我们之间的协议；</p>
                    <p style="text-indent:4em"> o	如果我们不再向您提供产品或服务，或者保存期限届满。</p>
                    <p style="text-indent:4em"> o	您撤回了对处理您个人数据的同意</p>
                    <p style="text-indent:4em"> o	根据法律法规要求，在以下情况，我们无法响应您的请求：</p>
                    <p style="text-indent:4em"> o	法律、行政法规规定的其他情形</p>
                    <p>
                        当您或我们协助您删除相关信息后，由于法律法规规定的存储期限未届满，或者删除从技术上难以实现的，我们可能无法立即从系统中删除相应的信息，此时我们将对您的个人信息停止除存储和采取必要的安全保护措施之外的处理。<br />
                    </p>
                    <p>
                        2.	您可在以后任何时间撤回您对于收集、处理和使用您个人数据的同意。但是，您撤回同意的决定不影响同意被撤回前关于您个人数据的收集、使用、处理和存储。 您可以停止使用部分服务和功能。如果您不希望我们按照本声明第一条第（四）款的内容向您提供个性化服务，您可通过我们提示的方式进行关闭。关闭后您仍然可能会看到营销活动信息、商业性电子信息或广告内容，但相关性会降低。为了您的账户和个人信息的安全，您向我们提出查阅、复制、更正、补充、删除、转移等权利请求时，我们可能会要求先验证您的身份，需要您提供您的姓名、身份证件号码、联系方式以及具体请求内容等材料，然后我们才能处理您的请求。
                    </p>
                    <p> 3.	根据法律法规要求，在以下情况，我们无法响应您的上述请求：</p>
                    <p style="text-indent:2em">
                        a)	事关国家安全
                    </p>
                    <p style="text-indent:2em">
                        b)	事关公共安全、公共健康、重大公共利益
                    </p>
                    <p style="text-indent:2em">
                        c)	事关犯罪调查、控诉和审判
                    </p>
                    <p style="text-indent:2em">
                        d)	能够证明您滥用您的权利
                    </p>
                    <p style="text-indent:2em">  e)	响应您的请求会严重损害您或其他个人或组织的合法权利。</p>

                    <p>4.	如果您想行使您的上述权利，请通过第七条所述联系方式提出请求  ( 联系方式)。</p>
                    <p class="t2" style="color:#365f91">
                        六、无需征得您的同意处理个人信息的情形
                    </p>
                    <p> 根据相关法律法规，符合以下任一情形，我们处理您的个人信息无需征得您的同意：</p>
                    <p style="text-indent:2em">
                        1. 为订立、履行您与我们之间的合同所必需；
                    </p>
                    <p style="text-indent:2em">
                        2. 我们履行法定职责或者法定义务所必需；
                    </p>
                    <p style="text-indent:2em">
                        3. 为应对突发公共卫生事件，或者紧急情况下为保护您或其他自然人的生命健康和财产安全所必需的；
                    </p>
                    <p style="text-indent:2em">
                        4. 为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理您的个人信息；
                    </p>
                    <p style="text-indent:2em">
                        5. 在合理的范围内处理您自行公开或者其他已经合法公开的个人信息；
                    </p>
                    <p style="text-indent:2em">
                        6. 法律法规规定的其他无需征得您同意的情形。
                    </p>
                    <p class="t2" style="color:#365f91">七、联系方式</p>
                    <p> 如您有关于隐私方面的任何问题，如对本声明存在任何疑问或任何相关的投诉或意见，请发电子邮件至[DP.China@bayer.com] 或通过以下方式联系公司的隐私保护经理， 我们将在15个工作日内予以响应。</p>
                    <p class="t2" style="color:#365f91"> 八、隐私声明的修订</p>
                    <p>我们可能会不时更新我们的隐私声明。我们隐私声明的更新将发布在我们的公众号上。任何修订一经发布，立即生效。因此，我们建议您定期访问我们的公众号以了解可能的更新。</p>
                    <p>
                        颁布发布日期：2018年10月22日
                        <br />
                        <br />
                        最后更新日期：2021年11月1日
                    </p>

                </div>`
    };
  },
  watch: {},
  filter: {},
  created() {},
  mounted() {},
  computed: {},
  methods: {
    toRegister() {
      this.outCallBack({
        next: '/register'
      });
      this.close();
    },
    cancleRegister() {
      this.outCallBack({
        next: "from"
      });
      this.close();
    },
    open(callBack) {
      this.outCallBack = callBack;
      this.showPop = true;
    },
    close() {
      this.popFullClose();
    },
    maskHandle() {
      if (this.mask) {
        this.popFullClose();
      }
    },
    popFullClose() {
      this.showPop = false;
      this.$emit("cancle");
    }
  }
};