"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("vant/es/popup/style");
var _popup = _interopRequireDefault(require("vant/es/popup"));
require("vant/es/swipe-item/style");
var _swipeItem = _interopRequireDefault(require("vant/es/swipe-item"));
require("vant/es/swipe/style");
var _swipe = _interopRequireDefault(require("vant/es/swipe"));
var _index = require("@/utils/index.js");
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _customSelect = _interopRequireDefault(require("@/components/common/customSelect.vue"));
var _list = _interopRequireDefault(require("@/components/course/list.vue"));
var _monitor = require("@/utils/monitor");
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    vanSwipe: _swipe.default,
    vanSwipeItem: _swipeItem.default,
    courseList: _list.default,
    vanPopup: _popup.default,
    customSelect: _customSelect.default
  },
  data() {
    return {
      need_veeva: "",
      noThenData: false,
      searchWords: "",
      isReachBottom: false,
      isFirstRequest: true,
      loadFinal: false,
      urlQuery: {},
      courseClassUseType: null,
      courseClassSecUseType: null,
      showDrawer: true,
      courseClass: [],
      current: 1,
      pageSize: 20,
      courseList: [],
      category_id: null,
      courseLessonTagList: [],
      courseLessonTagListIdArr: [],
      show_type: null,
      sort_type: null,
      isActivity: false,
      formContent: {
        check: false,
        value: null,
        defaultWord: "课程形式",
        selectData: [
        /* {
          key: "课程形式"
        }, */
        {
          key: "视频",
          value: 1
        }, {
          key: "图文",
          value: 2
        }, {
          key: "病例",
          value: 3
        }, {
          key: "全部"
        }]
      },
      roundSort: {
        check: false,
        value: null,
        defaultWord: "最新",
        selectData: [
        // {
        //   key: "综合排序"
        // },
        {
          key: "最新",
          value: 0
        }, {
          key: "最热",
          value: 1
        }]
      },
      showTags: false,
      isLoadAllData: false
    };
  },
  watch: {
    courseClassUse(newValue) {
      var _this$courseClass$thi, _this$courseClass$thi5, _this$courseClass$thi6, _this$courseClass$thi7;
      if ((_this$courseClass$thi = this.courseClass[this.courseClassUse[0]]) !== null && _this$courseClass$thi !== void 0 && (_this$courseClass$thi = _this$courseClass$thi.type) !== null && _this$courseClass$thi !== void 0 && _this$courseClass$thi.includes(3)) {
        var _this$courseClass$thi2, _this$courseClass$thi3, _this$courseClass$thi4;
        this.isActivity = true;
        if (!((_this$courseClass$thi2 = this.courseClass[this.courseClassUse[0]]) !== null && _this$courseClass$thi2 !== void 0 && (_this$courseClass$thi2 = _this$courseClass$thi2.children) !== null && _this$courseClass$thi2 !== void 0 && _this$courseClass$thi2.length)) {
          this.category_id = this.courseClass[this.courseClassUse[0]].id;
        }
        if ((_this$courseClass$thi3 = this.courseClass[this.courseClassUse[0]]) !== null && _this$courseClass$thi3 !== void 0 && (_this$courseClass$thi3 = _this$courseClass$thi3.children) !== null && _this$courseClass$thi3 !== void 0 && _this$courseClass$thi3.length && this.courseClassUse[1] == null) {
          return;
        }
        if (this.courseClassUse[1] == -1) {
          this.category_id = this.courseClass[this.courseClassUse[0]].id;
          return;
        }
        this.category_id = (_this$courseClass$thi4 = this.courseClass[this.courseClassUse[0]]) === null || _this$courseClass$thi4 === void 0 ? void 0 : _this$courseClass$thi4.children[this.courseClassUse[1]].id;

        /* this.current = 1
        this.pageSize = 10
        this.courseList = []
        this.courseLessonTagListIdArr = []
        this.courseLessonTagList = []
        this.getActivity() */

        return;
      }
      this.isActivity = false;
      if (!((_this$courseClass$thi5 = this.courseClass[this.courseClassUse[0]]) !== null && _this$courseClass$thi5 !== void 0 && (_this$courseClass$thi5 = _this$courseClass$thi5.children) !== null && _this$courseClass$thi5 !== void 0 && _this$courseClass$thi5.length)) {
        this.category_id = this.courseClass[this.courseClassUse[0]].id;
        return;
      }
      if ((_this$courseClass$thi6 = this.courseClass[this.courseClassUse[0]]) !== null && _this$courseClass$thi6 !== void 0 && (_this$courseClass$thi6 = _this$courseClass$thi6.children) !== null && _this$courseClass$thi6 !== void 0 && _this$courseClass$thi6.length && this.courseClassUse[1] == null) {
        return;
      }
      if (this.courseClassUse[1] == -1) {
        this.category_id = this.courseClass[this.courseClassUse[0]].id;
        return;
      }
      this.category_id = (_this$courseClass$thi7 = this.courseClass[this.courseClassUse[0]]) === null || _this$courseClass$thi7 === void 0 ? void 0 : _this$courseClass$thi7.children[this.courseClassUse[1]].id;
      // this.category_id = this.courseClassUse[1]
      // this.getData()
    },
    category_id() {
      this.formContent.value = null;
      this.roundSort.value = null;
      this.resetData();
    }
  },
  filter: {},
  created() {
    this.urlQuery = this.$route.query;
    this.category_id = this.urlQuery.id;
    // this.$store.commit("SET_LOADINGPOP", true)

    this.initData();
    this.initReachBottom();
  },
  mounted() {},
  computed: {
    courseClassUse() {
      return [this.courseClassUseType, this.courseClassSecUseType];
    }
    /* courseClass(){
      return this.$store.getters.courseClass
    },
    courseClassIdArr(){
      return this.$store.getters.courseClassIdArr
    } */
  },
  destroyed() {
    window.removeEventListener("reachBottom", this.reachBottomHandle);
  },
  methods: {
    chooseNeedVeevaTags() {
      if (!this.loadFinal) return;
      this.need_veeva = this.need_veeva ? '' : 1;
      this.clearList(false);
    },
    /* 级别为第二级，需要清除列表数据 标签数据 下拉筛选 */
    searchHandle() {
      this.roundSort.value = null;
      this.formContent.value = null;
      this.show_type = null;
      this.sort_type = null;
      this.resetData();
    },
    reachBottomHandle(evt) {
      const self = this;
      if (evt.detail.path != "/allcontent") {
        return;
      }
      if (self.isReachBottom) {
        return;
      }
      if (self.isLoadAllData) {
        return;
      }
      self.isReachBottom = true;

      /* if (self.isActivity) {
        self.getActivity()
        return
      } */
      self.getAllContentTag();
      self.getAllContent();
    },
    initReachBottom() {
      const self = this;
      window.addEventListener("reachBottom", self.reachBottomHandle);
    },
    async getActivity() {
      try {
        let params = {
          current: this.current,
          pageSize: this.pageSize,
          category_id: this.category_id
        };
        this.searchWords && (params["filter[name]"] = this.searchWords);
        let res = await this.$api.getCampainList(params);
        this.isReachBottom = false;
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCampainList" + res.errmsg)
          return;
        }
        const finalData = (0, _index.limitData)({
          interfaceData: res,
          baseData: this.courseList,
          limit: params
        });
        finalData.currentAdd && this.current++ && (this.noThenData = false);
        this.courseList = finalData.baseData;
        !finalData.currentAdd && (this.noThenData = true);
        this.showDrawer = false;
      } catch (error) {}
    },
    /* 此处清除了 列表数据 标签数据 */
    resetData() {
      // this.$store.commit("SET_LOADINGPOP", true)
      this.current = 1;
      this.pageSize = 20;
      this.courseList = [];
      this.noThenData = false;
      this.isReachBottom = false;
      this.isLoadAllData = false;
      this.need_veeva = "";
      this.courseLessonTagListIdArr = [];
      this.courseLessonTagList = [];
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          searchWords: this.searchWords
        }
      });
      let params = {};
      if (this.courseLessonTagListIdArr.length) {
        params["tag_ids"] = this.courseLessonTagListIdArr.join(",");
      }
      /* 关键字搜索 */
      this.searchWords && (params["name"] = this.searchWords);
      /* 0 最新 1 最热 */
      this.sort_type != null && (params["sort_type"] = this.sort_type);
      /* 1 视频 2 图文 3案列 */
      this.show_type != null && (params["show_type"] = this.show_type);
      let moniStr = `${document.title}:筛选-`;
      for (const key in params) {
        moniStr += `${key}=${params[key]}-`;
      }
      moniStr += `category_id=${this.category_id}`;
      (0, _monitor.monitorClick)(moniStr);

      /* if (this.isActivity) {
        this.getActivity()
        return
      } */

      /* 目前标签是跟着分页走的，加载分页数据的时候，要把标签也继续加载 */
      this.getAllContentTag();
      this.getAllContent();
    },
    tagHandle(im) {
      if (!this.loadFinal) return;
      im.check = !im.check;
      const idIndex = this.courseLessonTagListIdArr.indexOf(im.id);
      idIndex < 0 ? this.courseLessonTagListIdArr.push(im.id) : this.courseLessonTagListIdArr.splice(idIndex, 1);

      // dosth 触发获取列表的接口
      /* 清除数据 */
      this.clearList(false);
    },
    clearList(clearTag) {
      this.current = 1;
      this.pageSize = 20;
      this.courseList = [];
      this.noThenData = false;
      this.isReachBottom = false;
      this.isLoadAllData = false;
      /* 重新获取列表数据 */
      this.getAllContent();
    },
    initData() {
      var _this$urlQuery;
      /* 获取分类 */

      this.searchWords = (_this$urlQuery = this.urlQuery) === null || _this$urlQuery === void 0 ? void 0 : _this$urlQuery.searchWords;
      if (this.searchWords) this.showDrawer = false;
      this.getCourseCate();
    },
    /* 获取标签 */
    async getAllContentTag() {
      this.showTags = false;
      try {
        let params = {
          current: this.current,
          pageSize: this.pageSize,
          category_id: this.category_id
        };
        if (this.courseLessonTagListIdArr.length) {
          params["tag_ids"] = this.courseLessonTagListIdArr.join(",");
        }

        /* 关键字搜索 */
        this.searchWords && (params["name"] = this.searchWords);
        /* 0 最新 1 最热 */
        params["sort_type"] = this.sort_type;
        /* 1 视频 2 图文 3案列 */
        params["show_type"] = this.show_type;
        let res = await this.$api.getAllContentTag(params);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCourseLessonTagList请求失败：" + res.errmsg)
          return;
        }

        /* const finalData = limitData({ interfaceData: res, baseData: this.courseList, limit: params })
        this.courseList = finalData.baseData
        finalData.currentAdd && this.current++ */

        res.forEach(im => {
          im.check = false;
        });
        this.courseLessonTagList = (0, _index.quchong)([...this.courseLessonTagList, ...res]);
        if (this.courseLessonTagList.length > 0) this.showTags = true;
        setTimeout(() => {
          (0, _index.handleTagCss)(this.$refs.courseTag, this.$refs.courseTagInner, this.$refs.courseTagInnerItem);
        }, 0);
      } catch (error) {
        console.log(error, '==>> error');
      }
    },
    async getAllContent() {
      this.loadFinal = false;
      try {
        let params = {
          current: this.current,
          pageSize: this.pageSize,
          category_id: this.category_id,
          need_veeva: this.need_veeva
        };
        if (this.courseLessonTagListIdArr.length) {
          params["tag_ids"] = this.courseLessonTagListIdArr.join(",");
        }
        /* 关键字搜索 */
        this.searchWords && (params["name"] = this.searchWords);
        /* 0 最新 1 最热 */
        params["sort_type"] = this.sort_type;
        /* 1 视频 2 图文 3案列 */
        params["show_type"] = this.show_type;
        let res = await this.$api.getAllContent(params);
        this.isReachBottom = false;
        this.loadFinal = true;
        // this.$store.commit("SET_LOADINGPOP", false)

        if (!this.isFirstRequest) {
          setTimeout(() => {
            this.showDrawer = false;
          }, 500);
        }
        this.isFirstRequest = false;
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getMyFaver请求失败：" + res.errmsg)
          return;
        }
        if ((res === null || res === void 0 ? void 0 : res.data.length) < params.pageSize) {
          this.isLoadAllData = true;
        }
        const finalData = (0, _index.limitData)({
          interfaceData: res,
          baseData: this.courseList,
          limit: params
        });
        this.courseList = finalData.baseData;
        finalData.currentAdd && this.current++ && (this.noThenData = false);
        !finalData.currentAdd && (this.noThenData = true);
      } catch (error) {
        console.log(error, '==>> error');
      }
    },
    async getCourseCate() {
      try {
        var _this$courseClass$cal;
        /* 获取全部分类 */
        let res = await this.$api.getCourseCate(0);
        // this.$store.commit("SET_LOADINGPOP", false)

        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCourseCate(0)失败" + res.errmsg)
          return;
        }
        this.courseClass = res;
        console.log(this.courseClassUseType, '==> this.courseClassUseType>> ');
        const calcIndex = this.courseClass.findIndex(im => im.id == Number(this.urlQuery.id));
        this.courseClassUseType = calcIndex > -1 ? calcIndex : null;
        if (calcIndex > -1 && (_this$courseClass$cal = this.courseClass[calcIndex]) !== null && _this$courseClass$cal !== void 0 && (_this$courseClass$cal = _this$courseClass$cal.children) !== null && _this$courseClass$cal !== void 0 && _this$courseClass$cal.length) {
          this.courseClassSecUseType = -1;
        }
        this.getData();
      } catch (error) {}
    },
    async getData() {
      var _this$courseClass$thi8;
      const firstClass = this.courseClass[this.courseClassUse[0]];
      const secClass = (_this$courseClass$thi8 = this.courseClass[this.courseClassUse[0]]) === null || _this$courseClass$thi8 === void 0 || (_this$courseClass$thi8 = _this$courseClass$thi8.children) === null || _this$courseClass$thi8 === void 0 ? void 0 : _this$courseClass$thi8[this.courseClassUse[1]];
    }
  }
});