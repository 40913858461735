"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/utils/index.js");
var _index2 = _interopRequireDefault(require("@/components/customSelect/index.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    customSelect: _index2.default
  },
  data() {
    return {
      modifying: false,
      errorTipWords: "",
      encryptionArray: ["email", "name"],
      formData: {
        privacy: false
        // terms: false
      },
      formDataRule: {
        name: [{
          required: true,
          message: "请填写姓名"
        }],
        email: [{
          required: true,
          message: "请填写邮箱"
        }, {
          crypt: true
        }],
        code: [{
          required: true,
          message: "请填写验证码"
        }],
        privacy: [{
          required: true,
          message: "请浏览并同意签署《个人信息披露知情同意》"
        }]
        // terms: [
        //   {
        //     required: true,
        //     message: "请确认勾选隐私声明 及使用条款"
        //   }
        // ]
      },
      getEmailCode: new _index.getSmsAfter(),
      isSubmiting: false,
      codeSending: false
    };
  },
  watch: {},
  filter: {},
  created() {
    this.formData = {
      ...this.formData,
      ...this.userInfo
    };
    if (this.encryptionArray.length) {
      const myCrypt = new _index.Crypt();
      for (const key in this.formData) {
        if (this.encryptionArray.includes(key)) {
          this.formData[key] = myCrypt.decrypt(this.formData[key]);
        }
        /* if (key == "email") { 
          console.log(this.formData[key],'==>?this.formData[key]');
          this.formData[key] = this.formData[key].replace('@bayer.com', '') 
          console.log(this.formData[key],'==>?this.formData[key] afafafaf');
        } */
      }
    }
    this.formData["email"] = this.formData["email"].replace('@bayer.com', '');
  },
  mounted() {},
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    async uploadImg() {
      // this.$refs["fileInput"].click()
      let res = await this.$chooseImage();
      if (res !== null && res !== void 0 && res.imageBase64) {
        this.fileInputChange(res.imageBase64);
      }
    },
    async fileInputChange(base64) {
      const self = this;
      const selectFileInfo = self.$refs.fileInput.files;
      let params = new FormData();
      params.append("file", base64);
      let res = await this.$api.imageuploadBase64(params);
      if (res !== null && res !== void 0 && res.errcode) {
        // alert("文件上传失败")
        this.$toatsView.show({
          tipWords: `文件上传失败，请重试`
        });
        return;
      }
      this.formData.avatar = res.url;
      this.$forceUpdate();
    },
    showUserInfo() {
      const myCrypt = new _index.Crypt();

      // this.formData.name = myCrypt.decrypt(this.userInfo.name)
      // this.formData.email = myCrypt.decrypt(this.userInfo.email)

      return {
        name: myCrypt.decrypt(this.userInfo.name),
        email: myCrypt.decrypt(this.userInfo.email)
      };
    },
    async sendEmailCode() {
      if (this.getEmailCode.timer) {
        this.errorTipWords = `验证码已发送`;
        this.$refs[`customPop-error`].open();
        setTimeout(() => {
          this.$refs[`customPop-error`].close();
        }, 1500);
        return;
      }
      if (!this.formData.email) {
        this.errorTipWords = `请输入邮箱`;
        this.$refs[`customPop-error`].open();
        return;
      }
      const self = this;

      /* 邮箱发送接口 */
      const myCrypt = new _index.Crypt();
      let params = {
        email: myCrypt.encrypt(self.formData.email + "@bayer.com")
      };
      params["crypt_key"] = myCrypt.rsa_key();
      this.getEmailCode.countDown();
      if (this.codeSending) return;
      this.codeSending = true;
      let res = await this.$api.mailSend(params);
      setTimeout(() => {
        this.codeSending = false;
      }, 500);
      if (res !== null && res !== void 0 && res.errcode) {
        this.errorTipWords = `发送失败：${res.errmsg}`;
        this.$refs[`customPop-error`].open();
        this.getEmailCode.clearStatus();
        return;
      }
      this.errorTipWords = `验证码发送成功`;
      this.$refs[`customPop-error`].open();
      setTimeout(() => {
        this.$refs[`customPop-error`].close();
      }, 1500);
    },
    checkValue(params) {
      this.formData[params.field] = params.check;
    },
    to(value) {
      console.log(value, '==>value');
    },
    checkValue() {
      for (let key in this.formDataRule) {
        this.formDataRule[key].forEach((im, ix) => {
          var _this$formData$key;
          if (im.required && (!this.formData[key] || this.formData[key] instanceof String && !((_this$formData$key = this.formData[key]) !== null && _this$formData$key !== void 0 && _this$formData$key.trim()))) {
            this.errorTipWords = im.message;
            this.$refs[`customPop-error`].open();
            throw im.message;
          }
        });
      }
      return true;
    },
    async saveSubmit() {
      if (this.checkValue()) {
        let params = JSON.parse(JSON.stringify(this.formData));
        if (this.encryptionArray.length) {
          const myCrypt = new _index.Crypt();
          for (const key in params) {
            if (this.encryptionArray.includes(key)) {
              if (key == "email") {
                params[key] = params[key] + '@bayer.com';
              }
              params[key] = myCrypt.encrypt(params[key]);
            }
          }
          params["crypt_key"] = myCrypt.rsa_key();
        }
        delete params['privacy'];
        // delete params['terms']

        console.log(params, '==>this.formData');
        if (this.isSubmiting) return;
        this.isSubmiting = true;
        let res = await this.$api.updateStaffInfo(params);
        setTimeout(() => {
          this.isSubmiting = false;
        }, 500);
        if (res !== null && res !== void 0 && res.errcode) {
          this.$toatsView.show({
            tipWords: "提交失败：" + res.errmsg
          });
          return;
        }
        this.$toatsView.show({
          tipWords: "提交成功"
        });
        this.$store.dispatch("getUserInfo");
        setTimeout(() => {
          this.$GO(-1);
        }, 1500);
        this.modifying = false;
        // setTimeout(() => { this.$PUSH({ path: '/' }) }, 1500)

        // this.$refs["customPop-cus"].open()
      }

      // this.$refs.customPop.open()
    },
    customPopCancle() {},
    customPopConfirm() {},
    registerSuccessHandleDelay() {
      this.$refs['customPop-cus'].close();
    },
    registerSuccessHandleNow() {
      this.$refs['customPop-cus'].close();
    }
  }
});