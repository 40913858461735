"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/popup/style");
var _popup = _interopRequireDefault(require("vant/es/popup"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _indexBadge = _interopRequireDefault(require("@/components/customPopview/indexBadge.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    vanPopup: _popup.default,
    BadgePop: _indexBadge.default
  },
  data() {
    return {
      showPowerBoxTip: false,
      badgeList: {},
      getCount: 0,
      tempBadgeInfo: {}
    };
  },
  watch: {},
  filter: {},
  created() {
    this.init();
  },
  mounted() {
    /* setTimeout(() => {
        if (this.userInfo?.type == 2) {
            this.$REDIRECT({ path: "/" })
        }
    }, 1500); */
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    hideBadgeInfo() {
      this.$refs["customPop-badgeInfo"].close();
    },
    showBadgeInfo(im, isGray) {
      this.tempBadgeInfo = {
        ...im,
        isGray
      };
      this.$refs["customPop-badgeInfo"].open();
    },
    async init() {
      var _res$my_list;
      let res = await this.$api.getBadgeList();
      this.badgeList = res;
      res.list.forEach(im => {
        if (im.member) {
          this.getCount++;
        }
      });
      this.getCount += ((_res$my_list = res.my_list) === null || _res$my_list === void 0 ? void 0 : _res$my_list.length) || 0;
    },
    handleIntroduction(im) {
      var _im;
      im = (_im = im) === null || _im === void 0 ? void 0 : _im.replace(/['↵'\n\r]/g, "<br />");
      return im;
    }
  }
});