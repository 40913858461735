"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.string.replace-all.js");
require("vant/es/image-preview/style");
var _imagePreview = _interopRequireDefault(require("vant/es/image-preview"));
require("vant/es/sticky/style");
var _sticky = _interopRequireDefault(require("vant/es/sticky"));
var _monitor = require("@/utils/monitor.js");
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _index = require("@/utils/index.js");
var _meetingPoster = _interopRequireDefault(require("@/components/meeting/meetingPoster.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _customVideo = _interopRequireDefault(require("@/components/common/customVideo.vue"));
var _interaction = _interopRequireDefault(require("@/components/course/interaction.vue"));
var _interactionAnalysis = _interopRequireDefault(require("@/components/course/interactionAnalysis.vue"));
var _comment = _interopRequireDefault(require("@/components/course/comment.vue"));
var _meetingVideo = _interopRequireDefault(require("@/components/meeting/meetingVideo.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    customVideo: _customVideo.default,
    interaction: _interaction.default,
    comment: _comment.default,
    interactionAnalysis: _interactionAnalysis.default,
    vanSticky: _sticky.default,
    videoList: _meetingVideo.default,
    meetingPoster: _meetingPoster.default
  },
  data() {
    return {
      showModuleCheck: 0,
      urlQuery: {},
      pageSize: 10,
      current: 1,
      listCampainContent: [],
      listCampainContentIndex: 0,
      myIsZan: false,
      myIsFaver: false,
      formEchoInfo: {},
      sectionDetail: {},
      loadZan: false,
      loadFaver: false,
      tipWords: "",
      isReachBottom: false
    };
  },
  watch: {
    listCampainContentIndex() {
      console.log(this.listCampainContentIndex, '==> listCampainContentIndex for watch ');
      this.$nextTick(() => {
        var _this$$refs$customVid, _this$$refs$customVid2;
        ((_this$$refs$customVid = this.$refs.customVideo) === null || _this$$refs$customVid === void 0 ? void 0 : _this$$refs$customVid.isPlay) && (this.$refs.customVideo.isPlay = false);
        (_this$$refs$customVid2 = this.$refs.customVideo) === null || _this$$refs$customVid2 === void 0 || _this$$refs$customVid2.resetUrl();
      });
      this.getFormEchoInfo();
    }
  },
  filter: {},
  created() {
    this.urlQuery = this.$route.query;
    this.initData();
    this.getModuleDetail();
    this.initReachBottom();
  },
  destroyed() {
    window.removeEventListener("reachBottom", this.reachBottomHandle);
  },
  mounted() {},
  computed: {
    contentDetail() {
      return this.listCampainContent[this.listCampainContentIndex];
    }
  },
  methods: {
    /* 分页 start */
    initReachBottom() {
      window.addEventListener("reachBottom", this.reachBottomHandle);
    },
    reachBottomHandle(evt) {
      var _this$listCampainCont;
      const self = this;
      if (evt.detail.path != "/activity/detail") {
        return;
      }
      if (self.isReachBottom) {
        return;
      }
      if (this.showModuleCheck == 3 && (_this$listCampainCont = this.listCampainContent[this.listCampainContentIndex]) !== null && _this$listCampainCont !== void 0 && _this$listCampainCont.have_comments) {
        var _this$$refs;
        self.isReachBottom = true;
        (_this$$refs = this.$refs) === null || _this$$refs === void 0 || (_this$$refs = _this$$refs.mycomment) === null || _this$$refs === void 0 || _this$$refs.reachBottom();
      }
    },
    /* 分页 end */

    async zanHandle(type) {
      if (this.loadZan) return;
      this.loadZan = true;
      type && this.$store.dispatch("setScoreLog", {
        route: this.$route,
        action_type: "ZAN"
      });
      try {
        let res = await this.$api[type ? 'contentZan' : 'cancleContentZan']({
          content_id: this.urlQuery.id,
          type: 6
        });
        this.loadZan = false;
        if (type) {
          this.myIsZan = true;
          this.sectionDetail.zan++;
          this.$emit("setIsZan", true);
        } else {
          this.sectionDetail.zan--;
          this.myIsZan = false;
          this.$emit("setIsZan", false);
        }
      } catch (error) {}
    },
    async faverHandle(type) {
      if (this.loadFaver) return;
      this.loadFaver = true;
      type && this.$store.dispatch("setScoreLog", {
        route: this.$route,
        action_type: "FAVER"
      });
      try {
        let res = await this.$api[type ? 'contentFaver' : 'cancleContentFaver']({
          content_id: this.urlQuery.id,
          type: 6
        });
        this.loadFaver = false;
        if (type) {
          this.myIsFaver = true;
          this.sectionDetail.faver++;
          this.$emit("setIsFaver", true);
        } else {
          this.myIsFaver = false;
          this.sectionDetail.faver--;
          this.$emit("setIsFaver", false);
        }
      } catch (error) {}
    },
    async getZanCheck() {
      let res = await this.$api.getZanCheck(this.urlQuery.id, 6);
      if (res.errcode) {
        alert("getZanCheck(this.urlQuery.id, 6)" + res.errmsg);
        return;
      }
      if (res.count) {
        this.myIsZan = true;
        return;
      }
      this.myIsZan = false;
    },
    async getFaverCheck() {
      let res = await this.$api.getZanCheck(this.urlQuery.id, 6);
      if (res.errcode) {
        alert("getZanCheck(this.urlQuery.id, 6)" + res.errmsg);
        return;
      }
      if (res.count) {
        this.myIsFaver = true;
        return;
      }
      this.myIsFaver = false;
    },
    /* 获取模块详情 用于点赞收藏 */
    async getModuleDetail() {
      /* this.getZanCheck()
      this.getFaverCheck() */

      try {
        let res = await this.$api.getPreviewCampainSection({}, this.urlQuery.id);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getPreviewCampainSection" + res.errmsg)
          return;
        }
        if (res.content) res.content = res.content.replaceAll('<img', '<img style="max-width:100%;"');
        this.sectionDetail = res;
      } catch (error) {}
    },
    /* 发送监测 */
    postMonitor() {
      (0, _monitor.dataStatistics)({
        event_type: 'view',
        // view = 浏览  click = 点击   share = 分享
        page_title: `拜影云学院:${this.listCampainContent[this.listCampainContentIndex].name}`,
        typeMap: 7,
        content_id: this.listCampainContent[this.listCampainContentIndex].id
      });
    },
    /* 生成分享海报 */
    myShare() {
      const detailPosterInner = document.querySelector(".detailPosterInnerOut");
      (0, _html2canvas.default)(detailPosterInner, {
        useCORS: true
      }).then(canvas => {
        this.capUrl = canvas.toDataURL();
        (0, _imagePreview.default)({
          images: [this.capUrl],
          closeable: true
        });
        setTimeout(() => {
          this.$store.dispatch("setScoreLog", {
            route: this.$route,
            action_type: "SHARE"
          });
        }, 2000);
      });
    },
    /* 富文本插入 */
    showEdtier(im) {
      const item = im.replaceAll('<img', '<img style="max-width:100%;"');
      console.log(item, '==> item');
      return item;
    },
    initData() {
      this.getCampainContent();
    },
    /* 获取目录列表 */
    async getCampainContent() {
      try {
        var _this$listCampainCont2;
        let params = {
          section_id: this.urlQuery.id,
          current: this.current,
          pageSize: 9999
          // pageSize: this.pageSize
        };
        let res = await this.$api.getPreviewCampainContent(params);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getPreviewCampainContent" + res.errmsg)
          return;
        }
        const finalData = (0, _index.limitData)({
          interfaceData: res,
          baseData: this.listCampainContent,
          limit: params
        });
        finalData.currentAdd && this.current++;
        this.listCampainContent = finalData.baseData;
        if (((_this$listCampainCont2 = this.listCampainContent) === null || _this$listCampainCont2 === void 0 ? void 0 : _this$listCampainCont2.length) < 1) {
          this.tipWords = "暂无数据";
          this.$refs.customPop.open();
        }
        const listCampainContentIndex = this.listCampainContent.findIndex(im => im.id + '' == this.urlQuery.demo_content_id);
        this.listCampainContentIndex = listCampainContentIndex > -1 ? listCampainContentIndex : 0;
        this.postMonitor();

        /* 未登录无法请求接口 */
        this.getFormEchoInfo();
      } catch (error) {}
    },
    /* 根据目录列表当前选中的数据，获取互动数据（获取的是已经填写过的 用于展示结果。未填写的在目录列表是数据中） */
    async getFormEchoInfo() {
      return;
      let formEchoInfo = await this.$api.getFormEchoInfo({}, this.listCampainContent[this.listCampainContentIndex].id, this.listCampainContent[this.listCampainContentIndex].type);
      if (formEchoInfo !== null && formEchoInfo !== void 0 && formEchoInfo.errcode) {
        alert("getFormEchoInfo获取失败" + formEchoInfo.errmsg);
        return;
      }
      this.formEchoInfo = formEchoInfo;
      console.log(formEchoInfo, '==> formEchoInfo');
    }
  }
});