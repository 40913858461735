"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/popup/style");
var _popup = _interopRequireDefault(require("vant/es/popup"));
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _index = require("@/utils/index.js");
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _cerImg = _interopRequireDefault(require("@/components/personal/cerImg.vue"));
var _cerImgSmall = _interopRequireDefault(require("@/components/personal/cerImgSmall.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    vanPopup: _popup.default,
    cerImg: _cerImg.default,
    cerImgSmall: _cerImgSmall.default
  },
  data() {
    return {
      showPowerBoxTip: false,
      cerList: {},
      getCount: 0,
      cerDetail: {},
      cerDetailShow: false
    };
  },
  watch: {},
  filter: {},
  created() {
    this.$store.commit("SET_LOADINGPOP", true);
    this.init();
  },
  mounted() {
    /* setTimeout(() => {
        if (this.userInfo?.type == 2) {
            this.$REDIRECT({ path: "/" })
        }
    }, 1500); */

    setTimeout(() => {
      this.$refs['customPop-cerInfo'].open();
    }, 5000);
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    cerListSmallClick(im, ix) {
      this.cerDetail = {
        ...im,
        isNoHas: true
      };
      this.cerDetail.path = this.$refs[`cerImgSmall${ix}`][0].drawData;
      this.cerDetailShow = true;
    },
    cerListClick(im, ix) {
      this.cerDetail = im;
      this.cerDetail.path = this.$refs[`cerImg${ix}`][0].drawData;
      this.cerDetailShow = true;
    },
    async init() {
      let res = await this.$api.getCerList();
      this.cerList = res;
      setTimeout(() => {
        this.$store.commit("SET_LOADINGPOP", false);
      }, 2300);

      /*  res.list.forEach(im => {
           if (im.member) {
               this.getCount++
           }
       });
          this.getCount += res.my_list?.length || 0 */
    },
    handleName(words) {
      const deCrypt = new _index.Crypt();
      return deCrypt.decrypt(words);
    }
  }
});