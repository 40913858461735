"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _default = exports.default = [{
  path: '/coursedetail',
  name: '',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/preview/coursedetail'))),
  meta: {
    title: '课程详情',
    keepAlive: false,
    wechatAuth: false,
    hideTabbar: true
  }
}, {
  path: '/previewmeetingdetail',
  name: '',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/preview/meetingdetail'))),
  meta: {
    title: '会议详情',
    keepAlive: false,
    wechatAuth: false,
    hideTabbar: true
  }
}, {
  path: '/activitymoduledetail',
  name: '',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/preview/activitydetailBefore'))),
  meta: {
    title: '活动模块详情',
    keepAlive: false,
    wechatAuth: false,
    hideTabbar: true
  }
}, {
  path: '/activitycontentdetail',
  name: '',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/preview/activityContentdetail'))),
  meta: {
    title: '活动内容详情',
    keepAlive: false,
    wechatAuth: false,
    hideTabbar: true
  }
}, {
  path: '/activityrichlist',
  name: '',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/preview/activityRich/list'))),
  meta: {
    title: '活动内容列表',
    keepAlive: false,
    wechatAuth: false,
    hideTabbar: true
  }
}, {
  path: '/activityrichdetail',
  name: '',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/preview/activityRich/detail'))),
  meta: {
    title: '活动内容详情',
    keepAlive: false,
    wechatAuth: false,
    hideTabbar: true
  }
}];