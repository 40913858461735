import { render, staticRenderFns } from "./welcome.vue?vue&type=template&id=8d4126ca&scoped=true"
import script from "./welcome.vue?vue&type=script&lang=js"
export * from "./welcome.vue?vue&type=script&lang=js"
import style0 from "./welcome.vue?vue&type=style&index=0&id=8d4126ca&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d4126ca",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\work\\code\\RADAcademy\\bayer-rad-academy-shadowcloud-academy-html\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8d4126ca')) {
      api.createRecord('8d4126ca', component.options)
    } else {
      api.reload('8d4126ca', component.options)
    }
    module.hot.accept("./welcome.vue?vue&type=template&id=8d4126ca&scoped=true", function () {
      api.rerender('8d4126ca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/entry/welcome.vue"
export default component.exports