"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$contentDetail, _vm$contentDetail2, _vm$contentDetail3, _vm$contentDetail4, _vm$formEchoInfo, _vm$formEchoInfo2, _vm$contentDetail5;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("stickyHeader"), (_vm$contentDetail = _vm.contentDetail) !== null && _vm$contentDetail !== void 0 && _vm$contentDetail.id ? _c("div", {
    staticClass: "detailTopOut"
  }, [(_vm$contentDetail2 = _vm.contentDetail) !== null && _vm$contentDetail2 !== void 0 && _vm$contentDetail2.id ? _c("div", {
    staticClass: "detailTitle"
  }, [_vm._v(_vm._s(_vm.contentDetail.name))]) : _vm._e(), _c("div", {
    staticClass: "detailAuthor"
  }, [(_vm$contentDetail3 = _vm.contentDetail) !== null && _vm$contentDetail3 !== void 0 && _vm$contentDetail3.need_veeva ? _c("div", {
    staticClass: "authIcon"
  }, [_c("img", {
    attrs: {
      alt: "",
      srcset: "",
      src: require("img/common/authIcon.png")
    }
  })]) : _vm._e(), _vm.contentDetail.speaker ? _c("div", [_vm._v("讲者：" + _vm._s(_vm.contentDetail.speaker))]) : _vm._e()]), _vm.contentDetail.hospital ? _c("div", {
    staticClass: "detailAuthor"
  }, [_vm._v("讲者所在医院：" + _vm._s(_vm.contentDetail.hospital))]) : _vm._e(), _c("div", {
    staticClass: "detailHeaderTag flex flex-wrap"
  }, [_vm.need_veeva ? _c("div", {
    ref: "courseTagInnerItem",
    staticClass: "detailHeaderTagItem cursmallCourseTagItem"
  }, [_vm._v("认证专享")]) : _vm._e(), _vm._l(_vm.contentDetail.tag_name_list, function (im, ix) {
    return _c("div", {
      key: ix,
      staticClass: "detailHeaderTagItem"
    }, [_vm._v(_vm._s(im))]);
  })], 2), _vm.contentDetail.content_type + "" == "1" && _vm.contentDetail.video_url ? _c("div", {
    staticClass: "detailVideo fixedScale"
  }, [_c("customVideo", {
    attrs: {
      src: _vm.contentDetail.video_url
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "detailRich lh1-5 ql-editor",
    domProps: {
      innerHTML: _vm._s(_vm.$VIDEOPLAYSINLINE(_vm.contentDetail.content) || "")
    },
    on: {
      click: _vm.$imageEnlargement
    }
  }), (_vm$contentDetail4 = _vm.contentDetail) !== null && _vm$contentDetail4 !== void 0 && _vm$contentDetail4.id ? _c("div", {
    staticClass: "detailStatistics flex align-center justify-between"
  }, [_c("div", {
    staticClass: "detailStatisticsLeft flex align-center"
  }, [_c("img", {
    staticClass: "browseCount",
    attrs: {
      src: require("img/common/browseCount.png"),
      alt: ""
    }
  }), _vm._v(" " + _vm._s(_vm.contentDetail.read_num || 0) + " ")]), _c("div", {
    staticClass: "detailStatisticsRight flex align-center"
  }, [_c("div", {
    staticClass: "detailStatisticsRightItem flex align-center",
    on: {
      click: function ($event) {
        return _vm.myShare();
      }
    }
  }, [_c("img", {
    staticClass: "browseShare",
    attrs: {
      src: require("img/common/share.png"),
      alt: ""
    }
  }), _vm._v(" 分享 ")]), _c("div", {
    staticClass: "detailStatisticsRightItem flex align-center"
  }, [_vm.isZan ? _c("img", {
    staticClass: "browsegive",
    attrs: {
      src: require("img/common/give.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        return _vm.zanHandle(0);
      }
    }
  }) : _c("img", {
    staticClass: "browsegive",
    attrs: {
      src: require("img/common/giveNull.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        return _vm.zanHandle(1);
      }
    }
  }), _vm._v(" " + _vm._s(_vm.contentDetail.zan || 0) + " ")]), _c("div", {
    staticClass: "detailStatisticsRightItem flex align-center"
  }, [_vm.isFaver ? _c("img", {
    staticClass: "browsestar",
    attrs: {
      src: require("img/common/star.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        return _vm.faverHandle(0);
      }
    }
  }) : _c("img", {
    staticClass: "browsestar",
    attrs: {
      src: require("img/common/starNull.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        return _vm.faverHandle(1);
      }
    }
  }), _vm._v(" " + _vm._s(_vm.contentDetail.faver || 0) + " ")])])]) : _vm._e(), _vm.contentDetail.have_form || _vm.contentDetail.have_comments ? _c("div", {
    staticClass: "detailModuleCheck flex"
  }, [_vm.contentDetail.have_form ? _c("div", {
    staticClass: "detailModuleCheckItem justify-center align-center flex",
    class: [!_vm.showModuleCheck ? "detailModuleCheckItemCheck" : "", _vm.contentDetail.have_form && !_vm.contentDetail.have_comments && "onlyOne"],
    on: {
      click: function ($event) {
        _vm.showModuleCheck = 0;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.contentDetail.menu[0].interact || "互动") + " ")]) : _vm._e(), _vm.contentDetail.have_form && _vm.contentDetail.have_comments ? _c("div", {
    staticClass: "detailModuleCheckItemLine"
  }) : _vm._e(), _vm.contentDetail.have_comments ? _c("div", {
    staticClass: "detailModuleCheckItem justify-center align-center flex",
    class: [_vm.showModuleCheck ? "detailModuleCheckItemCheck" : "", !_vm.contentDetail.have_form && _vm.contentDetail.have_comments && "onlyOne"],
    on: {
      click: function ($event) {
        _vm.showModuleCheck = 1;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.contentDetail.menu[0].comment || "评论") + " ")]) : _vm._e()]) : _vm._e()]) : _vm._e(), !_vm.showModuleCheck && _vm.contentDetail.have_form && !((_vm$formEchoInfo = _vm.formEchoInfo) !== null && _vm$formEchoInfo !== void 0 && _vm$formEchoInfo.id) ? _c("interaction", {
    attrs: {
      formInfo: _vm.detailForm
    }
  }) : _vm._e(), !_vm.showModuleCheck && _vm.contentDetail.have_form && (_vm$formEchoInfo2 = _vm.formEchoInfo) !== null && _vm$formEchoInfo2 !== void 0 && _vm$formEchoInfo2.id ? _c("interactionAnalysis", {
    attrs: {
      dataBase: _vm.formEchoInfo
    }
  }) : _vm._e(), _vm.showModuleCheck == 1 && _vm.contentDetail.have_comments ? _c("comment", {
    ref: "comment"
  }) : _vm._e(), _c("customPop", {
    ref: "customPop",
    attrs: {
      tipWords: _vm.tipWords
    }
  }), (_vm$contentDetail5 = _vm.contentDetail) !== null && _vm$contentDetail5 !== void 0 && _vm$contentDetail5.id ? _c("meetingPoster", {
    attrs: {
      dataBase: _vm.contentDetail,
      posterType: 1,
      tagList: _vm.contentDetail.tag_name_list
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;