"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/utils/index.js");
var _select = require("@/utils/area/select.js");
var _index2 = _interopRequireDefault(require("@/components/customSelect/index.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _pattern = require("@/utils/pattern.js");
var _hospital = _interopRequireDefault(require("@/utils/hospital.json"));
var _bodyParser = require("body-parser");
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    customSelect: _index2.default
  },
  data() {
    return {
      selecthospitalList: [],
      hospital: _hospital.default,
      /* 其它医院名称 -- 当医院选择为其它时显示 */
      hospitalOther: "",
      errorTipWords: "",
      formData: {
        privacy: false
        // terms: false
      },
      formDataRule: {
        name: [{
          required: true,
          message: "请填写姓名"
        }],
        phone: [{
          required: true,
          message: "请输入您的手机号码"
        }, {
          verify: true,
          verifyArr: [_pattern.isPhone],
          verifyArrMsg: ["手机号输入不正确"]
        }],
        code: [{
          required: true,
          message: "请填写验证码"
        }],
        province: [{
          required: true,
          message: "请选择省份"
        }],
        hospital: [{
          required: true,
          message: "请选择医院"
        }],
        department: [{
          required: true,
          message: "请选择科室"
        }],
        title: [{
          required: true,
          message: "请选择职称"
        }],
        position: [{
          required: true,
          message: "请选择行政职务"
        }],
        privacy: [{
          required: true,
          message: "请浏览并同意签署《个人信息披露知情同意》"
        }]
        /* terms: [
          {
            required: true,
            message: "请确认勾选隐私声明 及使用条款"
          }
        ] */
      },
      /* 需要加密的字段 --key */
      encryptionArray: ["name", "phone"],
      getSms: new _index.getSmsAfter(),
      isSubmiting: false,
      codeSending: false,
      userFrom: null,
      hospitalTip: "请输入医院关键字进行搜索"
    };
  },
  watch: {
    "formData.name"(newVal, oldVal) {
      const reg = /[^\a-\z\A-\Z\u4E00-\u9FA5]/;
      const hasSym = reg.test(this.formData.name);
      if (hasSym) {
        this.formData.name = oldVal;
      }

      // this.formData.name = this.formData.name.replace(/[^\a-\z\A-\Z\u4E00-\u9FA5]/g,'')

      // replace(/[^\a-\z\A-\Z\u4E00-\u9FA5]/g,'')
    }
  },
  filter: {},
  created() {},
  mounted() {
    console.log(this.initMonitor, '==>monitorView>monitorView');
    const {
      query
    } = this.$route;
    const userFrom = localStorage.getItem("userFrom", query.source);
    this.userFrom = userFrom;
    if (query.from == 'welcome') {
      this.weChatShare.selfLink = `${window.location.origin}/bayer-rad-academy-shadowcloud-academy-html/welcome?source=SubscribeQRCode`;
      this.initWechat();
    }
  },
  computed: {
    positionSelectList() {
      return this.$store.getters.positionSelectList;
    },
    departmentSelectList() {
      return this.$store.getters.departmentSelectList;
    },
    titleSelectList() {
      return this.$store.getters.titleSelectList;
    },
    provinceSelectList() {
      return this.$store.getters.provinceSelectList;
    },
    hospitalSelectList() {
      return this.$store.getters.hospitalSelectList;
    },
    callUs() {
      return this.$store.getters.callUs;
    }
  },
  methods: {
    ewmpopClose() {
      const registerBackUrl = localStorage.getItem("registerBackUrl");
      if (registerBackUrl) {
        window.location.href = decodeURIComponent(registerBackUrl);
        localStorage.removeItem("registerBackUrl");
        return;
      }
      this.$REDIRECT({
        path: '/'
      });
    },
    registerCancle() {
      var _this$$route$query, _this$$route;
      if (((_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.handle) == "backtohome") {
        this.$REDIRECT({
          path: "/"
        });
        return;
      }
      const hasToHome = (_this$$route = this.$route) === null || _this$$route === void 0 || (_this$$route = _this$$route.query) === null || _this$$route === void 0 || (_this$$route = _this$$route.redirect) === null || _this$$route === void 0 ? void 0 : _this$$route.indexOf("gotohome=true");
      if (hasToHome != -1) {
        this.$REDIRECT({
          path: "/"
        });
        return;
      }
      if (document.referrer) {
        this.$GO(-1);
        return;
      }
      this.$REDIRECT({
        path: "/"
      });
    },
    async getCode() {
      if (this.getSms.timer) {
        this.errorTipWords = `验证码已发送`;
        this.$refs[`customPop-error`].open();
        setTimeout(() => {
          this.$refs[`customPop-error`].close();
        }, 1500);
        return;
      }
      const key = "phone";
      if (this.testAlone(key)) {
        const myCrypt = new _index.Crypt();
        let params = {};
        params[key] = myCrypt.encrypt(this.formData[key]);
        params["crypt_key"] = myCrypt.rsa_key();
        this.getSms.countDown();
        if (this.codeSending) return;
        this.codeSending = true;
        let res = await this.$api.smsSend(params);
        setTimeout(() => {
          this.codeSending = false;
        }, 500);
        if (res !== null && res !== void 0 && res.errcode) {
          this.errorTipWords = `发送失败，${res.errmsg}`;
          this.$refs[`customPop-error`].open();
          this.getSms.clearStatus();
          return;
        }
        this.errorTipWords = `验证码发送成功`;
        this.$refs[`customPop-error`].open();
        setTimeout(() => {
          this.$refs[`customPop-error`].close();
        }, 1500);
      }
    },
    closeAllRef(refName) {
      const allRef = this.$refs;
      for (const key in allRef) {
        if (key != refName) {
          var _this$$refs$key;
          (_this$$refs$key = this.$refs[key]) === null || _this$$refs$key === void 0 || _this$$refs$key.closePop();
        }
      }
    },
    async checkValue(params) {
      this.$set(this.formData, params.field, params.check);
      if (params.field == "province") {
        this.hospitalTip = "正在获取医院数据...";
        this.selecthospitalList = [];
        this.$refs.hospitalComponent.check = null;
        this.$refs.hospitalComponent.inputFilterWords = "";
        this.$refs.hospitalComponent.resetCheck();
        let res = await this.$api.getHospital({
          province: this.formData.province
        });
        this.hospitalTip = "请输入医院关键字进行搜索";

        // this.selecthospitalList = [...this.hospitalSelectList, ...res]
        this.selecthospitalList = [...res, ...this.hospitalSelectList];
      }
      this.$forceUpdate();
    },
    to(value) {
      console.log(value, '==>value');
    },
    testAlone(key) {
      this.formDataRule[key].forEach((im, ix) => {
        var _this$formData$key;
        if (im.required && (!this.formData[key] || this.formData[key] instanceof String && !((_this$formData$key = this.formData[key]) !== null && _this$formData$key !== void 0 && _this$formData$key.trim()))) {
          this.errorTipWords = im.message;
          this.$refs[`customPop-error`].open();
          throw im.message;
        }
        if (im.verify) {
          im.verifyArr.forEach((km, kx) => {
            if (!km(this.formData[key])) {
              this.errorTipWords = im.verifyArrMsg[kx];
              this.$refs[`customPop-error`].open();
              throw km.message;
            }
          });
        }
      });
      return true;
    },
    testValue() {
      for (let key in this.formDataRule) {
        this.formDataRule[key].forEach((im, ix) => {
          var _this$formData$key2;
          if (im.required && (!this.formData[key] || this.formData[key] instanceof String && !((_this$formData$key2 = this.formData[key]) !== null && _this$formData$key2 !== void 0 && _this$formData$key2.trim()))) {
            this.errorTipWords = im.message;
            this.$refs[`customPop-error`].open();
            throw im.message;
          }
          if (im.verify) {
            im.verifyArr.forEach((km, kx) => {
              if (!km(this.formData[key])) {
                this.errorTipWords = im.verifyArrMsg[kx];
                this.$refs[`customPop-error`].open();
                throw km.message;
              }
            });
          }
        });
      }
      return true;
    },
    encryption() {},
    testName() {
      var _this$formData$name, _this$formData$name2;
      /* 需求  注册姓名
          ● 名字必须包含中文填写（不用全中文）
          ● 字数在一个字以上
          ● 包含“先生、女士”这俩关键字无法提交成功
        */
      const newReg = /[\u4e00-\u9fa5]+/g;
      const tempStr = this.formData.name;
      const isHasMr = (_this$formData$name = this.formData.name) === null || _this$formData$name === void 0 ? void 0 : _this$formData$name.indexOf("先生");
      const isHasMIss = (_this$formData$name2 = this.formData.name) === null || _this$formData$name2 === void 0 ? void 0 : _this$formData$name2.indexOf("女士");
      const rgeStr = tempStr === null || tempStr === void 0 ? void 0 : tempStr.match(newReg);
      if (!(rgeStr !== null && rgeStr !== void 0 && rgeStr.length) || (tempStr === null || tempStr === void 0 ? void 0 : tempStr.length) < 2 || isHasMr != -1 || isHasMIss != -1) {
        return false;
      }
      return true;
    },
    getQueryString: function (url, name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = url.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    async registerSubmit() {
      // this.$PUSH({ path: '/information' })

      const {
        query
      } = this.$route;
      if (this.testValue()) {
        var _this$$route2;
        if (!this.testName()) {
          this.errorTipWords = `请输入真实中文姓名，您可在个人中心设置站内昵称`;
          // this.errorTipWords = `【${this.formData.name}】请输入真实中文姓名，您可在个人中心设置站内昵称`
          this.$refs[`customPop-error`].open();
          return;
        }
        if (this.formData.hospital == '其他') {
          if (!this.hospitalOther) {
            this.errorTipWords = `请输入医院名称`;
            this.$refs[`customPop-error`].open();
            return;
          }
          if (this.hospitalOther) {
            const hasHospitalIndex = this.selecthospitalList.indexOf(this.hospitalOther);
            if (hasHospitalIndex != -1) {
              this.formData.hospital = this.selecthospitalList[hasHospitalIndex];
            } else {
              this.formData.hospital_other = this.hospitalOther;
            }
          }
        }
        let params = JSON.parse(JSON.stringify(this.formData));
        if (params.hospital == '其他') {
          if (params.hospital_other) {
            const hasHospitalIndex = this.selecthospitalList.indexOf(this.hospitalOther);
            if (hasHospitalIndex != -1) {
              params.hospital = this.selecthospitalList[hasHospitalIndex];
              delete params.hospital_other;
            } else {
              params.hospital_other = this.hospitalOther;
            }
          }
        }
        if (this.encryptionArray.length) {
          const myCrypt = new _index.Crypt();
          for (const key in params) {
            if (this.encryptionArray.includes(key)) {
              params[key] = myCrypt.encrypt(params[key]);
            }
          }
          params["crypt_key"] = myCrypt.rsa_key();
        }
        delete params['privacy'];
        // delete params['terms']

        if (this.isSubmiting) return;
        this.isSubmiting = true;
        const userFrom = localStorage.getItem("userFrom");
        const isRegister = localStorage.getItem("isRegister");
        if (!isRegister && userFrom == "SubscribeQRCode") {
          params["source"] = 1;
          params["sync_scrm_flag"] = 9;
        }
        //如果url上直接有channel参数，则直接获取
        if (query !== null && query !== void 0 && query.channel) {
          params["channel"] = query === null || query === void 0 ? void 0 : query.channel;
        }
        //如果是其他页面的url有channel跳转到注册页后channel在编译的url中，此时编译后再获取
        var channel = this.getQueryString(decodeURIComponent((_this$$route2 = this.$route) === null || _this$$route2 === void 0 || (_this$$route2 = _this$$route2.query) === null || _this$$route2 === void 0 ? void 0 : _this$$route2.redirect), 'channel');
        if (channel) {
          params["channel"] = channel;
        }
        let res = await this.$api.memberRegister(params);
        // setTimeout(() => {
        //   this.isSubmiting = false
        // }, 2000);

        if (res !== null && res !== void 0 && res.errcode) {
          this.isSubmiting = false;
          this.errorTipWords = `注册失败：${res.errmsg}`;
          this.$refs[`customPop-error`].open();
          return;
        }
        this.$store.dispatch("getUserInfo");
        if ((query === null || query === void 0 ? void 0 : query.poptype) == "qrcode" || !isRegister && userFrom == "SubscribeQRCode") {
          this.isSubmiting = false;
          // this.$store.dispatch("getAssistantsInfo")
          this.$refs["customPop-ewm"].open();
          return;
        }
        setTimeout(() => {
          this.$refs["customPop-cus"].open();
        }, 2500);
      }
      // this.$refs.customPop.open()
    },
    customPopCancle() {},
    customPopConfirm() {},
    registerSuccessHandleDelay() {
      this.$refs['customPop-cus'].close();
      const registerBackUrl = localStorage.getItem("registerBackUrl");
      if (registerBackUrl) {
        window.location.href = decodeURIComponent(registerBackUrl);
        localStorage.removeItem("registerBackUrl");
        return;
      }
      this.$REDIRECT({
        path: '/personal'
      });
      // this.$REDIRECT({ path: '/' })
    },
    registerSuccessHandleNow() {
      var _this$$route3;
      // this.$refs['customPop-cus'].close()
      // this.$REDIRECT({ path: '/userInformationEdit' })

      let query = {};
      if ((_this$$route3 = this.$route) !== null && _this$$route3 !== void 0 && (_this$$route3 = _this$$route3.query) !== null && _this$$route3 !== void 0 && _this$$route3.channel) {
        var _this$$route4;
        query['channel'] = (_this$$route4 = this.$route) === null || _this$$route4 === void 0 || (_this$$route4 = _this$$route4.query) === null || _this$$route4 === void 0 ? void 0 : _this$$route4.channel;
      }
      this.$REDIRECT({
        path: '/information',
        query
      });
    },
    toStaff() {
      var _this$$route5;
      let query = {};
      if ((_this$$route5 = this.$route) !== null && _this$$route5 !== void 0 && (_this$$route5 = _this$$route5.query) !== null && _this$$route5 !== void 0 && _this$$route5.channel) {
        var _this$$route6;
        query.channel = (_this$$route6 = this.$route) === null || _this$$route6 === void 0 || (_this$$route6 = _this$$route6.query) === null || _this$$route6 === void 0 ? void 0 : _this$$route6.channel;
      }
      this.$PUSH({
        path: "/staffregister",
        query
      });
    }
  }
});