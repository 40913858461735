"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _popup = _interopRequireDefault(require("components/popup"));
var _default = exports.default = {
  components: {
    popup: _popup.default
  },
  data() {
    return {
      show: false,
      opacity: 0.7,
      overlay: 0,
      duration: 0,
      callback: () => {},
      confirm: () => {}
    };
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
    close() {
      this.show = false;
    },
    closed() {
      this.$refs.aiPopup.remove();
      this.$destroy();
    }
  }
};