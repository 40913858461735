"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  name: "approvalNo",
  props: {
    filterMap: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  watch: {},
  filter: {},
  created() {},
  mounted() {},
  computed: {},
  methods: {}
};