"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/image-preview/style");
var _imagePreview = _interopRequireDefault(require("vant/es/image-preview"));
var _meetingPoster = _interopRequireDefault(require("@/components/meeting/meetingPoster.vue"));
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _index = require("@/utils/index.js");
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _default = exports.default = window.$initPage({
  components: {
    meetingPoster: _meetingPoster.default,
    stickyHeader: _header.default
  },
  name: "activityRichList",
  data() {
    return {
      myIsFaver: false,
      myIsZan: false,
      loadZan: false,
      sectionDetail: {},
      showAll: false,
      loadFinish: false,
      urlQuery: {},
      listCampainContent: [],
      listCampainContentIndex: 0
    };
  },
  watch: {
    closeLoading() {
      if (this.closeLoading) {
        this.$store.commit("SET_LOADINGPOP", false);
      }
    }
  },
  filter: {},
  created() {
    this.$store.commit("SET_LOADINGPOP", true);
    this.urlQuery = this.$route.query;
    this.getCampainContent();
  },
  mounted() {},
  computed: {
    closeLoading() {
      return this.loadFinish && this.showAll;
    }
  },
  methods: {
    studyState(im) {
      return Number(im.learn_time) > 20 ? "已学习" : "待学习";
    },
    toPath(im) {
      /* const path = `/activity/richdetail`
      const query = {
          id: im.id,
          campain_id: im.campain_id,
          section_id: this.urlQuery.id
      }
      this.$PUSH({
          path, query
      }) */
    },
    /* 生成分享海报 */
    myShare() {
      const self = this;
      const detailPosterInner = document.querySelector(".detailPosterInnerOut");
      (0, _html2canvas.default)(detailPosterInner, {
        useCORS: true
      }).then(canvas => {
        self.capUrl = canvas.toDataURL();
        (0, _imagePreview.default)({
          images: [self.capUrl],
          closeable: true,
          onClose() {
            self.$store.commit('SET_SHOWSAVEBTN', false);
          }
        });
        self.$store.commit('SET_SHOWSAVEBTN', true);
        setTimeout(() => {
          // self.$store.commit('SET_INTEGRAL', { show: true, integral: 10 })
          self.$store.dispatch("setScoreLog", {
            route: self.$route,
            action_type: "SHARE"
          });
        }, 2000);
      });
    },
    /* -------------------------------------- */
    /* 获取目录列表 */
    async getCampainContent() {
      try {
        var _this$listCampainCont;
        let params = {
          section_id: this.urlQuery.id,
          current: 1,
          pageSize: 99999
        };
        let res = await this.$api.getPreviewCampainContent(params);
        this.loadFinish = true;
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCampainContent" + res.errmsg)
          return;
        }
        const finalData = (0, _index.limitData)({
          interfaceData: res,
          baseData: this.listCampainContent,
          limit: params
        });
        finalData.currentAdd && this.current++;
        this.$store.commit("SET_LOADINGPOP", false);
        this.listCampainContent = finalData.baseData;
        console.log(this.listCampainContent, '==>this.listCampainContent');
        if (((_this$listCampainCont = this.listCampainContent) === null || _this$listCampainCont === void 0 ? void 0 : _this$listCampainCont.length) < 1) {
          this.tipWords = "暂无数据";
          this.$refs.customPop.open();
          return;
        }
      } catch (error) {}
    }
  }
});