"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container-comment"
  }, [_c("div", {
    staticClass: "areaShadow"
  }, [!_vm.preview ? _c("div", {
    staticClass: "commentRelease flex align-center justify-between"
  }, [_c("div", {
    staticClass: "commentReleaseInput flex"
  }, [_c("textarea", {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.commentContent,
      expression: "commentContent",
      modifiers: {
        trim: true
      }
    }],
    attrs: {
      type: "text",
      maxlength: "300",
      placeholder: "欢迎评论，您的留言将会在审核通过后对所有人展示。"
    },
    domProps: {
      value: _vm.commentContent
    },
    on: {
      blur: [function ($event) {
        _vm.commentInputCursor = false;
      }, function ($event) {
        return _vm.$forceUpdate();
      }],
      focus: function ($event) {
        _vm.commentInputCursor = true;
      },
      keydown: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.sendComment.apply(null, arguments);
      },
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.commentContent = $event.target.value.trim();
      }
    }
  }), _c("div", {
    staticClass: "commentReleaseSubmit flex justify-center align-center",
    on: {
      click: _vm.sendComment
    }
  }, [_vm._v("发送")])])]) : _vm._e(), _c("div", {
    staticClass: "commentTitle"
  }), _vm.commentList.length > 0 ? _c("div", {
    staticClass: "commentAreaList"
  }, _vm._l(_vm.commentList, function (im, ix) {
    return _c("commentAreaListItem", {
      key: im.id,
      attrs: {
        isRichTextDetail: _vm.isRichTextDetail,
        renderModule: _vm.renderModule,
        preview: _vm.preview,
        dataBase: im,
        isNoneMargin: ix == 0
      },
      on: {
        updateIm: evt => _vm.updateIm(evt, ix)
      }
    });
  }), 1) : _vm._e(), !_vm.isLoading && _vm.commentList.length > 1 ? _c("div", {
    staticClass: "noMoreData"
  }, [_vm._v("加载中...")]) : _vm._e(), !_vm.isMoreData && _vm.commentList.length > 1 ? _c("div", {
    staticClass: "noMoreData"
  }, [_vm._v("没有更多内容了")]) : _vm._e()]), _vm.commentList.length < 1 ? _c("div", {
    staticClass: "nomore"
  }, [_vm._v("暂无更多数据")]) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;