"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container",
    on: {
      touchmove: function ($event) {
        $event.preventDefault();
      }
    }
  }, [_vm.screenTip ? _c("transition", {
    attrs: {
      name: "popFull",
      appear: ""
    }
  }, [_c("div", {
    staticClass: "popFull flex align-center justify-center",
    on: {
      click: function ($event) {
        return _vm.errorHandle("again");
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tipsWords) + " ")])]) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;