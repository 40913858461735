"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _index = require("@/utils/index.js");
var _filter = require("@/filters/filter.js");
var _default = exports.default = {
  components: {},
  props: {
    dataBase: {
      type: Object,
      default: () => {}
    },
    dataIx: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      drawData: null
    };
  },
  watch: {},
  filter: {},
  created() {},
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.drawCer();
      }, 1500);
    });
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    formatDate: _filter.formatDate,
    isShowDate() {
      let upLineTime = '2024-01-23 22:00:00';
      return (0, _filter.compareDates)(this.dataBase.certificate_created_at, upLineTime);
    },
    renderImgPath(path) {
      console.log(path, '==>path');
      const hasSym = path === null || path === void 0 ? void 0 : path.indexOf('?');
      if (hasSym > -1) {
        return `${path}&v=${new Date().getTime()}`;
      }
      return `${path}?v=${new Date().getTime()}`;
    },
    async drawCer() {
      const detailPosterInner = document.querySelector(`.getCerListItem${this.dataIx}`);
      let res = await (0, _html2canvas.default)(detailPosterInner, {
        useCORS: true,
        scale: 3,
        width: detailPosterInner.offsetWidth,
        height: detailPosterInner.offsetHeight
      });
      this.drawData = res.toDataURL();
    },
    handleName(words) {
      const deCrypt = new _index.Crypt();
      return deCrypt.decrypt(words);
    }
  }
};