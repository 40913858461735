"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const ctx = require.context('./modules/', true, /\.js/);
const modules = {};
ctx.keys().forEach(key => {
  let name = key.replace(/(^\.\/|\.js$)/g, ''); // 去后缀
  name = name.substr(0, 1).toLowerCase() + name.substr(1); // 首字母小写
  modules[name] = ctx(key).default;
});

// console.log('模块打印', modules)
var _default = exports.default = modules;