"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
require("core-js/modules/es.array.push.js");
var _vue = _interopRequireDefault(require("vue"));
var _index = _interopRequireDefault(require("@/router/index.js"));
_vue.default.prototype.$PUSH = params => {
  /* 不用 setTimeout 修改为异步的话，无法触发 v-monitor */
  setTimeout(() => {
    let pathinfo = JSON.parse(sessionStorage.getItem('path'));
    if (!pathinfo) {
      pathinfo = [];
    }
    pathinfo.push({
      path: params.path,
      query: params.query,
      replace: params.replace
    });
    console.log(JSON.stringify(pathinfo));

    //sessionStorage.setItem("pages", Number(sessionStorage.getItem("pages"))+1)
    sessionStorage.setItem("path", JSON.stringify(pathinfo));
    _index.default.push({
      path: params.path,
      query: params.query,
      replace: params.replace
    });
  }, 0);
};
_vue.default.prototype.$REDIRECT = params => {
  _index.default.replace({
    path: params.path,
    query: params.query
  });
};
_vue.default.prototype.$GO = param => {
  //sessionStorage.setItem("pages", sessionStorage.getItem("pages")-1)
  let pathinfo = sessionStorage.getItem('path');
  if (!pathinfo) {
    _index.default.replace({
      path: "/"
    });
    return;
  }
  // if(sessionStorage.getItem("pages")-1 <= 1){

  // }

  // sessionStorage.setItem("pages", sessionStorage.getItem("pages")-1)

  _index.default.go(param);
};