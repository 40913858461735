"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = _interopRequireDefault(require("utils/http"));
var _default = exports.default = {
  /* 首页-每日推荐 */
  getHomeRec(params) {
    return (0, _http.default)({
      url: `/api/platform/home/recommend`,
      method: 'get'
    });
  },
  /* 首页-Banner推荐 */
  getHomeBanner(params) {
    return (0, _http.default)({
      url: `/api/platform/home/banner`,
      method: 'get'
    });
  },
  /* 首页-功能隐藏-设置 */
  getHomeSetting(params) {
    return (0, _http.default)({
      url: `/api/platform/home/setting`,
      method: 'get'
    });
  },
  /* 首页-课程推荐(最新0/最热1) 返回5条 */
  getContentHome(params, show_type) {
    return (0, _http.default)({
      url: `/api/platform/content/home/${show_type}`,
      method: 'get'
    });
  },
  /* 成就分享的回流 */
  setMedalcertificateback(params) {
    return (0, _http.default)({
      url: `/api/platform/medalcertificateback`,
      method: 'post',
      data: params
    });
  },
  /* 首页-直播推荐 返回3条 */
  getHomeMeeting(params, show_type) {
    return (0, _http.default)({
      url: `/api/platform/home/home-meeting`,
      method: 'get'
    });
  },
  imageupload(params) {
    return (0, _http.default)({
      url: `/api/platform/imageupload`,
      method: 'post',
      upload: 1,
      data: params
    });
  },
  homeSearch(params) {
    return (0, _http.default)({
      url: `/api/platform/home-search`,
      method: 'get',
      params
    });
  }
};