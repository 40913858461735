"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _config = _interopRequireDefault(require("@/config"));
var _index = require("@/utils/index.js");
const WXSDK = require('weixin-js-sdk');
var _default = exports.default = window.$initPage({
  components: {},
  data() {
    return {
      aaa: "",
      bbb: "",
      base64Src: ""
    };
  },
  watch: {},
  filter: {},
  created() {
    // this.clear()
    this.setmyVideo();
  },
  mounted() {},
  computed: {},
  methods: {
    async uploadImg() {
      let res = await this.$chooseImage();
      console.log(res, '==>res');
      if (res !== null && res !== void 0 && res.imageBase64) {
        this.fileInputChange(res.imageBase64);
      }
      // this.$refs["fileInput"].click()
    },
    async fileInputChange(base64) {
      const self = this;
      console.log();
      let params = new FormData();
      params.append("file", base64);
      console.log(this.$api, '==> this.$api');
      console.log(this.$api.imageuploadBase64, '==>this.$api.imageuploadBase64');
      let res = await this.$api.imageuploadBase64(params);
      if (res !== null && res !== void 0 && res.errcode) {
        // alert("文件上传失败")
        this.$toatsView.show({
          tipWords: `文件上传失败，请重试`
        });
        return;
      }
      this.base64Src = res.url;
    },
    loadedmetadata(evt) {
      console.log(evt.target.duration, '==> evt');
    },
    setmyVideo() {
      this.$nextTick(() => {
        this.$refs.myVideo.src = `https://cn-ph-new-rad-q.s3.cn-north-1.amazonaws.com.cn/minisite/20230210/rc-upload-1676012878377-31.mp4`;
        const a = this.$refs.myVideo.duration;
        console.log(a, '==> a');
        // console.log(document.get (a).addEventListenter("loadedmetadata", function() {
        //   console.log(this,'==> refs.myVideo.addEventListenter this');
        // }),'==>m .$refs.myVideo');
      });
    },
    clear() {
      this.$cookies.remove('redirect', '');
      this.$cookies.remove('openid', '');
      this.$cookies.remove('wechatInfo', '');
      alert("清除成功--cookie");

      // window.location.href = "/bayer-rad-academy-shadowcloud-academy-html"
    },
    //清除所有cookie函数
    clearAllCookie() {
      var date = new Date();
      date.setTime(date.getTime() - 10000);
      //keys为所要删除cookie的名字
      var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
      //删除所有cookie
      if (keys) {
        for (var i = keys.length; i--;) document.cookie = keys[i] + "=0; expire=" + date.toGMTString() + "; path=/";
        document.cookie = keys[i] + "=0; expire=" + date.toGMTString() + "; path=/bayer-rad-academy-shadowcloud-academy-html";
        document.cookie = keys[i] + "=0; expire=" + date.toGMTString() + "; path=bayer-rad-academy-shadowcloud-academy-html/";
      }
    },
    claerCookie() {
      // var cookies = document.cookie.split(";");
      // for (var i = 0; i < cookies.length; i++) {
      //   var cookie = cookies[i];
      //   var eqPos = cookie.indexOf("=");
      //   var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      //   document.cookie =
      //     name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      // }
      // if (cookies.length > 0) {
      //   for (var i = 0; i < cookies.length; i++) {
      //     var cookie = cookies[i];
      //     var eqPos = cookie.indexOf("=");
      //     var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      //     var domain = location.host.substr(location.host.indexOf("."));
      //     document.cookie =
      //       name +
      //       "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=" +
      //       domain;
      //   }
      // }

      // this.clearAllCookie()

      this.$cookies.set("openid", "", "1s", "/bayer-rad-academy-shadowcloud-academy-html");
      this.$cookies.set("wechatInfo", "", "1s", "/bayer-rad-academy-shadowcloud-academy-html");
      this.$cookies.set("redirect", "", "1s", "/bayer-rad-academy-shadowcloud-academy-html");
      this.$cookies.remove('redirect', '');
      this.$cookies.remove('openid', '');
      this.$cookies.remove('wechatInfo', '');
      localStorage.clear();
      alert("清除成功");
      setTimeout(() => {
        window.location.href = "/bayer-rad-academy-shadowcloud-academy-html";
      }, 2000);
    },
    claerLocal() {
      localStorage.clear();
      alert("清除成功 --local --cookie");
      // window.location.href = "/bayer-rad-academy-shadowcloud-academy-html"
    },
    setCrypt() {
      const myCrypt = new _index.Crypt();
      console.log(myCrypt, "==> myCrypt");
      let params = {
        aaa: myCrypt.encrypt(this.aaa),
        bbb: myCrypt.encrypt(this.bbb),
        crypt_key: myCrypt.rsa_key()
      };
      console.log(params, '==> params');
    }
  }
});