"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _monitor = require("@/utils/monitor");
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default
  },
  data() {
    return {
      link: null,
      qtitle: "",
      detail: {}
    };
  },
  created() {
    this.$store.commit("SET_LOADINGPOP", true);
    this.init();
  },
  methods: {
    async initWeChatShare() {
      var _this$detail;
      this.weChatShare.title = this.detail.title;
      this.weChatShare.desc = this.detail.title;
      this.weChatShare.imgUrl = ((_this$detail = this.detail) === null || _this$detail === void 0 ? void 0 : _this$detail.cover) || `${this.$publicPath}/shareIcon.png`;
      // this.weChatShare.imgUrl = `${this.$publicPath}/shareIcon.png`

      this.initWechat();
    },
    async init() {
      const {
        query
      } = this.$route;
      const wechatInfo = JSON.parse(localStorage.getItem("wechatInfo") || "{}");
      let res = await this.$api.getQuestionDetail({}, query.id);
      console.log(_monitor.monitorView, '==>monitorView>monitorView>monitorView');
      // monitorView()
      this.detail = res;
      this.initWeChatShare();
      this.$store.commit("SET_LOADINGPOP", false);
      let link = res.link;
      this.link = link.indexOf("?") != -1 ? link += `&sojumpparm=${wechatInfo.unionid}` : link += `?sojumpparm=${wechatInfo.unionid}`;
      // if (link.indexOf("radbayer.wjx.cn") != -1) {

      //     return
      // }
      // this.link = link
    },
    toPath() {
      window.open(this.link);
      // window.open(this.link, "_blank")
    }
  }
});