"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _login = _interopRequireDefault(require("@/components/customPopview/login.vue"));
var _privacy = _interopRequireDefault(require("@/components/customPopview/privacy.vue"));
var _terms = _interopRequireDefault(require("@/components/customPopview/terms.vue"));
var _index = _interopRequireDefault(require("@/components/customPopview/index.vue"));
var _newAgree = _interopRequireDefault(require("@/components/customPopview/newAgree.vue"));
var _ImportRegister = _interopRequireDefault(require("@/components/customPopview/ImportRegister.vue"));
var _hospitalLose = _interopRequireDefault(require("@/components/customPopview/hospitalLose.vue"));
var _experiencePop = _interopRequireDefault(require("@/components/customPopview/experiencePop.vue"));
//使用extend创建的是一个组件构造器
const registerViewConstructor = _vue.default.extend(_login.default);
const privacyViewConstructor = _vue.default.extend(_privacy.default);
const termsViewConstructor = _vue.default.extend(_terms.default);
const toatsViewConstructor = _vue.default.extend(_index.default);
const newAgreeViewConstructor = _vue.default.extend(_newAgree.default);
const importRegisterViewConstructor = _vue.default.extend(_ImportRegister.default);
const hospitalLoseViewConstructor = _vue.default.extend(_hospitalLose.default);
const experiencePopConstructor = _vue.default.extend(_experiencePop.default);

/* 注册弹窗 Start */
//实例化组并创建一个div
let instance = new registerViewConstructor({
  el: document.createElement('div')
});
const newRegisterView = {
  show(callBack) {
    // 接受传递过来的问题，调用此方法时同事显示弹窗
    instance.open(callBack);
    document.body.appendChild(instance.$el);
  },
  hide() {
    // 隐藏方法
    instance.close();
  }
};
/* 注册弹窗 End */

/* 30天体验权限弹窗 Start */
//实例化组并创建一个div
let instanceExperience = new experiencePopConstructor({
  el: document.createElement('div')
});
const instanceExperienceView = {
  show(options) {
    // 接受传递过来的问题，调用此方法时同事显示弹窗
    // let {isBack,callBack,days} = options;
    instanceExperience.open(options);
    document.body.appendChild(instanceExperience.$el);
  },
  hide() {
    // 隐藏方法
    instanceExperience.close();
  }
};
/* 30天体验权限弹窗 End */

/* 医院无效弹窗 Start */
//实例化组并创建一个div
let hospitalLoseInstance = new hospitalLoseViewConstructor({
  el: document.createElement('div')
});
const hospitalLose = {
  show(callBack) {
    // 接受传递过来的问题，调用此方法时同事显示弹窗
    hospitalLoseInstance.open(callBack);
    document.body.appendChild(hospitalLoseInstance.$el);
  },
  hide() {
    // 隐藏方法
    hospitalLoseInstance.close();
  }
};
/* 医院无效弹窗 End */

/* 导入用户的完善信息弹窗 Start */
//实例化组并创建一个div
let importRegisterInstance = new importRegisterViewConstructor({
  el: document.createElement('div')
});
const newImportRegister = {
  show(callBack) {
    // 接受传递过来的问题，调用此方法时同事显示弹窗
    importRegisterInstance.open(callBack);
    document.body.appendChild(importRegisterInstance.$el);
  },
  hide() {
    // 隐藏方法
    importRegisterInstance.close();
  }
};
/* 导入用户的完善信息弹窗 End */

/* 全局弹窗 Start */
//实例化组并创建一个div
let toatsInstance = new toatsViewConstructor({
  el: document.createElement('div')
});
const newToatsView = {
  show(callBack) {
    // 接受传递过来的问题，调用此方法时同事显示弹窗
    toatsInstance.open(callBack);
    document.body.appendChild(toatsInstance.$el);
  },
  hide(params) {
    // 隐藏方法
    toatsInstance.close(params);
  }
};
/* 全局弹窗 End */

/* 个人信息披露知情同意 Start */
//实例化组并创建一个div
let newAgreeViewinstance = new newAgreeViewConstructor({
  el: document.createElement('div')
});
const newPrivacyInstanceView = {
  show(callBack) {
    // 接受传递过来的问题，调用此方法时同事显示弹窗
    newAgreeViewinstance.open(callBack);
    document.body.appendChild(newAgreeViewinstance.$el);
  },
  hide() {
    // 隐藏方法
    newAgreeViewinstance.close();
  }
};
/* 个人信息披露知情同意 End */

/* 隐私声明 Start */
//实例化组并创建一个div
let privacyViewinstance = new privacyViewConstructor({
  el: document.createElement('div')
});
const newPrivacyView = {
  show(callBack) {
    // 接受传递过来的问题，调用此方法时同事显示弹窗
    privacyViewinstance.open(callBack);
    document.body.appendChild(privacyViewinstance.$el);
  },
  hide() {
    // 隐藏方法
    privacyViewinstance.close();
  }
};
/* 隐私声明 End */

/* 使用条款 Start */
//实例化组并创建一个div
let termsViewinstance = new termsViewConstructor({
  el: document.createElement('div')
});
const newtermsView = {
  show(callBack) {
    // 接受传递过来的问题，调用此方法时同事显示弹窗
    termsViewinstance.open(callBack);
    document.body.appendChild(termsViewinstance.$el);
  },
  hide() {
    // 隐藏方法
    termsViewinstance.close();
  }
};
/* 使用条款 End */

const install = function () {
  if (!_vue.default.prototype.$registerView) {
    _vue.default.prototype.$registerView = newRegisterView;
  }
  ;
  if (!_vue.default.prototype.$importRegisterView) {
    _vue.default.prototype.$importRegisterView = newImportRegister;
  }
  ;
  if (!_vue.default.prototype.$hospitalLoseView) {
    _vue.default.prototype.$hospitalLoseView = hospitalLose;
  }
  ;
  if (!_vue.default.prototype.$privacyView) {
    _vue.default.prototype.$privacyView = newPrivacyView;
  }
  ;
  if (!_vue.default.prototype.$toatsView) {
    _vue.default.prototype.$toatsView = newToatsView;
  }
  ;
  if (!_vue.default.prototype.$termsView) {
    _vue.default.prototype.$termsView = newtermsView;
  }
  ;
  if (!_vue.default.prototype.$privacyInstanceView) {
    _vue.default.prototype.$privacyInstanceView = newPrivacyInstanceView;
  }
  ;
  if (!_vue.default.prototype.$instanceExperienceView) {
    _vue.default.prototype.$instanceExperienceView = instanceExperienceView;
  }
  ;
  _vue.default.mixin({
    created() {
      _vue.default.prototype.$toatsView = _vue.default.prototype.$toatsView;
      _vue.default.prototype.$registerView = _vue.default.prototype.$registerView;
      _vue.default.prototype.$importRegisterView = _vue.default.prototype.$importRegisterView;
      _vue.default.prototype.$privacyView = _vue.default.prototype.$privacyView;
      _vue.default.prototype.$termsView = _vue.default.prototype.$termsView;
      _vue.default.prototype.$privacyInstanceView = _vue.default.prototype.$privacyInstanceView;
      _vue.default.prototype.$instanceExperienceView = _vue.default.prototype.$instanceExperienceView;
    }
  });
};
var _default = exports.default = install;