"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "notify"
  }, [_c("stickyHeader"), _c("div", {
    staticClass: "firstSeat"
  }), _vm._l(_vm.messageList, function (im, ix) {
    return _c("div", {
      key: ix,
      staticClass: "notifyList"
    }, [_c("div", {
      staticClass: "notifyTimeLine"
    }, [_vm._v(_vm._s(im.created_at))]), im.action_type == 1 && im.content_type == 3 ? _c("div", {
      staticClass: "notifyMessage flex justify-between"
    }, [_vm._m(0, true), _c("div", {
      staticClass: "notifyMessageRight"
    }, [_c("img", {
      staticClass: "messageOrr",
      attrs: {
        src: require("img/notify/messageOrr.png"),
        alt: ""
      }
    }), _c("VeevaCode", {
      attrs: {
        dataBase: im
      }
    })], 1)]) : _vm._e(), im.action_type == 1 && im.content_type == 1 ? _c("div", {
      staticClass: "notifyMessage flex justify-between"
    }, [_vm._m(1, true), _c("div", {
      staticClass: "notifyMessageRight"
    }, [_c("img", {
      staticClass: "messageOrr",
      attrs: {
        src: require("img/notify/messageOrr.png"),
        alt: ""
      }
    }), _c("VeevaSupple")], 1)]) : _vm._e(), im.action_type == 1 && im.content_type == 4 ? _c("div", {
      staticClass: "notifyMessage flex justify-between"
    }, [_vm._m(2, true), _c("div", {
      staticClass: "notifyMessageRight"
    }, [_c("img", {
      staticClass: "messageOrr",
      attrs: {
        src: require("img/notify/messageOrr.png"),
        alt: ""
      }
    }), _c("VeevaCodeNew", {
      attrs: {
        dataBase: im,
        list: _vm.messageList
      }
    })], 1)]) : _vm._e(), im.action_type == 0 ? _c("div", {
      staticClass: "notifyMessage flex justify-between"
    }, [_vm._m(3, true), _c("div", {
      staticClass: "notifyMessageRight"
    }, [_c("img", {
      staticClass: "messageOrr",
      attrs: {
        src: require("img/notify/messageOrr.png"),
        alt: ""
      }
    }), _c("div", {
      staticClass: "notifyMessageRightContent"
    }, [_vm._v(_vm._s(im.content))])])]) : _vm._e(), im.action_type == 1 && im.content_type == 0 ? _c("div", {
      staticClass: "notifyMessage flex justify-between"
    }, [_vm._m(4, true), _c("div", {
      staticClass: "notifyMessageRight"
    }, [_c("img", {
      staticClass: "messageOrr",
      attrs: {
        src: require("img/notify/messageOrr.png"),
        alt: ""
      }
    }), _c("div", {
      staticClass: "notifyMessageRightContent"
    }, [_vm._v(_vm._s(im.content))])])]) : _vm._e(), im.action_type == 1 && im.content_type == 2 ? _c("div", {
      staticClass: "notifyMessage flex justify-between"
    }, [_vm._m(5, true), _c("div", {
      staticClass: "notifyMessageRight"
    }, [_c("img", {
      staticClass: "messageOrr",
      attrs: {
        src: require("img/notify/messageOrr.png"),
        alt: ""
      }
    }), _c("VeevaAddSupple")], 1)]) : _vm._e(), im.action_type == 2 ? _c("div", {
      staticClass: "notifyMessage flex justify-between"
    }, [_vm._m(6, true), _c("div", {
      staticClass: "notifyMessageRight"
    }, [_c("img", {
      staticClass: "messageOrr",
      attrs: {
        src: require("img/notify/messageOrr.png"),
        alt: ""
      }
    }), _c("div", {
      staticClass: "veevaCodeWords"
    }, [_c("div", {
      staticClass: "veevaCodeWordsWords"
    }, [_vm._v("恭喜您已获得30日免认证学习资格，即刻畅享训练营等精品课程！")]), _c("div", {
      staticClass: "veevaCodeWordsBtn",
      on: {
        click: function ($event) {
          return _vm.$PUSH({
            path: "/activity"
          });
        }
      }
    }, [_vm._v(" 立即学习 ")])])])]) : _vm._e(), im.action_type == 3 ? _c("div", {
      staticClass: "notifyMessage flex justify-between"
    }, [_vm._m(7, true), _c("div", {
      staticClass: "notifyMessageRight"
    }, [_c("img", {
      staticClass: "messageOrr",
      attrs: {
        src: require("img/notify/messageOrr.png"),
        alt: ""
      }
    }), _c("div", {
      staticClass: "veevaCodeWords"
    }, [_c("div", {
      staticClass: "veevaCodeWordsWords"
    }, [_vm._v("想要解锁永久学习权益？点击下方蓝色文字，身份认证通过后即可获得！还可一键解锁学术能量排行榜，参与更多优质活动！")]), _c("div", {
      staticClass: "veevaCodeWordsBtn",
      on: {
        click: function ($event) {
          return _vm.$PUSH({
            path: "/veeva"
          });
        }
      }
    }, [_vm._v(" 前往认证 ")])])])]) : _vm._e(), im.action_type == 4 ? _c("div", {
      staticClass: "notifyMessage flex justify-between"
    }, [_vm._m(8, true), _c("div", {
      staticClass: "notifyMessageRight"
    }, [_c("img", {
      staticClass: "messageOrr",
      attrs: {
        src: require("img/notify/messageOrr.png"),
        alt: ""
      }
    }), _c("div", {
      staticClass: "veevaCodeWords"
    }, [_c("div", {
      staticClass: "veevaCodeWordsWords"
    }, [_vm._v("已收到您的认证资料，预计在1-2周内完成处理，您已获得20天免认证学习资格，认证通过后将享受永久权益，即刻畅享系列精品课等学习权益吧！")]), _c("div", {
      staticClass: "veevaCodeWordsBtn",
      on: {
        click: function ($event) {
          return _vm.$PUSH({
            path: "/activity"
          });
        }
      }
    }, [_vm._v(" 立即学习 ")])])])]) : _vm._e(), im.action_type == 5 ? _c("div", {
      staticClass: "notifyMessage flex justify-between"
    }, [_vm._m(9, true), _c("div", {
      staticClass: "notifyMessageRight"
    }, [_c("img", {
      staticClass: "messageOrr",
      attrs: {
        src: require("img/notify/messageOrr.png"),
        alt: ""
      }
    }), _c("div", {
      staticClass: "veevaCodeWords"
    }, [_c("div", {
      staticClass: "veevaCodeWordsWords"
    }, [_vm._v("还差一步！本内容仅供医疗卫生专业人士学习交流使用，前往认证即可立即解锁免费学习权益！")]), _c("div", {
      staticClass: "veevaCodeWordsBtn",
      on: {
        click: function ($event) {
          return _vm.$PUSH({
            path: "/veeva"
          });
        }
      }
    }, [_vm._v(" 前往认证 ")])])])]) : _vm._e()]);
  })], 2);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "notifyMessageLeft"
  }, [_c("img", {
    attrs: {
      src: require("img/notify/icon.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "notifyMessageLeft"
  }, [_c("img", {
    attrs: {
      src: require("img/notify/icon.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "notifyMessageLeft"
  }, [_c("img", {
    attrs: {
      src: require("img/notify/icon.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "notifyMessageLeft"
  }, [_c("img", {
    attrs: {
      src: require("img/notify/icon.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "notifyMessageLeft"
  }, [_c("img", {
    attrs: {
      src: require("img/notify/icon.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "notifyMessageLeft"
  }, [_c("img", {
    attrs: {
      src: require("img/notify/icon.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "notifyMessageLeft"
  }, [_c("img", {
    attrs: {
      src: require("img/notify/icon.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "notifyMessageLeft"
  }, [_c("img", {
    attrs: {
      src: require("img/notify/icon.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "notifyMessageLeft"
  }, [_c("img", {
    attrs: {
      src: require("img/notify/icon.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "notifyMessageLeft"
  }, [_c("img", {
    attrs: {
      src: require("img/notify/icon.png"),
      alt: ""
    }
  })]);
}];
render._withStripped = true;