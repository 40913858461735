"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/customSelect/index.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    customSelect: _index.default
  },
  data() {
    return {
      pageQuery: null,
      tipWords: "",
      formData: {}
    };
  },
  watch: {},
  filter: {},
  created() {
    this.pageQuery = this.$route.query;
    this.formData = {
      ...this.userInfo
    };
  },
  mounted() {},
  computed: {
    positionSelectList() {
      return this.$store.getters.positionSelectList;
    },
    skillAreaSelectList() {
      return this.$store.getters.skillAreaSelectList;
    },
    intrastingTopicSelectList() {
      return this.$store.getters.intrastingTopicSelectList;
    },
    intrastingSysSelectList() {
      return this.$store.getters.intrastingSysSelectList;
    },
    workUpgradesSelectList() {
      return this.$store.getters.workUpgradesSelectList;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    /* 文件上传 Start */
    async uploadImg() {
      // this.$refs["fileInput"].click()
      let res = await this.$chooseImage();
      if (res !== null && res !== void 0 && res.imageBase64) {
        this.fileInputChange(res.imageBase64);
      }
    },
    async fileInputChange(base64) {
      const self = this;
      const selectFileInfo = self.$refs.fileInput.files;
      console.log(selectFileInfo, '==> selectFileInfo');
      let params = new FormData();
      params.append("file", base64);
      let res = await this.$api.imageuploadBase64(params);
      if (res !== null && res !== void 0 && res.errcode) {
        // alert("文件上传失败")
        this.$toatsView.show({
          tipWords: `文件上传失败，请重试`
        });
        return;
      }
      this.formData.avatar_rankings = res.url;
      this.$forceUpdate();
    },
    async registerSubmit() {
      let params = JSON.parse(JSON.stringify(this.formData));
      params['flag'] = 2;
      delete params["sync_scrm_flag"];
      let res = await this.$api.updateUserInfo(params);
      if (res !== null && res !== void 0 && res.errcode) {
        // alert("提交失败")
        this.$toatsView.show({
          tipWords: `提交失败，请重试`
        });
        return;
      }
      this.$toatsView.show({
        tipWords: "提交成功"
      });
      this.$store.dispatch("getUserInfo");
      // this.$REDIRECT({ path: '/' })

      setTimeout(() => {
        this.$GO(-1);
      }, 1500);
    },
    customPopCancle() {},
    customPopConfirm() {},
    registerSuccessHandleDelay() {
      this.$refs['customPop-cus'].close();
    },
    registerSuccessHandleNow() {
      this.$refs['customPop-cus'].close();
    }
  }
});