"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Crypt = Crypt;
exports.debounce = void 0;
exports.decrypt = decrypt;
exports.encrypt = encrypt;
exports.getSmsAfter = void 0;
exports.getSystem = getSystem;
exports.getUrlParams = getUrlParams;
exports.handleParmas = handleParmas;
exports.limitData = exports.handleTagCss = void 0;
exports.param2Obj = param2Obj;
exports.throttle = exports.reachBottom = exports.quchong = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _cryptoJs = _interopRequireDefault(require("crypto-js"));
var _config = _interopRequireDefault(require("../config"));
var _jsencrypt = _interopRequireDefault(require("jsencrypt"));
/**
 * 索引
 * param2Obj - 获取 url 参数并转换成对象
 * handleParmas - 去除无效参数（undefined、null、0 等等）
 * getUrlParams - 获取 url 参数并转换成对象
 * encrypt - 加密
 * decrypt - 解密
 * */

/**
 * @param {string} url
 * @returns {Object}
 */
function param2Obj(url) {
  const search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

/** 去除无效参数（undefined、null、0 等等） */
function handleParmas(params) {
  const keys = Object.keys(params);
  const result = {};
  keys.map(key => {
    result[key] = params[key];
  });
  return result;
}

// 获取链接上所有参数
function getUrlParams() {
  const urlArr = window.location.href.split('?'); // 获取url中"?"符后的字串
  let url = '';
  if (urlArr.length > 1) url = '?' + urlArr[1];
  const theRequest = new Object();
  if (url.indexOf('?') != -1) {
    const str = url.substr(1);
    const strs = str.split('&');
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1]);
    }
  }
  return theRequest;
}
/** 加密
 * @param word 加密数据
 */
function encrypt(word) {
  word = `${word || ''}`;
  if (word) {
    try {
      const key = _cryptoJs.default.enc.Utf8.parse(_config.default.crypto.key);
      const iv = _cryptoJs.default.enc.Utf8.parse(_config.default.crypto.iv);
      const encoded = _cryptoJs.default.AES.encrypt(word, key, {
        iv,
        mode: _cryptoJs.default.mode.CBC,
        padding: _cryptoJs.default.pad.Pkcs7
      });
      return encoded.toString();
    } catch (err) {
      return err;
    }
  }
  return '';
}

/** 解密
 * @param word 解密数据
 */
function decrypt(word) {
  word = `${word || ''}`;
  if (word) {
    try {
      const key = _cryptoJs.default.enc.Utf8.parse(_config.default.crypto.key);
      const iv = _cryptoJs.default.enc.Utf8.parse(_config.default.crypto.iv);
      const decode = _cryptoJs.default.AES.decrypt(word, key, {
        iv,
        mode: _cryptoJs.default.mode.CBC,
        padding: _cryptoJs.default.pad.Pkcs7
      });
      return decode.toString(_cryptoJs.default.enc.Utf8);
    } catch (err) {
      return err;
    }
  }
  return '';
}
/** 新版加密
 * @param word 加密数据
 */
function Crypt() {
  this.iv = _cryptoJs.default.lib.WordArray.random(128 / 16).toString(_cryptoJs.default.enc.Hex);
  this.key = _cryptoJs.default.lib.WordArray.random(128 / 8).toString(_cryptoJs.default.enc.Hex);
  this.publicKey = _config.default.crypto.publicKey;
  this.encrypt = function (text) {
    if (text) {
      try {
        const encoded = _cryptoJs.default.AES.encrypt(text, _cryptoJs.default.enc.Utf8.parse(this.key), {
          iv: _cryptoJs.default.enc.Utf8.parse(this.iv),
          mode: _cryptoJs.default.mode.CBC,
          padding: _cryptoJs.default.pad.Pkcs7
        });
        return encoded.toString();
      } catch (err) {
        return err;
      }
    }
    return '';
  };
  this.decrypt = function (text) {
    if (text) {
      /* cryptIv: state => "bXnJpZoClCchkPNM",
      cryptKey: state => "xdz3gagd3M0jMXmI", */
      const iv = "bXnJpZoClCchkPNM";
      const key = "xdz3gagd3M0jMXmI";
      try {
        const decode = _cryptoJs.default.AES.decrypt(text, _cryptoJs.default.enc.Utf8.parse(key), {
          iv: _cryptoJs.default.enc.Utf8.parse(iv),
          mode: _cryptoJs.default.mode.CBC,
          padding: _cryptoJs.default.pad.Pkcs7
        });
        return decode.toString(_cryptoJs.default.enc.Utf8);
      } catch (err) {
        return err;
      }
    }
    return '';
  };
  this.rsa_key = function () {
    const encrypt = new _jsencrypt.default();
    encrypt.setPublicKey(this.publicKey);
    return encrypt.encrypt(JSON.stringify({
      iv: this.iv,
      key: this.key
    }));
  };
}

/** 防抖
 * @param fn 业务逻辑函数
 * @param time 延迟时间
 */
const debounce = (fn, time = 500) => {
  let timer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = null;
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, time);
  };
};

/** 节流
 * @param fn 业务逻辑函数
 * @param time 延迟时间
 */
exports.debounce = debounce;
const throttle = (fn, time = 300) => {
  let timer;
  return function () {
    const context = this;
    const args = arguments;
    if (!timer) {
      timer = setTimeout(function () {
        timer = null;
        fn.apply(context, args);
      }, time);
    }
  };
};

/** 获取系统 */
exports.throttle = throttle;
function getSystem() {
  const u = navigator.userAgent;
  const ua = window.navigator.userAgent.toLowerCase();
  return {
    iOS: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
    android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
    wechat: ua.match(/MicroMessenger/i) === 'micromessenger'
  };
}

// 获取验证码 -- 接口成功后的动作
class getSmsAfter {
  constructor() {
    (0, _defineProperty2.default)(this, "countDown", dom => {
      this.timer = setInterval(() => {
        this.time--;
        /* 兼容jq */
        dom && $(dom).text(this.time);
        this.words = `${this.time}后重新获取`;
        if (this.time < 1) {
          clearInterval(this.timer);
          /* 兼容jq */
          dom && $(dom).text("获取验证码");
          this.words = "获取验证码";
          this.time = 60;
          this.timer = null;
        }
      }, 1000);
    });
    (0, _defineProperty2.default)(this, "clearStatus", dom => {
      clearInterval(this.timer);
      this.timer = null;
      this.words = "获取验证码";
      /* 兼容jq */
      dom && $(dom).text("获取验证码");
      this.time = 60;
    });
    this.timer = null;
    this.time = 60;
    this.words = "获取验证码";
  }
}

/** 分页接口数据处理
  * @param params.interfaceData 接口返回数据
  * @param params.baseData 页面现有数据
  * @param params.limit 接口传入的分页参数
*/
exports.getSmsAfter = getSmsAfter;
const limitData = params => {
  let interfaceData = params.interfaceData;
  let baseData = params.baseData;
  let limit = params.limit;
  let currentAdd = false;
  /* 当前数据的长度和返回的总条数一样  不做如何动作 */
  if (baseData.length >= interfaceData.total) {
    // alert("全部加载了")

    return {
      baseData,
      currentAdd
    };
  }
  /* 当前数据的长度和返回的总条数不一样 并且条数没满params[pageSize]的数量 */

  if (interfaceData.data.length < limit["pageSize"]) {
    /* 首先把多余的数据去除 */
    baseData = baseData.slice(0, Number(limit["pageSize"]) * Number(limit["current"]));
    /* 去除多余数据后，将本次数据和去除后的数据合并 */
    baseData = [...baseData, ...interfaceData.data];
    return {
      baseData,
      currentAdd
    };
  }
  currentAdd = true;
  baseData = [...baseData, ...interfaceData.data];
  return {
    baseData,
    currentAdd
  };
};

/* 去重 */
exports.limitData = limitData;
const quchong = arr => {
  const tempMap = new Map();
  return arr.filter(im => !tempMap.has(im.id) && tempMap.set(im.id, im));
};

/* 触底 */
exports.quchong = quchong;
class reachBottom {
  constructor(enterParams) {
    console.log(enterParams, '==>enterParams reachBottom');
    this.loadFn = enterParams.loadFn;
    this.isLoading = false;
    console.log(this, '==>this-- reachBottom');
  }
  reachBottomHandle() {
    console.log("enter utils reachBottom");
    console.log(this, '==> for utils reachBottom');

    /* if (evt.detail.path != "/activity") {
      return
    } */

    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.loadFn();
  }
  init() {
    console.log(this, '==> this reachBottom init');
    window.addEventListener("reachBottom", this.reachBottomHandle);
  }
  destroy() {
    window.removeEventListener("reachBottom", this.reachBottomHandle);
  }
}
exports.reachBottom = reachBottom;
const handleTagCss = (outDom, innerDom, innerDomItem) => {
  /* 
    outDom = this.$refs.meetingTag
    innerDom = this.$refs.meetingTagInners
    innerDomItem = this.$refs.meetingTagItem
  */
  const scaleNum = outDom.offsetWidth / 100;
  let textw = innerDom.offsetWidth;
  let boxw = outDom.offsetWidth;
  let num = Math.ceil(textw / boxw);
  let maxnum = 3;
  if (num <= 2 && num > 1) {
    maxnum = 2;
  } else if (num <= 1) {
    maxnum = 1;
  }
  let addwidth = innerDom.offsetWidth / maxnum + 10;
  let starw = 0;
  let maxw = 0;
  for (var i = 0; i < innerDomItem.length; i++) {
    var computedStyle = getComputedStyle(innerDomItem[i], null);
    starw = starw + innerDomItem[i].clientWidth + parseInt(computedStyle.marginRight) + 1;
    console.log(maxw, starw, 'maxw', i, addwidth);
    if (starw >= addwidth && starw >= maxw) {
      console.log(i);
      maxw = starw;
      starw = 0;
    } else if (starw >= addwidth) {
      starw = 0;
    }
    // console.log(maxw,i)
  }
  innerDom.style.width = maxw + 'px';
  innerDom.style.whiteSpace = 'normal';
};
exports.handleTagCss = handleTagCss;