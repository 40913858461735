"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("stickyHeader"), _c("div", {
    staticClass: "pageHeaderSearch flex align-center"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchWords,
      expression: "searchWords"
    }],
    attrs: {
      type: "text",
      placeholder: "请输入名称",
      "placeholder-style": "color: #57585a;"
    },
    domProps: {
      value: _vm.searchWords
    },
    on: {
      keydown: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.tosearch.apply(null, arguments);
      },
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.searchWords = $event.target.value;
      }
    }
  }), _c("img", {
    attrs: {
      src: require("img/home/searchIcon.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.tosearch.apply(null, arguments);
      }
    }
  })]), _c("div", {
    staticClass: "pageSwiper"
  }, [_c("van-swipe", {
    staticClass: "my-swipe",
    staticStyle: {
      height: "100%"
    },
    attrs: {
      autoplay: 3000,
      "indicator-color": "white"
    }
  }, _vm._l(_vm.bannerList, function (im, ix) {
    return _c("van-swipe-item", {
      key: ix,
      on: {
        click: function ($event) {
          return _vm.toLink(im);
        }
      }
    }, [_c("img", {
      staticStyle: {
        width: "100%",
        height: "100%"
      },
      attrs: {
        src: im.cover,
        alt: ""
      }
    })]);
  }), 1)], 1), _c("div", {
    staticClass: "forum"
  }, [_c("div", {
    staticClass: "forumTitle fontWeight"
  }, [_vm._v("精讲堂")]), _c("div", {
    staticClass: "forumEntry flex flex-wrap"
  }, _vm._l(_vm.courseCateList, function (im, ix) {
    return _c("div", {
      directives: [{
        name: "monitor",
        rawName: "v-monitor"
      }],
      key: ix,
      staticClass: "forumEntryItem flex flex-direction align-center",
      attrs: {
        "event-key": `精讲堂${im.name}`
      },
      on: {
        click: function ($event) {
          return _vm.$PUSH({
            path: "/course/class",
            query: {
              id: im.id
            }
          });
        }
      }
    }, [_c("div", {
      staticClass: "forumEntryItemIcon"
    }, [_c("img", {
      attrs: {
        src: im.cover,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "forumEntryItemTitle"
    }, [_vm._v(_vm._s(im.name))])]);
  }), 0)]), _c("div", {
    staticClass: "recommendCourse"
  }, [_c("div", {
    staticClass: "recommendCourseTitle fontWeight"
  }, [_vm._v("推荐课程")]), _c("div", {
    staticClass: "recommendCourseFilter flex align-center"
  }, [_vm._l(_vm.recommendCourseFilter, function (im, ix) {
    return _c("div", {
      key: ix,
      staticClass: "recommendCourseFilterItem fontWeight",
      class: _vm.recommendCourseFilterType == im.type ? "recommendCourseFilterItemCheck" : "",
      on: {
        click: function ($event) {
          _vm.recommendCourseFilterType = im.type;
        }
      }
    }, [_vm._v(_vm._s(im.title))]);
  }), _c("div", {
    staticClass: "recommendCourseFilterItem fontWeight"
  })], 2), _c("div", {
    staticClass: "recommendCourseList"
  }, [_vm._l(_vm.lessonList, function (im, ix) {
    var _im$content;
    return _c("courseList", {
      key: ix,
      attrs: {
        dataBase: im.content,
        moduleType: (_im$content = im.content) === null || _im$content === void 0 ? void 0 : _im$content.type
      }
    });
  }), !_vm.lessonList.length && _vm.loadFinal ? _c("div", {
    staticClass: "meetingEmpty flex align-center justify-center"
  }, [_vm._v(" 暂无数据 ")]) : _vm._e()], 2)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;