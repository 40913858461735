"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("vant/es/dialog/style");
var _dialog = _interopRequireDefault(require("vant/es/dialog"));
require("vant/es/toast/style");
var _toast = _interopRequireDefault(require("vant/es/toast"));
require("vant/es/popup/style");
var _popup = _interopRequireDefault(require("vant/es/popup"));
require("vant/es/loading/style");
var _loading = _interopRequireDefault(require("vant/es/loading"));
var _index = _interopRequireDefault(require("@/components/customSelect/index.vue"));
var _default = exports.default = {
  components: {
    customSelect: _index.default,
    vantLoading: _loading.default,
    vantPopup: _popup.default
  },
  props: {
    step: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      uploadErrorIndex: null,
      uploadError: false,
      showPopLoading: false,
      selecthospitalList: [],
      hospitalTip: "请输入医院关键字进行搜索",
      formData: {},
      fileList: [],
      fileListTemp: [],
      fileListProgress: {},
      fileListError: {},
      fileListStorage: [],
      veevaInfo: {},
      upCount: 25,
      errorUploadIndexArr: [],
      uploadFinalCount: 0,
      contrCount: 0
    };
  },
  watch: {
    fileListProgress() {},
    fileList(newVal, oldVal) {
      console.log(this.fileList, '==>fileList --fileList');
    },
    contrCount() {
      console.log(this.contrCount, '==>contrCount --contrCount');
    }
  },
  created() {
    const storageFileList = localStorage.getItem("fileList");
    const storageTempFileList = localStorage.getItem("fileListTemp");
    const storagefileListProgress = localStorage.getItem("fileListProgress");
    const storagefileListError = localStorage.getItem("fileListError");
    if (storageFileList) {
      this.fileList = JSON.parse(storageFileList);
      this.fileListTemp = JSON.parse(storageTempFileList);
      this.fileListProgress = JSON.parse(storagefileListProgress);
      this.fileListError = JSON.parse(storagefileListError);
    }
  },
  computed: {},
  methods: {
    isAllFinal() {
      return new Promise((resolve, reject) => {
        for (const key in this.fileListError) {
          if (this.fileListError[key].fileStatus != 'success') {
            resolve({
              status: false
            });
            return;
          }
        }
        resolve({
          status: true
        });
      });
    },
    checkImg1(im) {
      this.$SHOWIMGPREVIEW(im);
    },
    checkImg(im) {
      var _this$fileListError;
      if (this.fileListProgress[im.progress] != '100%' && ((_this$fileListError = this.fileListError) === null || _this$fileListError === void 0 || (_this$fileListError = _this$fileListError[im.progress]) === null || _this$fileListError === void 0 ? void 0 : _this$fileListError.fileStatus) != 'fail') return;
      this.$SHOWIMGPREVIEW(im.url);
    },
    setStorageBase() {
      const storageVeevaInfo = localStorage.getItem("veevaInfo");
      if (storageVeevaInfo) {
        this.initData(JSON.parse(storageVeevaInfo));
      }
    },
    async back() {
      let {
        status
      } = await this.isAllFinal();
      if (!status) {
        (0, _toast.default)('文件正在上传中，请稍后。');
        return;
      }
      localStorage.setItem("fileList", JSON.stringify(this.fileList));
      localStorage.setItem("fileListTemp", JSON.stringify(this.fileListTemp));
      localStorage.setItem("fileListProgress", JSON.stringify(this.fileListProgress));
      localStorage.setItem("fileListError", JSON.stringify(this.fileListError));
      this.$GO(-1);
    },
    toPersonal() {
      this.$PUSH({
        path: "/personal"
      });
    },
    async submit() {
      let {
        status
      } = await this.isAllFinal();
      if (!status) {
        (0, _toast.default)('文件正在上传中，请稍后。');
        return;
      }
      let othersArr = [];
      this.fileList.forEach(im => {
        othersArr.push(im.url);
      });
      let params = {
        ...this.veevaInfo,
        other_imgs: othersArr
        // other_imgs: this.veevaInfo?.other_imgs instanceof Array ? [...this.fileList, ...this.veevaInfo?.other_imgs] : [...this.fileList]
      };
      if (params.other_imgs.length < 1) {
        // if(this.fileList.length < 1){
        this.$toatsView.show({
          tipWords: "最少上传一张图片"
        });
        setTimeout(() => {
          this.$toatsView.hide();
        }, 1500);
        return;
      }
      params.other_imgs = JSON.stringify(params.other_imgs);
      let res = await this.$api.createVeeva(params);
      // let res = (params.status == 3 || params.status == 2) ? await this.$api.PutVeevaInfo(params) : await this.$api.createVeeva(params)

      localStorage.removeItem("veevaInfo");
      localStorage.removeItem("record");
      localStorage.removeItem("fileList");
      localStorage.removeItem("fileListTemp");
      localStorage.removeItem("fileListProgress");
      localStorage.removeItem("fileListError");
      this.$toatsView.show({
        tipWords: "提交成功"
      });
      setTimeout(() => {
        this.$toatsView.hide();
        this.$PUSH({
          path: "/personal"
        });
      }, 1500);
      console.log(res, '=-=> res --');
    },
    initData(data) {
      this.veevaInfo = data;
      this.$store.commit("SET_LOADINGPOP", false);
    },
    removePic(ix) {
      const trait = this.fileListTemp[ix].progress;
      let fileListIndex = null;
      this.fileList.forEach((item, index) => {
        if (item.progress == trait) {
          fileListIndex = index;
        }
      });
      const self = this;
      _dialog.default.confirm({
        message: '确认删除这张图片吗？',
        confirmButtonColor: "#00617f"
      }).then(() => {
        self.contrCount--;
        delete self.fileListError[trait];
        self.fileListTemp.splice(ix, 1);
        !isNaN(Number(fileListIndex)) && self.fileList.splice(fileListIndex, 1);
        // on close
      }).catch(() => {
        // on cancel
      });
    },
    /* 文件上传 Start */
    async uploadImg() {
      console.log(this.fileList, '==<>this.fileList --');
      if (this.fileList.length >= this.upCount || this.contrCount >= this.upCount) {
        this.$toatsView.show({
          tipWords: "最多总共上传" + this.upCount + "张图片"
        });
        setTimeout(() => {
          this.$toatsView.hide();
        }, 1500);
        return;
      }
      let res = await this.$chooseImage("multiple");
      for (const iterator of res === null || res === void 0 ? void 0 : res.imageBase64) {
        if (this.contrCount >= this.upCount) {
          continue;
        }
        this.contrCount += 1;
        const progress = Date.now() + parseInt(Math.random() * 100);
        this.fileListTemp.push({
          url: iterator,
          progress
        });
        this.fileInputChange(iterator, progress);
        // await this.fileInputChange(iterator, progress)
      }
    },
    async fileInputChange(base64, progress) {
      const self = this;
      let params = new FormData();
      params.append("file", base64);
      try {
        this.fileListError[progress] = {
          fileStatus: 'uploading'
        };
        let res = await this.$api.imageuploadBase64(params, ret => {
          this.fileListProgress[progress] = ret;
          this.$forceUpdate();
        });
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("文件上传失败")
          this.$toatsView.show({
            tipWords: `文件上传失败，请重试`
          });
          return;
        }
        if (this.fileList.length >= this.upCount) {
          this.$toatsView.show({
            tipWords: "最多总共上传" + this.upCount + "张图片"
          });
          setTimeout(() => {
            this.$toatsView.hide();
          }, 1500);
          return;
        }
        this.fileList.push({
          url: res.url,
          progress
        });
        this.fileListError[progress] = {
          fileStatus: 'success'
        };
        // this.formData.avatar_virtual = res.url
        this.$forceUpdate();
      } catch (error) {
        this.handleUploadError(progress);
      }
    },
    /* 文件上传 End */
    handleUploadError(progress) {
      const index = this.fileListTemp.findIndex(im => im.progress == progress);
      // console.log(index,'==> index ---');

      this.fileListError[progress] = {
        fileStatus: 'fail',
        errorIndex: index
      };
      this.$forceUpdate();

      // this.uploadErrorIndex = index
      // this.uploadError = true

      /* const self = this
      Dialog.confirm({
          message: '部分图片上传失败',
          confirmButtonColor: "#00617f",
          // cancelButtonColor: "#00617f",
          showCancelButton: true,
          confirmButtonText: "查看",
          cancelButtonText: "关闭"
      }).then(() => {
          self.fileListTemp.splice(index, 1)
          self.fileListProgress[progress] = null
          // on close
      }).catch(() => {
          // on cancel
      }); */

      return;
      this.fileListTemp.splice(index, 1);
      this.fileListProgress[progress] = null;
    }
  }
};