"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _default = exports.default = {
  components: {},
  props: {
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    },
    /* 
      展示形式
        words： 文字提示
        slot  插槽
    */
    showType: {
      type: String,
      default: 'words'
    },
    /* 提示文字 */
    tipWords: {
      type: String,
      default: '请传入提示文字'
    }
  },
  data() {
    return {
      grayFlag: false,
      showPop: false,
      outCallBack: null,
      isNeedBack: false,
      days: 0,
      query: 1231
    };
  },
  filter: {},
  created() {},
  mounted() {
    // this.query = this.$router
  },
  computed: {
    isRegister() {
      return localStorage.getItem('isRegister');
    }
  },
  methods: {
    handleShareRouting() {
      const queryParams = new URLSearchParams(window.location.search);
      const shareData = queryParams.get('sharedata');
      if (shareData === 'true') {
        // 如果shareData参数为true，重定向到首页
        return true;
      }
      return '';
    },
    isNewUser() {
      return this.days > 0;
    },
    toRegister() {
      this.outCallBack({
        next: '/veeva'
      });
      setTimeout(() => {
        this.showPop = false;
      }, 700);
    },
    cancleRegister() {
      this.outCallBack({
        next: 'from'
      });
      this.close();
    },
    open(options) {
      let {
        isNeedBack,
        callBack,
        days
      } = options;
      this.isNeedBack = isNeedBack;
      this.outCallBack = callBack;
      /* 从路由拦截过来以后，点击closeicon的时候调取取消的方法 */
      this.days = days;
      this.showPop = true;
      if (this.days <= 0) {
        this.setGrayBg();
      }
    },
    setGrayBg() {
      this.grayFlag = true;
    },
    close() {
      this.popFullClose();
    },
    maskHandle() {
      if (this.mask) {
        this.popFullClose();
      }
    },
    popFullClose() {
      if (this.isNewUser()) {
        this.showPop = false;
        this.$emit('cancle');
        return;
      }
      if (this.isNeedBack == 'cur') {
        this.showPop = false;
        return;
      }
      if (!this.isNeedBack) {
        if (this.handleShareRouting()) {
          this.showPop = false;
          this.$REDIRECT({
            path: '/'
          });
        } else {
          this.$GO(-1);
          setTimeout(() => {
            this.showPop = false;
          }, 700);
        }
      } else {
        this.showPop = false;
      }
    }
  }
};