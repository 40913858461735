"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const ctx = require.context('./modules/', true, /\.js/);
let modules = {};

/**
 * 请根据实际业务情况，选择一种模式
 */
ctx.keys().forEach(key => {
  /**
   * 单模块
   * 适用于 api 不多的情况下，可在调用的时候省去模块名
   * 例如：this.$api.get()
   */
  modules = {
    ...modules,
    ...ctx(key).default
  };

  /**
   * 多模块
   * 业务模块多，接口繁杂，归类接口分别存放，但是调用时需要加模块名
   * 例如：this.$api.api.get()     this.$api.example.test()
   */
  // let name = key.replace(/(^\.\/|\.js$)/g, '') // 去后缀
  // name = name.substr(0, 1).toLowerCase() + name.substr(1) // 首字母小写
  // modules[name] = ctx(key).default
});

// console.log('接口打印', modules)
var _default = exports.default = modules;