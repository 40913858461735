"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "interactionView",
    staticClass: "page-container"
  }, [_vm.showScore ? _c("div", {
    staticClass: "myScore"
  }, [_c("div", {
    staticClass: "myScoreInner flex flex-direction align-center"
  }, [_c("div", {
    staticClass: "myScoreInnerTip"
  }, [_vm._v("考试分数")]), _c("div", {
    staticClass: "myScoreInnerTop flex"
  }, [_c("b", [_vm._v(_vm._s(_vm.myScore) + " ")]), _vm._v(" "), _c("span", [_vm._v("/")]), _vm._v(" " + _vm._s(_vm.allScore) + " ")])])]) : _vm._e(), _vm._l(_vm.dataBase.answer, function (im, ix) {
    var _im$question_cover, _im$other_comments;
    return _c("div", {
      key: ix,
      staticClass: "interactionList"
    }, [_c("div", {
      staticClass: "interactionListQues"
    }, [!im.is_required || im.is_required == 1 ? _c("span", {
      staticStyle: {
        color: "#f00"
      }
    }, [_vm._v("*")]) : _vm._e(), _vm._v(_vm._s(ix + 1) + ". " + _vm._s(im.question_title))]), _c("div", {
      staticClass: "interactionListQuesTip"
    }, [_vm._v("（" + _vm._s(_vm.showTip(im)) + "）")]), im !== null && im !== void 0 && (_im$question_cover = im.question_cover) !== null && _im$question_cover !== void 0 && _im$question_cover.length ? _c("div", {
      staticClass: "interactionListQuesCover"
    }, [_c("div", {
      staticClass: "interactionListQuesCoverPos flex align-center"
    }, _vm._l(im.question_cover, function (km, kx) {
      return _c("div", {
        staticClass: "interactionListQuesCoverItem flex flex-direction align-center",
        on: {
          click: function ($event) {
            return _vm.$SHOWIMGPREVIEW(km);
          }
        }
      }, [_c("img", {
        staticClass: "interactionListQuesCoverItemImg",
        attrs: {
          src: km,
          alt: ""
        }
      }), _vm._v(" 图" + _vm._s(kx + 1) + " ")]);
    }), 0)]) : _vm._e(), im.question_type == 1 || im.question_type == 3 ? _c("div", {
      staticClass: "interactionListAnswer"
    }, [_vm._l(im.answer, function (km, kx) {
      var _im$other_right_optio, _km$cover;
      return _c("div", {
        key: kx,
        staticClass: "interactionListAnswerLine",
        class: km.selectCheck == "success" ? "interactionListAnswerLineSuccess" : km.selectCheck == "error" ? "interactionListAnswerLineSuccess interactionListAnswerLineError" : ""
      }, [_c("div", {
        staticClass: "flex align-center justify-between"
      }, [_c("span", {
        staticStyle: {
          "line-height": "1.3",
          flex: "1"
        }
      }, [_vm._v(_vm._s(_vm.toUC(kx)) + ". " + _vm._s(km.options))]), im.is_answer == "2" ? _c("div", {
        staticClass: "interactionListAnswerLineCheck"
      }, [im.question_type == "1" && km.selectCheck != "success" && km.optionCheck == "success" ? _c("img", {
        staticClass: "interactionListAnswerLineSuccessIcon",
        attrs: {
          src: require("img/course/interactionListAnswerLineSuccess.png"),
          alt: ""
        }
      }) : _vm._e(), im.question_type == "1" && km.selectCheck == "success" ? _c("img", {
        staticClass: "interactionListAnswerLineSuccessIcon",
        attrs: {
          src: require("img/course/interactionListAnswerLineSuccess.png"),
          alt: ""
        }
      }) : _vm._e(), im.question_type == "1" && km.selectCheck == "error" ? _c("img", {
        staticClass: "interactionListAnswerLineErrorIcon",
        attrs: {
          src: require("img/course/interactionListAnswerLineError.png"),
          alt: ""
        }
      }) : _vm._e(), im.question_type == "3" ? _c("span", [_vm._v(_vm._s(((_im$other_right_optio = im.other_right_options) === null || _im$other_right_optio === void 0 ? void 0 : _im$other_right_optio[kx]) || 0) + "人")]) : _vm._e()]) : _vm._e()]), km !== null && km !== void 0 && (_km$cover = km.cover) !== null && _km$cover !== void 0 && _km$cover.length ? _c("div", {
        staticClass: "interactionListQuesCover",
        on: {
          click: function ($event) {
            $event.stopPropagation();
          }
        }
      }, [_c("div", {
        staticClass: "interactionListQuesCoverPos flex align-center"
      }, _vm._l(km.cover, function (jm, jx) {
        return _c("div", {
          staticClass: "interactionListQuesCoverItem flex flex-direction align-center",
          on: {
            click: function ($event) {
              return _vm.$SHOWIMGPREVIEW(jm);
            }
          }
        }, [_c("img", {
          staticClass: "interactionListQuesCoverItemImg",
          attrs: {
            src: jm,
            alt: ""
          }
        }), _vm._v(" 图" + _vm._s(jx + 1) + " ")]);
      }), 0)]) : _vm._e()]);
    }), im.getScore > 0 && (!im.is_required || im.is_required == 1) ? _c("div", {
      staticClass: "typeInteractionTrueFalse"
    }, [_vm._v("回答正确：得" + _vm._s(im.getScore) + "分")]) : _vm._e(), im.getScore < 1 && (!im.is_required || im.is_required == 1) ? _c("div", {
      staticClass: "typeInteractionTrueFalse",
      staticStyle: {
        color: "#f00"
      }
    }, [_vm._v("回答错误：得" + _vm._s(im.getScore) + "分")]) : _vm._e(), im.question_type == "1" && im.is_answer == "2" ? _c("div", {
      staticClass: "typeInteractionTrueFalse"
    }, [_vm._v(_vm._s(_vm.questionTip(im)))]) : _vm._e()], 2) : _c("div", {
      staticClass: "interactionListAnswerText"
    }, [im.question_type == "4" ? _c("div", {
      staticClass: "typeQuestionnaire"
    }, [_c("div", {
      staticClass: "typeQuestionnaireTitle"
    }, [_vm._v("您的答案")]), _c("div", {
      staticClass: "typeQuestionnaireContent"
    }, [_vm._v(" " + _vm._s(im.answer_check || "未填写答案"))]), im.question_type == "4" && _vm.activeHideAnswerBlock ? _c("div", {
      staticClass: "typeQuestionnaireOtherAnswer"
    }, [_c("div", {
      staticClass: "typeQuestionnaireOtherAnswerArea flex align-center justify-between"
    }, [_c("div", {
      staticClass: "typeQuestionnaireOtherAnswerAreaTitle"
    }, [_vm._v("其他答案")]), _c("div", {
      staticClass: "typeQuestionnaireOtherAnswerAreaSort flex align-center",
      on: {
        click: function ($event) {
          return _vm.resetGetOtherComment(im, ix);
        }
      }
    }, [_c("img", {
      staticClass: "sortHot",
      attrs: {
        src: require("img/course/sortHot.png"),
        alt: ""
      }
    }), _vm._v(" " + _vm._s(im.sort_by ? "按热度" : "按时间") + " ")])]), _c("div", {
      staticClass: "typeQuestionnaireOtherAnswerList"
    }, _vm._l(im.other_comments, function (jm, jx) {
      return _c("commentAreaListItem", {
        key: jx,
        attrs: {
          preview: _vm.preview,
          question_idex: jx,
          isAnswerComponent: true,
          dataBase: jm
        }
      });
    }), 1), !((_im$other_comments = im.other_comments) !== null && _im$other_comments !== void 0 && _im$other_comments.length) ? _c("div", {
      staticClass: "meetingEmpty flex align-center justify-center"
    }, [_vm._v(" 暂无更多答案 ")]) : _vm._e()]) : _vm._e()]) : _vm._e(), im.question_type == "2" ? _c("div", {
      staticClass: "typeInteraction"
    }, [_c("div", {
      staticClass: "typeInteractionTitle"
    }, [_vm._v("您的答案")]), _c("div", {
      staticClass: "typeInteractionMyAns"
    }, [_vm._v(_vm._s(im.answer_check || "未填写答案"))])]) : _vm._e()]), im !== null && im !== void 0 && im.problem_analysis && (im === null || im === void 0 ? void 0 : im.is_answer) == "2" ? _c("div", {
      staticClass: "typeInteractionAnalysis flex align-center justify-center"
    }, [_vm._v("解析")]) : _vm._e(), im !== null && im !== void 0 && im.problem_analysis && (im === null || im === void 0 ? void 0 : im.is_answer) == "2" ? _c("div", {
      staticClass: "typeInteractionAnalysisContent ql-editor",
      domProps: {
        innerHTML: _vm._s(_vm.$VIDEOPLAYSINLINE(im === null || im === void 0 ? void 0 : im.problem_analysis) || "")
      },
      on: {
        click: _vm.$imageEnlargement
      }
    }) : _vm._e()]);
  }), _c("customPop", {
    ref: "customPop-error",
    attrs: {
      tipWords: _vm.errorTipWords
    }
  })], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;