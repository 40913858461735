"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container-register",
    on: {
      touchmove: function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c("transition", {
    attrs: {
      name: "popFull",
      appear: ""
    }
  }, [_vm.showPop ? _c("div", {
    staticClass: "popFull",
    on: {
      click: _vm.maskHandle
    }
  }, [_c("div", {
    staticClass: "popFullInner",
    on: {
      click: function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c("img", {
    staticClass: "popClose",
    attrs: {
      src: require("img/common/popClose.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.cancleRegister.apply(null, arguments);
      }
    }
  }), _c("div", {
    staticClass: "popFullInnerContent popFullInnerContent-slot"
  }, [_c("div", {
    staticClass: "registerSuccess"
  }, [_c("div", {
    staticClass: "registerSuccessTip"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(" 抱歉，您还没有注册，请点击前往。")]), _c("p", {
    staticClass: "content"
  }, [_vm._v("注册后将获得30日免认证学习资格"), _c("br"), _vm._v("前往认证即可解锁永久权益！")])]), _c("div", {
    staticClass: "registerSuccessHandle flex align-center justify-between"
  }, [_c("div", {
    staticClass: "registerSuccessHandleNow flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.toRegister.apply(null, arguments);
      }
    }
  }, [_vm._v(" 注册 ")]), _c("div", {
    staticClass: "registerSuccessHandleDelay flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.cancleRegister.apply(null, arguments);
      }
    }
  }, [_vm._v(" 取消 ")])])])])])]) : _vm._e()])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;