import { render, staticRenderFns } from "./detailIntroduce.vue?vue&type=template&id=54ab0dcc&scoped=true"
import script from "./detailIntroduce.vue?vue&type=script&lang=js"
export * from "./detailIntroduce.vue?vue&type=script&lang=js"
import style0 from "./detailIntroduce.vue?vue&type=style&index=0&id=54ab0dcc&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54ab0dcc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\ApacheServer\\web_php\\yxxy_html\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('54ab0dcc')) {
      api.createRecord('54ab0dcc', component.options)
    } else {
      api.reload('54ab0dcc', component.options)
    }
    module.hot.accept("./detailIntroduce.vue?vue&type=template&id=54ab0dcc&scoped=true", function () {
      api.rerender('54ab0dcc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/meeting/detailIntroduce.vue"
export default component.exports