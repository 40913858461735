"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defaultGetters = _interopRequireDefault(require("./defaultGetters"));
var _select = require("@/utils/area/select.js");
const getters = {
  ..._defaultGetters.default,
  // 业务示例
  getTest: state => state.app.test,
  courseClass: state => state.course.courseClass,
  tabIndex: state => state.app.tabIndex,
  courseClassIdArr: state => state.course.courseClassIdArr,
  loadingShow: state => state.app.loadingShow,
  userInfo: state => state.app.userInfo,
  callUs: state => state.app.callUs,
  callUsShow: state => state.app.callUsShow,
  tologin: state => state.app.tologin,
  projectAuthStatus: state => state.app.projectAuthStatus,
  integralInfo: state => state.app.integralInfo,
  showSaveBtn: state => state.app.showSaveBtn,
  showClaim: state => state.app.showClaim,
  screenTip: state => state.app.screenTip,
  /* 公用参数 */
  departmentSelectList: state => ['医学影像科', '超声影像', '肿瘤', '内科', '内科 肝内', '心内科', '外科', '外科 肝外', '介入科', '神经内科', '神经外科', '其他科室'],
  titleSelectList: state => ['医师', '主治医师', '副主任医师', '主任医师', '技师', '主管技师', '副主任技师', '主任技师', '护士', '护师', '主管护师', '副主任护师', '主任护师', '其他'],
  provinceSelectList: state => (0, _select.selectPrev)().map(im => im.AREANAME),
  hospitalSelectList: state => ["其他"],
  positionSelectList: state => ['科室主任', 'MRI主任', 'CT主任', '科室副主任', '院长', '书记', '科员', '技师长', '护士长', '其他'],
  skillAreaSelectList: state => ['头颈影像', '心胸影像', '乳腺影像', '腹部影像', '骨肌影像', '技术诊断', '护理规范', '科室管理', 'CT', 'MRI', '儿童', '无特定领域'],
  intrastingTopicSelectList: state => ['对比剂安全及规范管理', '成像技术原理', '影像诊断思路', '病例分析', '不同部位或患者人群的扫描方案', '最新文献指南解读', '护理与质控', '科室管理', '科研专题（立项，申请等）', '国家政策国际学术前沿新技术', '数字化医疗', '无'],
  intrastingSysSelectList: state => ['心血管系统', '呼吸系统', '消化系统', '中枢神经系统', '泌尿系统', '生殖系统', '乳腺疾病', '骨骼肌肉', '儿童', '无'],
  workUpgradesSelectList: state => ['科室管理能力', '设备操作', '读片能力', '教学能力', '临床沟通技巧', '科研能力', '无'],
  cryptIv: state => "bXnJpZoClCchkPNM",
  cryptKey: state => "xdz3gagd3M0jMXmI",
  contentType: state => ({
    0: "未选",
    1: "视频",
    2: "图文"
  }),
  listType: state => ({
    1: "课程",
    2: "病例",
    3: "其他",
    4: "会议",
    5: "活动"
  })
};
var _default = exports.default = getters;