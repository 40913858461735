"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$cerList;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container-out"
  }, [_c("stickyHeader"), _vm._m(0), _c("div", {
    staticClass: "getCergeCount flex"
  }, [_vm._v("已获得 "), _c("span", [_vm._v(_vm._s(_vm.cerList.received_))]), _vm._v(" 个证书")]), _vm._m(1), _c("div", {
    staticClass: "getCerList flex flex-wrap justify-between"
  }, [(_vm$cerList = _vm.cerList) !== null && _vm$cerList !== void 0 && (_vm$cerList = _vm$cerList.unclaimed_certificate) !== null && _vm$cerList !== void 0 && _vm$cerList.length ? _c("div", {
    staticClass: "waitGet"
  }, [_c("div", {
    staticClass: "waitGetTop"
  }, [_vm._v(" 您有" + _vm._s(_vm.cerList.unclaimed_count) + "个证书待领取 ")]), _c("div", {
    staticClass: "waitGetList flex flex-wrap justify-between"
  }, _vm._l(_vm.cerList.unclaimed_certificate, function (im, ix) {
    return _c("div", {
      directives: [{
        name: "monitor",
        rawName: "v-monitor"
      }],
      key: ix,
      staticClass: "waitGetListCer",
      attrs: {
        "event-key": `查看证书-${im.name}`
      },
      on: {
        click: function ($event) {
          return _vm.cerListSmallClick(im, ix);
        }
      }
    }, [_c("div", {
      staticClass: "waitGetListCerTop"
    }, [_c("cerImgSmall", {
      ref: `cerImgSmall${ix}`,
      refInFor: true,
      attrs: {
        dataBase: im,
        dataIx: ix
      }
    }), _c("div", {
      staticClass: "waitGetListCerTopNew flex justify-center align-center"
    }, [_vm._v("NEW")])], 1), _c("div", {
      staticClass: "waitGetListCerTitle"
    }, [_vm._v(_vm._s(im.certificate_name))]), _c("div", {
      staticClass: "waitGetListCerBtn flex justify-center align-center",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.$PUSH({
            path: `/personal/achievedetail?type=cer&id=${im.id}`
          });
        }
      }
    }, [_vm._v("立即领取")])]);
  }), 0)]) : _vm._e(), _vm._l(_vm.cerList.received_certificate, function (im, ix) {
    return _c("div", {
      directives: [{
        name: "monitor",
        rawName: "v-monitor"
      }],
      staticStyle: {
        margin: "auto",
        position: "relative"
      },
      attrs: {
        "event-key": `查看证书-${im.name}`
      },
      on: {
        click: function ($event) {
          return _vm.cerListClick(im, ix);
        }
      }
    }, [_vm._m(2, true), _c("cerImg", {
      ref: `cerImg${ix}`,
      refInFor: true,
      attrs: {
        dataBase: im,
        dataIx: ix
      }
    }), _c("div", {
      staticClass: "cerName"
    }, [_vm._v(_vm._s(im.certificate_name))])], 1);
  })], 2), _c("vanPopup", {
    model: {
      value: _vm.cerDetailShow,
      callback: function ($$v) {
        _vm.cerDetailShow = $$v;
      },
      expression: "cerDetailShow"
    }
  }, [_c("div", {
    staticClass: "achieveDetailContentCer",
    on: {
      click: function ($event) {
        _vm.cerDetailShow = false;
      }
    }
  }, [_c("div", {
    staticClass: "achieveDetailContentCertop"
  }, [_c("img", {
    staticClass: "achieveDetailContentBadgeStar3 achieveDetailContentBadgeStar",
    attrs: {
      src: require("img/personal/ach/star3.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "achieveDetailContentBadgeStar2 achieveDetailContentBadgeStar",
    attrs: {
      src: require("img/personal/ach/star2.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "achieveDetailContentBadgeStar1 achieveDetailContentBadgeStar",
    attrs: {
      src: require("img/personal/ach/star1.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "achieveDetailContentCertopCer"
  }, [_c("img", {
    staticClass: "achieveDetailContentCertopCerShadow",
    staticStyle: {
      opacity: "0.6",
      "pointer-events": "none"
    },
    attrs: {
      src: require("img/personal/ach/badgeShadow.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "achieveDetailContentCertopCerShadowImg",
    staticStyle: {
      "pointer-events": "none"
    },
    attrs: {
      src: require("img/personal/ach/cerImgShadow.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "achieveDetailContentCertopCerImg",
    class: _vm.cerDetail.isNoHas && "gray",
    attrs: {
      src: _vm.cerDetail.path,
      alt: ""
    }
  })])]), _c("div", {
    staticClass: "achieveDetailContentCerName"
  }, [_vm._v(" " + _vm._s(_vm.cerDetail.certificate_name))]), !_vm.cerDetail.isNoHas ? _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "achieveDetailContentCerBtn achieveDetailContentCerBtnShare flex align-center justify-center",
    attrs: {
      "event-key": `分享证书-${_vm.cerDetail.name}`
    },
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$PUSH({
          path: `/personal/achieveshare?type=cer&id=${_vm.cerDetail.certificate_id}`
        });
      }
    }
  }, [_vm._v("分享证书")]) : _vm._e()])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "pageImg"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/ach/pageImg.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "getCerTip flex align-center justify-center"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/ach/smallTip.png"),
      alt: ""
    }
  }), _vm._v(" 点击可查看详情及进行分享 ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "shadowImgOut",
    staticStyle: {
      "pointer-events": "none"
    }
  }, [_c("img", {
    attrs: {
      src: require("img/personal/ach/cerImgShadow.png"),
      alt: ""
    }
  })]);
}];
render._withStripped = true;