"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container outScreen"
  }, [_c("div", {
    staticClass: "outScreen"
  }, [_c("qriously", {
    ref: "QRCode",
    attrs: {
      value: _vm.createValue(),
      foreground: "#ffffff",
      size: 200,
      backgroundAlpha: 0
    }
  })], 1), _c("div", {
    staticClass: "detailPoster"
  }, [_c("div", {
    staticClass: "detailPosterInnerOut"
  }, [_c("div", {
    staticClass: "detailPosterInner"
  }, [_vm._m(0), _c("div", {
    staticClass: "detailPosterInnerTitle marginAuto"
  }, [_vm._v(_vm._s(_vm.showContent().title))]), _c("div", {
    staticClass: "detailPosterInnersTitle marginAuto"
  }, [_vm._v(_vm._s(_vm.dataBase.name))]), _c("div", {
    staticClass: "detailPosterInnerContent marginAuto"
  }, [_vm._v(" " + _vm._s(_vm.dataBase.description) + " ")]), _vm.tagList.length ? _c("div", {
    staticClass: "detailPosterInnerTag marginAuto flex flex-wrap"
  }, _vm._l(_vm.tagList, function (im, ix) {
    return _c("div", {
      key: ix,
      staticClass: "detailPosterInnerTagItem"
    }, [_vm._v(_vm._s(im))]);
  }), 0) : _vm._e(), _vm._m(1), _c("div", {
    staticClass: "detailPosterInnerInfoCard"
  }, [_c("img", {
    staticClass: "detailPosterInnerInfoCardBg",
    attrs: {
      src: require("img/meeting/infoCard.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "detailPosterInnerInfoCardContent marginAuto flex justify-between"
  }, [_c("div", {
    staticClass: "detailPosterInnerInfoCardContentLeft"
  }, [_vm.posterType != 1 ? _c("div", {
    staticClass: "detailPosterInnerInfoCardContentLeftLineTitle"
  }, [_vm._v(_vm._s(_vm.posterType == 7 ? "活动时间：" : "会议时间："))]) : _vm._e(), _vm.posterType != 1 ? _c("div", {
    staticClass: "detailPosterInnerInfoCardContentLeftLineTime"
  }, [_vm._v(_vm._s(_vm.$SPLITDATE(_vm.dataBase.start_time, "yyyy-mm-dd h:m")) + " ")]) : _vm._e(), _c("div", {
    staticClass: "detailPosterInnerInfoCardContentLeftLineEntry"
  }, [_vm._v(_vm._s(_vm.showContent().tipsWords))])]), _c("div", {
    staticClass: "detailPosterInnerInfoCardContentRight"
  }, [_c("img", {
    attrs: {
      src: _vm.base64QRCode,
      alt: ""
    }
  })])])])])])])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "detailPosterInnerLogo"
  }, [_c("img", {
    attrs: {
      src: require("img/logo.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "detailPosterInnerInfoCardTop"
  }, [_c("div", {
    staticClass: "detailPosterInnerInfoCardTopLeft"
  }), _c("div", {
    staticClass: "detailPosterInnerInfoCardTopRight"
  })]);
}];
render._withStripped = true;