"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$sectionDetail, _vm$sectionDetail2, _vm$formEchoInfo, _vm$formEchoInfo2, _vm$formEchoInfo3;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    ref: "scrollWrapper",
    staticClass: "page-container"
  }, [_c("stickyHeader"), (_vm$sectionDetail = _vm.sectionDetail) !== null && _vm$sectionDetail !== void 0 && _vm$sectionDetail.id ? _c("div", {
    staticClass: "detailTopOut"
  }, [(_vm$sectionDetail2 = _vm.sectionDetail) !== null && _vm$sectionDetail2 !== void 0 && _vm$sectionDetail2.id ? _c("div", {
    staticClass: "detailTitle"
  }, [_vm._v(_vm._s(_vm.sectionDetail.name))]) : _vm._e(), _c("div", {
    staticClass: "detailTips"
  }, [_vm._v("*完成下方互动问答，即可立即学习！")])]) : _vm._e(), !_vm.showModuleCheck && _vm.sectionDetail.have_form && !((_vm$formEchoInfo = _vm.formEchoInfo) !== null && _vm$formEchoInfo !== void 0 && _vm$formEchoInfo.id) ? _c("interaction", {
    ref: "childRef",
    attrs: {
      formInfo: _vm.detailForm,
      isActiveFlag: true,
      is_preview: _vm.is_preview
    }
  }) : _vm._e(), !_vm.showModuleCheck && _vm.sectionDetail.have_form && (_vm$formEchoInfo2 = _vm.formEchoInfo) !== null && _vm$formEchoInfo2 !== void 0 && _vm$formEchoInfo2.id ? _c("interactionAnalysis", {
    attrs: {
      activeHideAnswerBlock: false,
      dataBase: _vm.formEchoInfo
    }
  }) : _vm._e(), !_vm.showModuleCheck && _vm.sectionDetail.have_form && (_vm$formEchoInfo3 = _vm.formEchoInfo) !== null && _vm$formEchoInfo3 !== void 0 && _vm$formEchoInfo3.id && _vm.is_preview == null ? _c("div", {
    staticClass: "fixedBox flex align-center justify-center",
    on: {
      click: _vm.goSecondHandle
    }
  }, [_c("div", {
    staticClass: "handleBtn flex align-center justify-center entryPendding goSecondBtn",
    attrs: {
      "event-key": "进入活动详情"
    }
  }, [_vm._v(" 进入活动 ")])]) : _vm._e(), _c("div", {
    staticClass: "zwBox"
  }), _c("customPop", {
    ref: "customPop",
    attrs: {
      tipWords: _vm.tipWords
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;