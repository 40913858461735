"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _index = require("@/utils/index.js");
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _list = _interopRequireDefault(require("@/components/meeting/list.vue"));
var _monitor = require("@/utils/monitor");
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    meetingList: _list.default
  },
  data() {
    return {
      need_veeva: '',
      noThenData: false,
      searchWords: "",
      meetingTagIdArr: [],
      meetingTagList: [],
      filterMap: {
        meetingState: {
          check: false,
          value: null,
          selectData: [{
            key: "未开始",
            value: 1
          }, {
            key: "进行中",
            value: 2
          }, {
            key: "已结束",
            value: 4
          }, {
            key: "回放中",
            value: 3
          }, {
            key: "全部",
            value: -1
          }]
        },
        meetingPosition: {
          check: false,
          value: null,
          selectData: [
          // {
          //   key: "部位",
          //   value: null
          // },
          {
            key: "头颈影像",
            value: 0
          }, {
            key: "心胸影像",
            value: 1
          }, {
            key: "乳腺影像",
            value: 2
          }, {
            key: "腹部影像",
            value: 3
          }, {
            key: "骨肌影像",
            value: 4
          }, {
            key: "技术诊断",
            value: 5
          }, {
            key: "神经影像",
            value: 6
          }, {
            key: "科室管理",
            value: 7
          }, {
            key: "全部",
            value: -1
          }]
        },
        meetingComprehensive: {
          check: false,
          value: null,
          selectData: [
          // {
          //   key: "综合排序",
          //   value: null
          // },
          {
            key: "最新",
            value: 0
          }, {
            key: "最热",
            value: 1
          }, {
            key: "收藏最多",
            value: 2
          }]
        }
      },
      current: 1,
      pageSize: 20,
      meetingList: [],
      isReachBottom: false,
      showTags: false,
      loadFinal: false,
      isLoadAllData: false
    };
  },
  destroyed() {
    window.removeEventListener("reachBottom", this.reachBottomHandle);
  },
  watch: {},
  filter: {},
  created() {
    this.$store.commit("SET_LOADINGPOP", true);
    this.initData();
    this.getMeetingTagList();
    this.initReachBottom();
  },
  mounted() {},
  computed: {},
  methods: {
    chooseNeedVeevaTags() {
      if (!this.loadFinal) return;
      this.need_veeva = this.need_veeva ? '' : 1;
      this.clearList(false);
    },
    renderMeetStatus(status) {
      let word = "";
      this.filterMap.meetingState.selectData.forEach(im => {
        if (im.value == status) {
          word = im.key;
        }
      });
      return word;
    },
    /* 分页 start */
    initReachBottom() {
      window.addEventListener("reachBottom", this.reachBottomHandle);
    },
    reachBottomHandle(evt) {
      const self = this;
      if (evt.detail.path != "/meeting") {
        return;
      }
      if (self.isReachBottom) {
        return;
      }
      if (self.isLoadAllData) {
        return;
      }
      console.log("reachBottomHandle -- reachBottomHandle");
      self.isReachBottom = true;
      this.initData();
      this.getMeetingTagList();
    },
    /* 分页 end */

    toSearch() {
      for (const key in this.filterMap) {
        this.filterMap[key].value = null;
      }
      this.need_veeva = "";
      this.clearList(true);
    },
    tagHandle(im) {
      if (!this.loadFinal) return;
      im.check = !im.check;
      const idIndex = this.meetingTagIdArr.indexOf(im.id);
      idIndex < 0 ? this.meetingTagIdArr.push(im.id) : this.meetingTagIdArr.splice(idIndex, 1);
      this.clearList(false);
    },
    clearList(clearTag) {
      this.current = 1;
      this.pageSize = 20;
      this.meetingList = [];
      this.noThenData = false;
      this.isReachBottom = false;
      this.isLoadAllData = false;
      let params = {};
      /* 部位 */
      this.filterMap.meetingPosition.value != null && this.filterMap.meetingPosition.selectData[this.filterMap.meetingPosition.value].key != "全部" && this.filterMap.meetingPosition.selectData[this.filterMap.meetingPosition.value].key != "部位" && (params["filter[section]"] = this.filterMap.meetingPosition.selectData[this.filterMap.meetingPosition.value].key);
      /* 状态 */
      // this.filterMap.meetingState.value && (params["filter[status]"] = this.filterMap.meetingState.value)
      this.filterMap.meetingState.value && this.filterMap.meetingState.value != -1 && (params["filter[status]"] = this.filterMap.meetingState.value);
      /* 排序 */
      this.filterMap.meetingComprehensive.value != null && (params["orderby"] = this.filterMap.meetingComprehensive.value + 1);
      /* 标签 */
      this.meetingTagIdArr.length && (params["tag_ids"] = this.meetingTagIdArr.join(","));
      // this.meetingTagIdArr.length && (params["filter[tag_ids]"] = this.meetingTagIdArr.join(","))
      /* 文字搜索 */
      this.searchWords && (params["filter[name]"] = this.searchWords);
      let moniStr = `${document.title}:筛选-会议状态-`;
      params['need_veeva'] = this.need_veeva;
      console.log(params, '==>params');
      for (const key in params) {
        moniStr += `${key}:${params[key]}-`;
      }
      (0, _monitor.monitorClick)(moniStr);

      // v-monitor :event-key="`筛选-会议状态-${km.key}`"

      if (clearTag) {
        this.need_veeva = "";
        this.meetingTagIdArr = [];
        this.meetingTagList = [];
        this.getMeetingTagList();
      }
      this.initData();
    },
    async getMeetingTagList() {
      this.showTags = false;
      try {
        let params = {
          current: this.current,
          pageSize: this.pageSize
        };
        /* 部位 */
        this.filterMap.meetingPosition.value != null && this.filterMap.meetingPosition.selectData[this.filterMap.meetingPosition.value].key != "全部" && this.filterMap.meetingPosition.selectData[this.filterMap.meetingPosition.value].key != "部位" && (params["filter[section]"] = this.filterMap.meetingPosition.selectData[this.filterMap.meetingPosition.value].key);
        /* 状态 */
        this.filterMap.meetingState.value && this.filterMap.meetingState.value != -1 && (params["filter[status]"] = this.filterMap.meetingState.value);
        /* 排序 */
        this.filterMap.meetingComprehensive.value != null && (params["orderby"] = this.filterMap.meetingComprehensive.value + 1);
        /* 标签 */
        this.meetingTagIdArr.length && (params["tag_ids"] = this.meetingTagIdArr.join(","));
        // this.meetingTagIdArr.length && (params["filter[tag_ids]"] = this.meetingTagIdArr.join(","))
        /* 文字搜索 */
        this.searchWords && (params["filter[name]"] = this.searchWords);
        console.log("params--------", params);
        let res = await this.$api.getMeetingtTag(params);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getMyFaver请求失败：" + res.errmsg)
          return;
        }
        res.forEach(im => {
          im.check = false;
        });
        this.meetingTagList = (0, _index.quchong)([...this.meetingTagList, ...res]);
        this.showTags = true;
        setTimeout(() => {
          (0, _index.handleTagCss)(this.$refs.meetingTag, this.$refs.meetingTagInners, this.$refs.meetingTagItem);
        }, 0);
        console.log(this.meetingTagList, '==> this.meetingTagList');
      } catch (error) {}
    },
    async initData() {
      this.loadFinal = false;
      try {
        let params = {
          current: this.current,
          pageSize: this.pageSize,
          need_veeva: this.need_veeva
        };
        /* 部位 */
        this.filterMap.meetingPosition.value != null && this.filterMap.meetingPosition.selectData[this.filterMap.meetingPosition.value].key != "全部" && this.filterMap.meetingPosition.selectData[this.filterMap.meetingPosition.value].key != "部位" && (params["filter[section]"] = this.filterMap.meetingPosition.selectData[this.filterMap.meetingPosition.value].key);
        /* 状态 */
        this.filterMap.meetingState.value && this.filterMap.meetingState.value != -1 && (params["filter[status]"] = this.filterMap.meetingState.value);
        /* 排序 */
        this.filterMap.meetingComprehensive.value != null && (params["orderby"] = this.filterMap.meetingComprehensive.value + 1);
        /* 标签 */
        this.meetingTagIdArr.length && (params["tag_ids"] = this.meetingTagIdArr.join(","));
        // this.meetingTagIdArr.length && (params["filter[tag_ids]"] = this.meetingTagIdArr.join(","))
        /* 文字搜索 */
        this.searchWords && (params["filter[name]"] = this.searchWords);
        let res = await this.$api.getMeetingtList(params);
        this.loadFinal = true;
        this.$store.commit("SET_LOADINGPOP", false);
        setTimeout(() => {
          this.isReachBottom = false;
        }, 500);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getMyFaver请求失败：" + res.errmsg)
          return;
        }
        if ((res === null || res === void 0 ? void 0 : res.data.length) < params.pageSize) {
          this.isLoadAllData = true;
        }
        const finalData = (0, _index.limitData)({
          interfaceData: res,
          baseData: this.meetingList,
          limit: params
        });
        this.meetingList = finalData.baseData;
        finalData.currentAdd && this.current++ && (this.noThenData = false);
        !finalData.currentAdd && (this.noThenData = true);
      } catch (error) {}
    }
  }
});