"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = _interopRequireDefault(require("utils/http"));
var _default = exports.default = {
  /* 会议-列表 */
  getMeetingtList(params) {
    return (0, _http.default)({
      url: `/api/platform/meeting`,
      method: 'get',
      params
    });
  },
  /* 会议-详情 */
  getMeetingtDetail(meetingId) {
    return (0, _http.default)({
      url: `/api/platform/meeting/${meetingId}`,
      method: 'get'
    });
  },
  /* 会议-标签 */
  getMeetingtTag(params) {
    return (0, _http.default)({
      url: `/api/platform/meeting?get_tag=1`,
      // url: `/api/platform/meeting/tags`,
      method: 'get',
      params
    });
  },
  /* 会议-报名 */
  setSubscribe(params) {
    return (0, _http.default)({
      url: `/api/platform/meeting/subscribe`,
      method: 'post',
      data: params
    });
  },
  /* 会议-我的会议 */
  getMyMeeting(params) {
    return (0, _http.default)({
      url: `/api/platform/meeting/my-meeting`,
      method: 'get',
      params
    });
  },
  /* 会议-时长 */
  setMeetingTime(params) {
    return (0, _http.default)({
      url: `/api/platform/learn-log`,
      method: 'post',
      data: params
    });
  }
};