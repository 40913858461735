"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  props: {
    /* 标签列表 */
    tagList: {
      type: Array,
      default: () => []
    },
    /* 海报类型，不同类型需要调整 */
    posterType: {
      type: Number || String,
      default: 1
    },
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    },
    /*
      展示形式
        words： 文字提示
        slot  插槽
    */
    showType: {
      type: String,
      default: "words"
    },
    /* 提示文字 */
    tipWords: {
      type: String,
      default: "请传入提示文字"
    },
    dataBase: {
      type: Object,
      default: () => {}
    },
    getKey: {
      type: String
    },
    pathname: {
      type: String
    }
  },
  data() {
    return {
      showPop: false,
      base64QRCode: ""
    };
  },
  watch: {},
  filter: {},
  created() {
    this.$nextTick(() => {
      this.getBase64QRCode();
    });
  },
  mounted() {},
  computed: {},
  methods: {
    showContent() {
      /*
      1 课程
      2 病例
      3 其他
      4 会议
      */
      let ContentMap = {
        1: {
          tipsWords: `长按识别二维码查看课程详情，进入课程`,
          title: `拜耳课程`
        },
        4: {
          tipsWords: `长按识别二维码查看详情，进入会议`,
          title: `拜耳会议`
        },
        7: {
          tipsWords: `长按识别二维码查看详情，进入活动`,
          title: `拜耳活动`
        }
      };
      return ContentMap[this.posterType];
    },
    getBase64QRCode() {
      this.base64QRCode = this.$refs.QRCode.qrious.canvas.toDataURL();
    },
    createValue() {
      const location = window.location;
      const urlParams = this.$route.query;
      let shareUrl = `${location.origin}${this.pathname || location.pathname}`;
      for (let key in urlParams) {
        shareUrl += `${shareUrl.indexOf('?') > -1 ? '&' : '?'}${key}=${urlParams[key]}`;
      }
      const openid = localStorage.getItem("openid");
      if (openid) {
        shareUrl += `&sourceopenid=${openid}`;
      }
      console.log(shareUrl, '==>shareUrl>shareUrl');
      return shareUrl;
    },
    open() {
      this.showPop = true;
    },
    close() {
      this.popFullClose();
    },
    maskHandle() {
      if (this.mask) {
        this.popFullClose();
      }
    },
    popFullClose() {
      this.showPop = false;
      this.$emit("cancle");
    }
  }
};