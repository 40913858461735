"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _config = require("@/config");
var _vueCookies = _interopRequireDefault(require("vue-cookies"));
const localVersion = localStorage.getItem("version");

/* 监听input 输入version时弹出当前版本和本地版本 */
let alertTimer = null;
window.addEventListener("input", evt => {
  if (alertTimer) {
    clearTimeout(alertTimer);
    alertTimer = null;
  }
  alertTimer = setTimeout(() => {
    var _evt$target$value;
    if (((_evt$target$value = evt.target.value) === null || _evt$target$value === void 0 ? void 0 : _evt$target$value.toLocaleLowerCase()) == 'version') {
      alert(`当前版本：${_config.version}；本地版本：${localVersion}`);
    }
    clearTimeout(alertTimer);
    alertTimer = null;
  }, 3000);
});

/* 对比版本，版本不一致重新授权 */
if (!localVersion || localVersion != _config.version) {
  _vueCookies.default.set("openid", "", "1s", "/bayer-rad-academy-shadowcloud-academy-html");
  _vueCookies.default.set("wechatInfo", "", "1s", "/bayer-rad-academy-shadowcloud-academy-html");
  _vueCookies.default.set("redirect", "", "1s", "/bayer-rad-academy-shadowcloud-academy-html");
  _vueCookies.default.remove('redirect', '');
  _vueCookies.default.remove('openid', '');
  _vueCookies.default.remove('wechatInfo', '');
  localStorage.clear();

  /* 清除完成以后，直接重置版本【可以不用在路由里赋值】 */
  localStorage.setItem("version", _config.version);
}