"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _default = exports.default = [{
  path: '/personal/task',
  name: 'personalTask',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/personal/task'))),
  meta: {
    title: '个人中心提升',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    checkUser: true,
    tabIndex: '3'
  }
}, {
  path: '/personal/rank',
  name: 'personalRank',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/personal/rank'))),
  meta: {
    title: '排行榜',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜学术能量排行榜',
      desc: ''
    },
    hideTabbar: true,
    checkUser: true,
    tabIndex: '3'
  }
}, {
  path: '/personal/warning',
  name: 'personalWarning',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/personal/warning'))),
  meta: {
    title: '药物警戒',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    tabIndex: '3'
  }
}, {
  path: '/personal/badge',
  name: 'personalBadge',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/personal/badge'))),
  meta: {
    title: '我的勋章',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    tabIndex: '3',
    checkUser: true
  }
}, {
  path: '/personal/cer',
  name: 'personalCer',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/personal/cer'))),
  meta: {
    title: '我的证书',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    tabIndex: '3',
    checkUser: true
  }
}, {
  path: '/personal/achievedetail',
  name: 'achieveDetail',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/personal/achieveDetail'))),
  meta: {
    title: '成就详情',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    tabIndex: '3',
    checkUser: true
  }
}, {
  path: '/personal/achieveshare',
  name: 'achieveShare',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/personal/achieveShare'))),
  meta: {
    title: '成就分享',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    tabIndex: '3',
    checkUser: true
  }
}, {
  path: '/personal/collection',
  name: 'personalCollection',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/personal/collection'))),
  meta: {
    title: '我的收藏',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    checkUser: true,
    tabIndex: '3',
    reachBottom: true
  }
}, {
  path: '/personal/history',
  name: 'personalHistory',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/personal/history'))),
  meta: {
    title: '学习记录',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    checkUser: true,
    tabIndex: '3'
  }
}, {
  path: '/personal/signup',
  name: 'personalSignUp',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/personal/signUp'))),
  meta: {
    title: '会议报名',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    checkUser: true,
    tabIndex: '3',
    reachBottom: true
  }
}, {
  path: '/personal/question',
  name: 'personalQuestion',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/personal/question/index'))),
  meta: {
    title: '我的问卷',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    checkUser: true,
    tabIndex: '3',
    reachBottom: true
  }
}, {
  path: '/personal/intercept',
  name: 'personalIntercept',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/personal/question/intercept'))),
  meta: {
    title: '我的问卷',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    checkUser: true,
    tabIndex: '3',
    reachBottom: true
  }
}, {
  path: '/notify',
  name: 'notify',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/notify/index'))),
  meta: {
    title: '消息中心',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜消息中心',
      desc: ''
    },
    checkUser: true,
    reachBottom: true
  }
}, {
  path: '/veeva',
  name: 'veevaHandle',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/veevaAuth/index'))),
  meta: {
    title: '医疗卫生专业人士认证 ',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜医疗卫生专业人士认证 ',
      desc: ''
    },
    checkUser: true,
    reachBottom: true,
    hideApproval: true
  }
}, {
  path: '/veevaupload',
  name: 'uploadInfo',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/veevaAuth/upload'))),
  meta: {
    title: '医疗卫生专业人士认证 ',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜医疗卫生专业人士认证 ',
      desc: ''
    },
    checkUser: true,
    reachBottom: true
  }
}, {
  path: '/bindStep',
  name: 'veevaHandle',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/veevaAuth/bindStep'))),
  meta: {
    title: '绑定拜耳学术会议平台步骤',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '绑定拜耳学术会议平台步骤 ',
      desc: '绑定拜耳学术会议平台步骤'
    },
    checkUser: true,
    reachBottom: true,
    hideApproval: true
  }
}, {
  path: '/contactUs',
  name: 'contactUs',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/personal/contactUs'))),
  meta: {
    title: '联系我们',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜联系我们',
      desc: ''
    },
    checkUser: true,
    reachBottom: true,
    ingoreCheck: true
  }
}];