"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("button", {
    on: {
      click: _vm.claerCookie
    }
  }, [_vm._v("清除缓存")])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;