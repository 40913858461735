"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = _interopRequireDefault(require("utils/http"));
var _default = exports.default = {
  /* 会议页面-预览接口 */
  getPreviewMeeting(params, id) {
    return (0, _http.default)({
      url: `/api/platform/demo/meeting/${id}`,
      method: 'get',
      params
    });
  },
  /* 活动-模块-预览接口 */
  getPreviewCampainSection(params, id) {
    return (0, _http.default)({
      url: `/api/platform/demo/campain-section/${id}`,
      method: 'get',
      params
    });
  },
  /* 活动-内容-预览接口 */
  getPreviewCampainContent(params, id) {
    return (0, _http.default)({
      url: `/api/platform/demo/campain-content`,
      method: 'get',
      params
    });
  },
  /* 课程-预览接口 */
  getPreviewCourseContent(params, id) {
    return (0, _http.default)({
      url: `/api/platform/demo/content/${id}`,
      method: 'get',
      params
    });
  },
  /* 活动-内容-图文-预览接口 */
  getPreviewCampainContentRich(params, id) {
    return (0, _http.default)({
      url: `/api/platform/demo/campain-content/${id}`,
      method: 'get',
      params
    });
  }
};