"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container-out page-container-view question"
  }, [_c("stickyHeader"), _vm.questionList.length ? _c("div", {
    staticClass: "questionList"
  }, _vm._l(_vm.questionList, function (im, ix) {
    return _c("div", {
      key: ix,
      staticClass: "questionListItem marginAuto flex align-center justify-between"
    }, [_c("div", {
      staticClass: "questionListItemLeft"
    }, [im.status == 0 ? _c("div", {
      staticClass: "questionListItemLeftIcon"
    }, [_c("img", {
      attrs: {
        src: require("img/personal/question/toFill.png"),
        alt: ""
      }
    })]) : _vm._e(), im.status == 2 ? _c("div", {
      staticClass: "questionListItemLeftIcon"
    }, [_c("img", {
      attrs: {
        src: require("img/personal/question/hasUpdate.png"),
        alt: ""
      }
    })]) : _vm._e(), im.status == 1 ? _c("div", {
      staticClass: "questionListItemLeftIcon"
    }, [_c("img", {
      attrs: {
        src: require("img/personal/question/isPass.png"),
        alt: ""
      }
    })]) : _vm._e()]), _c("div", {
      staticClass: "questionListItemCenter"
    }, [_c("div", {
      staticClass: "questionListItemCenterContent hideWords-More2"
    }, [_vm._v(" " + _vm._s(im.title))]), _c("div", {
      staticClass: "questionListItemCenterTime"
    }, [_vm._v(_vm._s(im.start_time))])]), im.status == 0 ? _c("div", {
      staticClass: "questionListItemRight flex align-center justify-center questionListItemRightFill",
      on: {
        click: function ($event) {
          return _vm.$PUSH({
            path: `/personal/intercept`,
            query: {
              id: im.id,
              typeMap: 8
            }
          });
        }
      }
    }, [_vm._v(" 去填写")]) : _vm._e(), im.status == 2 ? _c("div", {
      staticClass: "questionListItemRight flex align-center justify-center questionListItemRightUpdate",
      on: {
        click: function ($event) {
          return _vm.$PUSH({
            path: `/personal/intercept`,
            query: {
              id: im.id,
              typeMap: 8
            }
          });
        }
      }
    }, [_vm._v(" 有更新")]) : _vm._e(), im.status == 1 ? _c("div", {
      staticClass: "questionListItemRight flex align-center justify-center questionListItemRightPass",
      on: {
        click: function ($event) {
          return _vm.$PUSH({
            path: `/personal/intercept`,
            query: {
              id: im.id,
              typeMap: 8
            }
          });
        }
      }
    }, [_vm._v(" 查看 ")]) : _vm._e()]);
  }), 0) : _c("div", {
    staticClass: "meetingEmpty flex align-center justify-center"
  }, [_vm._v(" 暂无数据 ")])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;