"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _aiAlertVue = _interopRequireDefault(require("aui/senior/ai-alert-vue"));
const AiAlert = _vue.default.extend(_aiAlertVue.default);
_aiAlertVue.default.install = function (data) {
  const instance = new AiAlert({
    data
  }).$mount();
  document.body.appendChild(instance.$el);
  _vue.default.nextTick(() => {
    instance.show = true;
  });
};
var _default = exports.default = _aiAlertVue.default;