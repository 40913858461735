"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.constantRouterMap = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _course = _interopRequireDefault(require("./modules/course"));
var _activity = _interopRequireDefault(require("./modules/activity"));
var _personal = _interopRequireDefault(require("./modules/personal"));
var _sign = _interopRequireDefault(require("./modules/sign"));
var _preview = _interopRequireDefault(require("./modules/preview"));
/**
 * 基础路由
 * @type { *[] }
 */

const hideApprovalRouterArr = [..._preview.default];
hideApprovalRouterArr.forEach(im => {
  im.meta.hideApproval = true;
});
const constantRouterMap = exports.constantRouterMap = [{
  path: '/',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/layouts/index'))),
  redirect: '/',
  meta: {
    title: '',
    keepAlive: false
  },
  children: [
  /*
  * share
  * title: '' // 默认取页面 title
  * desc: '' // 默认拼接 (点击查看 + title)
  * link: '' // 默认自动拼接
  * imgUrl: '' // 默认取 public/logo.png
  * */
  /*
  * setting
  * keepAlive: false // 页面缓存
  * wechatAuth: true // 微信授权
  * customTitle: false // 动态标题，默认 false 为 true 时取 query 中的 title，需要在搭配链接中参数
  * */
  {
    path: '/',
    name: '',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/entry/index'))),
    meta: {
      title: '首页',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜首页',
        desc: ''
      },
      tabIndex: '0'
    }
  }, {
    path: '/template',
    name: '',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/template'))),
    meta: {
      title: '首页',
      keepAlive: false,
      wechatAuth: false,
      share: {
        title: '拜耳影像学苑｜清除缓存',
        desc: '清除缓存'
      },
      tabIndex: '0'
    }
  }, {
    path: '/allcontent',
    name: 'AllContent',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/allContent/index'))),
    meta: {
      title: '全部内容',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜全部内容',
        desc: ''
      },
      reachBottom: true
    }
  }, {
    path: '/activity',
    name: 'Activity',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/entry/activity'))),
    meta: {
      title: '活动列表',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜热门活动',
        desc: ''
      },
      reachBottom: true
    }
  }, {
    path: '/casearea',
    name: 'caseArea',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/entry/caseArea'))),
    meta: {
      title: '病例专区',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜病例专区',
        desc: ''
      },
      reachBottom: true
    }
  }, {
    path: '/personal',
    name: 'personal',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/entry/personal'))),
    meta: {
      title: '个人中心',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜个人中心',
        desc: ''
      },
      tabIndex: '3'
    }
  }, {
    path: '/meeting',
    name: 'meeting',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/meeting/index'))),
    meta: {
      title: '会议',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜拜耳会议',
        desc: ''
      },
      tabIndex: '1',
      reachBottom: true
    }
  }, {
    path: '/meetingdetail',
    name: 'meetingDetail',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/meeting/detail'))),
    meta: {
      title: '会议详情',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜会议详情',
        desc: '会议详情'
      },
      tabIndex: '1',
      hideApproval: true
    }
  }, {
    path: '/meetingdetailplayback',
    name: 'detailplayback',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/meeting/detailplayback'))),
    meta: {
      title: '会议详情',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜会议详情',
        desc: '会议详情'
      },
      tabIndex: '1',
      hideApproval: true
    }
  }, {
    path: '/allsearch',
    name: 'allSearch',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/entry/allSearch'))),
    meta: {
      title: '搜索',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜拜耳搜索',
        desc: ''
      },
      tabIndex: '0'
    }
  }, ..._course.default, ..._activity.default, ..._personal.default, ..._sign.default, ..._preview.default]
}];