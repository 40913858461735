"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.string.replace-all.js");
require("vant/es/image-preview/style");
var _imagePreview = _interopRequireDefault(require("vant/es/image-preview"));
var _meetingPoster = _interopRequireDefault(require("@/components/meeting/meetingPoster.vue"));
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _monitor = require("@/utils/monitor.js");
var _index = require("@/utils/index.js");
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _ConfirmDialog = _interopRequireDefault(require("@/components/ConfirmDialog.vue"));
var _default = exports.default = window.$initPage({
  components: {
    meetingPoster: _meetingPoster.default,
    stickyHeader: _header.default,
    ConfirmDialog: _ConfirmDialog.default
  },
  name: 'activityRichList',
  data() {
    return {
      myIsFaver: false,
      myIsZan: false,
      loadZan: false,
      sectionDetail: {},
      showAll: false,
      loadFinish: false,
      urlQuery: {},
      listCampainContent: [],
      listCampainContentIndex: 0,
      getCampainSectionDetailData: null,
      loadFinal: false,
      count: 0,
      hasShare: false,
      isSetLogFlag: true
    };
  },
  watch: {
    closeLoading() {
      if (this.closeLoading) {
        this.$store.commit('SET_LOADINGPOP', false);
      }
    }
  },
  filter: {},
  activated() {
    this.count++;
    console.log(this.count, '==>this.count');
  },
  created() {
    this.$store.commit('SET_LOADINGPOP', true);
    this.urlQuery = this.$route.query;
    this.getCampainContent();
    this.getModuleDetail();
    // this.isShowSignPage()
  },
  mounted() {},
  computed: {
    closeLoading() {
      return this.loadFinish && this.showAll;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    async isShowSignPage(res) {
      console.log('isShowSignPage');
      // let res = await this.$api.getCampainSectionDetailAuth({}, this.urlQuery.id)
      console.log(res, '==>res>res>res -------------------------');
      if (res !== null && res !== void 0 && res.errcode) return;
      let formsData = await this.$api.getCampainListAuth({}, res.campain_id);
      let authShowPop = await this.$api.getIsShowIntroduceAuth({}, res.campain_id, '5');
      // const resData = await this.$api.getShowPopDaysInfo({})
      //需要报名 且不是内部员工 且有表单
      const isApply = authShowPop.need_show_desc == 1 && this.userInfo.type == 1 && formsData.have_form == 1;
      if (res.need_veeva) {
        const resData = await this.$api.getShowPopDaysInfo({});
        this.isSetLogFlag = resData.veeva == 1 || resData.days > 0;
        if (resData.need_tips && resData.veeva == 0) {
          //是否展示体验认证弹框
          this.showDaysPop(resData, res, isApply);
          if (this.isSetLogFlag) {
            this.isSetLog(res);
          }
          return;
        }
      } else {
        this.isSetLogFlag = true;
      }
      this.isSetLog(res);
      if (isApply) {
        this.$refs.confirmDialog.open('浏览完整活动介绍，参与互动后，即可立即学习！感谢您的支持！', '').then(() => {
          this.goApplyRedirect(res);
          // 执行确认后的逻辑
        }).catch(error => {
          console.log('用户取消了操作:', error);
          this.$PUSH({
            path: '/'
          });
          // 处理取消逻辑
        });
      }
    },
    isSetLog(res) {
      let setScoreLogParams = {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            typeMap: 6
          }
        },
        section_id: res.id,
        action_type: 'VIEW'
      };
      this.$store.dispatch('setScoreLog', setScoreLogParams);
    },
    showDaysPop(res, resBackData, isApply) {
      let days = res.days || 0;
      this.$instanceExperienceView.show({
        days: days,
        //体验剩余天数
        isNeedBack: isApply,
        //是否重定向返回原页面
        callBack: ret => {
          if (ret.next == 'from') {
            if (isApply) {
              this.goApplyRedirect(resBackData);
              return;
            }
          } else {
            //立即认证跳转
            this.$PUSH({
              path: ret.next
            });
          }
        }
      });
    },
    goApplyRedirect(res) {
      let url = window.location.href.split('?')[1];
      window.sessionStorage.setItem('redirectUrl', `richlist?${url}`);
      this.$PUSH({
        path: `/activity/activeShowOncePoster?id=${res.campain_id}&_contentId=${res.id}&_typeMap=${res.type}`
      });
    },
    setWeChatShare(params) {
      this.weChatShare.title = params.name;
      this.weChatShare.desc = params.name;
      this.weChatShare.imgUrl = params.cover;
      // params?.contentid && (this.weChatShare.contentid = params.contentid)

      this.initWechat();
    },
    studyState(im) {
      return Number(im.learn_time) > 20 ? '已学习' : '待学习';
    },
    toPath(im) {
      const path = `/activity/richdetail`;
      const query = {
        id: this.urlQuery.id,
        campain_id: im.campain_id,
        section_id: this.urlQuery.id,
        typeMap: 7,
        contentid: im.id
      };
      this.$PUSH({
        path,
        query
      });
    },
    /* 生成分享海报 */
    myShare() {
      const self = this;
      const detailPosterInner = document.querySelector('.detailPosterInnerOut');
      (0, _html2canvas.default)(detailPosterInner, {
        useCORS: true
      }).then(canvas => {
        self.capUrl = canvas.toDataURL();
        (0, _imagePreview.default)({
          images: [self.capUrl],
          closeable: true,
          onClose() {
            self.$store.commit('SET_SHOWSAVEBTN', false);
          }
        });
        self.$store.commit('SET_SHOWSAVEBTN', true);
        setTimeout(() => {
          // self.$store.commit('SET_INTEGRAL', { show: true, integral: 10 })
          self.$store.dispatch('setScoreLog', {
            route: self.$route,
            action_type: 'SHARE',
            section_id: self.urlQuery.id
          });
        }, 2000);
      });
    },
    /* 发送监测 */
    oncePostMonitor() {
      console.log('==>oncePostMonitor>oncePostMonitor');
      (0, _monitor.dataStatistics)({
        event_type: 'view',
        // view = 浏览  click = 点击   share = 分享
        page_title: `拜影云学院:模块图文列表:${this.listCampainContent[this.listCampainContentIndex].name}`,
        firstParams: true,
        typeMap: 6,
        content_id: this.listCampainContent[this.listCampainContentIndex].section_id,
        campain_id: this.listCampainContent[this.listCampainContentIndex].campain_id
      });
    },
    /* -------------------------------------- */
    /* 获取目录列表 */
    async getCampainContent() {
      try {
        var _this$listCampainCont;
        let params = {
          section_id: this.urlQuery.id,
          current: 1,
          pageSize: 99999
        };
        let res = await this.$api.getCampainContent(params);
        this.loadFinish = true;
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCampainContent" + res.errmsg)
          return;
        }
        const finalData = (0, _index.limitData)({
          interfaceData: res,
          baseData: this.listCampainContent,
          limit: params
        });
        finalData.currentAdd && this.current++;
        this.listCampainContent = finalData.baseData;
        this.loadFinal = true;
        this.hasShare = this.listCampainContent.every(item => item.need_veeva == 1);
        console.log(this.hasShare, '==>this.listCampainContent');
        if (((_this$listCampainCont = this.listCampainContent) === null || _this$listCampainCont === void 0 ? void 0 : _this$listCampainCont.length) < 1) {
          this.tipWords = '暂无数据';
          this.$refs.customPop.open();
          return;
        }

        // const fIndex = this.listCampainContent.findIndex(im => im.id == this.urlQuery.contentid)
        // this.listCampainContentIndex = fIndex > -1 ? fIndex : 0

        // let shareParams = {
        //     name: this.listCampainContent[this.listCampainContentIndex].name,
        //     cover: this.listCampainContent[this.listCampainContentIndex].cover,
        //     contentid: this.listCampainContent[this.listCampainContentIndex].id
        // }
        // this.setWeChatShare(shareParams)

        // this.oncePostMonitor()
      } catch (error) {}
    },
    /* ------------------------------ */
    async zanHandle(type) {
      if (this.loadZan) return;
      type = !this.myIsZan;
      this.loadZan = true;
      type && this.$store.dispatch('setScoreLog', {
        route: this.$route,
        action_type: 'ZAN',
        section_id: this.urlQuery.id
      });
      try {
        let res = await this.$api[type ? 'contentZan' : 'cancleContentZan']({
          content_id: this.urlQuery.id,
          type: 6
        });
        this.loadZan = false;
        if (type) {
          this.myIsZan = true;
          this.sectionDetail.zan++;
          this.$emit('setIsZan', true);
        } else {
          this.sectionDetail.zan--;
          this.myIsZan = false;
          this.$emit('setIsZan', false);
        }
      } catch (error) {}
    },
    async faverHandle(type) {
      if (this.loadFaver) return;
      type = !this.myIsFaver;
      this.loadFaver = true;
      type && this.$store.dispatch('setScoreLog', {
        route: this.$route,
        action_type: 'FAVER',
        section_id: this.urlQuery.id
      });
      try {
        let res = await this.$api[type ? 'contentFaver' : 'cancleContentFaver']({
          content_id: this.urlQuery.id,
          type: 6
        });
        this.loadFaver = false;
        if (type) {
          this.myIsFaver = true;
          this.sectionDetail.faver++;
          this.$emit('setIsFaver', true);
        } else {
          this.myIsFaver = false;
          this.sectionDetail.faver--;
          this.$emit('setIsFaver', false);
        }
      } catch (error) {}
    },
    async getZanCheck() {
      let res = await this.$api.getZanCheck(this.urlQuery.id, 6);
      if (res.errcode) {
        alert('getZanCheck(this.urlQuery.id, 6)' + res.errmsg);
        return;
      }
      if (res.count) {
        this.myIsZan = true;
        return;
      }
      this.myIsZan = false;
    },
    async getFaverCheck() {
      let res = await this.$api.getFaverCheck(this.urlQuery.id, 6);
      if (res.errcode) {
        alert('getZanCheck(this.urlQuery.id, 6)' + res.errmsg);
        return;
      }
      if (res.count) {
        this.myIsFaver = true;
        return;
      }
      this.myIsFaver = false;
    },
    /* 查询当前内容有没有权限 */
    async getContentForId() {
      const isToDetail = localStorage.getItem('isToDetail');
      const res = await this.$api.getCampainContentId({}, this.urlQuery.contentid);
      console.log(res, '==.');
      if (res.errcode == 50002) {
        if (isToDetail) {
          localStorage.setItem('isToDetail', '');
          return;
        }
        this.$toatsView.show({
          tipWords: '抱歉！您暂无法查看该内容，请查看该活动下的其他内容'
        });
      }
    },
    /* 获取模块详情 用于点赞收藏 */
    async getModuleDetail() {
      try {
        let res = await this.$api.getCampainSectionDetailAuth({}, this.urlQuery.id);
        this.isShowSignPage(res);
        if ((res === null || res === void 0 ? void 0 : res.hasErrCode) != 50001) {
          var _this$urlQuery;
          this.showAll = true;
          if ((_this$urlQuery = this.urlQuery) !== null && _this$urlQuery !== void 0 && _this$urlQuery.source) {
            /* 查询当前内容有没有权限 */
            this.getContentForId();
          }
        }
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCampainSectionDetail" + res.errmsg)
          return;
        }
        if (res.content) res.content = res.content.replaceAll('<img', '<img style="max-width:100%;"');
        this.sectionDetail = res;
        console.log(this.sectionDetail, '==>this.sectionDetail>this.sectionDetail');
        this.setWeChatShare({
          name: res.name,
          cover: res.cover
        });
        this.getZanCheck();
        this.getFaverCheck();
      } catch (error) {}
    }
  }
});