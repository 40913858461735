"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _swiper = _interopRequireDefault(require("swiper"));
var _default = exports.default = window.$initPage({
  props: {
    dataBase: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    return {
      nowIndex: 0,
      statusArr: [{
        word: '未开始',
        color: '#fd9a64',
        bgcolor: '253,154,100'
      }, {
        word: '进行中',
        color: '#80cf1a',
        bgcolor: '128,207,26'
      }, {
        word: '回放中',
        color: '#00b6ff',
        bgcolor: '0,182,255'
      }, {
        word: '已结束',
        color: '#00617f',
        bgcolor: '0,97,127'
      }]
    };
  },
  watch: {
    dataBase: function (handle) {
      if (handle.length) {
        this.$nextTick(() => {
          this.initSwiper();
        });
      }
    }
  },
  filter: {},
  created() {},
  mounted() {
    if (this.dataBase.length) {
      this.$nextTick(() => {
        this.initSwiper();
      });
    }
  },
  computed: {},
  methods: {
    showFormatTime(im) {
      const start_time = this.$SPLITDATE(im.start_time, 'yyyy-mm-dd h:m');
      const end_time = this.$SPLITDATE(im.end_time, 'yyyy-mm-dd h:m');
      const start_time_arr = start_time.split(' ');
      const end_time_arr = end_time.split(' ');
      if (start_time_arr[0] == end_time_arr[0]) {
        return start_time + ' - ' + end_time_arr[1];
      }
      return start_time + ' - ' + end_time;
    },
    initSwiper() {
      let _this = this;
      try {
        var swiper = new _swiper.default('.mySwiper', {
          slidesPerView: 'auto',
          centeredSlides: true,
          // spaceBetween: 22,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          on: {
            slideChangeTransitionStart: function () {
              // alert(this.activeIndex);
              _this.nowIndex = this.activeIndex;
            }
          }
        });
      } catch (error) {
        setTimeout(() => {
          this.initSwiper();
        }, 500);
      }
    }
  }
});