"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "veevaStep"
  }, [_c("div", {
    staticClass: "flex justify-center align-center veevaStepOut"
  }, [_c("div", {
    staticClass: "veevaStepLeft",
    class: _vm.step == 1 ? "veevaStepCurrent" : ""
  }, [_vm._m(0), _c("div", {
    staticClass: "veevaStepLeftWord",
    class: _vm.step == 1 ? "veevaStepCurrent" : ""
  }, [_vm._v("填写信息")])]), _c("div", {
    staticClass: "veevaStepRight flex flex-direction align-end",
    class: _vm.step == 2 ? "veevaStepCurrent" : ""
  }, [_vm._m(1), _c("div", {
    staticClass: "veevaStepRighttWord",
    class: _vm.step == 2 ? "veevaStepCurrent" : ""
  }, [_vm._v("上传资料")])])])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "veevaStepLeftDesc"
  }, [_c("div", {
    staticClass: "veevaStepLeftDescCenter"
  }, [_c("div", {
    staticClass: "veevaStepLeftDescInner"
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "veevaStepRightDesc"
  }, [_c("div", {
    staticClass: "veevaStepRightDescCenter"
  }, [_c("div", {
    staticClass: "veevaStepRightDescInner"
  })])]);
}];
render._withStripped = true;