"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container-out"
  }, [_c("stickyHeader"), _vm._m(0), _vm.type == "cer" ? _c("div", {
    staticClass: "achieveDetailContentCer"
  }, [_c("div", {
    staticClass: "achieveDetailContentCertop"
  }, [_c("img", {
    staticClass: "achieveDetailContentBadgeStar3 achieveDetailContentBadgeStar",
    attrs: {
      src: require("img/personal/ach/star3.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "achieveDetailContentBadgeStar2 achieveDetailContentBadgeStar",
    attrs: {
      src: require("img/personal/ach/star2.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "achieveDetailContentBadgeStar1 achieveDetailContentBadgeStar",
    attrs: {
      src: require("img/personal/ach/star1.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "achieveDetailContentCertopCer"
  }, [_c("img", {
    staticClass: "achieveDetailContentCertopCerShadowImg",
    attrs: {
      src: require("img/personal/ach/cerImgShadow.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "achieveDetailContentCertopCerImg"
  }, [_c("cerImg", {
    ref: `cerImg${9}`,
    attrs: {
      getCer: true,
      dataBase: _vm.detail,
      dataIx: 9
    }
  })], 1)])]), _c("div", {
    staticClass: "achieveDetailContentCerName"
  }, [_vm._v(_vm._s(_vm.detail.name))]), _vm.isGet ? _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "achieveDetailContentCerBtn achieveDetailContentCerBtnShare flex align-center justify-center",
    attrs: {
      "event-key": `分享成就-${_vm.detail.name}`
    },
    on: {
      click: function ($event) {
        return _vm.$REDIRECT({
          path: `/personal/achieveshare?type=${_vm.type}&id=${_vm.$route.query.id}`
        });
      }
    }
  }, [_vm._v("分享成就")]) : _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "achieveDetailContentCerBtn flex align-center justify-center",
    attrs: {
      "event-key": `收下成就-${_vm.detail.name}`
    },
    on: {
      click: _vm.getNow
    }
  }, [_vm._v("立即收下")])]) : _vm._e(), _vm.type == "badge" ? _c("div", {
    staticClass: "achieveDetailContent"
  }, [_c("div", {
    staticClass: "achieveDetailContentBadge"
  }, [_c("img", {
    staticClass: "achieveDetailContentBadgeIcon",
    attrs: {
      src: _vm.detail.path,
      alt: ""
    }
  }), _c("img", {
    staticClass: "achieveDetailContentBadgeStar3 achieveDetailContentBadgeStar",
    attrs: {
      src: require("img/personal/ach/star3.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "achieveDetailContentBadgeStar2 achieveDetailContentBadgeStar",
    attrs: {
      src: require("img/personal/ach/star2.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "achieveDetailContentBadgeStar1 achieveDetailContentBadgeStar",
    attrs: {
      src: require("img/personal/ach/star1.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "achieveDetailContentName"
  }, [_vm._v(_vm._s(_vm.detail.name) + " ")]), _vm.type == "badge" ? _c("div", {
    staticClass: "achieveDetailContentDesc"
  }, [_vm._v(_vm._s(_vm.detail.introduction))]) : _vm._e(), _vm.isGet ? _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "achieveDetailContentBtn achieveDetailContentBtnShare flex align-center justify-center",
    attrs: {
      "event-key": `分享成就-${_vm.detail.name}`
    },
    on: {
      click: function ($event) {
        return _vm.$REDIRECT({
          path: `/personal/achieveshare?type=${_vm.type}&id=${_vm.$route.query.id}`
        });
      }
    }
  }, [_vm._v("分享成就")]) : _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "achieveDetailContentBtn flex align-center justify-center",
    attrs: {
      "event-key": `收下成就-${_vm.detail.name}`
    },
    on: {
      click: _vm.getNow
    }
  }, [_vm._v("立即收下")])]) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "achieveDetailWords"
  }, [_vm._v("经过您在拜耳影像学苑的不懈努力 "), _c("br"), _vm._v(" 恭喜获得以下勋章")]);
}];
render._withStripped = true;