"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  name: 'customVideo',
  props: {
    // isShow: {
    //   type: [String, Boolean],
    //   default: false
    // }
  },
  data() {
    return {
      isShow: false
    };
  },
  watch: {},
  filter: {},
  created() {},
  mounted() {
    this.getInfo();
  },
  computed: {},
  methods: {
    close() {
      this.isShow = false;
      this.setPop();
    },
    async getInfo() {
      let res = await this.$api.getVeevaPop({
        type: 'ISTIP_HOME'
      });
      if ((res === null || res === void 0 ? void 0 : res.istip) == 0) {
        this.isShow = true;
      }
    },
    async setPop() {
      this.$api.updateVeevaPop({
        type: 'ISTIP_HOME'
      });
    },
    goWxGZH() {
      window.location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU2NjQ3NDk4MA==&scene=117#wechat_redirect';
    }
  }
};