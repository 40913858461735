"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$sectionDetail, _vm$sectionDetail2;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "posterIntroducePage"
  }, [_c("div", {
    staticClass: "page-container"
  }, [_c("stickyHeader"), (_vm$sectionDetail = _vm.sectionDetail) !== null && _vm$sectionDetail !== void 0 && _vm$sectionDetail.id ? _c("div", {}, [_c("div", {
    staticClass: "detailHeaderCover"
  }, [_c("img", {
    attrs: {
      src: _vm.sectionDetail.cover,
      alt: ""
    }
  })]), _c("div", {
    staticClass: "detailHeaderTitle flex justify-between"
  }, [_c("div", {
    staticClass: "detailHeaderTitleLeft hideWords-More3"
  }, [_vm._v(_vm._s(_vm.sectionDetail.name))])]), (_vm$sectionDetail2 = _vm.sectionDetail) !== null && _vm$sectionDetail2 !== void 0 && _vm$sectionDetail2.need_veeva ? _c("div", {
    staticClass: "authIcon"
  }, [_c("img", {
    attrs: {
      alt: "",
      srcset: "",
      src: require("img/common/authIcon.png")
    }
  })]) : _vm._e(), _c("div", {
    staticClass: "detailMeetingTime flex align-center"
  }, [_c("img", {
    attrs: {
      src: require("img/common/timeClockBlack.png"),
      alt: ""
    }
  }), _vm._v(" 活动时间：" + _vm._s(_vm.showFormatTime(_vm.sectionDetail)) + " ")]), _c("div", {
    staticClass: "detailLine"
  }), _c("div", {
    staticClass: "detailIntroduce"
  }, [_c("div", {
    staticClass: "detailIntroduceTitle"
  }, [_vm._v("介绍")]), _c("div", {
    staticClass: "detailIntroduceContent ql-editor",
    domProps: {
      innerHTML: _vm._s(_vm.$VIDEOPLAYSINLINE(_vm.sectionDetail.description))
    },
    on: {
      click: _vm.$imageEnlargement
    }
  })]), _vm.userInfo.type == "2" || _vm.sectionDetail.have_form == 0 || _vm.sectionDetail.have_form == null ? _c("div", {
    staticClass: "fixedHeight"
  }) : _vm._e(), !_vm.is_preview && _vm.isShowActiveBtn ? _c("div", {
    staticClass: "fixedBox flex align-center justify-center"
  }, [_c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "handleBtn flex align-center justify-center engrySign",
    attrs: {
      "event-key": _vm.userInfo.type == "2" || _vm.sectionDetail.have_form == 0 || _vm.sectionDetail.have_form == null ? "进入活动" : "提交表单，进入活动"
    },
    on: {
      click: _vm.enterActivity
    }
  }, [_vm._v(" " + _vm._s(_vm.userInfo.type == "2" || _vm.sectionDetail.have_form == 0 || _vm.sectionDetail.have_form == null ? "进入活动" : "提交表单，进入活动") + " ")])]) : _vm._e()]) : _vm._e(), _c("customPop", {
    ref: "customPop-tip",
    attrs: {
      showType: "slot"
    }
  }, [_c("div", {
    staticClass: "enterActivity"
  }, [_c("div", {
    staticClass: "enterActivityTip"
  }, [_vm._v("该活动仅面向Iv.3以上用户 "), _c("br"), _vm._v("请马上提升等级")]), _c("div", {
    staticClass: "enterActivityHandle flex align-center justify-between"
  }, [_c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "enterActivityHandleNow flex align-center justify-center enterActivityHandleBtn",
    attrs: {
      "event-key": "进入活动被拦截点击去提升"
    },
    on: {
      click: _vm.enterActivityHandleNow
    }
  }, [_vm._v(" 去提升 ")]), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "enterActivityHandleDelay flex align-center justify-center enterActivityHandleBtn",
    attrs: {
      "event-key": "进入活动被拦截点击取消"
    },
    on: {
      click: _vm.enterActivityHandleDelay
    }
  }, [_vm._v(" 取消 ")])])])])], 1), _vm.sectionDetail ? _c("activeShowOnceForm", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowNextPageFlag,
      expression: "!isShowNextPageFlag"
    }],
    ref: "parentRef",
    attrs: {
      id: "formContainer",
      sectionDetail: _vm.sectionDetail
    },
    on: {
      isShowParentAddActiveBtn: _vm.isShowParentAddActiveBtn
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;