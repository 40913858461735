"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$callUs;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "veevaPass"
  }, [_vm._m(0), _c("div", {
    staticClass: "veevaPassTip font-weight"
  }, [_vm._v("您现可以")]), _c("div", {
    staticClass: "veevaPassContent marginAuto"
  }, [_c("div", {
    staticClass: "veevaPassContentList veevaPassContentList1 flex justify-between align-center"
  }, [_vm._m(1), _c("div", {
    staticClass: "veevaPassContentListLeft"
  }, [_c("div", {
    staticClass: "veevaPassContentListLeftTitle"
  }, [_vm._v("畅享精品系列课程")]), _c("div", {
    staticClass: "veevaPassContentListLeftDesc"
  }, [_vm._v("肝脏、脑血管、规范化等系列训练营不限时学习")]), _c("div", {
    staticClass: "veevaPassContentListLeftBtn1 flex align-center justify-center",
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/activity"
        });
      }
    }
  }, [_vm._v(" 立即学习 ")])])]), _c("div", {
    staticClass: "veevaPassContentList veevaPassContentList1 flex justify-between align-center"
  }, [_vm._m(2), _c("div", {
    staticClass: "veevaPassContentListLeft"
  }, [_c("div", {
    staticClass: "veevaPassContentListLeftTitle"
  }, [_vm._v("绑定拜耳学术会议平台")]), _c("div", {
    staticClass: "veevaPassContentListLeftDesc"
  }, [_vm._v("点击右侧二维码进行绑定")]), _c("div", {
    staticClass: "veevaPassContentListLeftBtn1 flex align-center justify-center",
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/bindStep"
        });
      }
    }
  }, [_vm._v(" 查看绑定步骤 ")])]), _c("div", {
    staticClass: "veevaPassContentListRight",
    on: {
      click: _vm.scalePicHandle
    }
  }, [_c("img", {
    attrs: {
      src: _vm.base64QRCode,
      alt: ""
    }
  })])]), _c("div", {
    staticClass: "veevaPassContentList veevaPassContentList2 flex justify-between"
  }, [_vm._m(3), _vm._m(4), _c("div", {
    staticClass: "veevaPassContentListRight"
  }, [_c("img", {
    attrs: {
      src: ((_vm$callUs = _vm.callUs) === null || _vm$callUs === void 0 ? void 0 : _vm$callUs.qrcode) || _vm.$STAFFVEEVAICON,
      alt: ""
    }
  })])]), _c("div", {
    staticClass: "veevaPassContentList veevaPassContentList3 flex justify-between"
  }, [_vm._m(5), _c("div", {
    staticClass: "veevaPassContentListLeft"
  }, [_c("div", {
    staticClass: "veevaPassContentListLeftTitle"
  }, [_vm._v("查看您的学术能量排行榜")]), _c("div", {
    staticClass: "veevaPassContentListLeftBtn flex align-center justify-center",
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/personal/rank"
        });
      }
    }
  }, [_vm._v(" 点此解锁 ")])])])]), _c("picLook", {
    ref: "scalePic",
    attrs: {
      url: _vm.base64QRCode
    }
  }), _c("div", {
    staticClass: "outScreen"
  }, [_c("qriously", {
    ref: "QRCode",
    attrs: {
      value: _vm.link(),
      foreground: "#000000",
      size: 200,
      backgroundAlpha: 0
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "veevaPassTop flex justify-between"
  }, [_c("div", {
    staticClass: "veevaPassTopLeft"
  }, [_c("div", {
    staticClass: "veevaPassTopLeftTitle"
  }, [_vm._v("医疗卫生专业人士")]), _c("div", {
    staticClass: "veevaPassTopLeftdesc"
  }, [_vm._v("身份已经点亮")])]), _c("div", {
    staticClass: "veevaPassTopRight"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/veevapass/icon.png"),
      alt: ""
    }
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "veevaPassContentListNum"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/veevapass/one.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "veevaPassContentListNum"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/veevapass/two.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "veevaPassContentListNum"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/veevapass/three.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "veevaPassContentListLeft"
  }, [_c("div", {
    staticClass: "veevaPassContentListLeftTitle"
  }, [_vm._v("联系学术助手或加入学术社群")]), _c("div", {
    staticClass: "veevaPassContentListLeftDesc"
  }, [_vm._v(" 识别右侧二维码可以添加您的专属学术助手，备注“我要进学术社群”，与同行进行学术及实践交流 ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "veevaPassContentListNum"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/veevapass/four.png"),
      alt: ""
    }
  })]);
}];
render._withStripped = true;