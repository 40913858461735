"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container-components"
  }, [_c("div", {
    staticClass: "pageHeader flex align-center justify-between"
  }, [_c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "pageHeaderBack",
    attrs: {
      "event-key": `navbar拜耳影像学苑-返回按钮`
    },
    on: {
      click: _vm.pageBack
    }
  }, [_c("img", {
    attrs: {
      src: require("img/commonHeader/backIcon.png")
    }
  })]), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "flex align-center titleIconOut",
    attrs: {
      "event-key": `navbar拜耳影像学苑`
    },
    on: {
      click: function ($event) {
        return _vm.reload();
      }
    }
  }, [_c("img", {
    staticClass: "titleIcon",
    attrs: {
      src: require("img/commonHeader/titleIcon.png")
    }
  })]), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "pageHeaderRight flex align-center",
    attrs: {
      "event-key": `navbar联系我们`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/contactUs"
        });
      }
    }
  }, [_c("img", {
    attrs: {
      src: require("img/commonHeader/call.png"),
      alt: ""
    }
  })])]), _c("div", {
    staticClass: "pageHeaderSeat pageHeaderSeatActiveSpecial"
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;