"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$sectionDetail, _vm$sectionDetail2;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "activityRichList"
  }, [_c("stickyHeader"), _vm.listCampainContent.length && _vm.loadFinal ? _c("div", {
    staticClass: "handleList flex flex-direction justify-between"
  }, [_vm.hasShare ? _c("div", {
    staticClass: "handleListMask"
  }) : _vm._e(), _c("div", {
    staticClass: "handleListItem flex align-center justify-center",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.myShare.apply(null, arguments);
      }
    }
  }, [_c("img", {
    staticClass: "handleListItemShareIcon",
    attrs: {
      src: require("img/common/shareWhite.png"),
      alt: ""
    }
  }), _vm._v(" 分享 ")]), _c("div", {
    staticClass: "handleListItem flex align-center justify-center",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.faverHandle.apply(null, arguments);
      }
    }
  }, [!_vm.myIsFaver ? _c("img", {
    staticClass: "handleListItemStarIcon",
    attrs: {
      src: require("img/common/starWhite.png"),
      alt: ""
    }
  }) : _c("img", {
    staticClass: "handleListItemStarIcon",
    attrs: {
      src: require("img/common/starWhiteFill.png"),
      alt: ""
    }
  }), _vm._v(" " + _vm._s(_vm.sectionDetail.faver || 0) + " ")]), _c("div", {
    staticClass: "handleListItem flex align-center justify-center",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.zanHandle.apply(null, arguments);
      }
    }
  }, [!_vm.myIsZan ? _c("img", {
    staticClass: "handleListItemGiveIcon",
    attrs: {
      src: require("img/common/giveWhite.png"),
      alt: ""
    }
  }) : _c("img", {
    staticClass: "handleListItemGiveIcon",
    attrs: {
      src: require("img/common/giveWhiteFill.png"),
      alt: ""
    }
  }), _vm._v(" " + _vm._s(_vm.sectionDetail.zan || 0) + " ")])]) : _vm._e(), _c("div", {
    staticClass: "activityRichListView marginAuto"
  }, _vm._l(_vm.listCampainContent, function (im, ix) {
    return _c("div", {
      directives: [{
        name: "monitor",
        rawName: "v-monitor"
      }],
      key: ix,
      staticClass: "activityRichListViewItem flex justify-between",
      attrs: {
        "event-key": `活动模块图文列表-${im.name}`
      },
      on: {
        click: function ($event) {
          return _vm.toPath(im);
        }
      }
    }, [im !== null && im !== void 0 && im.need_veeva ? _c("div", {
      staticClass: "authIcon smallAuthIcon samllAuthPos"
    }, [_c("img", {
      attrs: {
        alt: "",
        srcset: "",
        src: require("img/common/authIcon.png")
      }
    })]) : _vm._e(), _c("div", {
      staticClass: "activityRichListViewItemLeft"
    }, [_c("img", {
      attrs: {
        src: im.cover,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "activityRichListViewItemRight"
    }, [_c("div", {
      staticClass: "activityRichListViewItemRightTitle hideWords-More4"
    }, [_vm._v(_vm._s(im.name))]), _c("div", {
      staticClass: "activityRichListViewItemRightBottom flex align-center"
    }, [_c("div", {
      staticClass: "leftBox flex align-center"
    }, [_c("div", {
      staticClass: "activityRichListViewItemRightBottomTag"
    }, [_vm._v(_vm._s(_vm.$MAPTYPE(im.content_type)))]), _c("div", {
      staticClass: "activityRichListViewItemRightBottomTime"
    }, [_vm._v(_vm._s(_vm.studyState(im)))])]), _c("div", {
      staticClass: "previewBox"
    }, [_c("div", {
      staticClass: "previewContent"
    }, [_c("img", {
      staticClass: "previeImg",
      attrs: {
        src: require("img/common/browseCountOther.png"),
        alt: "",
        srcset: ""
      }
    }), _c("span", {
      staticClass: "previewNum"
    }, [_vm._v(_vm._s(im.read_num || 0))])])])])])]);
  }), 0), _c("ConfirmDialog", {
    ref: "confirmDialog",
    attrs: {
      backgroundStyle: true
    }
  }), (_vm$sectionDetail = _vm.sectionDetail) !== null && _vm$sectionDetail !== void 0 && _vm$sectionDetail.id ? _c("meetingPoster", {
    attrs: {
      dataBase: _vm.sectionDetail,
      posterType: 7,
      tagList: (_vm$sectionDetail2 = _vm.sectionDetail) === null || _vm$sectionDetail2 === void 0 ? void 0 : _vm$sectionDetail2.tag_name_list
    }
  }) : _vm._e(), !_vm.listCampainContent.length && _vm.loadFinal ? _c("div", {
    staticClass: "meetingEmpty flex align-center justify-center"
  }, [_vm._v(" 暂无数据 ")]) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;