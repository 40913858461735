"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$detail, _vm$detail2, _vm$detail3;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container-out page-container-view intercept"
  }, [_c("stickyHeader"), _vm._m(0), (_vm$detail = _vm.detail) !== null && _vm$detail !== void 0 && _vm$detail.id ? _c("div", {
    staticClass: "interceptBox marginAuto flex flex-direction align-center"
  }, [_c("div", {
    staticClass: "interceptBoxQuestionTop"
  }, [_c("div", {
    staticClass: "interceptBoxQuestionTopWords flex align-center justify-center"
  }, [_vm._v("问卷调查")]), _c("div", {
    staticClass: "interceptBoxQuestionImg"
  }, [(_vm$detail2 = _vm.detail) !== null && _vm$detail2 !== void 0 && _vm$detail2.cover ? _c("img", {
    attrs: {
      src: _vm.detail.cover,
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("img/personal/question/interceptIcon.png"),
      alt: ""
    }
  })])]), _c("div", {
    staticClass: "interceptBoxWords"
  }, [_vm._v(_vm._s(_vm.detail.title)), _c("img", {
    staticClass: "quesIcon",
    attrs: {
      src: require("img/personal/question/quesIcon.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "interceptBoxTime"
  }, [_c("div", {
    staticClass: "interceptBoxTimeInner"
  }, [_vm._v(" " + _vm._s(_vm.$SPLITDATE(_vm.detail.start_time)) + " ")])])]) : _vm._e(), (_vm$detail3 = _vm.detail) !== null && _vm$detail3 !== void 0 && _vm$detail3.id ? _c("div", {
    staticClass: "interceptPass marginAuto flex align-center justify-center",
    on: {
      click: _vm.toPath
    }
  }, [_c("div", {
    staticClass: "interceptPassInner flex align-center justify-center"
  }, [_vm._v(" 点击前往 ")])]) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "interceptBG"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/question/interceptBG.png"),
      alt: ""
    }
  })]);
}];
render._withStripped = true;