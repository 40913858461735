"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("vant/es/popup/style");
var _popup = _interopRequireDefault(require("vant/es/popup"));
require("vant/es/swipe-item/style");
var _swipeItem = _interopRequireDefault(require("vant/es/swipe-item"));
require("vant/es/swipe/style");
var _swipe = _interopRequireDefault(require("vant/es/swipe"));
var _index = require("@/utils/index.js");
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _list = _interopRequireDefault(require("@/components/course/list.vue"));
var _monitor = require("@/utils/monitor");
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    vanSwipe: _swipe.default,
    vanSwipeItem: _swipeItem.default,
    courseList: _list.default,
    vanPopup: _popup.default
  },
  data() {
    return {
      need_veeva: null,
      noThenData: false,
      urlQuery: {},
      courseClassUseType: null,
      courseClassSecUseType: null,
      showDrawer: true,
      courseClass: [],
      current: 1,
      pageSize: 20,
      courseList: [],
      category_id: null,
      courseLessonTagList: [],
      courseLessonTagListIdArr: [],
      searchWords: '',
      loadFinal: false,
      isReachBottom: false,
      showTags: false,
      isLoadAllData: false
    };
  },
  watch: {
    courseClassUse(newValue) {
      var _this$courseClass$thi;
      console.log(this.courseClassUse, '==>this.courseClassUse>this.courseClassUse');
      if (this.courseClassUse[1] == null) {
        console.log('for null ==================');
        this.category_id = this.courseClass[this.courseClassUse[0]].id;
        return;
      }
      console.log('not null ==================');
      this.category_id = (_this$courseClass$thi = this.courseClass[this.courseClassUse[0]]) === null || _this$courseClass$thi === void 0 ? void 0 : _this$courseClass$thi.children[this.courseClassUse[1]].id;
      // this.category_id = this.courseClassUse[1]
      // this.getData()
    },
    category_id() {
      // this.$store.commit("SET_LOADINGPOP", true)

      this.current = 1;
      this.pageSize = 20;
      this.courseLessonTagList = [];
      this.courseList = [];
      this.noThenData = false;
      this.isReachBottom = false;
      this.isLoadAllData = false;
      this.sendMonitor();
      this.getCourseLessonList('category_id');
      this.getCourseLessonTagList();
    }
  },
  filter: {},
  created() {
    this.urlQuery = this.$route.query;

    // this.category_id = this.urlQuery.id
    // this.$store.commit("SET_LOADINGPOP", true)

    this.initData();
    this.initReachBottom();
  },
  destroyed() {
    window.removeEventListener('reachBottom', this.reachBottomHandle);
  },
  mounted() {},
  computed: {
    courseClassUse() {
      return [this.courseClassUseType, this.courseClassSecUseType];
    }
    /* courseClass(){
      return this.$store.getters.courseClass
    },
    courseClassIdArr(){
      return this.$store.getters.courseClassIdArr
    } */
  },
  methods: {
    chooseNeedVeevaTags() {
      if (!this.loadFinal) return;
      this.need_veeva = this.need_veeva ? '' : 1;
      this.clearList(false);
    },
    sendMonitor() {
      let params = {};
      this.category_id && (params['category_id'] = this.category_id);
      this.searchWords && (params['filter[name]'] = this.searchWords);
      if (this.courseLessonTagListIdArr.length) {
        params['tag_ids'] = this.courseLessonTagListIdArr.join(',');
      }
      let moniStr = `${document.title}:筛选-`;
      this.searchWords && (params['filter[name]'] = this.searchWords);
      for (const key in params) {
        moniStr += `${key}=${params[key]}-`;
      }
      (0, _monitor.monitorClick)(moniStr);
    },
    /* 分页 start */
    initReachBottom() {
      window.addEventListener('reachBottom', this.reachBottomHandle);
    },
    reachBottomHandle(evt) {
      const self = this;
      if (evt.detail.path != '/course/class') {
        return;
      }
      console.log('self.isReachBottom', self.isReachBottom, self.isLoadAllData);
      if (self.isReachBottom) {
        return;
      }
      if (self.isLoadAllData) {
        return;
      }
      self.isReachBottom = true;
      this.getCourseLessonList('reachBottomHandle');
      this.getCourseLessonTagList();
    },
    /* 分页 end */

    getSearch() {
      this.current = 1;
      this.pageSize = 20;
      this.need_veeva = '';
      this.courseLessonTagList = [];
      this.courseList = [];
      this.noThenData = false;
      this.isReachBottom = false;
      this.isLoadAllData = false;
      this.sendMonitor();
      this.getCourseLessonList();
      this.getCourseLessonTagList();
    },
    tagHandle(im) {
      if (!this.loadFinal) return;
      im.check = !im.check;
      const idIndex = this.courseLessonTagListIdArr.indexOf(im.id);
      idIndex < 0 ? this.courseLessonTagListIdArr.push(im.id) : this.courseLessonTagListIdArr.splice(idIndex, 1);

      // dosth 触发获取列表的接口
      this.clearList();
    },
    clearList(clearTag) {
      this.current = 1;
      this.pageSize = 20;
      this.courseList = [];
      this.noThenData = false;
      this.isReachBottom = false;
      this.isLoadAllData = false;
      this.getCourseLessonList();
      this.sendMonitor();
    },
    initData() {
      this.getCourseCate();
      /* 在watch监听到了create中给id赋值，所以不用在这里触发  后续可能需要 */
      // this.getCourseLessonList()

      /* 获取标签 */
      // this.getCourseLessonTagList()
    },
    async getCourseLessonTagList() {
      this.showTags = false;
      try {
        let params = {
          current: this.current,
          pageSize: this.pageSize
        };
        this.category_id && (params['category_id'] = this.category_id);
        if (this.courseLessonTagListIdArr.length) {
          params['tag_ids'] = this.courseLessonTagListIdArr.join(',');
          // params["filter[tag_ids]"] = this.courseLessonTagListIdArr.join(",")
        }
        this.searchWords && (params['filter[name]'] = this.searchWords);
        let res = await this.$api.getCourseLessonTagList(params);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCourseLessonTagList请求失败：" + res.errmsg)
          return;
        }
        res.forEach(im => {
          im.check = false;
        });
        this.courseLessonTagList = (0, _index.quchong)([...this.courseLessonTagList, ...res]);
        this.showTags = true;
        setTimeout(() => {
          (0, _index.handleTagCss)(this.$refs.courseTag, this.$refs.courseTagInner, this.$refs.courseTagInnerItem);
        }, 0);
      } catch (error) {
        console.log(error, '==>> error');
      }
    },
    async getCourseLessonList(form) {
      console.log(form, '==>form>form>form');
      this.loadFinal = false;
      try {
        let params = {
          current: this.current,
          pageSize: this.pageSize,
          need_veeva: this.need_veeva
        };
        this.category_id != null && (params['category_id'] = this.category_id);
        this.searchWords && (params['filter[name]'] = this.searchWords);
        if (this.courseLessonTagListIdArr.length) {
          params['tag_ids'] = this.courseLessonTagListIdArr.join(',');
          // params["filter[tag_ids]"] = this.courseLessonTagListIdArr.join(",")
        }
        let res = await this.$api.getCourseLessonList(params);
        this.isReachBottom = false;
        this.loadFinal = true;

        // this.$store.commit("SET_LOADINGPOP", false)
        setTimeout(() => {
          this.showDrawer = false;
        }, 500);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getMyFaver请求失败：" + res.errmsg)
          return;
        }
        if ((res === null || res === void 0 ? void 0 : res.data.length) < params.pageSize) {
          this.isLoadAllData = true;
        }
        const finalData = (0, _index.limitData)({
          interfaceData: res,
          baseData: this.courseList,
          limit: params
        });
        this.courseList = finalData.baseData;
        finalData.currentAdd && this.current++ && (this.noThenData = false);
        !finalData.currentAdd && (this.noThenData = true);
      } catch (error) {
        console.log(error, '==>> error');
      }
    },
    async getCourseCate() {
      try {
        /* 获取全部分类 */
        let res = await this.$api.getCourseCate(1);
        this.$store.commit('SET_LOADINGPOP', false);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCourseCate(0)失败" + res.errmsg)
          return;
        }
        this.courseClass = res;
        const calcIndex = this.courseClass.findIndex(im => im.id == Number(this.urlQuery.id));
        this.courseClassUseType = calcIndex > -1 ? calcIndex : 0;
        this.getData();
      } catch (error) {}
    },
    async getData() {
      var _this$courseClass$thi2;
      const firstClass = this.courseClass[this.courseClassUse[0]];
      const secClass = (_this$courseClass$thi2 = this.courseClass[this.courseClassUse[0]]) === null || _this$courseClass$thi2 === void 0 || (_this$courseClass$thi2 = _this$courseClass$thi2.children) === null || _this$courseClass$thi2 === void 0 ? void 0 : _this$courseClass$thi2[this.courseClassUse[1]];
    }
  }
});