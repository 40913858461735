"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  props: {
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      veevaPopInfo: {},
      base64QRCode: ''
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    /* 通知数据库修改弹窗状态 */
    async setPop() {
      this.$api.updateVeevaPop({
        type: 'VEEVA_HOME'
      });
    },
    getBase64QRCode() {
      this.base64QRCode = this.$refs.QRCode.qrious.canvas.toDataURL();
      this.show = true;
    },
    lookBindStepPath() {
      this.$PUSH({
        path: `/bindStep`
      });
    },
    async getInfo() {
      let res = await this.$api.getVeevaPop({
        type: 'VEEVA_HOME'
      });
      if (res !== null && res !== void 0 && res.link) {
        this.veevaPopInfo = res;
        this.$nextTick(() => {
          this.getBase64QRCode();
        });
      }
    },
    open() {
      this.show = true;
    },
    maskHandle() {
      if (this.mask) {
        this.popFullClose();
      }
    },
    popFullClose() {
      this.setPop();
      this.show = false;
      this.$emit('cancle');
    }
  }
};