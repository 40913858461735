"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  props: {
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    },
    /* 
      展示形式
        words： 文字提示
        slot  插槽
    */
    showType: {
      type: String,
      default: "words"
    },
    /* 提示文字 */
    tipWords: {
      type: String,
      default: "请传入提示文字"
    }
  },
  data() {
    return {
      showPop: false,
      outCallBack: null,
      isToHome: false,
      formRouter: false
    };
  },
  watch: {},
  filter: {},
  created() {},
  mounted() {},
  computed: {
    isRegister() {
      return localStorage.getItem("isRegister");
    }
  },
  methods: {
    toRegister() {
      this.outCallBack({
        next: '/importregister'
      });
      this.close();
    },
    cancleRegister() {
      this.outCallBack({
        next: "from"
      });
      this.close();
    },
    open(callBack, isToHome, formRouter) {
      this.isToHome = isToHome;
      this.outCallBack = callBack;
      /* 从路由拦截过来以后，点击closeicon的时候调取取消的方法 */
      this.formRouter = formRouter;
      this.showPop = true;
    },
    close() {
      if (this.formRouter) {
        this.cancleRegister();
        return;
      }
      this.popFullClose();
    },
    maskHandle() {
      if (this.mask) {
        this.popFullClose();
      }
    },
    popFullClose(type) {
      this.showPop = false;
      if (type == "toHome") {
        if (!this.isRegister && this.isToHome) {
          this.isToHome = false;
          this.$REDIRECT({
            path: '/'
          });
        }
        return;
      }
      this.$emit("cancle");
    }
  }
};