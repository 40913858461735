"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/popup/style");
var _popup = _interopRequireDefault(require("vant/es/popup"));
var _default = exports.default = {
  components: {
    vanPopup: _popup.default
  },
  props: {},
  data() {
    return {
      showPop: false,
      showWaitReceive: false,
      memberPopover: {},
      nextUrl: "/personal/badge"
    };
  },
  watch: {},
  filter: {},
  created() {
    this.getMemberPopover();
  },
  mounted() {},
  computed: {
    /* showWaitReceive:{
      get(){
        const { achievements } = this.memberPopover
        return (achievements?.['certificate_status'] || achievements?.['medal_status'])
      },
      set(value){ }
    }, */
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    descReceive() {
      const {
        popover
      } = this.memberPopover;
      let str, nextUrl;
      if (Number(popover.certificate_count)) {
        str = `${popover.certificate_count}个证书`;
        nextUrl = `/personal/cer`;
      }
      if (Number(popover.medal_count)) {
        str = `${popover.medal_count}个勋章`;
        nextUrl = `/personal/badge`;
      }
      if (Number(popover.medal_count) && Number(popover.certificate_count)) {
        str = `${popover.medal_count}个勋章和${popover.certificate_count}个证书`;
        nextUrl = `/personal/badge`;
      }
      this.nextUrl = nextUrl;
      return str;
    },
    async getMemberPopover() {
      let res = await this.$api.getMemberPopover();
      this.memberPopover = res;
      const hasCount = Number(res.popover.certificate_count) + Number(res.popover.medal_count);
      if (hasCount) {
        this.showWaitReceive = true;
      }
    }
  }
};