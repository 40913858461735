"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _register = _interopRequireDefault(require("@/api/modules/register.js"));
var _common = _interopRequireDefault(require("@/api/modules/common.js"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default
  },
  data() {
    return {
      isInintData: false,
      userInfo: {},
      callUs: {}
    };
  },
  filter: {},
  async created() {
    console.log('created');
    this.$store.commit("SET_LOADINGPOP", true);
    await this.initData();
  },
  mounted() {},
  methods: {
    async initData() {
      try {
        console.log('methods1');
        await this.getUserInfo();
        if (this.isInintData) return;
        console.log('methods2');
        this.$nextTick(() => {
          console.log('methods3');
          this.$store.commit("SET_LOADINGPOP", false);
          this.isInintData = true;
        });
      } catch (error) {}
    },
    async getUserInfo() {
      let res = await _register.default.userInfo();
      if (res !== null && res !== void 0 && res.errcode) {
        return;
      }
      this.userInfo = res;
      await this.getAssistantsInfo();
    },
    async getAssistantsInfo() {
      let res = await _common.default.getAssistantsInfo();
      if (res !== null && res !== void 0 && res.errcode) {
        return;
      }
      this.callUs = res;
    }
  }
});