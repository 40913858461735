"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.string.replace-all.js");
require("vant/es/image-preview/style");
var _imagePreview = _interopRequireDefault(require("vant/es/image-preview"));
require("vant/es/sticky/style");
var _sticky = _interopRequireDefault(require("vant/es/sticky"));
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _customVideo = _interopRequireDefault(require("@/components/common/customVideo.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _list = _interopRequireDefault(require("@/components/meeting/list.vue"));
var _detailIntroduce = _interopRequireDefault(require("@/components/meeting/detailIntroduce.vue"));
var _meetingVideo = _interopRequireDefault(require("@/components/meeting/meetingVideo.vue"));
var _meetingPoster = _interopRequireDefault(require("@/components/meeting/meetingPoster.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    meetingList: _list.default,
    vanSticky: _sticky.default,
    meetingIntroduce: _detailIntroduce.default,
    meetingVideo: _meetingVideo.default,
    meetingPoster: _meetingPoster.default,
    customVideo: _customVideo.default
  },
  data() {
    return {
      need_veeva: "",
      backGray: false,
      showModule: 0,
      capUrl: "",
      urlQuery: {},
      detailInfo: {},
      isBackLive: false,
      detailInfoVideos: 0,
      tipWords: "",
      baomingLoading: false,
      pageViewTime: 0,
      // 页面停留时长
      timer: null,
      // 计时器
      onlySessionNum: this.$GetSessionNum(),
      /* 页面停留时长相关 */
      viewTimer: null,
      viewPageViewTime: 0
    };
  },
  watch: {
    detailInfoVideos() {
      /* 应该是列表里的时长 */
      clearInterval(this.timer);
      this.timer = null;
      this.sendTime();
      this.pageViewTime = 0;

      /* 清除页面停留监测，并重新开启 */
      clearInterval(this.viewTimer);
      this.viewTimer = null;
      this.viewPageViewTime = 0;
      this.viewCount();
    },
    showModule() {
      if (this.showModule == 1) {
        this.viewCount();
      }
      if (!this.showModule) {
        clearInterval(this.viewTimer);
        this.viewTimer = null;
        this.viewPageViewTime = 0;
      }
    }
  },
  filter: {},
  created() {
    this.urlQuery = this.$route.query;
    this.$store.commit("SET_LOADINGPOP", true);
    this.initData();
  },
  destroyed() {
    this.pauseSetBrowse();
    clearInterval(this.viewTimer);
    this.viewTimer = null;
    this.viewPageViewTime = 0;
  },
  mounted() {},
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    showDaysPop(res) {
      this.$instanceExperienceView.show({
        days: res.days || 0,
        //体验剩余天数
        isNeedBack: false,
        //是否重定向返回原页面
        callBack: ret => {
          if (ret.next == '/veeva') {
            //立即认证跳转
            this.$PUSH({
              path: ret.next
            });
          }
        }
      });
    },
    async isNeedTipsFunc(need_veeva) {
      if (!need_veeva) return;
      const resData = await this.$api.getShowPopDaysInfo({});
      if (resData.need_tips && resData.veeva == 0) this.showDaysPop(resData);
    },
    viewCount() {
      this.viewTimer = setInterval(() => {
        this.viewPageViewTime += 3;
        this.sendViewTime();
      }, 3000);
    },
    async setShowModule(param) {
      if (param) {
        const openid = localStorage.getItem("openid");
        const wechatInfo = localStorage.getItem("wechatInfo");
        const isRegister = localStorage.getItem("isRegister");
        if (!openid || !wechatInfo || !isRegister) {
          this.$registerView.show(ret => {
            if (ret.next == "from") {
              this.$store.commit("SET_CALLUSSHOW", false);
            } else {
              this.$store.commit("SET_CALLUSSHOW", false);
              this.$PUSH({
                path: `/register`,
                query: {
                  redirect: encodeURIComponent(this.$route.fullPath)
                }
              });
            }
          });
          return;
        }
        if (!this.userInfo.reconfirmation) {
          this.$importRegisterView.show(ret => {
            if (ret.next == "from") {
              // next(from)
            } else {
              let strUrl = `${ret.next}?redirect=${encodeURIComponent(this.$route.fullPath)}`;
              const toUrl = strUrl;
              // next(toUrl)

              this.$PUSH({
                path: toUrl
              });
            }
          }, false, true);
          this.$store.commit('SET_CALLUSSHOW', false);
          return;
        }
        let checkStatus = await this.checkUserInfo();
        if (checkStatus == "stop") return;
      }
      this.showModule = param;
    },
    setFaver(param) {
      this.detailInfo.isFaver = param == "true" ? true : false;
      param == "true" ? this.detailInfo.faver++ : this.detailInfo.faver--;
    },
    setZan(param) {
      this.detailInfo.isZan = param == "true" ? true : false;
      param == "true" ? this.detailInfo.zan++ : this.detailInfo.zan--;
    },
    checkUserInfo() {
      var _this$userInfo, _this$userInfo2;
      const self = this;
      const openid = localStorage.getItem("openid");
      const wechatInfo = localStorage.getItem("wechatInfo");
      const isRegister = localStorage.getItem("isRegister");
      const {
        query,
        fullPath
      } = this.$route;
      console.log(this.$route, '==>this.$route');
      if (!openid || !wechatInfo || !isRegister) {
        this.$registerView.show(ret => {
          if (ret.next == "from") {
            this.$store.commit("SET_CALLUSSHOW", false);
          } else {
            this.$store.commit("SET_CALLUSSHOW", false);
            this.$PUSH({
              path: `/register`,
              query: {
                redirect: encodeURIComponent(this.$route.fullPath)
              }
            });
          }
        });
        return Promise.resolve("stop");
      }
      if (!((_this$userInfo = this.userInfo) !== null && _this$userInfo !== void 0 && _this$userInfo.reconfirmation)) {
        this.$importRegisterView.show(ret => {
          if (ret.next == "from") {
            // next(from)
          } else {
            let strUrl = `${ret.next}?redirect=${encodeURIComponent(fullPath)}`;
            const toUrl = query.sharedata ? `${strUrl}&gotohome=true` : strUrl;
            // next(toUrl)
            self.$PUSH({
              path: toUrl
            });
          }
        }, query.sharedata ? true : false, true);
        return Promise.resolve("stop");
      }
      if ((_this$userInfo2 = this.userInfo) !== null && _this$userInfo2 !== void 0 && _this$userInfo2.re_perfect_type) {
        this.$hospitalLoseView.show(ret => {
          if (ret.next == "from") {
            // next(from)
          } else {
            let strUrl = `${ret.next}?redirect=${encodeURIComponent(fullPath)}`;
            // const toUrl = `${ret.next}?gotohome=true`
            // console.log(toUrl, '==toUrl>toUrl>toUrl');
            self.$PUSH({
              path: strUrl
            });
            // next(toUrl)
          }
        }, query.sharedata ? true : false, true);
        return Promise.resolve("stop");
      }
      return Promise.resolve("next");
    },
    async createPoster() {
      const self = this;
      let checkStatus = await this.checkUserInfo();
      if (checkStatus == "stop") return;
      const detailPosterInner = document.querySelector(".detailPosterInnerOut");
      (0, _html2canvas.default)(detailPosterInner, {
        useCORS: true
      }).then(canvas => {
        this.capUrl = canvas.toDataURL();
        (0, _imagePreview.default)({
          images: [self.capUrl],
          closeable: true,
          onClose() {
            self.$store.commit('SET_SHOWSAVEBTN', false);
          }
        });
        self.$store.commit('SET_SHOWSAVEBTN', true);
        setTimeout(() => {
          this.$store.dispatch("setScoreLog", {
            route: this.$route,
            action_type: "SHARE",
            type: "click"
          });
        }, 2000);
      });
    },
    goBackLive() {
      // this.isBackLive = true
      this.$PUSH({
        path: `/meetingdetailplayback?id=${this.urlQuery.id}&typeMap=4`
      });
    },
    async entryLive(type) {
      const openid = localStorage.getItem("openid");
      const wechatInfo = localStorage.getItem("wechatInfo");
      const isRegister = localStorage.getItem("isRegister");
      if (!openid || !wechatInfo || !isRegister) {
        this.$registerView.show(ret => {
          if (ret.next == "from") {
            this.$store.commit("SET_CALLUSSHOW", false);
          } else {
            this.$store.commit("SET_CALLUSSHOW", false);
            this.$PUSH({
              path: `/register`,
              query: {
                redirect: encodeURIComponent(this.$route.fullPath)
              }
            });
          }
        });
        return;
      }
      if (!this.userInfo.reconfirmation) {
        this.$importRegisterView.show(ret => {
          if (ret.next == "from") {
            // next(from)
          } else {
            let strUrl = `${ret.next}?redirect=${encodeURIComponent(this.$route.fullPath)}`;
            const toUrl = strUrl;
            // next(toUrl)

            this.$PUSH({
              path: toUrl
            });
          }
        }, false, true);
        this.$store.commit('SET_CALLUSSHOW', false);
        return;
      }
      let checkStatus = await this.checkUserInfo();
      if (checkStatus == "stop") return;
      const wechatInfoParse = JSON.parse(localStorage.getItem("wechatInfo"));
      let hasSymbol = this.detailInfo.meeting_url.indexOf("?");
      if (type == "once") {
        // 通知后台加积分if(res){
        let res = await this.$store.dispatch("setScoreLog", {
          action_type: 'MEETING_ATTEND',
          route: this.$route,
          needBack: true,
          type: 'click'
        });
        if (res) {
          let jumpUrl;
          if (this.detailInfo.meeting_url.indexOf("bayer-rad-academy-shadowcloud-academy-html") > -1) {
            jumpUrl = `${this.detailInfo.meeting_url}`;
          } else {
            jumpUrl = `${this.detailInfo.meeting_url}${hasSymbol > -1 ? '&' : '?'}${"openid=" + localStorage.getItem("openid") + "&unionid=" + wechatInfoParse.unionid}`;
          }
          if (res !== null && res !== void 0 && res.score) {
            setTimeout(() => {
              window.location.href = jumpUrl;
            }, 2000);
            return;
          }
          setTimeout(() => {
            window.location.href = jumpUrl;
          }, 500);
        }
        return;
      }
      let jumpUrl;
      if (this.detailInfo.meeting_url.indexOf("bayer-rad-academy-shadowcloud-academy-html") > -1) {
        jumpUrl = `${this.detailInfo.meeting_url}`;
      } else {
        jumpUrl = `${this.detailInfo.meeting_url}${hasSymbol > -1 ? '&' : '?'}${"openid=" + localStorage.getItem("openid") + "&unionid=" + wechatInfoParse.unionid}`;
      }
      setTimeout(() => {
        window.location.href = jumpUrl;
      }, 500);
    },
    statusBtn() {
      var _this$detailInfo$vide;
      const nowS = Date.now();
      const startS = new Date(this.detailInfo.start_time.replace(/\-/g, "/")).getTime();
      const endS = new Date(this.detailInfo.end_time.replace(/\-/g, "/")).getTime();
      if ((nowS > endS || this.detailInfo.status == 3) && (_this$detailInfo$vide = this.detailInfo.videos) !== null && _this$detailInfo$vide !== void 0 && _this$detailInfo$vide.length) {
        this.isBackLive = true;
      }
      if (nowS < startS) return 1;
      if (nowS > startS && nowS < endS) return 2;
      if (nowS > endS) return 3;
    },
    async setSubscribe() {
      const openid = localStorage.getItem("openid");
      const wechatInfo = localStorage.getItem("wechatInfo");
      const isRegister = localStorage.getItem("isRegister");
      if (!openid || !wechatInfo || !isRegister) {
        this.$registerView.show(ret => {
          if (ret.next == "from") {
            this.$store.commit("SET_CALLUSSHOW", false);
          } else {
            this.$store.commit("SET_CALLUSSHOW", false);
            this.$PUSH({
              path: `/register`,
              query: {
                redirect: encodeURIComponent(this.$route.fullPath)
              }
            });
          }
        });
        return;
      }
      let checkStatus = await this.checkUserInfo();
      if (checkStatus == "stop") return;
      if (this.baomingLoading) {
        return;
      }
      this.baomingLoading = true;

      // this.$store.commit("SET_LOADINGPOP", true)

      try {
        let res = await this.$api.setSubscribe({
          meeting_id: this.detailInfo.id
        });
        this.baomingLoading = false;
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("setSubscribe 请求失败：" + res.errmsg)
          return;
        }
        // alert("报名成功")

        this.tipWords = "报名成功";
        this.$refs.customPop.open();
        setTimeout(() => {
          this.$refs.customPop.close();
        }, 1500);
        this.initData();
      } catch (error) {}
    },
    pauseSetBrowse(endTime, finish) {
      clearInterval(this.timer);
      this.timer = null;
      if (finish && endTime > this.pageViewTime) {
        endTime = this.pageViewTime;
        finish = false;
      }
      this.sendTime(this.pageViewTime, finish);
    },
    setBrowse() {
      this.timer = setInterval(() => {
        this.pageViewTime += 3;
        this.sendTime();
      }, 3000);
    },
    async sendTime(endTime, finish) {
      var _this$detailInfo$vide2;
      let params = {
        content_id: this.urlQuery.id,
        video_uid: (_this$detailInfo$vide2 = this.detailInfo.videos[this.detailInfoVideos]) === null || _this$detailInfo$vide2 === void 0 ? void 0 : _this$detailInfo$vide2.uid,
        type: 4,
        times: this.pageViewTime,
        session_num: this.onlySessionNum,
        time_type: 1
      };
      endTime && (params["endTime"] = endTime);
      finish && (params["finish"] = finish ? 1 : 0);
      await this.$api.setMeetingTime(params);
    },
    async sendViewTime() {
      var _this$detailInfo$vide3;
      let params = {
        content_id: this.urlQuery.id,
        video_uid: (_this$detailInfo$vide3 = this.detailInfo.videos[this.detailInfoVideos]) === null || _this$detailInfo$vide3 === void 0 ? void 0 : _this$detailInfo$vide3.uid,
        type: 4,
        times: this.viewPageViewTime,
        session_num: this.onlySessionNum,
        time_type: 2
      };
      await this.$api.setMeetingTime(params);
    },
    async initData() {
      try {
        var _res$videos, _res$videos2;
        let res = await this.$api.getMeetingtDetail(this.urlQuery.id);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getMeetingtList 请求失败：" + res.errmsg)
          this.$store.commit("SET_LOADINGPOP", false);
          return;
        }
        this.need_veeva = res === null || res === void 0 ? void 0 : res.need_veeva;
        this.isNeedTipsFunc(res === null || res === void 0 ? void 0 : res.need_veeva);
        /* 修改微信配置 Start */
        this.weChatShare.title = res.name;
        this.weChatShare.desc = res.name;
        this.weChatShare.imgUrl = res.cover;
        this.initWechat();
        /* 修改微信配置 End */

        document.title = res === null || res === void 0 ? void 0 : res.name;
        if (res.content) res.content = res.content.replaceAll('<img', '<img style="max-width:100%;"');
        let zanCheck = await this.$api.getZanCheck(res.id, res.type);
        let faverCheck = await this.$api.getFaverCheck(res.id, res.type);
        zanCheck.count > 0 && (res.isZan = true);
        faverCheck.count > 0 && (res.isFaver = true);
        this.$store.commit("SET_LOADINGPOP", false);
        (_res$videos = res.videos) === null || _res$videos === void 0 || _res$videos.forEach(im => {
          im.sort = Number(im === null || im === void 0 ? void 0 : im.sort) || 99999;
        });
        res.videos = (_res$videos2 = res.videos) === null || _res$videos2 === void 0 ? void 0 : _res$videos2.sort((a, b) => a.sort - b.sort);

        /* console.log(tempVideos,'==>tempVideosSort');
        res.videos = tempVideos */

        this.detailInfo = res;
        this.statusBtn();
      } catch (error) {}
    },
    getCanvas() {
      const detailPosterInner = document.querySelector(".detailPosterInnerOut");
      (0, _html2canvas.default)(detailPosterInner, {
        useCORS: true
      }).then(canvas => {
        this.capUrl = canvas.toDataURL();
      });
    }
  }
});