"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _modules = _interopRequireDefault(require("./modules"));
var _getters = _interopRequireDefault(require("./getters"));
_vue.default.use(_vuex.default);
const store = new _vuex.default.Store({
  modules: _modules.default,
  getters: _getters.default
});
var _default = exports.default = store;