"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.string.replace-all.js");
require("vant/es/image-preview/style");
var _imagePreview = _interopRequireDefault(require("vant/es/image-preview"));
var _interaction = _interopRequireDefault(require("@/components/course/interaction.vue"));
var _interactionAnalysis = _interopRequireDefault(require("@/components/course/interactionAnalysis.vue"));
var _comment = _interopRequireDefault(require("@/components/course/comment.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _meetingPoster = _interopRequireDefault(require("@/components/meeting/meetingPoster.vue"));
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _monitor = require("@/utils/monitor.js");
var _ConfirmDialog = _interopRequireDefault(require("@/components/ConfirmDialog.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    interaction: _interaction.default,
    interactionAnalysis: _interactionAnalysis.default,
    comment: _comment.default,
    meetingPoster: _meetingPoster.default,
    ConfirmDialog: _ConfirmDialog.default
  },
  name: 'activityRichDetail',
  data() {
    return {
      contentDetail: {},
      /* 模块相关 */
      myIsFaver: false,
      myIsZan: false,
      loadZan: false,
      sectionDetail: {},
      showAll: false,
      loadFinish: false,
      /* ----------------- */

      /* 课程形式相关 */
      showModuleCheck: 0,
      formEchoInfo: {},
      detailForm: {},
      /* ----------------- */

      urlQuery: {},
      listCampainContent: [],
      listCampainContentIndex: 0,
      onlySessionNum: this.$GetSessionNum(),
      isReachBottom: false,
      setScoreTimeView: 0,
      thisTimeView: 0,
      hideAll: false,
      isSetLogFlag: true
    };
  },
  watch: {},
  filter: {},
  destroyed() {
    this.pauseSetBrowseView();
    this.pauseLearnTimeLogView();
    window.removeEventListener('reachBottom', this.reachBottomHandle);
  },
  created() {
    this.urlQuery = this.$route.query;
    this.$store.commit('SET_LOADINGPOP', true);

    // this.initData()
    this.getModuleDetail();
    this.initReachBottom();
  },
  mounted() {
    console.log(this.$router, '==>this.$router');
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    async isShowSignPage(res) {
      console.log('isShowSignPage');
      if (res !== null && res !== void 0 && res.errcode) return;
      let formsData = await this.$api.getCampainListAuth({}, res.campain_id);
      let authShowPop = await this.$api.getIsShowIntroduceAuth({}, res.campain_id, '5');
      //需要报名 且不是内部员工 且有表单
      const isApply = authShowPop.need_show_desc == 1 && this.userInfo.type == 1 && formsData.have_form == 1;
      await this.initData();
      if (this.contentDetail.need_veeva) {
        const resData = await this.$api.getShowPopDaysInfo({});
        this.isSetLogFlag = resData.veeva == 1 || resData.days > 0;
        if (resData.need_tips && resData.veeva == 0) {
          //是否展示体验认证弹框
          this.showDaysPop(resData, res, isApply);
          if (this.isSetLogFlag) {
            this.isSetLog();
          }
          return;
        }
      } else {
        this.isSetLogFlag = true;
      }
      this.isSetLog();
      if (isApply) {
        this.$refs.confirmDialog.open('浏览完整活动介绍，参与互动后，即可立即学习！感谢您的支持！', '').then(() => {
          this.goApplyRedirect(res);
        }).catch(error => {
          console.log('用户取消了操作:', error);
          this.$PUSH({
            path: '/'
          });
        });
      }
    },
    isSetLog() {
      this.setLearnTimeLogView();
      this.setBrowseView();
    },
    showDaysPop(res, resBackData, isApply) {
      let days = res.days || 0;
      this.$instanceExperienceView.show({
        days: days,
        //体验剩余天数
        isNeedBack: isApply,
        //是否重定向返回原页面
        callBack: ret => {
          if (ret.next == 'from') {
            if (isApply) {
              this.goApplyRedirect(resBackData);
              return;
            }
          } else {
            //立即认证跳转
            this.$PUSH({
              path: ret.next
            });
          }
        }
      });
    },
    goApplyRedirect(res) {
      let url = window.location.href.split('?')[1];
      window.sessionStorage.setItem('redirectUrl', `richdetail?${url}`);
      this.$PUSH({
        path: `/activity/activeShowOncePoster?id=${res.campain_id}&_contentId=${res.id}&_typeMap=${res.type}`
      });
    },
    setWeChatShare(params) {
      this.weChatShare.title = params.name;
      this.weChatShare.desc = params.name;
      this.weChatShare.imgUrl = params.cover;
      // params?.contentid && (this.weChatShare.contentid = params.contentid)

      this.initWechat();
    },
    async initData() {
      let res = await this.$api.getCampainContentId({}, this.urlQuery.contentid);
      console.log(res, '==> reess');
      localStorage.setItem('isToDetail', true);
      this.$store.commit('SET_LOADINGPOP', false);
      if (res !== null && res !== void 0 && res.errcode || (res === null || res === void 0 ? void 0 : res.hasErrCode) == '50001') {
        this.hideAll = true;
        return;
      }
      this.setWeChatShare({
        name: res.name,
        cover: res.cover
      });
      this.contentDetail = res;
      this.getFormEchoInfo();
      this.postMonitor();
      this.getZanCheck();
      this.getFaverCheck();
      (res === null || res === void 0 ? void 0 : res.have_form) && (this.detailForm = res.forms);
      if (!(res !== null && res !== void 0 && res.have_form)) {
        this.showModuleCheck = 1;
      }
    },
    async getFormEchoInfo() {
      let formEchoInfo = await this.$api.getFormEchoInfo({}, this.contentDetail.id, this.contentDetail.type);
      if (formEchoInfo !== null && formEchoInfo !== void 0 && formEchoInfo.errcode) {
        alert('getFormEchoInfo获取失败' + formEchoInfo.errmsg);
        return;
      }
      console.log(formEchoInfo, '==>formEchoInfo');
      this.formEchoInfo = formEchoInfo;
    },
    initReachBottom() {
      window.addEventListener('reachBottom', this.reachBottomHandle);
    },
    reachBottomHandle(evt) {
      var _this$contentDetail;
      const self = this;
      if (evt.detail.path != '/activity/richdetail') {
        return;
      }
      if (self.isReachBottom) {
        return;
      }
      if (this.showModuleCheck == 1 && (_this$contentDetail = this.contentDetail) !== null && _this$contentDetail !== void 0 && _this$contentDetail.have_comments) {
        var _this$$refs;
        self.isReachBottom = true;
        (_this$$refs = this.$refs) === null || _this$$refs === void 0 || (_this$$refs = _this$$refs.mycomment) === null || _this$$refs === void 0 || _this$$refs.reachBottom();
      }
    },
    /* 学习监测 */
    /* 浏览积分-页面停留时长 Start */
    pauseSetBrowseView(endTime, finish) {
      // this.pauseLearnTimeLog()

      clearInterval(this.setScoreTimerView);
      this.setScoreTimerView = null;
      if (finish && endTime > this.setScoreTimeView + 3) {
        endTime = this.setScoreTimeView + 3;
        finish = false;
      }

      /* endTime || 
        修改前：
          endTime为视频播放完成后传递的视频时长   会覆盖掉历史时长，所以去掉此字段 仅传递记录时长
      */
      this.$store.dispatch('setScoreLog', {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            typeMap: 7
          }
        },
        action_type: 'LEARN',
        total_tims: this.setScoreTimeView,
        content_id: this.contentDetail.id,
        content_type: 1,
        campain_id: this.contentDetail.campain_id,
        section_id: this.contentDetail.section_id,
        session_num: this.onlySessionNum,
        finish
      });
      endTime && (this.listCampainContent[this.listCampainContentIndex].learn_time = endTime);
    },
    setBrowseView() {
      // this.setLearnTimeLog()

      console.log(this.contentDetail, '==>this.contentDetail');
      /* 应该赋值为返回数据中的时长，接口未完善 先写0 */
      this.setScoreTimeView = Number(this.contentDetail.learn_time) || 0;
      this.setScoreTimerView = setInterval(() => {
        this.setScoreTimeView += 3;
        this.$store.dispatch('setScoreLog', {
          route: {
            ...this.$route,
            query: {
              ...this.$route.query,
              typeMap: 7
            }
          },
          action_type: 'LEARN',
          total_tims: this.setScoreTimeView,
          content_id: this.contentDetail.id,
          content_type: 1,
          campain_id: this.contentDetail.campain_id,
          section_id: this.contentDetail.section_id,
          session_num: this.onlySessionNum,
          is_tw: 1
        });
      }, 3000);
    },
    /* 浏览积分-页面停留时长 End */

    setLearnTimeLogView() {
      this.thisTimeView = 0;
      this.thisTimerView = setInterval(() => {
        this.thisTimeView += 3;
        this.$store.dispatch('setLearnTimeLog', {
          route: {
            ...this.$route,
            query: {
              ...this.$route.query,
              typeMap: 7
            }
          },
          action_type: 'LEARN',
          total_tims: 3,
          content_id: this.contentDetail.id,
          content_type: 1,
          campain_id: this.contentDetail.campain_id,
          section_id: this.contentDetail.section_id,
          session_num: this.onlySessionNum,
          is_tw: 1
        });
      }, 3000);
    },
    pauseLearnTimeLogView() {
      if (this.thisTimerView) {
        this.$store.dispatch('setLearnTimeLog', {
          route: {
            ...this.$route,
            query: {
              ...this.$route.query,
              typeMap: 7
            }
          },
          action_type: 'LEARN',
          total_tims: 3,
          content_id: this.contentDetail.id,
          content_type: 1,
          campain_id: this.contentDetail.campain_id,
          section_id: this.contentDetail.section_id,
          session_num: this.onlySessionNum,
          is_tw: 1
        });
      }
      clearInterval(this.thisTimerView);
      this.thisTimerView = null;
      this.thisTimeView += 3;
    },
    /* --------------------------------------------- */

    postMonitor() {
      (0, _monitor.dataStatistics)({
        event_type: 'view',
        // view = 浏览  click = 点击   share = 分享
        page_title: `拜影云学院:${this.contentDetail.name}`,
        typeMap: 7,
        firstParams: true,
        content_id: this.contentDetail.id,
        section_id: this.contentDetail.section_id,
        campain_id: this.contentDetail.campain_id
      });
    },
    /* 模块相关 --------------------------------------------- */
    /* 生成分享海报 */
    myShare() {
      const self = this;
      const detailPosterInner = document.querySelector('.detailPosterInnerOut');
      (0, _html2canvas.default)(detailPosterInner, {
        useCORS: true
      }).then(canvas => {
        self.capUrl = canvas.toDataURL();
        (0, _imagePreview.default)({
          images: [self.capUrl],
          closeable: true,
          onClose() {
            self.$store.commit('SET_SHOWSAVEBTN', false);
          }
        });
        self.$store.commit('SET_SHOWSAVEBTN', true);
        setTimeout(() => {
          // self.$store.commit('SET_INTEGRAL', { show: true, integral: 10 })
          self.$store.dispatch('setScoreLog', {
            route: {
              ...self.$route,
              query: {
                ...self.$route.query,
                typeMap: 6
              }
            },
            action_type: 'SHARE',
            section_id: this.urlQuery.id
          });
        }, 2000);
      });
    },
    async zanHandle(type) {
      if (this.loadZan) return;
      type = !this.myIsZan;
      this.loadZan = true;
      type && this.$store.dispatch('setScoreLog', {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            typeMap: 6
          }
        },
        action_type: 'ZAN',
        section_id: this.urlQuery.id
      });
      try {
        let res = await this.$api[type ? 'contentZan' : 'cancleContentZan']({
          content_id: this.contentDetail.section_id,
          type: 6
        });
        this.loadZan = false;
        if (type) {
          this.myIsZan = true;
          this.sectionDetail.zan++;
          this.$emit('setIsZan', true);
        } else {
          this.sectionDetail.zan--;
          this.myIsZan = false;
          this.$emit('setIsZan', false);
        }
      } catch (error) {}
    },
    async faverHandle(type) {
      if (this.loadFaver) return;
      type = !this.myIsFaver;
      this.loadFaver = true;
      type && this.$store.dispatch('setScoreLog', {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            typeMap: 6
          }
        },
        action_type: 'FAVER',
        section_id: this.urlQuery.id
      });
      try {
        let res = await this.$api[type ? 'contentFaver' : 'cancleContentFaver']({
          content_id: this.contentDetail.section_id,
          type: 6
        });
        this.loadFaver = false;
        if (type) {
          this.myIsFaver = true;
          this.sectionDetail.faver++;
          this.$emit('setIsFaver', true);
        } else {
          this.myIsFaver = false;
          this.sectionDetail.faver--;
          this.$emit('setIsFaver', false);
        }
      } catch (error) {}
    },
    async getZanCheck() {
      let res = await this.$api.getZanCheck(this.contentDetail.section_id, 6);
      if (res.errcode) {
        alert('getZanCheck(this.urlQuery.id, 6)' + res.errmsg);
        return;
      }
      if (res.count) {
        this.myIsZan = true;
        return;
      }
      this.myIsZan = false;
    },
    async getFaverCheck() {
      let res = await this.$api.getFaverCheck(this.contentDetail.section_id, 6);
      if (res.errcode) {
        alert('getZanCheck(this.urlQuery.id, 6)' + res.errmsg);
        return;
      }
      if (res.count) {
        this.myIsFaver = true;
        return;
      }
      this.myIsFaver = false;
    },
    /* 查询当前内容有没有权限 */
    async getContentForId() {
      console.log('==>getContentForId-00');
      const res = await this.$api.getCampainContentId({}, this.urlQuery.contentid);
      console.log(res, '==.');
      this.$store.commit('SET_LOADINGPOP', true);
      if (res.errcode == 50002) {
        this.hideAll = true;
        this.$toatsView.show({
          tipWords: '抱歉！您暂无法查看该内容，请查看该活动下的其他内容',
          callBack: evt => {
            if (evt == 'close') {
              const {
                query
              } = this.$route;
              this.$router.replace({
                path: '/activity/richlist',
                query: {
                  id: query.id,
                  campain_id: query.campain_id,
                  contentid: query.contentid,
                  source: 1,
                  typeMap: 6
                }
              });
            }
          }
        });
        return;
      }
      // this.initData()
    },
    /* 获取模块详情 用于点赞收藏 */
    async getModuleDetail() {
      var _this$urlQuery;
      if ((_this$urlQuery = this.urlQuery) !== null && _this$urlQuery !== void 0 && _this$urlQuery.source) {
        /* 查询当前内容有没有权限 */
        this.getContentForId();
        // return
      }
      try {
        let res = await this.$api.getCampainSectionDetailAuth({}, this.urlQuery.id);
        await this.isShowSignPage(res);
        this.$store.commit('SET_LOADINGPOP', false);
        console.log(res, '==>res>res>res>res>res>res');
        if ((res === null || res === void 0 ? void 0 : res.hasErrCode) == 50001) {
          this.hideAll = true;
          return;
        }
        // this.initData()
        if ((res === null || res === void 0 ? void 0 : res.hasErrCode) != 50001) {
          console.log('=================sssssssssssssss');
          this.showAll = true;
        }
        console.log('==>-getModuleDetail-getModuleDetail-getModuleDetail');
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCampainSectionDetail" + res.errmsg)
          return;
        }
        if (res.content) res.content = res.content.replaceAll('<img', '<img style="max-width:100%;"');
        this.sectionDetail = res;
        console.log(this.sectionDetail, '==>this.sectionDetail>this.sectionDetail>this.sectionDetail');
      } catch (error) {}
    }
  }
});