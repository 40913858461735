"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.answerList = void 0;
exports.displayQuestion = displayQuestion;
exports.getPosterResult = getPosterResult;
exports.getRankTxt = getRankTxt;
exports.yearBackTxt = exports.posterDesc = void 0;
let yearBackTxt = exports.yearBackTxt = {
  oldUser: ` <span class="swiper_item" style="font-weight:bold;opacity:0"> “病症千奇百怪，<br /> 如何进阶成影像神探?”<br /><br /><br /></span>
<span class="swiper_item">影像征程路迢迢<br/>
有困惑、有挑战、有孤独<br/><br/><br/></span>

<span class="swiper_item">我们始终在你身畔<br/>
伴你持续在医海里远航 并为你收录<br/>
<span class="backBlodTxt swiper_item"  style="font-weight:bold"> 影像征程中的高光碎片……</span></span>`,
  newUser: ` <span class="swiper_item"  style="font-weight:bold">“是影像科还是全科，<br/>庞杂知识如何获取?”<br/><br/><br/></span>
<span class="swiper_item">
影像征程路迢迢<br/>   
有困惑、有挑战、有孤独<br/><br/><br/></span>



<span class="swiper_item">我们与你共启全新征程<br/>
在医海新旅中，去遇见、感知、学习、获得<br/>
<span class="swiper_item backBlodTxt" style="font-weight:bold">扬帆远航，成为更好的影像科专家</span></span>`
};
let answerList = exports.answerList = {
  question1: {
    title: '如果用一个词形容你2023年的工作状态，你会选？',
    options: [{
      name: 'A. 初涉影像',
      id: '0'
    }, {
      name: 'B. 渐入佳境',
      id: '1'
    }, {
      name: 'C. 轻车熟路',
      id: '2'
    }, {
      name: 'D. 求索高峰',
      id: '3'
    }]
  },
  question2: {
    title: '过去一年，<br />让您记忆最深刻的影像科碎片是？',
    options: [[{
      name: 'A. 打报告打到手抖',
      id: '0'
    }, {
      name: 'B. 家人朋友聚会变成「读片大会」',
      id: '1'
    }, {
      name: 'C. 发现微小病灶，喜提主任认可',
      id: '2'
    }], [{
      name: 'A. 最忙的一天，连续工作超过16小时',
      id: '0'
    }, {
      name: 'B. 熬夜科研，终于取得成果',
      id: '1'
    }, {
      name: 'C. 参与会诊，获得其他科室好评',
      id: '2'
    }], [{
      name: 'A. 被患者信任，点名找我拍片',
      id: '0'
    }, {
      name: 'B. 开始带学生一起科研，年轻人的热情超乎想象',
      id: '1'
    }, {
      name: 'C. 和大佬一同会诊，自己的意见越来越被重视',
      id: '2'
    }], [{
      name: 'A. 各科大佬排队等我会诊',
      id: '0'
    }, {
      name: 'B. 跨院、海外交流，带领医院影像科被更多同行认可',
      id: '1'
    }, {
      name: 'C. 学无止境，发现依然有很多技术、文献亟待学习',
      id: '2'
    }]]
  }
};
let posterDesc = exports.posterDesc = ['忙而不乱，为患者生成专业影像，也能和家人一起留下温暖的时光之影。', '勇担「医生的医生」，能力不受限，和各科室一起披荆斩棘。', '让更多人了解、信任影像科医生，让影像科也能闪闪发光。', '精进专业，探索技术前沿处，挑战影像更高峰。'];
function getPosterResult(idStr) {
  let result = {
    1: ['00', '10', '30'],
    2: ['11', '21', '32'],
    3: ['01', '20', '31'],
    4: ['02', '12', '22']
  };

  // 遍历result对象的每个键值对
  for (let poster in result) {
    // 检查每个poster数组是否包含idStr
    if (result[poster].includes(idStr)) {
      return poster; // 返回匹配的poster键
    }
  }

  // 如果没有找到匹配项，返回null或者相应提示
  return null;
}
function displayQuestion(minutes) {
  if (minutes > 130000) {
    return "排行第一名。超过92天的时间，你可以把82版的《西游记》反复刷117遍。";
  } else if (minutes > 10000 && minutes <= 130000) {
    return "相当于你把300多万字的《资治通鉴》从头到尾看了两遍。";
  } else if (minutes > 3000 && minutes <= 10000) {
    return "相当于你看完了一整部《甄嬛传》。";
  } else if (minutes > 1400 && minutes <= 3000) {
    return "相当于你把收藏了上千首歌曲的歌单听了个遍。";
  } else if (minutes > 300 && minutes <= 1400) {
    return "古有韦编三绝，今有每一个勤奋努力不断收获知识的你。";
  } else if (minutes > 60 && minutes <= 300) {
    return "爱迪生一路不断求知，创造了超过2,000项的发明。影像医海也如此，一点一滴的积累，助你航行更远处。";
  } else if (minutes >= 0 && minutes <= 60) {
    return "比萨斜塔实验中，落地的铁球震动了物理界，也许未来震动影像行业的就是你。";
  } else {
    return "比萨斜塔实验中，落地的铁球震动了物理界，也许未来震动影像行业的就是你。";
  }
}
function getRankTxt(rank, percent) {
  let rankText;
  if (rank == 1) {
    rankText = `排名第${rank}名`;
  } else if (rank > 1 && rank <= 10) {
    rankText = `排名第${rank}名`;
  } else if (rank > 10 && rank <= 50) {
    rankText = `排名第${rank}名`;
  } else if (rank > 50 && rank <= 100) {
    rankText = `排名第${rank}名`;
  } else if (rank > 100 && rank <= 200) {
    rankText = `排名第${rank}名`;
  } else {
    // 计算档次，每500名一个档次
    let bracket = Math.ceil(rank / 500) * 500;
    rankText = `排名前${bracket}名`;
  }
  let surpassText;
  if (rank == 1) {
    surpassText = `你已经超过<span style="font-size:19px;font-weight:bold;">${percent}%</span>的影像同行者，`;
  } else if (rank > 1 && rank <= 3000) {
    surpassText = `你已经超过了<span style="font-size:19px;font-weight:bold;">${percent}%</span>的影像同行者，`;
  } else {
    surpassText = "";
  }
  let encouragementText;
  if (rank == 1) {
    encouragementText = '祝你始终占据山顶，是永远的NO.1。';
  } else if (rank > 1 && rank <= 10) {
    encouragementText = '祝你勇攀巅峰站到更高的山峰。';
  } else if (rank > 10 && rank <= 50) {
    encouragementText = '祝你持续积蓄能量向着高出跃进。';
  } else if (rank > 50 && rank <= 100) {
    encouragementText = '祝你持续攀登探索更多未知风景。';
  } else if (rank > 100 && rank <= 200) {
    encouragementText = '愿你未来在医海中顺风远航。';
  } else if (rank > 200 && rank <= 1000) {
    encouragementText = '愿你御风而行赶赴新征程。';
  } else if (rank > 1000 && rank <= 3000) {
    encouragementText = '祝你攀登更高峰。';
  } else {
    encouragementText = '探索更多学术资源，拜耳影像学苑为你的影像征途护航。';
  }
  return {
    lv: rankText,
    surpass: surpassText,
    txt: encouragementText
  };
}