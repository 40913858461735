"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.string.replace-all.js");
var _listItem = _interopRequireDefault(require("@/components/activity/listItem.vue"));
var _ApprovalNo = _interopRequireDefault(require("@/components/common/ApprovalNo.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _ConfirmDialog = _interopRequireDefault(require("@/components/ConfirmDialog.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    listItem: _listItem.default,
    ApprovalNo: _ApprovalNo.default,
    ConfirmDialog: _ConfirmDialog.default
  },
  data() {
    return {
      urlQuery: {},
      sectionDetail: {},
      myIsZan: false,
      myIsFaver: false,
      loadZan: false,
      loadFaver: false
    };
  },
  watch: {},
  filter: {},
  created() {
    this.urlQuery = this.$route.query;
    this.initData();
  },
  mounted() {},
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    async isShowSignPage() {
      console.log('isShowSignPage');
      let res = await this.$api.getCampainSectionDetailAuth({}, this.urlQuery.id);
      console.log(res, '==>res>res>res -------------------------');
      if (res !== null && res !== void 0 && res.errcode) return;
      let formsData = await this.$api.getCampainListAuth({}, res.campain_id);
      let authShowPop = await this.$api.getIsShowIntroduceAuth({}, res.campain_id, '5');
      //需要报名 且不是内部员工 且有表单
      const isApply = authShowPop.need_show_desc == 1 && this.userInfo.type == 1 && formsData.have_form == 1;
      if (res.need_veeva) {
        const resData = await this.$api.getShowPopDaysInfo({});
        if (resData.need_tips && resData.veeva == 0) {
          //是否展示体验认证弹框
          this.showDaysPop(resData, res, isApply);
          return;
        }
      }
      if (isApply) {
        this.$refs.confirmDialog.open('浏览完整活动介绍，参与互动后，即可立即学习！感谢您的支持！', '').then(() => {
          this.goApplyRedirect(res);
          // 执行确认后的逻辑
        }).catch(error => {
          console.log('用户取消了操作:', error);
          this.$PUSH({
            path: '/'
          });
          // 处理取消逻辑
        });
      }
      await this.$api.putShowIntroduceAuth({}, this.urlQuery.id, 6);
    },
    showFormatTime(im) {
      const start_time = this.$SPLITDATE(im.start_time, 'yyyy-mm-dd h:m');
      const end_time = this.$SPLITDATE(im.end_time, 'yyyy-mm-dd h:m');
      const start_time_arr = start_time.split(' ');
      const end_time_arr = end_time.split(' ');
      if (start_time_arr[0] == end_time_arr[0]) {
        return start_time + ' - ' + end_time_arr[1];
      }
      return start_time + ' - ' + end_time;
    },
    async zanHandle(type) {
      if (this.loadZan) return;
      this.loadZan = true;
      type && this.$store.dispatch('setScoreLog', {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            typeMap: 6
          }
        },
        action_type: 'ZAN',
        section_id: this.urlQuery.id
      });
      try {
        let res = await this.$api[type ? 'contentZan' : 'cancleContentZan']({
          content_id: this.urlQuery.id,
          type: 6
        });
        this.loadZan = false;
        if (type) {
          this.myIsZan = true;
          this.sectionDetail.zan++;
          this.$emit('setIsZan', true);
        } else {
          this.sectionDetail.zan--;
          this.myIsZan = false;
          this.$emit('setIsZan', false);
        }
      } catch (error) {}
    },
    async faverHandle(type) {
      if (this.loadFaver) return;
      this.loadFaver = true;
      type && this.$store.dispatch('setScoreLog', {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            typeMap: 6
          }
        },
        action_type: 'FAVER',
        section_id: this.urlQuery.id
      });
      try {
        let res = await this.$api[type ? 'contentFaver' : 'cancleContentFaver']({
          content_id: this.urlQuery.id,
          type: 6
        });
        this.loadFaver = false;
        if (type) {
          this.myIsFaver = true;
          this.sectionDetail.faver++;
          this.$emit('setIsFaver', true);
        } else {
          this.myIsFaver = false;
          this.sectionDetail.faver--;
          this.$emit('setIsFaver', false);
        }
      } catch (error) {}
    },
    async getZanCheck() {
      let res = await this.$api.getZanCheck(this.urlQuery.id, 6);
      if (res.errcode) {
        alert('getZanCheck(this.urlQuery.id, 6)' + res.errmsg);
        return;
      }
      if (res.count) {
        this.myIsZan = true;
        return;
      }
      this.myIsZan = false;
    },
    async getFaverCheck() {
      let res = await this.$api.getFaverCheck(this.urlQuery.id, 6);
      if (res.errcode) {
        alert('getZanCheck(this.urlQuery.id, 6)' + res.errmsg);
        return;
      }
      if (res.count) {
        this.myIsFaver = true;
        return;
      }
      this.myIsFaver = false;
    },
    showDaysPop(res, resBackData, isApply) {
      let days = res.days || 0;
      this.$instanceExperienceView.show({
        days: days,
        //体验剩余天数
        isNeedBack: isApply,
        //是否重定向返回原页面
        callBack: ret => {
          if (ret.next == 'from') {
            if (isApply) {
              this.goApplyRedirect(resBackData);
              return;
            }
          } else {
            //立即认证跳转
            this.$PUSH({
              path: ret.next
            });
          }
        }
      });
    },
    goApplyRedirect(res) {
      let url = window.location.href.split('?')[1];
      window.sessionStorage.setItem('redirectUrl', `richdetail?${url}`);
      this.$PUSH({
        path: `/activity/activeShowOncePoster?id=${res.campain_id}&homeType=ZXZR&_contentId=${res.id}&_typeMap=${res.type}`
      });
    },
    async initData() {
      this.getZanCheck();
      this.getFaverCheck();
      try {
        let res = await this.$api.getCampainSectionDetail({}, this.urlQuery.id);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCampainSectionDetail" + res.errmsg)
          return;
        }
        this.isShowSignPage();

        /* 修改微信配置 Start */
        this.weChatShare.title = res.name;
        this.weChatShare.desc = res.name;
        this.weChatShare.imgUrl = res.cover;
        this.initWechat();
        /* 修改微信配置 End */

        if (res.content) res.content = res.content.replaceAll('<img', '<img style="max-width:100%;"');
        this.sectionDetail = res;
        console.log(' this.sectionDetail', this.sectionDetail);
      } catch (error) {}
    },
    enterActivityHandleDelay() {
      this.$refs['customPop-tip'].close();
    },
    enterActivityHandleNow() {
      this.$refs['customPop-tip'].close();
      this.$PUSH({
        path: `/personal/task`
      });
    },
    async enterActivity() {
      /* 2023-02-09 ac说客户又不做等级限制了 */

      let res = await this.$api.getCampainSectionDetailAuth({}, this.sectionDetail.id);
      if (this.sectionDetail.content_type == 1) {
        this.$PUSH({
          path: `/activity/detail?id=${this.sectionDetail.id}&typeMap=6&campain_id=${this.urlQuery.campain_id ? this.urlQuery.campain_id : res.campain_id}`
        });
        return;
      }
      this.$PUSH({
        path: `/activity/richlist?id=${this.sectionDetail.id}&typeMap=6&campain_id=${this.urlQuery.campain_id ? this.urlQuery.campain_id : res.campain_id}`
      });
      return;
      if (this.userInfo.level >= 3) {
        this.$PUSH({
          path: `/activity/detail?id=${this.sectionDetail.id}`
        });
        return;
      }
      this.$refs['customPop-tip'].open();
    }
  }
});