"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "veevaCodeaddSupple"
  }, [_c("div", {
    staticClass: "veevaCodeaddSuppleWords"
  }, [_vm._v("很遗憾！您没有通过医疗卫生专业人士身份认证，您可以修改个人信息、补充认证资料后，重新申请认证")]), _c("div", {
    staticClass: "veevaCodeaddSuppleBtn",
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/veeva"
        });
      }
    }
  }, [_vm._v(" 立即补全 ")])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;