"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = _interopRequireDefault(require("utils/http"));
var _default = exports.default = {
  // 接口示例
  getTest(params) {
    return (0, _http.default)({
      url: `/api/test`,
      method: 'get',
      params
      // loading: 1, // 请求时默认加载一个 loading
      // origin: 1, // 接口返回数据，原格式返回，需要自己判断 code 时使用
      // custom: 1, // 自定义链接请求地址，不会拼接全局 baseUrl
      // openid: 1, // 是否在参数中拼接 openid
      // upload: 1, // 修改 header 头为文件类型，formData 时使用
    });
  },
  // 接口示例
  postTest(data) {
    return (0, _http.default)({
      url: `/api/post`,
      method: 'post',
      data
    });
  }
};