"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$userInfo, _vm$userInfo2, _vm$userInfo3;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container"
  }, [(_vm$userInfo = _vm.userInfo) !== null && _vm$userInfo !== void 0 && _vm$userInfo.id ? _c("div", {
    staticClass: "userInfo flex align-center justify-between"
  }, [_c("div", {
    staticClass: "userInfoLeft flex align-center"
  }, [_c("div", {
    staticClass: "userInfoLeftAvatar"
  }, [_c("div", {
    staticClass: "userInfoLeftAvatarView"
  }, [_vm.showInfo().avatar ? _c("Picture", {
    attrs: {
      src: _vm.showInfo().avatar || _vm.$DEFAULTAVATAR
    }
  }) : _c("img", {
    attrs: {
      src: require("img/personal/avatarUserNull.png"),
      alt: ""
    }
  })], 1), _c("div", {
    staticClass: "userInfoLeftAvatarVeeva",
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/veeva"
        });
      }
    }
  }, [_vm.userInfo.veeva == 0 ? _c("img", {
    attrs: {
      src: require("img/personal/auth/none1.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.userInfo.veeva == 2 ? _c("img", {
    attrs: {
      src: require("img/personal/auth/wait1.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.userInfo.veeva == 1 ? _c("img", {
    attrs: {
      src: require("img/personal/auth/pass1.png"),
      alt: ""
    }
  }) : _vm._e()])]), _c("div", {
    staticClass: "userInfoLeftInfo flex flex-direction align-start justify-center"
  }, [_c("div", {
    staticClass: "flex align-center"
  }, [_c("div", {
    staticClass: "userInfoLeftNick"
  }, [_vm._v(_vm._s(_vm.showInfo().title))])]), _vm.userInfo.type == "1" ? _c("div", {
    staticClass: "userInfoLeftProgress"
  }, [_c("div", {
    staticClass: "userInfoLeftProgressInner",
    style: {
      width: `${Math.ceil(33 * _vm.userInfo.complete_level) + 1}%`
    }
  })]) : _vm._e(), _vm.userInfo.type == "1" ? _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "userInfoLeftProgressTip flex align-center",
    attrs: {
      "event-key": `编辑资料-信息完善度`
    },
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$PUSH({
          path: "/userInfoedit"
        });
      }
    }
  }, [_vm._v(" 信息完善度" + _vm._s(_vm.complete_level[_vm.userInfo.complete_level]) + " "), _c("img", {
    attrs: {
      src: require("img/personal/userInfoLeftProgressTip.png"),
      alt: ""
    }
  })]) : _vm._e()])]), _vm.showSign && (_vm$userInfo2 = _vm.userInfo) !== null && _vm$userInfo2 !== void 0 && _vm$userInfo2.id ? _c("div", {
    staticClass: "userInfoRight flex flex-direction align-end"
  }, [_vm.isSign ? _c("div", {
    staticClass: "userInfoRightSign flex align-center justify-center isSign"
  }, [_c("span", [_vm._v("已签到")])]) : _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "userNullRightSign flex align-center justify-center",
    attrs: {
      "event-key": `签到`
    },
    on: {
      click: _vm.setSign
    }
  }, [_c("img", {
    staticClass: "userNullRightSignIcon",
    attrs: {
      src: require("img/personal/signIcon.png"),
      alt: ""
    }
  }), _c("span", [_vm._v("签到")])]), (_vm$userInfo3 = _vm.userInfo) !== null && _vm$userInfo3 !== void 0 && _vm$userInfo3.continuous_login_num ? _c("div", {
    staticClass: "userInfoRightDay"
  }, [_vm._v(" 已连续签到" + _vm._s(_vm.userInfo.continuous_login_num || "0") + "天 ")]) : _vm._e()]) : _vm._e()]) : _c("div", {
    staticClass: "userNull flex align-center justify-between"
  }, [_c("div", {
    staticClass: "userNullLeft flex align-center"
  }, [_c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "userNullLeftAvatar",
    attrs: {
      "event-key": `注册`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/register"
        });
      }
    }
  }, [_c("img", {
    attrs: {
      src: require("img/personal/avatarUserNull.png"),
      alt: ""
    }
  })]), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "userNullLeftNick",
    attrs: {
      "event-key": `注册`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/register"
        });
      }
    }
  }, [_vm._v("请注册")])]), _c("div", {
    staticClass: "userNullRight flex flex-direction align-end"
  })])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;