"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$dataBase;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("div", {
    staticClass: "detailHeaderTitleComponent"
  }, [_c("div", {
    staticClass: "detailHeaderTitle flex justify-between"
  }, [_c("div", {
    staticClass: "detailHeaderTitleLeft"
  }, [_vm._v(_vm._s(_vm.dataBase.name))]), _c("div", {
    staticClass: "detailHeaderTitleRight flex align-center"
  }, [_c("img", {
    attrs: {
      src: require("img/meeting/browseNumber.png"),
      alt: ""
    }
  }), _vm._v(" " + _vm._s(_vm.dataBase.read_num || 0) + " ")])]), _c("div", {
    staticClass: "detailHeaderUp flex align-center"
  }, [(_vm$dataBase = _vm.dataBase) !== null && _vm$dataBase !== void 0 && _vm$dataBase.need_veeva ? _c("div", {
    staticClass: "authIcon"
  }, [_c("img", {
    attrs: {
      alt: "",
      srcset: "",
      src: require("img/common/authIcon.png")
    }
  })]) : _vm._e(), _c("div", {
    staticClass: "detailHeaderUpItem flex align-center"
  }, [_vm.dataBase.isFaver ? _c("img", {
    staticClass: "detailHeaderUpItemStar",
    attrs: {
      src: require("img/common/meetingstar.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        return _vm.faverHandle(0);
      }
    }
  }) : _c("img", {
    staticClass: "detailHeaderUpItemStar",
    attrs: {
      src: require("img/common/starNull.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        return _vm.faverHandle(1);
      }
    }
  }), _c("div", {
    staticClass: "detailHeaderUpItemNumber"
  }, [_vm._v(_vm._s(_vm.dataBase.faver || 0))])]), _c("div", {
    staticClass: "detailHeaderUpItem flex align-center"
  }, [_vm.dataBase.isZan ? _c("img", {
    staticClass: "detailHeaderUpItemGive",
    attrs: {
      src: require("img/common/meetinggive.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        return _vm.zanHandle(0);
      }
    }
  }) : _c("img", {
    staticClass: "detailHeaderUpItemGive",
    attrs: {
      src: require("img/common/giveNull.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        return _vm.zanHandle(1);
      }
    }
  }), _c("div", {
    staticClass: "detailHeaderUpItemNumber"
  }, [_vm._v(_vm._s(_vm.dataBase.zan || 0))])])]), _c("div", {
    staticClass: "detailHeaderTag flex flex-wrap"
  }, [_vm.need_veeva ? _c("div", {
    ref: "courseTagInnerItem",
    staticClass: "detailHeaderTagItem cursmallCourseTagItem"
  }, [_vm._v("认证专享")]) : _vm._e(), _vm._l(_vm.dataBase.tag_name_list, function (im, ix) {
    return _c("div", {
      key: ix,
      staticClass: "detailHeaderTagItem"
    }, [_vm._v(_vm._s(im))]);
  })], 2), _c("div", {
    staticClass: "detailHeaderInfo"
  }, [_vm.dataBase.speaker ? _c("div", {
    staticClass: "detailHeaderInfoLine flex align-center"
  }, [_c("img", {
    staticClass: "detailHeaderInfoLineJZ",
    attrs: {
      src: require("img/meeting/jiangzhe.png"),
      alt: ""
    }
  }), _vm._v(" 讲者：" + _vm._s(_vm.dataBase.speaker) + " ")]) : _vm._e(), _vm.dataBase.hospital ? _c("div", {
    staticClass: "detailHeaderInfoLine flex align-center"
  }, [_c("img", {
    staticClass: "detailHeaderInfoLineszyy",
    attrs: {
      src: require("img/meeting/szyy.png"),
      alt: ""
    }
  }), _vm._v(" 讲者所在医院：" + _vm._s(_vm.dataBase.hospital) + " ")]) : _vm._e(), _c("div", {
    staticClass: "detailHeaderInfoLine flex align-center"
  }, [_c("img", {
    staticClass: "detailHeaderInfoLineSJ",
    attrs: {
      src: require("img/meeting/hysj.png"),
      alt: ""
    }
  }), _vm._v(" 会议时间：" + _vm._s(_vm.showFormatTime(_vm.dataBase)) + " ")])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;