"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$handleLevel, _vm$showTipInfoWords;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container-out"
  }, [_c("stickyHeader"), _c("div", {
    staticClass: "taskHeader"
  }, [_c("div", {
    staticClass: "taskHeaderInner"
  }, [_c("div", {
    staticClass: "taskHeaderInnerCount"
  }, [_vm._v(_vm._s(_vm.userInfo.score || 0))]), _c("div", {
    staticClass: "taskHeaderInnerLevel flex justify-center align-end"
  }, [_c("div", {
    staticClass: "taskHeaderInnerLevelWord"
  }, [_vm._v("学术能量")]), _c("div", {
    staticClass: "taskHeaderInnerLevelTag",
    class: (!_vm.userInfo.score || _vm.userInfo.score < 30) && "bigtaskHeaderInnerLevelTag"
  }, [_vm._v("Lv." + _vm._s(_vm.userInfo.level) + " " + _vm._s((_vm$handleLevel = _vm.handleLevel()) === null || _vm$handleLevel === void 0 ? void 0 : _vm$handleLevel.title))])]), _c("div", {
    staticClass: "taskHeaderInnerProgress"
  }, [_vm.handleLevel().progress != 100 ? _c("div", {
    staticClass: "taskHeaderInnerProgressInner",
    style: {
      width: `calc(${_vm.handleLevel().progress}% - 9px)`
    }
  }) : _c("div", {
    staticClass: "taskHeaderInnerProgressInner",
    style: {
      width: `calc(${_vm.handleLevel().progress}%)`
    }
  }), _vm.handleLevel().progress != 100 ? _c("div", {
    staticClass: "taskHeaderInnerProgressSpot",
    style: {
      left: `calc(${_vm.handleLevel().progress}% - 16px)`
    }
  }) : _c("div", {
    staticClass: "taskHeaderInnerProgressSpot",
    style: {
      left: `calc(${_vm.handleLevel().progress}%)`
    }
  })]), Number(_vm.handleLevel().nextLevelScore) - Number(_vm.userInfo.score) > 0 ? _c("div", {
    staticClass: "taskHeaderInnerProgressCount"
  }, [_vm._v(_vm._s(_vm.handleLevel().nextLevelScore) + " ")]) : _vm._e(), Number(_vm.handleLevel().nextLevelScore) - Number(_vm.userInfo.score) > 0 ? _c("div", {
    staticClass: "taskHeaderInnerTip"
  }, [_vm._v(" 还需 "), _c("span", [_vm._v(_vm._s(Number(_vm.handleLevel().nextLevelScore) - Number(_vm.userInfo.score)))]), _vm._v(" 点学术能量升级为Lv." + _vm._s(_vm.handleLevel().nextLevel) + " ")]) : _vm._e()])]), _c("div", {
    staticClass: "ruleListWord"
  }, [_vm._v("学术能量规则")]), _c("div", {
    staticClass: "ruleList"
  }, [_c("div", {
    staticClass: "ruleListItem flex align-center justify-between"
  }, [_vm._m(0), _c("div", {
    staticClass: "ruleListItemRight flex align-center"
  }, [_vm.userInfo.veeva == 1 ? _c("div", {
    staticClass: "ruleListItemRightBtn isDone flex align-center justify-center"
  }, [_vm._v("已完成")]) : _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "ruleListItemRightBtn isNo flex align-center justify-center",
    attrs: {
      "event-key": `医疗卫生专业人士认证-去完成`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: `/veeva`
        });
      }
    }
  }, [_c("div", {
    staticClass: "ruleListItemRightBtnTip flex align-center justify-center"
  }, [_vm._v("NEW")]), _vm._v(" 去完成")])])]), _c("div", {
    staticClass: "ruleListItem flex align-center justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeft flex align-center"
  }, [_vm._m(1), _c("div", {
    staticClass: "ruleListItemLeftInfo flex flex-direction justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeftInfoTitle"
  }, [_vm._v("绑定拜耳学术会议平台")]), _c("div", {
    staticClass: "ruleListItemLeftInfoTip",
    staticStyle: {
      color: "#f29826"
    }
  }, [_vm._v("学术能量 "), _c("span", {
    staticClass: "fontWeight"
  }, [_vm._v("+100 "), _c("img", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    attrs: {
      src: require("img/personal/wenhao.png"),
      "event-key": `绑定拜耳学术会议平台`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/bindStep"
        });
      }
    }
  })])])])]), _c("div", {
    staticClass: "ruleListItemRight flex align-center"
  }, [_vm.userInfo.fusion == 1 ? _c("div", {
    staticClass: "ruleListItemRightBtn isDone flex align-center justify-center"
  }, [_vm._v("已完成")]) : _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "ruleListItemRightBtn isNo flex align-center justify-center",
    attrs: {
      "event-key": `拜耳学术会议-去完成`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: `/veeva`
        });
      }
    }
  }, [_c("div", {
    staticClass: "ruleListItemRightBtnTip flex align-center justify-center"
  }, [_vm._v("NEW")]), _vm._v(" 去完成")])])]), _vm.userInfo.type != "2" ? _c("div", {
    staticClass: "ruleListItem flex align-center justify-between"
  }, [_vm._m(2), _c("div", {
    staticClass: "ruleListItemRight flex align-center"
  }, [_vm.userInfo.complete_level != 3 ? _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "ruleListItemRightBtn isNo flex align-center justify-center",
    attrs: {
      "event-key": `完善信息-去完成`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/userInfoedit"
        });
      }
    }
  }, [_vm._v("去完成")]) : _vm._e(), _vm.userInfo.complete_level == 3 ? _c("div", {
    staticClass: "ruleListItemRightBtn isDone flex align-center justify-center"
  }, [_vm._v("已完成")]) : _vm._e()])]) : _vm._e(), _c("div", {
    staticClass: "ruleListItem flex align-center justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeft flex align-center"
  }, [_vm._m(3), _c("div", {
    staticClass: "ruleListItemLeftInfo flex flex-direction justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeftInfoTitle"
  }, [_vm._v("填写问卷")]), _c("div", {
    staticClass: "ruleListItemLeftInfoTip",
    staticStyle: {
      color: "#f29826"
    }
  }, [_vm._v("学术能量 "), _c("span", {
    staticClass: "fontWeight"
  }, [_vm._v("+100")]), _vm._v(" "), _c("img", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    attrs: {
      src: require("img/personal/wenhao.png"),
      "event-key": `填写问卷-弹窗`,
      alt: ""
    },
    on: {
      click: function ($event) {
        _vm.setShowTipInfoWords("填写问卷", "完成可得大量学术能量，快速提升学术能量排名！");
        _vm.showTipInfo();
      }
    }
  })])])]), _c("div", {
    staticClass: "ruleListItemRight flex align-center"
  }, [_c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "ruleListItemRightBtn isNo flex align-center justify-center",
    attrs: {
      "event-key": `填写问卷-去完成`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: `/personal/question`
        });
      }
    }
  }, [_c("div", {
    staticClass: "ruleListItemRightBtnTip flex align-center justify-center"
  }, [_vm._v("NEW")]), _vm._v(" 去完成")])])]), _c("div", {
    staticClass: "ruleListItem flex align-center justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeft flex align-center"
  }, [_vm._m(4), _c("div", {
    staticClass: "ruleListItemLeftInfo flex flex-direction justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeftInfoTitle"
  }, [_vm._v("每日签到")]), _c("div", {
    staticClass: "ruleListItemLeftInfoTip"
  }, [_vm._v("学术能量+5 "), _c("img", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    attrs: {
      src: require("img/personal/wenhao.png"),
      "event-key": `每日签到-弹窗`,
      alt: ""
    },
    on: {
      click: function ($event) {
        _vm.setShowTipInfoWords("每日签到", "连续签到5天，可额外+10");
        _vm.showTipInfo();
      }
    }
  })])])]), _c("div", {
    staticClass: "ruleListItemRight flex align-center"
  }, [!_vm.isSign && _vm.isLoadSign ? _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "ruleListItemRightBtn isNo flex align-center justify-center",
    attrs: {
      "event-key": `每日签到-去完成`
    },
    on: {
      click: _vm.setSign
    }
  }, [_vm._v("去完成")]) : _vm._e(), _vm.isSign && _vm.isLoadSign ? _c("div", {
    staticClass: "ruleListItemRightBtn isDone flex align-center justify-center"
  }, [_vm._v("已完成")]) : _vm._e()])]), _c("div", {
    staticClass: "ruleListItem flex align-center justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeft flex align-center"
  }, [_vm._m(5), _c("div", {
    staticClass: "ruleListItemLeftInfo flex flex-direction justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeftInfoTitle"
  }, [_vm._v("参加活动")]), _c("div", {
    staticClass: "ruleListItemLeftInfoTip"
  }, [_vm._v("学术能量+10 "), _c("img", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    attrs: {
      src: require("img/personal/wenhao.png"),
      "event-key": `参加活动-弹窗`,
      alt: ""
    },
    on: {
      click: function ($event) {
        _vm.setShowTipInfoWords("参加活动", "浏览时长也会增加学术能量值哦！");
        _vm.showTipInfo();
      }
    }
  })])])]), _c("div", {
    staticClass: "ruleListItemRight flex align-center"
  }, [_c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "ruleListItemRightBtn isNo flex align-center justify-center",
    attrs: {
      "event-key": `参加活动-去完成`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/activity"
        });
      }
    }
  }, [_vm._v("去完成")])])]), _c("div", {
    staticClass: "ruleListItem flex align-center justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeft flex align-center"
  }, [_vm._m(6), _c("div", {
    staticClass: "ruleListItemLeftInfo flex flex-direction justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeftInfoTitle"
  }, [_vm._v("浏览课程")]), _c("div", {
    staticClass: "ruleListItemLeftInfoTip"
  }, [_vm._v("学术能量+10 "), _c("img", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    attrs: {
      src: require("img/personal/wenhao.png"),
      "event-key": `参加活动-弹窗`,
      alt: ""
    },
    on: {
      click: function ($event) {
        _vm.setShowTipInfoWords("浏览课程", "浏览时长也会增加学术能量值哦！");
        _vm.showTipInfo();
      }
    }
  })])])]), _c("div", {
    staticClass: "ruleListItemRight flex align-center"
  }, [_c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "ruleListItemRightBtn isNo flex align-center justify-center",
    attrs: {
      "event-key": `参加活动-去完成`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/course"
        });
      }
    }
  }, [_vm._v("去完成")])])]), _c("div", {
    staticClass: "ruleListItem flex align-center justify-between"
  }, [_vm._m(7), _c("div", {
    staticClass: "ruleListItemRight flex align-center"
  }, [_c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "ruleListItemRightBtn isNo flex align-center justify-center",
    attrs: {
      "event-key": `参加会议-去完成`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/meeting"
        });
      }
    }
  }, [_vm._v("去完成")])])]), _c("div", {
    staticClass: "ruleListItem flex align-center justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeft flex align-center"
  }, [_vm._m(8), _c("div", {
    staticClass: "ruleListItemLeftInfo flex flex-direction justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeftInfoTitle"
  }, [_vm._v("互动或评论")]), _c("div", {
    staticClass: "ruleListItemLeftInfoTip"
  }, [_vm._v("学术能量+10 "), _c("img", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    attrs: {
      src: require("img/personal/wenhao.png"),
      "event-key": `互动或评论-弹窗`,
      alt: ""
    },
    on: {
      click: function ($event) {
        _vm.setShowTipInfoWords("互动或评论", `<div class='hasDesc'>互动类型：答题正确+10/题，答题错误+5每题，单日上限100点</div>
                        <div class='hasDesc'>调研类型：+5/题，单日上限100点</div>
                        <div class='hasDesc'>评论+10/条，单页上限20点，单日上限50点</div>`);
        _vm.showTipInfo();
      }
    }
  })])])]), _c("div", {
    staticClass: "ruleListItemRight flex align-center"
  }, [_c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "ruleListItemRightBtn isNo flex align-center justify-center",
    attrs: {
      "event-key": `互动或评论-去完成`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/allcontent"
        });
      }
    }
  }, [_vm._v("去完成")])])]), _c("div", {
    staticClass: "ruleListItem flex align-center justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeft flex align-center"
  }, [_vm._m(9), _c("div", {
    staticClass: "ruleListItemLeftInfo flex flex-direction justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeftInfoTitle"
  }, [_vm._v("点赞、收藏或转发")]), _c("div", {
    staticClass: "ruleListItemLeftInfoTip"
  }, [_vm._v("学术能量+5 "), _c("img", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    attrs: {
      src: require("img/personal/wenhao.png"),
      "event-key": `点赞、收藏或转发-弹窗`,
      alt: ""
    },
    on: {
      click: function ($event) {
        _vm.setShowTipInfoWords("点赞、收藏或转发", `<div class='hasDesc'>点赞文章：评论+5/次，单页上限20点，单日上限30点，点击</div>
                    <div class='hasDesc'>点击<生成海报分享>：+10/次，单页上限20点，单日上限50点</div>
                    <div class='hasDesc'>收藏+1/次：取消再添加不重复计数，单日上限10点</div>`);
        _vm.showTipInfo();
      }
    }
  })])])]), _c("div", {
    staticClass: "ruleListItemRight flex align-center"
  }, [_c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "ruleListItemRightBtn isNo flex align-center justify-center",
    attrs: {
      "event-key": `点赞、收藏或转发-去完成`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/allcontent"
        });
      }
    }
  }, [_vm._v("去完成")])])]), _c("div", {
    staticClass: "ruleListItem flex align-center justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeft flex align-center"
  }, [_vm._m(10), _c("div", {
    staticClass: "ruleListItemLeftInfo flex flex-direction justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeftInfoTitle"
  }, [_vm._v("添加「联系我们」企业微信，首次进入学术社群")]), _c("div", {
    staticClass: "ruleListItemLeftInfoTip"
  }, [_vm._v("学术能量+100 "), _c("img", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    attrs: {
      src: require("img/personal/wenhao.png"),
      "event-key": `添加「联系我们」企业微信，首次进入学术社群-弹窗`,
      alt: ""
    },
    on: {
      click: function ($event) {
        _vm.setShowTipInfoWords("联系我们", "获取时间有延迟，每用户最多获赠一次");
        _vm.showTipInfo();
      }
    }
  })])])]), _c("div", {
    staticClass: "ruleListItemRight flex align-center"
  }, [!_vm.checkAiRep ? _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "ruleListItemRightBtn isNo flex align-center justify-center",
    attrs: {
      "event-key": `添加「联系我们」企业微信，首次进入学术社群-去完成`
    },
    on: {
      click: function ($event) {
        return _vm.$store.commit("SET_CALLUSSHOW", true);
      }
    }
  }, [_vm._v("去完成")]) : _c("div", {
    staticClass: "ruleListItemRightBtn isDone flex align-center justify-center"
  }, [_vm._v("已完成")])])])]), _c("customPop", {
    ref: "customPop-tipInfo",
    attrs: {
      showType: "slot"
    }
  }, [_c("div", {
    staticClass: "tipInfo"
  }, [_c("div", {
    staticClass: "tipInfoTitle"
  }, [_vm._v(_vm._s((_vm$showTipInfoWords = _vm.showTipInfoWords) === null || _vm$showTipInfoWords === void 0 ? void 0 : _vm$showTipInfoWords.title))]), _c("div", {
    staticClass: "tipInfoWords",
    domProps: {
      innerHTML: _vm._s(_vm.$VIDEOPLAYSINLINE(_vm.showTipInfoWords.words))
    },
    on: {
      click: _vm.$imageEnlargement
    }
  }), _c("div", {
    staticClass: "tipInfoBtn flex align-center justify-center",
    on: {
      click: function ($event) {
        return _vm.$refs["customPop-tipInfo"].close();
      }
    }
  }, [_vm._v(" 我知道了")])])]), _c("vanPopup", {
    model: {
      value: _vm.showPowerBoxTip,
      callback: function ($$v) {
        _vm.showPowerBoxTip = $$v;
      },
      expression: "showPowerBoxTip"
    }
  }, [_c("div", {
    staticClass: "powerBoxTip"
  }, [_c("img", {
    staticClass: "powerAddIcon",
    attrs: {
      src: require("img/personal/powerAddIcon.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "powerBoxTipInner"
  }, [_c("div", {
    staticClass: "powerBoxTipInnerWords flex align-end justify-center"
  }, [_vm._v(" + "), _c("span", {
    staticClass: "powerBoxTipInnerWordsCount"
  }, [_vm._v("5")]), _vm._v(" "), _c("span", {
    staticClass: "powerBoxTipInnerWordsInfo"
  }, [_vm._v("学术能量")])]), _c("div", {
    staticClass: "powerBoxTipInnerBtn flex align-center justify-center",
    on: {
      click: function ($event) {
        _vm.showPowerBoxTip = false;
      }
    }
  }, [_vm._v(" 确认")])])])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "ruleListItemLeft flex align-center"
  }, [_c("div", {
    staticClass: "ruleListItemLeftIcon"
  }, [_c("img", {
    staticStyle: {
      transform: "scale(1.15)"
    },
    attrs: {
      src: require("img/personal/ylwsrz.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "ruleListItemLeftInfo flex flex-direction justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeftInfoTitle"
  }, [_vm._v("医疗卫生专业人士认证")]), _c("div", {
    staticClass: "ruleListItemLeftInfoTip",
    staticStyle: {
      color: "#f29826"
    }
  }, [_vm._v("学术能量 "), _c("span", {
    staticClass: "fontWeight"
  }, [_vm._v("+300")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "ruleListItemLeftIcon"
  }, [_c("img", {
    staticStyle: {
      transform: "scale(1.15)"
    },
    attrs: {
      src: require("img/personal/hyrz.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "ruleListItemLeft flex align-center"
  }, [_c("div", {
    staticClass: "ruleListItemLeftIcon"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/wsxx.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "ruleListItemLeftInfo flex flex-direction justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeftInfoTitle"
  }, [_vm._v("完善信息")]), _c("div", {
    staticClass: "ruleListItemLeftInfoTip"
  }, [_vm._v("学术能量+50 ")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "ruleListItemLeftIcon"
  }, [_c("img", {
    staticStyle: {
      transform: "scale(1.15)"
    },
    attrs: {
      src: require("img/personal/txwj.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "ruleListItemLeftIcon"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/mrqd.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "ruleListItemLeftIcon"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/cjhd.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "ruleListItemLeftIcon"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/llkc.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "ruleListItemLeft flex align-center"
  }, [_c("div", {
    staticClass: "ruleListItemLeftIcon"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/cjhy.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "ruleListItemLeftInfo flex flex-direction justify-between"
  }, [_c("div", {
    staticClass: "ruleListItemLeftInfoTitle"
  }, [_vm._v("参加会议")]), _c("div", {
    staticClass: "ruleListItemLeftInfoTip"
  }, [_vm._v("学术能量+10 ")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "ruleListItemLeftIcon"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/hdhpl.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "ruleListItemLeftIcon"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/dzsc.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "ruleListItemLeftIcon"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/jrxs.png"),
      alt: ""
    }
  })]);
}];
render._withStripped = true;