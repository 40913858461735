"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.string.replace-all.js");
require("vant/es/image-preview/style");
var _imagePreview = _interopRequireDefault(require("vant/es/image-preview"));
require("vant/es/sticky/style");
var _sticky = _interopRequireDefault(require("vant/es/sticky"));
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _customVideo = _interopRequireDefault(require("@/components/common/customVideo.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _list = _interopRequireDefault(require("@/components/meeting/list.vue"));
var _detailIntroduce = _interopRequireDefault(require("@/components/meeting/detailIntroduce.vue"));
var _meetingVideo = _interopRequireDefault(require("@/components/meeting/meetingVideo.vue"));
var _meetingPoster = _interopRequireDefault(require("@/components/meeting/meetingPoster.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    meetingList: _list.default,
    vanSticky: _sticky.default,
    meetingIntroduce: _detailIntroduce.default,
    meetingVideo: _meetingVideo.default,
    meetingPoster: _meetingPoster.default,
    customVideo: _customVideo.default
  },
  data() {
    return {
      showModule: 0,
      capUrl: "",
      urlQuery: {},
      detailInfo: {},
      isBackLive: false,
      detailInfoVideos: 0,
      tipWords: "",
      baomingLoading: false
    };
  },
  watch: {},
  filter: {},
  created() {
    this.urlQuery = this.$route.query;
    this.$store.commit("SET_LOADINGPOP", true);
    this.initData();
  },
  mounted() {},
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    setBrowse() {},
    pauseSetBrowse() {},
    setFaver(param) {
      this.detailInfo.isFaver = param == "true" ? true : false;
      param == "true" ? this.detailInfo.faver++ : this.detailInfo.faver--;
    },
    setZan(param) {
      this.detailInfo.isZan = param == "true" ? true : false;
      param == "true" ? this.detailInfo.zan++ : this.detailInfo.zan--;
    },
    createPoster() {
      const detailPosterInner = document.querySelector(".detailPosterInnerOut");
      (0, _html2canvas.default)(detailPosterInner, {
        useCORS: true
      }).then(canvas => {
        this.capUrl = canvas.toDataURL();
        (0, _imagePreview.default)({
          images: [this.capUrl],
          closeable: true
        });
        setTimeout(() => {
          this.$store.dispatch("setScoreLog", {
            route: this.$route,
            action_type: "SHARE"
          });
        }, 2000);
      });
    },
    goBackLive() {
      // this.isBackLive = true
      this.$PUSH({
        path: `/meetingdetailplayback?id=${this.urlQuery.id}&typeMap=4`
      });
    },
    entryLive() {
      const openid = localStorage.getItem("openid");
      const wechatInfo = localStorage.getItem("wechatInfo");
      const isRegister = localStorage.getItem("isRegister");
      if (!openid || !wechatInfo || !isRegister) {
        this.$registerView.show(ret => {
          if (ret.next == "from") {
            this.$store.commit("SET_CALLUSSHOW", false);
          } else {
            this.$store.commit("SET_CALLUSSHOW", false);
            this.$PUSH({
              path: `/register`,
              query: {
                redirect: encodeURIComponent(this.$route.fullPath)
              }
            });
          }
        });
        return;
      }
      window.location.href = this.detailInfo.meeting_url + "&openid=" + localStorage.getItem("openid");
    },
    statusBtn() {
      var _this$detailInfo$vide;
      const nowS = Date.now();
      const startS = new Date(this.detailInfo.start_time.replace(/\-/g, "/")).getTime();
      const endS = new Date(this.detailInfo.end_time.replace(/\-/g, "/")).getTime();
      if ((nowS > endS || this.detailInfo.status == 3) && (_this$detailInfo$vide = this.detailInfo.videos) !== null && _this$detailInfo$vide !== void 0 && _this$detailInfo$vide.length) {
        this.isBackLive = true;
      }
      if (nowS < startS) return 1;
      if (nowS > startS && nowS < endS) return 2;
      if (nowS > endS) return 3;
    },
    async setSubscribe() {
      const openid = localStorage.getItem("openid");
      const wechatInfo = localStorage.getItem("wechatInfo");
      const isRegister = localStorage.getItem("isRegister");
      if (!openid || !wechatInfo || !isRegister) {
        this.$registerView.show(ret => {
          if (ret.next == "from") {
            this.$store.commit("SET_CALLUSSHOW", false);
          } else {
            this.$store.commit("SET_CALLUSSHOW", false);
            this.$PUSH({
              path: `/register`,
              query: {
                redirect: encodeURIComponent(this.$route.fullPath)
              }
            });
          }
        });
        return;
      }
      if (this.baomingLoading) {
        return;
      }
      this.baomingLoading = true;

      // this.$store.commit("SET_LOADINGPOP", true)

      try {
        let res = await this.$api.setSubscribe({
          meeting_id: this.detailInfo.id
        });
        this.baomingLoading = false;
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("setSubscribe 请求失败：" + res.errmsg)
          return;
        }
        // alert("报名成功")

        this.tipWords = "报名成功";
        this.$refs.customPop.open();
        this.initData();
      } catch (error) {}
    },
    async initData() {
      try {
        let res = await this.$api.getPreviewMeeting({}, this.urlQuery.id);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getMeetingtList 请求失败：" + res.errmsg)
          this.$store.commit("SET_LOADINGPOP", false);
          return;
        }
        if (res.content) res.content = res.content.replaceAll('<img', '<img style="max-width:100%;"');

        /* let zanCheck = await this.$api.getZanCheck(res.id, res.type)
        let faverCheck = await this.$api.getFaverCheck(res.id, res.type)
          zanCheck.count > 0 && (res.isZan = true)
        faverCheck.count > 0 && (res.isFaver = true) */

        this.$store.commit("SET_LOADINGPOP", false);
        this.detailInfo = res;
        this.statusBtn();
      } catch (error) {}
    },
    getCanvas() {
      const detailPosterInner = document.querySelector(".detailPosterInnerOut");
      (0, _html2canvas.default)(detailPosterInner, {
        useCORS: true
      }).then(canvas => {
        this.capUrl = canvas.toDataURL();
      });
    }
  }
});