"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  props: {
    width: [String, Number],
    height: [String, Number]
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    w() {
      const W = this.width;
      return W ? `${W / 8.28}vw` : '100%';
    },
    h() {
      const H = this.height;
      return H ? `${H / 8.28}vw` : '.1px';
    }
  },
  filters: {},
  methods: {}
};