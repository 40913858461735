"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$detailInfo, _vm$detailInfo2, _vm$detailInfo$videos, _vm$detailInfo3, _vm$detailInfo4, _vm$detailInfo5, _vm$detailInfo$videos2, _vm$detailInfo6, _vm$detailInfo7;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container hasMinheight",
    class: _vm.backGray ? "backGray" : "backWhite"
  }, [_c("stickyHeader"), (_vm$detailInfo = _vm.detailInfo) !== null && _vm$detailInfo !== void 0 && _vm$detailInfo.id ? _c("div", {
    staticClass: "detailPosterOut"
  }, [_c("meetingPoster", {
    attrs: {
      dataBase: _vm.detailInfo,
      posterType: 4,
      tagList: _vm.detailInfo.tag_name_list
    }
  })], 1) : _vm._e(), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "detailPosterCreateBtn flex align-center",
    attrs: {
      "event-key": `生成海报分享`
    },
    on: {
      click: _vm.createPoster
    }
  }, [_c("img", {
    attrs: {
      src: require("img/common/shareWhite-meeting.png"),
      alt: ""
    }
  }), _vm._v(" 生成海报分享")]), (_vm$detailInfo2 = _vm.detailInfo) !== null && _vm$detailInfo2 !== void 0 && _vm$detailInfo2.id ? _c("div", {
    staticClass: "detailHeaderCover"
  }, [_vm.showModule == 0 ? _c("img", {
    attrs: {
      src: _vm.detailInfo.cover,
      alt: ""
    }
  }) : _vm._e(), _vm.showModule == 1 ? _c("customVideo", {
    ref: "customVideo",
    attrs: {
      scale: [238, 100],
      src: (_vm$detailInfo$videos = _vm.detailInfo.videos[_vm.detailInfoVideos]) === null || _vm$detailInfo$videos === void 0 ? void 0 : _vm$detailInfo$videos.url
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.isBackLive && (_vm$detailInfo3 = _vm.detailInfo) !== null && _vm$detailInfo3 !== void 0 && _vm$detailInfo3.id ? _c("div", {
    staticClass: "showEntry flex align-center"
  }, [_c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "showEntryModule flex align-center justify-center",
    class: !_vm.showModule ? "showEntryModuleCheck" : "",
    attrs: {
      "event-key": `${_vm.detailInfo.name}-会议简介`
    },
    on: {
      click: function ($event) {
        _vm.setShowModule(0);
        _vm.backGray = false;
      }
    }
  }, [_vm._v("会议简介 ")]), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "showEntryModule flex align-center justify-center",
    class: _vm.showModule ? "showEntryModuleCheck" : "",
    attrs: {
      "event-key": `${_vm.detailInfo.name}-回放目录`
    },
    on: {
      click: function ($event) {
        _vm.setShowModule(1);
        _vm.backGray = true;
      }
    }
  }, [_vm._v("回放目录 ")])]) : _vm._e(), !_vm.showModule && (_vm$detailInfo4 = _vm.detailInfo) !== null && _vm$detailInfo4 !== void 0 && _vm$detailInfo4.id ? _c("div", {
    staticClass: "detailHeader"
  }, [_c("meetingIntroduce", {
    attrs: {
      need_veeva: _vm.need_veeva,
      dataBase: _vm.detailInfo
    },
    on: {
      setIsFaver: _vm.setFaver,
      setIsZan: _vm.setZan
    }
  }), _c("div", {
    staticClass: "detailHeaderOther"
  }), _c("div", {
    staticClass: "detailIntroduce"
  }, [_c("div", {
    staticClass: "detailIntroduceTitle"
  }, [_vm._v("介绍")]), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "detailIntroduceContent ql-editor",
    attrs: {
      "event-key": `${_vm.detailInfo.name}-介绍`
    },
    domProps: {
      innerHTML: _vm._s(_vm.$VIDEOPLAYSINLINE(_vm.detailInfo.content))
    },
    on: {
      click: _vm.$imageEnlargement
    }
  })])], 1) : _vm._e(), _vm.showModule && (_vm$detailInfo5 = _vm.detailInfo) !== null && _vm$detailInfo5 !== void 0 && _vm$detailInfo5.id ? _c("div", {
    staticStyle: {
      background: "#f7f7f7",
      overflow: "hidden"
    }
  }, [_c("meetingVideo", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    attrs: {
      "event-key": `${_vm.detailInfo.name}-回放目录-${(_vm$detailInfo$videos2 = _vm.detailInfo.videos[_vm.detailInfoVideos]) === null || _vm$detailInfo$videos2 === void 0 ? void 0 : _vm$detailInfo$videos2.name}`,
      dataBase: _vm.detailInfo.videos,
      curtype: _vm.detailInfo.type,
      getWords: "name",
      playIndex: _vm.detailInfoVideos
    },
    on: {
      dataIndex: evt => {
        _vm.detailInfoVideos = evt;
        _vm.$refs["customVideo"].isPlay = false;
        _vm.$refs["customVideo"].resetUrl();
      }
    }
  })], 1) : _vm._e(), !_vm.showModule && !_vm.isBackLive && (_vm$detailInfo6 = _vm.detailInfo) !== null && _vm$detailInfo6 !== void 0 && _vm$detailInfo6.id && _vm.detailInfo.meeting_url ? _c("div", {
    staticClass: "fixedHeight"
  }) : _vm._e(), !_vm.showModule && !_vm.isBackLive && (_vm$detailInfo7 = _vm.detailInfo) !== null && _vm$detailInfo7 !== void 0 && _vm$detailInfo7.id && _vm.detailInfo.meeting_url ? _c("div", {
    staticClass: "fixedBox flex align-center justify-center"
  }, [!_vm.detailInfo.subscribe && _vm.statusBtn() == 1 ? _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "handleBtn flex align-center justify-center engrySign",
    attrs: {
      "event-key": `${_vm.detailInfo.name}-报名`
    },
    on: {
      click: _vm.setSubscribe
    }
  }, [_vm._v("报名")]) : _vm._e(), (_vm.detailInfo.subscribe && _vm.statusBtn() == 1 || _vm.detailInfo.status == 1) && _vm.detailInfo.subscribe ? _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "handleBtn flex align-center justify-center entryPendding",
    attrs: {
      "event-key": `${_vm.detailInfo.name}-未开始`
    }
  }, [_vm._v("未开始")]) : _vm._e(), _vm.statusBtn() == 3 || _vm.detailInfo.status == 3 ? _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "handleBtn flex align-center justify-center entryPendding",
    attrs: {
      "event-key": `${_vm.detailInfo.name}-已结束`
    }
  }, [_vm._v("已结束")]) : _vm._e(), (_vm.statusBtn() == 2 || _vm.detailInfo.status == 2) && _vm.detailInfo.meeting_url ? _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "handleBtn flex align-center justify-center engrySign",
    attrs: {
      "event-key": `${_vm.detailInfo.name}-进入直播`
    },
    on: {
      click: function ($event) {
        return _vm.entryLive("once");
      }
    }
  }, [_vm._v("进入直播 ")]) : _vm._e()]) : _vm._e(), _c("customPop", {
    ref: "customPop",
    attrs: {
      tipWords: _vm.tipWords
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;