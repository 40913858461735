"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _betterScroll = _interopRequireDefault(require("better-scroll"));
var _ApprovalNo = _interopRequireDefault(require("@/components/common/ApprovalNo.vue"));
var _navBar = _interopRequireDefault(require("@/components/nav-bar"));
var _loading = _interopRequireDefault(require("@/components/customPopview/loading.vue"));
var _screenTip = _interopRequireDefault(require("@/components/customPopview/screenTip.vue"));
var _mescroll = _interopRequireDefault(require("mescroll.js/mescroll.vue"));
var _default = exports.default = {
  name: 'AppLayout',
  components: {
    navBar: _navBar.default,
    cusLoading: _loading.default,
    MescrollVue: _mescroll.default,
    ApprovalNo: _ApprovalNo.default,
    screenTip: _screenTip.default
  },
  data() {
    return {
      hideApproval: false,
      showTabbar: true,
      mescrollUp: {
        callback: this.pageLoadNext
      },
      pageYBefore: 0,
      pageYAfter: 0,
      Bscroll: null,
      showSelfSeat: false
    };
  },
  computed: {
    projectAuthStatus() {
      return this.$store.getters.projectAuthStatus;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    callUs() {
      return this.$store.getters.callUs;
    },
    callUsShow() {
      return this.$store.getters.callUsShow;
    },
    tologin() {
      return this.$store.getters.tologin;
    },
    integralInfo() {
      return this.$store.getters.integralInfo;
    },
    showSaveBtn() {
      return this.$store.getters.showSaveBtn;
    }
  },
  created() {
    this.isAddScore();
    this.$nextTick(() => {
      this.scrollHandle();

      // this.$refs["customPop-toLogin"].open()
    });
    this.watchRouter();
  },
  beforeUpdate() {},
  updated() {},
  methods: {
    async isAddScore() {
      try {
        var _res$ids, _res$ids2;
        let res = await this.$api.isAddScore();
        console.log(res, '==> res');
        console.log(res === null || res === void 0 ? void 0 : res.ids, `res?.ids?.length.join(",")===res?.ids?.length.join(",")`);
        console.log(res === null || res === void 0 || (_res$ids = res.ids) === null || _res$ids === void 0 ? void 0 : _res$ids.join(","), `res?.ids?.length.join(",")===res?.ids?.length.join(",")`);
        if (res.result && res !== null && res !== void 0 && (_res$ids2 = res.ids) !== null && _res$ids2 !== void 0 && _res$ids2.length) {
          var _res$ids3;
          console.log("==================================");
          await this.$api.putAddScore({
            ids: res === null || res === void 0 || (_res$ids3 = res.ids) === null || _res$ids3 === void 0 ? void 0 : _res$ids3.join(",")
          });
        }
      } catch (error) {}
    },
    determineSmall() {
      const smallArr = ["/course/detail", "/activity/detail", "/personal/warning", "/meetingdetail"];
      return smallArr.includes(this.$route.path);
    },
    hideSeat() {
      const hideUrlArr = ["/personal/warning"];
      return hideUrlArr.includes(this.$route.path);
    },
    async showAuthPop() {
      const words = `您暂无法查看该内容，请联系学术助手`;
      // const words = window.location.href.indexOf("ishomerecommend=true") != -1 ? "首页来的0--0您没有查看该内容权限" : "您没有查看该内容权限"

      let callUs = this.callUs || (await this.$api.getAssistantsInfo());
      this.$toatsView.show({
        tipWords: words,
        callBack: ret => {
          if (ret == "close") {
            this.$store.commit("SET_SHOWAUTHPOP", false);
            // window.location.href = document.referrer
            document.referrer ? this.$GO(-1) : this.$REDIRECT({
              path: '/'
            });
          }
        },
        showCallUs: true,
        callUs,
        userInfo: this.userInfo
      });
    },
    /* 暂时先不管  等待完善（用于修复scroll触底反复触发的问题） */
    /* touchMove(evt){
      this.pageYAfter = evt.touches[0].pageY
    },
    touchstart(evt){
      this.pageYBefore = evt.touches[0].pageY
    }, */
    scrollHandle() {
      var _this$$route$meta;
      if (!((_this$$route$meta = this.$route.meta) !== null && _this$$route$meta !== void 0 && _this$$route$meta.reachBottom)) {
        console.log(this.$route.path + "关闭了触底方法");
        return;
      }
      const self = this;
      let trajectoryArr = [];
      window.addEventListener("scroll", evt => {
        // 滚动视口高度(也就是当前元素的真实高度)
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        // 可见区域高度
        let clientHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        // 滚动条顶部到浏览器顶部高度
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        trajectoryArr.push(scrollTop);

        /* 当数组的最后一位元素大于倒数第二位时候，说明在页面在向下滚动。 反之向上 */
        const isDirectionTop = trajectoryArr[trajectoryArr.length - 1] > trajectoryArr[trajectoryArr.length - 2];
        if (clientHeight + scrollTop >= Number(scrollHeight) - 30 && isDirectionTop) {
          const myEvent = new CustomEvent("reachBottom", {
            "detail": self.$route
          });
          // const myEvent = new Event("reachBottom", {"abc": 789456123})
          window.dispatchEvent(myEvent);
          // console.log('滚动条触底了 ========================== 滚动条触底了  --> 事件派发成功', this.$route.path)
        }
      });
    },
    pageLoadNext(page, mescroll) {
      console.log(page, mescroll, '==> page, mescroll');
    },
    watchRouter() {
      const {
        meta,
        query
      } = this.$route;

      /* veeva认证页面要求在本地存储，但是当跳出【veevaupload、veeva】 后清楚对应缓存 */
      console.log(this.$route, '==> qqq ---qqq');
      const clearVeevaStorage = ["/veeva", "/veevaupload"];
      if (!clearVeevaStorage.includes(this.$route.path)) {
        localStorage.getItem("veevaInfo") && localStorage.removeItem("veevaInfo");
        localStorage.getItem("fileList") && localStorage.removeItem("fileList");
        localStorage.getItem("record") && localStorage.removeItem("record");
        localStorage.getItem("fileListTemp") && localStorage.removeItem("fileListTemp");
        localStorage.getItem("fileListProgress") && localStorage.removeItem("fileListProgress");
        localStorage.getItem("fileListError") && localStorage.removeItem("fileListError");
      }
      if (query !== null && query !== void 0 && query.typeMap) {
        /* 这部分逻辑改来改去 太乱了，暂时实现ac的要求。 typeMap 6和7  都是活动的type，进入活动三级页面后手动触发加积分 */
        /* typeMap值为8时 是问卷跳转的拦截页面，不进行请求访问 */
        if ((query === null || query === void 0 ? void 0 : query.typeMap) != '6' && (query === null || query === void 0 ? void 0 : query.typeMap) != '7' && (query === null || query === void 0 ? void 0 : query.typeMap) != '8') {
          this.$store.dispatch("setScoreLog", {
            route: this.$route
          });
        }
      }
      if (meta !== null && meta !== void 0 && meta.hideTabbar) {
        this.showTabbar = false;
      } else {
        this.showTabbar = true;
      }
      console.log(this.$route, '==///routeroute');
      if (this.$route.name == "personalRank") {
        this.showSelfSeat = true;
      } else {
        this.showSelfSeat = false;
      }
      if (this.$route.meta.hideApproval) {
        this.hideApproval = true;
      } else {
        this.hideApproval = false;
      }

      // if (meta?.tabIndex) {
      this.$store.dispatch("setTabIndex", Number(meta === null || meta === void 0 ? void 0 : meta.tabIndex));
      // }
    }
  },
  watch: {
    integralInfo() {
      if (this.integralInfo.show) {
        this.$refs["customPop-integral"].open();
        setTimeout(() => {
          this.$store.commit('SET_INTEGRAL', {
            show: false,
            integral: null
          });
        }, 1000);
        return;
      }
      this.$refs["customPop-integral"].close();
    },
    projectAuthStatus(newValue) {
      if (newValue) {
        this.showAuthPop();
        return;
      }
      this.$toatsView.hide({
        type: "clean"
      });
    },
    callUsShow() {
      const openid = localStorage.getItem("openid");
      const wechatInfo = localStorage.getItem("wechatInfo");
      const isRegister = localStorage.getItem("isRegister");
      console.log(this.callUsShow, '==> callUsShow');
      if (this.callUsShow) {
        if (!openid || !wechatInfo || !isRegister) {
          this.$registerView.show(ret => {
            if (ret.next == "from") {
              this.$store.commit("SET_CALLUSSHOW", false);
            } else {
              this.$store.commit("SET_CALLUSSHOW", false);
              this.$PUSH({
                path: `/register`,
                query: {
                  redirect: encodeURIComponent(this.$route.fullPath)
                }
              });
            }
          }, false, true);
          this.$store.commit('SET_CALLUSSHOW', false);
          return;
        }
        if (!this.userInfo.reconfirmation) {
          this.$importRegisterView.show(ret => {
            if (ret.next == "from") {
              // next(from)
            } else {
              let strUrl = `${ret.next}?redirect=${encodeURIComponent(this.$route.fullPath)}`;
              const toUrl = strUrl;
              // next(toUrl)

              this.$PUSH({
                path: toUrl
              });
            }
          }, false, true);
          this.$store.commit('SET_CALLUSSHOW', false);
          return;
        }
        this.$refs["customPop-ewm"].open();
      }
    },
    $route() {
      /* 页面切换时，关闭所有的弹窗 */
      this.$toatsView.hide({
        type: "clean"
      });
      this.$store.commit("SET_SHOWAUTHPOP", false);
      this.$store.commit('SET_CALLUSSHOW', false);
      this.$store.commit('SET_SHOWSAVEBTN', false);
      this.$refs["customPop-ewm"].close({
        type: "clean"
      });
      this.$refs["customPop-integral"].close();
      this.watchRouter();
      this.$nextTick(() => {
        this.scrollHandle();
      });
    },
    tologin() {
      if (this.tologin) {
        this.$refs["customPop-toLogin"].open();
      }
    }
  }
};