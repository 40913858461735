"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _wechat = require("utils/wechat");
var _monitor = require("utils/monitor");
var _utils = require("utils");
var _vuex = require("vuex");
var _http = _interopRequireDefault(require("utils/http"));
var _config = require("@/config");
function jie(url) {
  let a = url.split("?");
  let b = a[1].split("&");
  let v = [];
  b.forEach(im => {
    v.push(im.split("="));
  });
  return v;
}
var _default = app => {
  const mixins = {
    data() {
      return {
        $os: null,
        loading: false,
        count: 0,
        weChatShare: {
          title: "",
          desc: "",
          imgUrl: ""
        }
      };
    },
    created() {
      this.$os = (0, _utils.getSystem)();
      if (this.$route.meta.share) this.initWechat(); // 微信配置
      if (_config.monitor.open_state) this.initMonitor(); // 监测配置
      // this.getInfo() // 更新关注状态
      // this.submitInfo('/api/platform/wechat/user') // 提交用户信息
    },
    computed: {
      ...(0, _vuex.mapGetters)(['wechatInfo'])
    },
    methods: {
      async initWechat() {
        // 微信配置
        await (0, _wechat.wechatConfig)();
        // 微信分享
        const meta = this.$route.meta;
        const share = meta.share;
        if (share) {
          var _this$weChatShare, _this$weChatShare2, _this$weChatShare3, _this$weChatShare4, _this$weChatShare5, _this$weChatShare6, _this$weChatShare7, _this$weChatShare8, _this$weChatShare9, _this$weChatShare10;
          let {
            title,
            desc,
            link,
            imgUrl,
            prefix
          } = share;
          title = title || meta.title || document.title;
          if (prefix) title = `${prefix}${title}`;
          if (desc === '_DynamicTitle_') {
            desc = document.title;
          } else {
            desc = desc || `点击查看${title}`;
          }
          link = link || window.location.href; // `${this.$publicPath}${this.$route.path}`
          imgUrl = imgUrl || `${this.$publicPath}/logo.png`;
          ((_this$weChatShare = this.weChatShare) === null || _this$weChatShare === void 0 ? void 0 : _this$weChatShare.title) && (title = (_this$weChatShare2 = this.weChatShare) === null || _this$weChatShare2 === void 0 ? void 0 : _this$weChatShare2.title);
          ((_this$weChatShare3 = this.weChatShare) === null || _this$weChatShare3 === void 0 ? void 0 : _this$weChatShare3.desc) && (desc = (_this$weChatShare4 = this.weChatShare) === null || _this$weChatShare4 === void 0 ? void 0 : _this$weChatShare4.desc);
          ((_this$weChatShare5 = this.weChatShare) === null || _this$weChatShare5 === void 0 ? void 0 : _this$weChatShare5.imgUrl) && (imgUrl = (_this$weChatShare6 = this.weChatShare) === null || _this$weChatShare6 === void 0 ? void 0 : _this$weChatShare6.imgUrl);
          ((_this$weChatShare7 = this.weChatShare) === null || _this$weChatShare7 === void 0 ? void 0 : _this$weChatShare7.selfLink) && (link = (_this$weChatShare8 = this.weChatShare) === null || _this$weChatShare8 === void 0 ? void 0 : _this$weChatShare8.selfLink);
          link += link.indexOf('?') > -1 ? "&sharedata=true" : "?sharedata=true";
          ((_this$weChatShare9 = this.weChatShare) === null || _this$weChatShare9 === void 0 ? void 0 : _this$weChatShare9.contentid) && (link += "&contentid=" + ((_this$weChatShare10 = this.weChatShare) === null || _this$weChatShare10 === void 0 ? void 0 : _this$weChatShare10.contentid));
          (0, _wechat.wechatShare)({
            title,
            desc,
            link,
            imgUrl
          });
        }
      },
      initMonitor() {
        (0, _monitor.monitorView)();
      },
      getInfo() {
        // 更新用户信息，并保存在 vuex 中
        if (this.$route.meta.wechatUpdate) {} // 'doSome'
      },
      submitInfo(api) {
        if (api && this.wechatInfo && this.wechatInfo.openid) {
          (0, _http.default)({
            url: api,
            // custom: 1, // 不传 | 0 为默认拼接 url; 1 为完全自定义; 'key' 为多域名 url
            origin: 1,
            openid: 1,
            method: 'post',
            data: this.wechatInfo // 参数
          }).then(res => {
            // console.log(res)
          });
        }
      }
    }
  };
  app.mixins = app.mixins || [];
  app.mixins.push(mixins);
  return app;
};
exports.default = _default;