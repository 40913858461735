"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  props: {
    url: [String, Object]
  },
  data() {
    return {
      showImgFlag: false
    };
  },
  methods: {
    open() {
      this.showImgFlag = true;
    },
    close() {
      this.showImgFlag = false;
    }
  }
};