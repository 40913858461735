"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _modules = _interopRequireDefault(require("./modules"));
const install = Vue => {
  Vue.prototype.$api = _modules.default;
};
var _default = exports.default = {
  install
};