"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container-welcome"
  }, [_c("customPop", {
    ref: "customPop-ewm",
    attrs: {
      showType: "slot"
    },
    on: {
      cancle: _vm.ewmpopClose
    }
  }, [_c("div", {
    staticClass: "enterActivity-All"
  }, [_c("div", {
    staticClass: "enterActivityTip"
  }, [_vm._v("请扫码添加学术助手 "), _c("br"), _vm._v("咨询相关问题")]), _c("div", {
    staticClass: "ewmIcon"
  }, [_c("img", {
    attrs: {
      src: _vm.callUs.qrcode,
      alt: ""
    }
  })])])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;