"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _userInfo = _interopRequireDefault(require("@/components/personal/userInfo.vue"));
var _userLevelCard = _interopRequireDefault(require("@/components/personal/userLevelCard.vue"));
var _userEntryModule = _interopRequireDefault(require("@/components/personal/userEntryModule.vue"));
var _userAchievement = _interopRequireDefault(require("@/components/personal/userAchievement.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    userInfoBox: _userInfo.default,
    userLevelCard: _userLevelCard.default,
    userEntryModule: _userEntryModule.default,
    userAchievement: _userAchievement.default
  },
  data() {
    return {
      urlQuery: {}
    };
  },
  watch: {
    courseClassUse() {
      this.getData();
    }
  },
  filter: {},
  created() {
    this.urlQuery = this.$route.query;
    const calcIndex = this.courseClassIdArr.indexOf(Number(this.urlQuery.classId));
    this.courseClassUseType = calcIndex > -1 ? calcIndex : 0;
    this.getData();
    this.$store.dispatch("getUserInfo");

    // setTimeout(() => {
    //   this.$store.commit('SET_SHOWCLAIM', false)
    // }, 2000);
  },
  mounted() {},
  computed: {
    courseClassUse() {
      return [this.courseClassUseType, this.courseClassSecUseType];
    },
    courseClass() {
      return this.$store.getters.courseClass;
    },
    courseClassIdArr() {
      return this.$store.getters.courseClassIdArr;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    getData() {
      var _this$courseClass$thi;
      const firstClass = this.courseClass[this.courseClassUse[0]];
      const secClass = (_this$courseClass$thi = this.courseClass[this.courseClassUse[0]]) === null || _this$courseClass$thi === void 0 || (_this$courseClass$thi = _this$courseClass$thi.children) === null || _this$courseClass$thi === void 0 ? void 0 : _this$courseClass$thi[this.courseClassUse[1]];
    }
  }
});