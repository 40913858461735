"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/utils/index.js");
var _default = exports.default = {
  components: {},
  props: {
    renderModule: {
      type: String,
      default: ""
    },
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    },
    /* 
      展示形式
        words： 文字提示
        slot  插槽
    */
    showType: {
      type: String,
      default: "words"
    },
    /* 提示文字 */
    tipWords: {
      type: String,
      default: "请传入提示文字"
    },
    isNonePadding: {
      type: Boolean,
      default: false
    },
    isNoneMargin: {
      type: Boolean,
      default: false
    },
    /* 展示的数据 */
    dataBase: {
      type: Object,
      default: () => {}
    },
    /* 是否为“其它答案”的组件 */
    isAnswerComponent: {
      type: Boolean,
      default: false
    },
    /* 提交点赞他人内容使用 -- 	第几个问题 */
    question_idex: {
      type: Number,
      default: 0
    },
    preview: {
      type: Boolean,
      default: false
    },
    /* 是否为活动图文详情 */
    isRichTextDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPop: false,
      isLoadingZan: false
    };
  },
  watch: {},
  filter: {},
  created() {},
  mounted() {},
  computed: {},
  methods: {
    handleShowUserInfo() {
      var _this$dataBase, _userInfo$shows, _userInfo$shows2;
      const deCrypt = new _index.Crypt();
      const userInfo = (_this$dataBase = this.dataBase) === null || _this$dataBase === void 0 ? void 0 : _this$dataBase.member;
      let params = {
        title: deCrypt.decrypt(userInfo === null || userInfo === void 0 ? void 0 : userInfo.name),
        avatar: userInfo === null || userInfo === void 0 ? void 0 : userInfo.avatar
      };
      if ((userInfo === null || userInfo === void 0 ? void 0 : userInfo.type) == '2') {
        return params;
      }
      if (userInfo !== null && userInfo !== void 0 && (_userInfo$shows = userInfo.shows) !== null && _userInfo$shows !== void 0 && _userInfo$shows.includes("1")) {
        params["avatar"] = userInfo.avatar_virtual;
      }
      if (userInfo !== null && userInfo !== void 0 && (_userInfo$shows2 = userInfo.shows) !== null && _userInfo$shows2 !== void 0 && _userInfo$shows2.includes("2")) {
        params["title"] = userInfo.nickname;
      }
      if (!userInfo) {
        params["avatar"] = null;
        params["title"] = `用户${this.dataBase.user_id}`;
      }
      return params;
    },
    async setFormZanLog() {
      let params = {
        content_id: this.dataBase.content_id,
        type: this.dataBase.type,
        form_id: this.dataBase.form_id,
        question_idex: this.dataBase.question_idex,
        flag: this.dataBase.have_zan ? 0 : 1,
        form_question_answer_id: this.dataBase.id
      };
      let res = await this.$api.setFormZanLog(params);

      // if(!res){
      //     // alert("")
      //     console.log("操作失败")
      //     return
      // }

      // this.$emit("updateIm", this.dataBase.have_zan ? 'cancle' : 'add')

      this.dataBase.have_zan = params.flag ? 1 : 0;
      params.flag ? this.dataBase.zan++ : this.dataBase.zan--;
      this.isLoadingZan = false;

      /* 活动模块下有特殊处理 需要触发父级的加积分行为  */
      if (this.renderModule == "activity") {
        this.dataBase.have_zan && this.$parent.$parent.setScoreLog("ZAN", this.dataBase.id || 0);
      } else {
        let scoreParams = {
          route: this.$route,
          action_type: "ZAN",
          comment_id: this.dataBase.id || 0
        };
        if (this.isRichTextDetail) {
          scoreParams["content_id"] = this.$route.query.contentid;
        }
        this.dataBase.have_zan && this.$store.dispatch("setScoreLog", scoreParams);
      }
    },
    async handleZan() {
      if (this.preview) {
        return;
      }
      if (this.isLoadingZan) {
        return;
      }
      this.isLoadingZan = true;
      if (this.isAnswerComponent) {
        this.setFormZanLog();
        return;
      }
      let params = {
        comment_id: this.dataBase.id,
        type: this.dataBase.type
      };
      let res = await this.$api[this.dataBase.have_zan ? "delCommentZan" : "setCommentZan"](params);
      this.isLoadingZan = false;
      if (res !== null && res !== void 0 && res.errcode) {
        // alert("失败" + res.errmsg)
        return;
      }
      /* 活动模块下有特殊处理 需要触发父级的加积分行为  */
      if (this.renderModule == "activity") {
        !this.dataBase.have_zan && this.$parent.$parent.setScoreLog("ZAN", this.dataBase.id || 0);
      } else {
        let scoreParams = {
          route: this.$route,
          action_type: "ZAN",
          comment_id: this.dataBase.id || 0
        };
        if (this.isRichTextDetail) {
          scoreParams["content_id"] = this.$route.query.contentid;
        }
        !this.dataBase.have_zan && this.$store.dispatch("setScoreLog", scoreParams);
      }

      /* 提交成功更新数据状态 */
      this.$emit("updateIm", this.dataBase.have_zan ? 'cancle' : 'add');
    },
    open() {
      this.showPop = true;
    },
    close() {
      this.popFullClose();
    },
    maskHandle() {
      if (this.mask) {
        this.popFullClose();
      }
    },
    popFullClose() {
      this.showPop = false;
      this.$emit("cancle");
    }
  }
};