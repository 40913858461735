"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  props: {},
  data() {
    return {
      showPop: false
    };
  },
  watch: {},
  filter: {},
  created() {},
  mounted() {},
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    handleLevel() {
      var _levelMap$this$userIn;
      const levelMap = {
        0: {
          title: "",
          score: 0
        },
        1: {
          title: "初入师门",
          score: 30
        },
        2: {
          title: "载酒问字",
          score: 100
        },
        3: {
          title: "负笈游学",
          score: 300
        },
        4: {
          title: "孜孜以求",
          score: 900
        },
        5: {
          title: "手不释卷",
          score: 3000
        },
        6: {
          title: "腹载五车",
          score: 8000
        },
        7: {
          title: "坐而论道",
          score: 24000
        },
        8: {
          title: "博古通今",
          score: 48000
        },
        9: {
          title: "智周万物",
          score: 102400
        }
      };
      const calcProgress = () => {
        var _levelMap;
        /* 下一阶段积分 */
        // const nextScore = levelMap[10]?.score
        const nextScore = (_levelMap = levelMap[this.userInfo.level + 1]) === null || _levelMap === void 0 ? void 0 : _levelMap.score;
        /* 当前积分 */
        const nowScore = this.userInfo.score;

        /* 如果没有拿到最顶级的积分 说明已经满级了 */
        if (!nextScore) {
          return {
            progress: 100,
            nextLevel: null
          };
        }
        return {
          progress: parseFloat(nowScore / nextScore).toFixed(2) * 100,
          nextLevel: this.userInfo.level + 1
        };
      };
      let params = {
        title: (_levelMap$this$userIn = levelMap[this.userInfo.level]) === null || _levelMap$this$userIn === void 0 ? void 0 : _levelMap$this$userIn.title,
        /* calcProgress为计算的宽度 */
        ...calcProgress()
      };
      return params;
    }
  }
};