"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _default = exports.default = {
  components: {},
  props: {
    noBorder: {
      type: Boolean,
      default: false
    },
    refName: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    /* 是否需要在列表中搜索 */
    inputFilter: {
      type: Boolean,
      default: false
    },
    /* 回传字段 */
    field: {
      type: String,
      default: ""
    },
    /* 显示tip */
    tipWord: {
      type: String,
      default: "请传入提示文字"
    },
    /* 是否必填 */
    required: {
      type: Boolean,
      default: false
    },
    /* 
      single ： 单选
      multiple ： 多选
    */
    mode: {
      type: String,
      default: "single"
    },
    /* 传入的选项 */
    transmitData: {
      type: Array,
      default: []
    },
    /* 编辑模式 */
    isEdit: {
      type: Boolean,
      default: false
    },
    /* 编辑模式下的选中数据 要结合field字段 */
    editSelectData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      inputFilterWords: '',
      checkIdArr: [],
      checkArr: [],
      check: null,
      showSelectList: false,
      customSelectFilterListData: this.transmitData,
      customSelectFilterListDataBase: this.transmitData
    };
  },
  watch: {
    transmitData() {
      this.customSelectFilterListData = this.transmitData;
      this.customSelectFilterListDataBase = this.transmitData;
    },
    showSelectList() {
      if (this.showSelectList) {
        this.$parent.closeAllRef(this.refName);
      }
      if (!this.showSelectList && this.check != null) {
        this.$emit("checkValue", {
          field: this.field,
          check: this.customSelectFilterListData[this.check] || this.transmitData[this.check]
        });
      }
      if (this.showSelectList) {
        this.$emit("setSwiperTouch", true);
      } else {
        this.$emit("setSwiperTouch", false);
      }
    },
    inputFilterWords() {
      this.searchHospital();
    }
  },
  filter: {},
  created() {},
  mounted() {
    this.isEdit && this.formatData();
  },
  computed: {
    hospitalSelectList() {
      return this.$store.getters.hospitalSelectList;
    }
  },
  methods: {
    renderShow(km, inputFilterWords) {
      if (!this.inputFilter) return true;
      return km == inputFilterWords ? true : false;
    },
    customSelectRightArrorClick() {
      if (this.disabled) return;
      this.showSelectList = !this.showSelectList;
    },
    closePop() {
      this.showSelectList = false;
    },
    resetCheck() {
      this.$emit("checkValue", {
        field: this.field,
        check: this.inputFilterWords == '其它' && this.check == null && this.field == 'hospital' ? '其他' : this.transmitData[this.check]
      });
    },
    searchHospital() {
      let customSelectFilterTempArr = [];
      this.customSelectFilterListData = [];
      this.customSelectFilterListDataBase.forEach((im, ix) => {
        if (String(im).indexOf(this.inputFilterWords) > -1) {
          customSelectFilterTempArr.push(im);
        }
      });
      this.customSelectFilterListData = customSelectFilterTempArr.includes("其他") ? [...customSelectFilterTempArr] : [...customSelectFilterTempArr, ...this.hospitalSelectList];
      // this.customSelectFilterListData = customSelectFilterTempArr.length ? customSelectFilterTempArr : this.transmitData
    },
    /* 回显时整理数据 */
    formatData() {
      this.transmitData.forEach((im, ix) => {
        if (this.mode == "single") {
          if (im == this.editSelectData[this.field]) {
            this.check = ix;
            return;
          }
        }
        if (this.mode == "multiple") {
          if (this.editSelectData[this.field].includes(im)) {
            this.checkArr.push(im);
            this.checkIdArr.push(ix);
          }
        }
      });
      this.$emit("checkValue", {
        field: this.field,
        check: this.mode == "single" ? this.transmitData[this.check] : this.checkArr
      });
    },
    removeSelect(jm, jx) {
      this.checkIdArr.splice(jx, 1);
      this.checkArr.splice(jx, 1);
      this.$emit("checkValue", {
        field: this.field,
        check: this.checkArr
      });
    },
    checkSelect(km, kx) {
      const checkIndex = this.checkIdArr.indexOf(kx);
      if (checkIndex > -1) {
        this.checkIdArr.splice(checkIndex, 1);
        this.checkArr.splice(checkIndex, 1);
      } else {
        this.checkIdArr.push(kx);
        this.checkArr.push(km);
      }
      this.$emit("checkValue", {
        field: this.field,
        check: this.checkArr
      });
    }
  }
};