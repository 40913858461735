"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
require("vant/es/loading/style");
var _loading = _interopRequireDefault(require("vant/es/loading"));
require("vant/es/popup/style");
var _popup = _interopRequireDefault(require("vant/es/popup"));
require("vant/es/tabbar/style");
var _tabbar = _interopRequireDefault(require("vant/es/tabbar"));
require("vant/es/tabbar-item/style");
var _tabbarItem = _interopRequireDefault(require("vant/es/tabbar-item"));
require("vant/es/image/style");
var _image = _interopRequireDefault(require("vant/es/image"));
require("vant/es/icon/style");
var _icon = _interopRequireDefault(require("vant/es/icon"));
require("vant/es/form/style");
var _form = _interopRequireDefault(require("vant/es/form"));
require("vant/es/field/style");
var _field = _interopRequireDefault(require("vant/es/field"));
require("vant/es/uploader/style");
var _uploader = _interopRequireDefault(require("vant/es/uploader"));
require("vant/es/picker/style");
var _picker = _interopRequireDefault(require("vant/es/picker"));
require("vant/es/toast/style");
var _toast = _interopRequireDefault(require("vant/es/toast"));
var _vue = _interopRequireDefault(require("vue"));
// 按需全局引入 vant组件

// 默认使用

_toast.default.allowMultiple();
_toast.default.setDefaultOptions('text', {
  position: 'bottom',
  transition: 'van-fade',
  className: 'custom-toast-text'
});
_toast.default.setDefaultOptions('loading', {
  forbidClick: true,
  duration: 0,
  message: '加载中...'
});
_vue.default.use(_toast.default).use(_loading.default).use(_popup.default).use(_tabbar.default).use(_tabbarItem.default).use(_image.default).use(_icon.default).use(_form.default).use(_field.default).use(_uploader.default).use(_picker.default);

// 自行添加
// import { Search } from 'vant'
// Vue.use(Search)