"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/swipe-item/style");
var _swipeItem = _interopRequireDefault(require("vant/es/swipe-item"));
require("vant/es/swipe/style");
var _swipe = _interopRequireDefault(require("vant/es/swipe"));
var _monitor = require("@/utils/monitor");
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _list = _interopRequireDefault(require("@/components/course/list.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    vanSwipe: _swipe.default,
    vanSwipeItem: _swipeItem.default,
    courseList: _list.default
  },
  data() {
    return {
      searchWords: "",
      apiStr: "getCourseHomeLessonList",
      recommendCourseFilterType: 1,
      recommendCourseFilter: [{
        title: "精讲堂",
        type: 1
      }, {
        title: "训练营",
        type: 2
      }],
      courseCateList: [],
      bannerList: [],
      lessonList: [],
      loadFinal: false
    };
  },
  watch: {
    recommendCourseFilterType() {
      this.lessonList = [];
      if (this.recommendCourseFilterType == 1) {
        this.apiStr = "getCourseHomeLessonList";
      }
      if (this.recommendCourseFilterType == 2) {
        this.apiStr = "getCourseHomeCampainList";
      }
      (0, _monitor.monitorClick)(`${document.title}:推荐课程-${this.recommendCourseFilterType == 1 ? '精讲堂' : this.recommendCourseFilterType == 2 ? '训练营' : ''}`);
      this.getCourseHomeLessonList();
    }
  },
  filter: {},
  created() {
    this.$store.commit("SET_LOADINGPOP", true);
    this.initData();
  },
  mounted() {},
  computed: {},
  methods: {
    tosearch() {
      (0, _monitor.monitorClick)(`${document.title}:搜索-${this.searchWords}`);
      this.$PUSH({
        path: `/allcontent?searchWords=${this.searchWords}`
      });
    },
    toLink(im) {
      (0, _monitor.monitorClick)(`${document.title}:banner-${im.id}`);
      setTimeout(() => {
        window.location.href = im.url;
      }, 500);
    },
    async initData() {
      this.getBanner();
      this.getAllCate();
      this.getCourseHomeLessonList();
    },
    async getCourseHomeLessonList() {
      try {
        let res = await this.$api[this.apiStr]({
          current: 1,
          pageSize: 3
        });
        this.$store.commit("SET_LOADINGPOP", false);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCourseHomeLessonList-err" + res.errmsg)
          return;
        }
        this.lessonList = res;
        this.loadFinal = true;
      } catch (error) {}
    },
    async getBanner() {
      try {
        /* 获取全部分类 */
        let res = await this.$api.getCourseHomeBanner();
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCourseHomeBanner失败" + res.errmsg)
          return;
        }
        this.bannerList = res;
      } catch (error) {}
    },
    async getAllCate() {
      try {
        /* 获取全部分类 */
        let res = await this.$api.getCourseCate(1);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCourseCate(0)失败" + res.errmsg)
          return;
        }
        this.courseCateList = res;
      } catch (error) {}
    }
  }
});