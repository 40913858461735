"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _config = _interopRequireDefault(require("@/config"));
const state = {
  monitor: false,
  openid: '',
  wechatInfo: '',
  redirect: ''
};
const mutations = {
  DIRECTIVE_MONITOR(state) {
    state.monitor = true;
  },
  SET_WECHAT_INFO(state, info) {
    state.openid = info.openid;
    state.wechatInfo = info;
    localStorage.setItem('openid', info.openid || '');
    localStorage.setItem('wechatInfo', JSON.stringify(info) || '');
    _vue.default.$cookies.set('openid', info.openid || '', _config.default.cookie.expires, _config.default.deploy.path);
    _vue.default.$cookies.set('wechatInfo', JSON.stringify(info) || '', _config.default.cookie.expires, _config.default.deploy.path);
  },
  CLEAR_WECHAT_INFO(state) {
    state.openid = '';
    state.wechatInfo = '';
    localStorage.removeItem('isRegister');
    localStorage.removeItem('openid');
    localStorage.removeItem('wechatInfo');
    _vue.default.$cookies.set('openid', '');
    _vue.default.$cookies.set('wechatInfo', '');
  },
  SET_REDIRECT(state, url) {
    state.redirect = url;
    _vue.default.$cookies.set('redirect', url);
  },
  REMOVE_REDIRECT(state) {
    state.redirect = '';
    _vue.default.$cookies.set('redirect', '');
  }
};
const actions = {
  directiveMonitor({
    commit
  }) {
    commit('DIRECTIVE_MONITOR');
  },
  setWechatInfo({
    commit
  }, info) {
    commit('SET_WECHAT_INFO', info);
  },
  clearWechatInfo({
    commit
  }) {
    commit('CLEAR_WECHAT_INFO');
  },
  setRedirect({
    commit
  }, url) {
    commit('SET_REDIRECT', url);
  },
  removeRedirect({
    commit
  }) {
    commit('REMOVE_REDIRECT');
  }
};
var _default = exports.default = {
  state,
  mutations,
  actions
};