"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$detailInfo, _vm$detailInfo$videos;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("stickyHeader"), (_vm$detailInfo = _vm.detailInfo) !== null && _vm$detailInfo !== void 0 && _vm$detailInfo.id ? _c("div", {
    staticClass: "detailPosterOut"
  }, [_c("meetingPoster", {
    attrs: {
      dataBase: _vm.detailInfo,
      posterType: 4,
      tagList: []
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "detailPosterCreateBtn flex align-center",
    on: {
      click: _vm.createPoster
    }
  }, [_c("img", {
    attrs: {
      src: require("img/common/shareWhite-meeting.png"),
      alt: ""
    }
  }), _vm._v(" 生成海报分享")]), _c("div", {
    staticClass: "detailHeaderCover"
  }, [_c("customVideo", {
    attrs: {
      src: (_vm$detailInfo$videos = _vm.detailInfo.videos[_vm.detailInfoVideos]) === null || _vm$detailInfo$videos === void 0 ? void 0 : _vm$detailInfo$videos.url
    }
  })], 1), _c("div", {
    staticClass: "showEntry flex align-center"
  }, [_c("div", {
    staticClass: "showEntryModule flex align-center justify-center",
    class: !_vm.showModule ? "showEntryModuleCheck" : "",
    on: {
      click: function ($event) {
        _vm.showModule = 0;
      }
    }
  }, [_vm._v("会议简介")]), _c("div", {
    staticClass: "showEntryModule flex align-center justify-center",
    class: _vm.showModule ? "showEntryModuleCheck" : "",
    on: {
      click: function ($event) {
        _vm.showModule = 1;
      }
    }
  }, [_vm._v("回放目录")])]), !_vm.showModule ? _c("div", {
    staticClass: "detailHeader"
  }, [_c("meetingIntroduce", {
    attrs: {
      dataBase: _vm.detailInfo
    },
    on: {
      setIsFaver: _vm.setFaver,
      setIsZan: param => {
        _vm.detailInfo.zan = param ? true : false;
        param ? _vm.detailInfo.zan++ : _vm.detailInfo.zan--;
      }
    }
  }), _c("div", {
    staticClass: "detailHeaderOther"
  }), _c("div", {
    staticClass: "detailIntroduce"
  }, [_c("div", {
    staticClass: "detailIntroduceTitle"
  }, [_vm._v("介绍")]), _c("div", {
    staticClass: "detailIntroduceContent ql-editor",
    domProps: {
      innerHTML: _vm._s(_vm.$VIDEOPLAYSINLINE(_vm.detailInfo.content))
    },
    on: {
      click: _vm.$imageEnlargement
    }
  })])], 1) : _vm._e(), _vm.showModule ? _c("div", {
    staticStyle: {
      background: "#f7f7f7",
      overflow: "hidden"
    }
  }, [_c("meetingVideo", {
    attrs: {
      dataBase: _vm.detailInfo.videos,
      curtype: _vm.detailInfo.type
    },
    on: {
      dataIndex: evt => _vm.detailInfoVideos = evt
    }
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;