"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _qrCode = _interopRequireDefault(require("./components/qrCode.vue"));
var _qrcodeNew = _interopRequireDefault(require("./components/qrcodeNew.vue"));
var _supple = _interopRequireDefault(require("./components/supple.vue"));
var _addSupple = _interopRequireDefault(require("./components/addSupple.vue"));
var _index = require("@/utils/index.js");
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    VeevaCode: _qrCode.default,
    VeevaCodeNew: _qrcodeNew.default,
    VeevaSupple: _supple.default,
    VeevaAddSupple: _addSupple.default
  },
  data() {
    return {
      isReachBottom: false,
      messageList: [],
      current: 1
    };
  },
  watch: {},
  filter: {},
  created() {
    this.getData();
    // this.initReachBottom()
  },
  mounted() {},
  computed: {},
  methods: {
    async getData() {
      let params = {
        current: this.current,
        pageSize: 9999999
      };
      let res = await this.$api.getMessagecneter(params);
      this.isReachBottom = false;
      const finalData = (0, _index.limitData)({
        interfaceData: res,
        baseData: this.messageList,
        limit: params
      });
      console.log(finalData, '==> 00-- finalData');
      this.messageList = finalData.baseData;
      finalData.currentAdd && this.current++;
      setTimeout(() => {
        window.scrollTo({
          top: 99999999
        });
      }, 0);
    },
    /* 分页 start */
    initReachBottom() {
      window.addEventListener("reachBottom", this.reachBottomHandle);
    },
    reachBottomHandle(evt) {
      const self = this;
      if (evt.detail.path != "/notify") {
        return;
      }
      if (self.isReachBottom) {
        return;
      }
      self.isReachBottom = true;
      this.getData();
    }
    /* 分页 end */
  }
});