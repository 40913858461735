"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$sectionDetail, _vm$contentDetail, _vm$contentDetail2, _vm$contentDetail3, _vm$contentDetail4, _vm$contentDetail5, _vm$sectionDetail2, _vm$sectionDetail3, _vm$formEchoInfo, _vm$formEchoInfo2, _vm$sectionDetail4;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container activityRichDetail"
  }, [_c("stickyHeader"), !_vm.hideAll && (_vm$sectionDetail = _vm.sectionDetail) !== null && _vm$sectionDetail !== void 0 && _vm$sectionDetail.id && (_vm$contentDetail = _vm.contentDetail) !== null && _vm$contentDetail !== void 0 && _vm$contentDetail.id ? _c("div", {
    staticClass: "handleList flex flex-direction justify-between"
  }, [_c("div", {
    staticClass: "handleListItem flex align-center justify-center",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.myShare.apply(null, arguments);
      }
    }
  }, [_c("img", {
    staticClass: "handleListItemShareIcon",
    attrs: {
      src: require("img/common/shareWhite.png"),
      alt: ""
    }
  }), _vm._v(" 分享 ")]), _c("div", {
    staticClass: "handleListItem flex align-center justify-center",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.faverHandle.apply(null, arguments);
      }
    }
  }, [!_vm.myIsFaver ? _c("img", {
    staticClass: "handleListItemStarIcon",
    attrs: {
      src: require("img/common/starWhite.png"),
      alt: ""
    }
  }) : _c("img", {
    staticClass: "handleListItemStarIcon",
    attrs: {
      src: require("img/common/starWhiteFill.png"),
      alt: ""
    }
  }), _vm._v(" " + _vm._s(_vm.sectionDetail.faver || 0) + " ")]), _c("div", {
    staticClass: "handleListItem flex align-center justify-center",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.zanHandle.apply(null, arguments);
      }
    }
  }, [!_vm.myIsZan ? _c("img", {
    staticClass: "handleListItemGiveIcon",
    attrs: {
      src: require("img/common/giveWhite.png"),
      alt: ""
    }
  }) : _c("img", {
    staticClass: "handleListItemGiveIcon",
    attrs: {
      src: require("img/common/giveWhiteFill.png"),
      alt: ""
    }
  }), _vm._v(" " + _vm._s(_vm.sectionDetail.zan || 0) + " ")])]) : _vm._e(), (_vm$contentDetail2 = _vm.contentDetail) !== null && _vm$contentDetail2 !== void 0 && _vm$contentDetail2.id && !_vm.hideAll ? _c("div", {
    staticClass: "detailTopOut"
  }, [(_vm$contentDetail3 = _vm.contentDetail) !== null && _vm$contentDetail3 !== void 0 && _vm$contentDetail3.id ? _c("div", {
    staticClass: "detailTitle"
  }, [_vm._v(_vm._s(_vm.contentDetail.name))]) : _vm._e(), _c("div", {
    staticClass: "detailAuthor"
  }, [(_vm$contentDetail4 = _vm.contentDetail) !== null && _vm$contentDetail4 !== void 0 && _vm$contentDetail4.need_veeva ? _c("div", {
    staticClass: "authIcon"
  }, [_c("img", {
    attrs: {
      alt: "",
      srcset: "",
      src: require("img/common/authIcon.png")
    }
  })]) : _vm._e(), _vm.contentDetail.speaker ? _c("div", [_vm._v("讲者：" + _vm._s(_vm.contentDetail.speaker))]) : _vm._e()]), _vm.contentDetail.hospital ? _c("div", {
    staticClass: "detailAuthor"
  }, [_vm._v("讲者所在医院：" + _vm._s(_vm.contentDetail.hospital))]) : _vm._e(), _c("div", {
    staticClass: "detailHeaderTag flex flex-wrap"
  }, [(_vm$contentDetail5 = _vm.contentDetail) !== null && _vm$contentDetail5 !== void 0 && _vm$contentDetail5.need_veeva ? _c("div", {
    ref: "courseTagInnerItem",
    staticClass: "detailHeaderTagItem cursmallCourseTagItem"
  }, [_vm._v("认证专享")]) : _vm._e(), _vm._l(_vm.contentDetail.tag_name_list, function (im, ix) {
    return _c("div", {
      key: ix,
      staticClass: "detailHeaderTagItem"
    }, [_vm._v(_vm._s(im))]);
  })], 2), _vm.contentDetail.content_type + "" == "1" && _vm.contentDetail.video_url ? _c("div", {
    staticClass: "detailVideo fixedScale"
  }, [_c("customVideo", {
    attrs: {
      src: _vm.contentDetail.video_url
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "detailRich lh1-5 ql-editor",
    domProps: {
      innerHTML: _vm._s(_vm.$VIDEOPLAYSINLINE(_vm.contentDetail.content) || "")
    },
    on: {
      click: _vm.$imageEnlargement
    }
  }), _vm.contentDetail.have_form || _vm.contentDetail.have_comments ? _c("div", {
    staticClass: "detailModuleCheck flex"
  }, [_vm.contentDetail.have_form ? _c("div", {
    staticClass: "detailModuleCheckItem justify-center align-center flex",
    class: [!_vm.showModuleCheck ? "detailModuleCheckItemCheck" : "", _vm.contentDetail.have_form && !_vm.contentDetail.have_comments && "onlyOne"],
    on: {
      click: function ($event) {
        _vm.showModuleCheck = 0;
      }
    }
  }, [_vm._v(" " + _vm._s(((_vm$sectionDetail2 = _vm.sectionDetail) === null || _vm$sectionDetail2 === void 0 || (_vm$sectionDetail2 = _vm$sectionDetail2.menu) === null || _vm$sectionDetail2 === void 0 ? void 0 : _vm$sectionDetail2[0].interact) || "互动") + " ")]) : _vm._e(), _vm.contentDetail.have_form && _vm.contentDetail.have_comments ? _c("div", {
    staticClass: "detailModuleCheckItemLine"
  }) : _vm._e(), _vm.contentDetail.have_comments ? _c("div", {
    staticClass: "detailModuleCheckItem justify-center align-center flex",
    class: [_vm.showModuleCheck ? "detailModuleCheckItemCheck" : "", !_vm.contentDetail.have_form && _vm.contentDetail.have_comments && "onlyOne"],
    on: {
      click: function ($event) {
        _vm.showModuleCheck = 1;
      }
    }
  }, [_vm._v(" " + _vm._s(((_vm$sectionDetail3 = _vm.sectionDetail) === null || _vm$sectionDetail3 === void 0 || (_vm$sectionDetail3 = _vm$sectionDetail3.menu) === null || _vm$sectionDetail3 === void 0 ? void 0 : _vm$sectionDetail3[0].comment) || "评论") + " ")]) : _vm._e()]) : _vm._e()]) : _vm._e(), !_vm.showModuleCheck && _vm.contentDetail.have_form && !((_vm$formEchoInfo = _vm.formEchoInfo) !== null && _vm$formEchoInfo !== void 0 && _vm$formEchoInfo.id) && !_vm.hideAll ? _c("interaction", {
    attrs: {
      formInfo: _vm.detailForm
    }
  }) : _vm._e(), !_vm.showModuleCheck && _vm.contentDetail.have_form && (_vm$formEchoInfo2 = _vm.formEchoInfo) !== null && _vm$formEchoInfo2 !== void 0 && _vm$formEchoInfo2.id && !_vm.hideAll ? _c("interactionAnalysis", {
    attrs: {
      dataBase: _vm.formEchoInfo
    }
  }) : _vm._e(), _vm.showModuleCheck == 1 && _vm.contentDetail.have_comments && !_vm.hideAll ? _c("comment", {
    ref: "mycomment",
    attrs: {
      isRichTextDetail: true
    }
  }) : _vm._e(), _c("ConfirmDialog", {
    ref: "confirmDialog",
    attrs: {
      backgroundStyle: true
    }
  }), (_vm$sectionDetail4 = _vm.sectionDetail) !== null && _vm$sectionDetail4 !== void 0 && _vm$sectionDetail4.id ? _c("meetingPoster", {
    attrs: {
      pathname: "/bayer-rad-academy-shadowcloud-academy-html/activity/richlist",
      dataBase: _vm.sectionDetail,
      posterType: 7,
      tagList: _vm.sectionDetail.tag_name_list
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;