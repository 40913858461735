"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/utils/index.js");
var _listItem = _interopRequireDefault(require("@/components/activity/listItem.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _monitor = require("@/utils/monitor");
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    listItem: _listItem.default
  },
  data() {
    return {
      noThenData: false,
      current: 1,
      pageSize: 10,
      activityList: [],
      searchWords: "",
      loadFinal: false,
      isReachBottom: false
    };
  },
  watch: {},
  filter: {},
  created() {
    this.initData();
    this.initReachBottom();
  },
  destroyed() {
    window.removeEventListener("reachBottom", this.reachBottomHandle);
  },
  mounted() {},
  computed: {},
  methods: {
    reachBottomHandle(evt) {
      const self = this;
      if (evt.detail.path != "/activity") {
        return;
      }
      if (self.isReachBottom) {
        return;
      }
      self.isReachBottom = true;
      self.initData();
    },
    initReachBottom() {
      window.addEventListener("reachBottom", this.reachBottomHandle);
    },
    resetData() {
      this.current = 1;
      this.activityList = [];
      let moniStr = `${document.title}:筛选-`;
      this.searchWords && (moniStr += `搜索-${this.searchWords}`);
      (0, _monitor.monitorClick)(moniStr);
      this.initData();
    },
    async initData() {
      this.loadFinal = false;
      try {
        let params = {
          current: this.current,
          pageSize: this.pageSize
        };
        this.searchWords && (params["filter[name]"] = this.searchWords);
        let res = await this.$api.getCampainList(params);
        this.loadFinal = true;
        this.isReachBottom = false;
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCampainList" + res.errmsg)
          return;
        }
        const finalData = (0, _index.limitData)({
          interfaceData: res,
          baseData: this.activityList,
          limit: params
        });
        finalData.currentAdd && this.current++ && (this.noThenData = false);
        !finalData.currentAdd && (this.noThenData = true);
        this.activityList = finalData.baseData;
      } catch (error) {}
    }
  }
});