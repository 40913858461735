"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/toast/style");
var _toast = _interopRequireDefault(require("vant/es/toast"));
var _index = _interopRequireDefault(require("@/components/customSelect/index.vue"));
var _index2 = require("@/utils/index.js");
var _default = exports.default = {
  components: {
    customSelect: _index.default
  },
  props: {
    step: {
      type: Number,
      default: 1
    },
    dataBase: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selecthospitalList: [],
      hospitalTip: '请输入医院关键字进行搜索',
      errorTipWords: "",
      formDataRule: {
        name: [{
          required: true,
          message: '请填写姓名'
        }]
        /* terms: [
          {
            required: true,
            message: "请确认勾选隐私声明 及使用条款"
          }
        ] */
      },
      formData: {},
      hospitalOther: '',
      departmentOther: '',
      titleOther: '',
      positionOther: '',
      showSelect: false,
      hosSetCount: 0
    };
  },
  watch: {
    '$store.getters.userInfo': {
      handler(newValue) {
        var _this$formData;
        if (!((_this$formData = this.formData) !== null && _this$formData !== void 0 && _this$formData.status)) {
          const deCrypt = new _index2.Crypt();
          newValue.id && this.initformData({
            ...this.userInfo,
            name: deCrypt.decrypt(this.userInfo['name'])
          });
        }
      },
      deep: true
    },
    'formData.name'(newVal, oldVal) {
      const reg = /[^\a-\z\A-\Z\u4E00-\u9FA5]/;
      const hasSym = reg.test(this.formData.name);
      if (hasSym) {
        this.formData.name = oldVal;
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    provinceSelectList() {
      return this.$store.getters.provinceSelectList;
    },
    hospitalSelectList() {
      return this.$store.getters.hospitalSelectList;
    },
    departmentSelectList() {
      return this.$store.getters.departmentSelectList;
    },
    positionSelectList() {
      return this.$store.getters.positionSelectList;
    },
    titleSelectList() {
      return this.$store.getters.titleSelectList;
    }
  },
  methods: {
    setSwiperTouch(evt) {
      this.$emit('setSwiperTouch', evt);
    },
    async initformData(evt) {
      console.log(evt, '-> evtt --initformData--');
      this.formData = {
        ...evt
      };

      // console.log(this.formData, '=-=> this.formData--');

      // return
      await this.getHospital();
      await this.setHospital();
      await this.setOtherFiled(this.titleSelectList, 'title', 'titleOther');
      await this.setOtherFiled(this.positionSelectList, 'position', 'positionOther');
      await this.setOtherFiled(this.departmentSelectList, 'department', 'departmentOther');
      this.showSelect = true;
      this.$store.commit('SET_LOADINGPOP', false);
    },
    setOtherFiled(dataBase, key, otherKey) {
      return new Promise((resolve, reject) => {
        let isMate = false;
        dataBase.forEach(im => {
          if (im == this.formData[key]) {
            isMate = true;
          }
        });
        if (!isMate) {
          this[otherKey] = this.formData[key];
          this.formData[key] = key == 'department' ? '其他科室' : '其他';
        }
        setTimeout(() => {
          resolve();
        }, 500);
      });
    },
    testName() {
      var _this$formData$name, _this$formData$name2;
      /* 需求  注册姓名
          ● 名字必须包含中文填写（不用全中文）
          ● 字数在一个字以上
          ● 包含“先生、女士”这俩关键字无法提交成功
        */
      const newReg = /[\u4e00-\u9fa5]+/g;
      const tempStr = this.formData.name;
      const isHasMr = (_this$formData$name = this.formData.name) === null || _this$formData$name === void 0 ? void 0 : _this$formData$name.indexOf('先生');
      const isHasMIss = (_this$formData$name2 = this.formData.name) === null || _this$formData$name2 === void 0 ? void 0 : _this$formData$name2.indexOf('女士');
      const rgeStr = tempStr === null || tempStr === void 0 ? void 0 : tempStr.match(newReg);
      if (!(rgeStr !== null && rgeStr !== void 0 && rgeStr.length) || (tempStr === null || tempStr === void 0 ? void 0 : tempStr.length) < 2 || isHasMr != -1 || isHasMIss != -1) {
        return false;
      }
      return true;
    },
    testValue() {
      for (let key in this.formDataRule) {
        this.formDataRule[key].forEach((im, ix) => {
          var _this$formData$key;
          if (im.required && (!this.formData[key] || this.formData[key] instanceof String && !((_this$formData$key = this.formData[key]) !== null && _this$formData$key !== void 0 && _this$formData$key.trim()))) {
            this.errorTipWords = im.message;
            this.$refs[`customPop-error`].open();
            throw im.message;
          }
          if (im.verify) {
            im.verifyArr.forEach((km, kx) => {
              if (!km(this.formData[key])) {
                this.errorTipWords = im.verifyArrMsg[kx];
                this.$refs[`customPop-error`].open();
                throw km.message;
              }
            });
          }
        });
      }
      return true;
    },
    customPopCancle() {},
    customPopConfirm() {},
    async nextStep() {
      console.log(this.formData, '==>formData --');
      /* return
              this.$PUSH({
                path: "/veevaupload"
            })
              return */
      if (this.testValue()) {
        if (!this.testName()) {
          (0, _toast.default)(`请输入真实中文姓名，您可在个人中心设置站内昵称`);
          // this.errorTipWords = `请输入真实中文姓名，您可在个人中心设置站内昵称`
          // // this.errorTipWords = `【${this.formData.name}】请输入真实中文姓名，您可在个人中心设置站内昵称`
          // this.$refs[`customPop-error`].open()
          return;
        }
      }
      if (this.formData.status == 1) {
        localStorage.setItem('record', true);
        this.$PUSH({
          path: '/veevaupload'
        });
        return;
      }
      const tempParams = {
        ...this.formData
      };
      if (!tempParams.hospital && !tempParams.hospital_other) {
        this.$toatsView.show({
          tipWords: '请选择医院'
        });
        setTimeout(() => {
          this.$toatsView.hide();
        }, 1500);
        return;
      }
      if (tempParams.hospital == '其他') {
        if (!this.hospitalOther) {
          this.$toatsView.show({
            tipWords: '请输入医院名称'
          });
          setTimeout(() => {
            this.$toatsView.hide();
          }, 1500);
          return;
        }
        tempParams.hospital = this.hospitalOther;
      }
      if (tempParams.position == '其他') {
        if (!this.positionOther) {
          this.$toatsView.show({
            tipWords: '请输入职务'
          });
          setTimeout(() => {
            this.$toatsView.hide();
          }, 1500);
          return;
        }
        tempParams.position = this.positionOther;
      }
      if (tempParams.title == '其他') {
        if (!this.titleOther) {
          this.$toatsView.show({
            tipWords: '请输入职称'
          });
          setTimeout(() => {
            this.$toatsView.hide();
          }, 1500);
          return;
        }
        tempParams.title = this.titleOther;
      }
      if (tempParams.department == '其他科室') {
        if (!this.departmentOther) {
          this.$toatsView.show({
            tipWords: '请输入科室'
          });
          setTimeout(() => {
            this.$toatsView.hide();
          }, 1500);
          return;
        }
        tempParams.department = this.departmentOther;
      }
      console.log(tempParams, '==>tempParams --');
      localStorage.setItem('veevaInfo', JSON.stringify(tempParams));
      localStorage.setItem('record', true);
      this.$PUSH({
        path: '/veevaupload'
      });

      // tempParams.other_imgs = JSON.stringify(tempParams.other_imgs)

      // let res = await this.$api.PutVeevaInfo(tempParams)
      // console.log(res, ' ==> ss');
    },
    closeAllRef(refName) {
      const allRef = this.$refs;
      for (const key in allRef) {
        if (key != refName) {
          if (this.$refs[key] && typeof this.$refs[key].closePop === 'function') {
            this.$refs[key].closePop();
          }
        }
      }
    },
    async getHospital() {
      let res = await this.$api.getHospital({
        province: this.formData.province
      });
      this.selecthospitalList = [...res, ...this.hospitalSelectList];
    },
    setHospital() {
      let hospitalCount = 0;
      return new Promise((resolve, reject) => {
        for (const key in this.selecthospitalList) {
          hospitalCount++;
          if (this.formData.hospital != '其他' && this.formData.hospital == this.selecthospitalList[key]) {
            resolve();
            return;
          }
        }
        if (hospitalCount == this.selecthospitalList.length) {
          this.hospitalOther = this.formData.hospital_other || this.formData.hospital;
          setTimeout(() => {
            this.formData.hospital = `其他`;
          }, 100);
        }
        resolve();
      });
    },
    async checkValue(params) {
      console.log(params, '==>params -- ');
      this.$set(this.formData, params.field, params.check);
      if (params.field == 'hospital') {
        this.$refs.hospitalComponent.inputFilterWords = params.check;
        this.hosSetCount++;
      }
      if (params.field == 'province') {
        this.hospitalTip = '正在获取医院数据...';
        this.selecthospitalList = [];
        if (this.hosSetCount > 0) {
          this.$refs.hospitalComponent.check = null;
          this.$refs.hospitalComponent.inputFilterWords = '';
          this.$refs.hospitalComponent.resetCheck();
        }
        let res = await this.$api.getHospital({
          province: this.formData.province
        });
        this.hospitalTip = '请输入医院关键字进行搜索';

        // this.selecthospitalList = [...this.hospitalSelectList, ...res]
        this.selecthospitalList = [...res, ...this.hospitalSelectList];
      }
      setTimeout(() => {
        this.$parent.mySwiper3.update();
      }, 100);
      this.$forceUpdate();
    }
  }
};