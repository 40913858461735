"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.string.replace-all.js");
var _commentAreaListItem = _interopRequireDefault(require("./commentAreaListItem.vue"));
var _vue = _interopRequireDefault(require("vue"));
var _default = exports.default = {
  components: {
    commentAreaListItem: _commentAreaListItem.default
  },
  props: {
    activeHideAnswerBlock: {
      type: Boolean,
      default: true
    },
    preview: {
      type: Boolean,
      default: false
    },
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    },
    /* 
      展示形式
        words： 文字提示
        slot  插槽
    */
    showType: {
      type: String,
      default: "words"
    },
    /* 提示文字 */
    tipWords: {
      type: String,
      default: "请传入提示文字"
    },
    /* 数据 */
    dataBase: {
      type: Object,
      default: () => {}
    },
    /* 针对活动回到顶部的标识 */
    isActivity: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showScore: false,
      myScore: 0,
      errorTipWords: null,
      letterArr: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'],
      showPop: false,
      dataBaseOtherComments: [],
      allScore: 0
    };
  },
  watch: {},
  filter: {},
  created() {
    /* 整理数据 */
    this.formatData();
  },
  mounted() {
    const isSubmitFinish = localStorage.getItem("isSubmitFinish");
    if (isSubmitFinish) {
      window.interactionView = this.$refs.interactionView;
      this.$nextTick(() => {
        window.scrollTo(0, this.isActivity ? 0 : Number(this.$refs.interactionView.offsetTop) - 130);
      });
      localStorage.setItem("isSubmitFinish", "");
    }
  },
  computed: {},
  methods: {
    showTip(im) {
      let words = ``;
      if (im.question_type == 1 || im.question_type == 3) {
        words = "";
        /* im.answer_type+'' == '1' ? "单选题" : im.answer_type+'' == '2' ? "多选题" */
        if (im.answer_type + '' == '1') {
          words += "单选题";
        }
        if (im.answer_type + '' == '2') {
          words += "多选题";
        }
        if (im.question_type + '' == "1" && (im.answer_type + '' == '2' || im.answer_type + '' == '1')) {
          words += `，${im.score}分`;
        }
      } else {
        words = "问答题";
      }
      words += !im.is_required || im.is_required == 1 ? "，必填" : "，非必填";
      return words;
    },
    questionTip(im) {
      var _im$answer, _im$answer_check;
      let correctAnsStr = [];
      let selfAnsStr = [];
      im === null || im === void 0 || (_im$answer = im.answer) === null || _im$answer === void 0 || _im$answer.forEach((im, ix) => {
        (im.is_right == "true" || im.is_right == "1") && correctAnsStr.push(this.toUC(ix));
      });
      (_im$answer_check = im.answer_check) === null || _im$answer_check === void 0 || _im$answer_check.forEach((im, ix) => {
        selfAnsStr.push(this.toUC(Number(im)));
      });
      return `正确答案：${correctAnsStr.join("")}，你的答案：${selfAnsStr.join("")}`;
    },
    resetGetOtherComment(im, ix) {
      im.sort_by = !im.sort_by;
      this.getOtherComments(im, ix);
    },
    async getOtherComments(im, ix) {
      let params = {
        content_id: this.dataBase.content_id,
        type: this.dataBase.type,
        question_idex: ix,
        form_question_answer_id: this.dataBase.id,
        sort_by: im.sort_by ? 1 : 0
      };
      let res = await this.$api.getOtherComments(params);
      im.other_comments = res;
    },
    formatData() {
      var _this$dataBase;
      /* 
          question_type :
              {
                  label: '互动-选择题',
                  value: 1,
              },
              {
                  label: '互动-问答题',
                  value: 2,
                  有解析
              },
              {
                  label: '问卷-选择',
                  value: 3,
                  展示多少人选择
              },
              {
                  label: '问卷-问答题',
                  value: 4,
                  展示其他人回答，可点赞
              },
                    answer_type： {
                  1： 单选
                  2： 多选
              }
      */

      this.allScore = 0;
      (_this$dataBase = this.dataBase) === null || _this$dataBase === void 0 || (_this$dataBase = _this$dataBase.answer) === null || _this$dataBase === void 0 || _this$dataBase.forEach((im, ix) => {
        var _im$problem_analysis;
        this.allScore += Number(im.score) || 0;

        /* 创建一个对象，用于去接口请求他人答案 */
        if (im.question_type == '4') {
          im.other_comments = [];
          /* 1 按热度 0 按创建时间 */
          im.sort_by = false;
          this.getOtherComments(im, ix);
        }

        /* 单选 */
        if (im.question_type == '1' && im.answer_type == '1') {
          var _im$answer2;
          this.showScore = true;
          im === null || im === void 0 || (_im$answer2 = im.answer) === null || _im$answer2 === void 0 || _im$answer2.forEach((jm, jx) => {
            var _im$answer_check2;
            if (jm.is_right == "true" || jm.is_right == "1") {
              jm.optionCheck = "success";
            }
            if ((_im$answer_check2 = im.answer_check) !== null && _im$answer_check2 !== void 0 && _im$answer_check2.includes(jx + '')) {
              if (jm.is_right == "true" || jm.is_right == "1") {
                jm.selectCheck = "success";
                im.getScore = Number(im.score);
                this.myScore += Number(im.score) || 0;
              } else {
                jm.selectCheck = "error";
                im.getScore = 0;
              }
            }

            /* if (jm.is_right == "true" || jm.is_right == "1") {
                if (im.answer_check?.includes(jx + '')) {
                    jm.selectCheck = "success"
                    im.getScore = Number(im.score)
                      this.myScore += Number(im.score)
                } else {
                    jm.selectCheck = "error"
                    im.getScore = 0
                }
            } */
          });
        }

        /* 多选 */
        if (im.question_type == '1' && im.answer_type == '2') {
          var _im$answer3;
          this.showScore = true;
          let ansErrorArr = [];
          im === null || im === void 0 || (_im$answer3 = im.answer) === null || _im$answer3 === void 0 || _im$answer3.forEach((jm, jx) => {
            if (jm.is_right == "true" || jm.is_right == "1") {
              var _im$answer_check3;
              if ((_im$answer_check3 = im.answer_check) !== null && _im$answer_check3 !== void 0 && _im$answer_check3.includes(jx + '')) {
                jm.selectCheck = "success";
                !ansErrorArr.length && (im.getScore = im.score);
              } else {
                if (jm.is_right == "true" || jm.is_right == "1") {
                  jm.optionCheck = "success";
                }
                // jm.selectCheck = "error"
                ansErrorArr.push(im);
                im.getScore = 0;
              }
            } else {
              var _im$answer_check4;
              if ((_im$answer_check4 = im.answer_check) !== null && _im$answer_check4 !== void 0 && _im$answer_check4.includes(jx + '')) {
                ansErrorArr.push(im);
                jm.selectCheck = "error";
                im.getScore = 0;
              }
            }
          });
          this.myScore += Number(im.getScore) || 0;
        }
        if (im.question_type == '3' && im.answer_type == '2') {
          var _im$answer4;
          im === null || im === void 0 || (_im$answer4 = im.answer) === null || _im$answer4 === void 0 || _im$answer4.forEach((jm, jx) => {
            var _im$answer_check5;
            if ((_im$answer_check5 = im.answer_check) !== null && _im$answer_check5 !== void 0 && _im$answer_check5.includes(jx + '')) {
              console.log("123333");
              jm.selectCheck = "success";
            }
          });
        }
        if (im.question_type == '3' && im.answer_type == '1') {
          var _im$answer5;
          im === null || im === void 0 || (_im$answer5 = im.answer) === null || _im$answer5 === void 0 || _im$answer5.forEach((jm, jx) => {
            var _im$answer_check6;
            if ((_im$answer_check6 = im.answer_check) !== null && _im$answer_check6 !== void 0 && _im$answer_check6.includes(jx + '')) {
              jm.selectCheck = "success";
            }
          });
        }
        im.problem_analysis = (_im$problem_analysis = im.problem_analysis) === null || _im$problem_analysis === void 0 ? void 0 : _im$problem_analysis.replaceAll('<img', '<img style="max-width:100%;"');
        // im.problem_analysis = im.problem_analysis?.replaceAll('<strong', '<strong style="font-weight: bold;"')
      });
    },
    open() {
      this.showPop = true;
    },
    close() {
      this.popFullClose();
    },
    maskHandle() {
      if (this.mask) {
        this.popFullClose();
      }
    },
    popFullClose() {
      this.showPop = false;
      this.$emit("cancle");
    },
    toUC(kx) {
      return this.letterArr[kx].toUpperCase();
    },
    interactionListAnswerLineCheckCurr(im, km, kx) {
      var _im$answer_check7;
      return (im === null || im === void 0 || (_im$answer_check7 = im.answer_check) === null || _im$answer_check7 === void 0 ? void 0 : _im$answer_check7.indexOf(kx)) > -1 ? 'interactionListAnswerLineCheckCurr' : '';
    }
  }
};