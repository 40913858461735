"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.string.replace-all.js");
require("vant/es/image-preview/style");
var _imagePreview = _interopRequireDefault(require("vant/es/image-preview"));
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _meetingPoster = _interopRequireDefault(require("@/components/meeting/meetingPoster.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _customVideo = _interopRequireDefault(require("@/components/common/customVideo.vue"));
var _interaction = _interopRequireDefault(require("@/components/course/interaction.vue"));
var _interactionAnalysis = _interopRequireDefault(require("@/components/course/interactionAnalysis.vue"));
var _comment = _interopRequireDefault(require("@/components/course/comment.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    customVideo: _customVideo.default,
    interaction: _interaction.default,
    comment: _comment.default,
    interactionAnalysis: _interactionAnalysis.default,
    meetingPoster: _meetingPoster.default
  },
  data() {
    return {
      need_veeva: '',
      showModuleCheck: 0,
      contentDetail: {},
      detailForm: {},
      tipWords: '',
      isReachBottom: false,
      isZan: false,
      isFaver: false,
      formEchoInfo: {},
      capUrl: '',
      loadZan: false,
      loadFaver: false,
      isVideoType: false,
      setScoreTimer: null,
      onlySessionNum: this.$GetSessionNum(),
      setScoreTime: 0,
      /* 本次学习时长 */
      thisTime: 0,
      thisTimer: null,
      setScoreViewTimer: null,
      setScoreViewTime: 0,
      isSetLogFlag: true
    };
  },
  watch: {
    isReachBottom() {}
  },
  destroyed() {
    this.pauseSetBrowse();
    this.pauseViewSetBrowse();
    if (this.thisTimer) {
      this.pauseLearnTimeLog();
    }
    window.removeEventListener('reachBottom', this.reachBottomHandle);
  },
  filter: {},
  created() {
    this.$store.commit('SET_LOADINGPOP', true);
    this.initData();
    this.initReachBottom();
  },
  mounted() {},
  computed: {},
  methods: {
    /* 本次学习时长 Start */

    setLearnTimeLog() {
      this.thisTime = 0;
      this.thisTimer = setInterval(() => {
        this.thisTime += 3;
        this.$store.dispatch('setLearnTimeLog', {
          route: this.$route,
          action_type: 'LEARN',
          total_tims: 3,
          content_type: this.contentDetail.content_type,
          session_num: this.onlySessionNum,
          time_type: 1
        });
      }, 3000);
    },
    pauseLearnTimeLog() {
      clearInterval(this.thisTimer);
      this.thisTimer = null;
      this.thisTime += 3;
      this.$store.dispatch('setLearnTimeLog', {
        route: this.$route,
        action_type: 'LEARN',
        total_tims: 3,
        content_type: this.contentDetail.content_type,
        session_num: this.onlySessionNum,
        time_type: 1
      });
    },
    /* 本次学习时长 End */

    myShare() {
      const self = this;
      const detailPosterInner = document.querySelector('.detailPosterInnerOut');
      (0, _html2canvas.default)(detailPosterInner, {
        useCORS: true
      }).then(canvas => {
        self.capUrl = canvas.toDataURL();
        (0, _imagePreview.default)({
          images: [self.capUrl],
          closeable: true,
          onClose() {
            self.$store.commit('SET_SHOWSAVEBTN', false);
          }
        });
        self.$store.commit('SET_SHOWSAVEBTN', true);
        setTimeout(() => {
          self.$store.dispatch('setScoreLog', {
            route: self.$route,
            action_type: 'SHARE'
          });
        }, 2000);
      });
    },
    initReachBottom() {
      window.addEventListener('reachBottom', this.reachBottomHandle);
    },
    reachBottomHandle(evt) {
      const self = this;
      if (evt.detail.path != '/course/detail') {
        return;
      }
      if (self.isReachBottom) {
        return;
      }
      if (this.showModuleCheck == 1) {
        var _this$$refs;
        self.isReachBottom = true;
        (_this$$refs = this.$refs) === null || _this$$refs === void 0 || (_this$$refs = _this$$refs.comment) === null || _this$$refs === void 0 || _this$$refs.reachBottom();
      }
    },
    async zanHandle(type) {
      if (this.loadZan) return;
      this.loadZan = true;
      type && this.$store.dispatch('setScoreLog', {
        route: this.$route,
        action_type: 'ZAN'
      });
      try {
        let res = await this.$api[type ? 'contentZan' : 'cancleContentZan']({
          content_id: this.contentDetail.id,
          type: this.contentDetail.type
        });
        this.loadZan = false;

        // this.tipWords = type ? '点赞成功' : "取消点赞成功"
        (res === null || res === void 0 ? void 0 : res.errcode) && (this.tipWords = res === null || res === void 0 ? void 0 : res.errmsg);
        (res === null || res === void 0 ? void 0 : res.errcode) && this.$refs.customPop.open();
        if (type) {
          this.contentDetail.zan++;
          this.isZan = true;
        } else {
          this.contentDetail.zan--;
          this.isZan = false;
        }
      } catch (error) {}
    },
    async faverHandle(type) {
      if (this.loadFaver) return;
      this.loadFaver = true;
      type && this.$store.dispatch('setScoreLog', {
        route: this.$route,
        action_type: 'FAVER'
      });
      try {
        let res = await this.$api[type ? 'contentFaver' : 'cancleContentFaver']({
          content_id: this.contentDetail.id,
          type: this.contentDetail.type
        });
        this.loadFaver = false;

        // this.tipWords = type ? '收藏成功' : "取消收藏成功"
        (res === null || res === void 0 ? void 0 : res.errcode) && (this.tipWords = res === null || res === void 0 ? void 0 : res.errmsg);
        (res === null || res === void 0 ? void 0 : res.errcode) && this.$refs.customPop.open();
        if (type) {
          this.contentDetail.faver++;
          this.isFaver = true;
        } else {
          this.contentDetail.faver--;
          this.isFaver = false;
        }
      } catch (error) {}
    },
    showDaysPop(res) {
      this.$instanceExperienceView.show({
        days: res.days || 0,
        //体验剩余天数
        isNeedBack: false,
        //是否重定向返回原页面
        callBack: ret => {
          if (ret.next == '/veeva') {
            //立即认证跳转
            this.$PUSH({
              path: ret.next
            });
          }
        }
      });
    },
    async isNeedTipsFunc(need_veeva) {
      if (!need_veeva) return;
      const resData = await this.$api.getShowPopDaysInfo({});
      this.isSetLogFlag = resData.veeva == 1 || resData.days > 0;
      if (resData.need_tips && resData.veeva == 0) this.showDaysPop(resData);
    },
    async initData() {
      try {
        var _res$content;
        let res = await this.$api.getContentDetail({}, this.$route.query.id);
        this.need_veeva = res === null || res === void 0 ? void 0 : res.need_veeva;
        this.isNeedTipsFunc(res === null || res === void 0 ? void 0 : res.need_veeva);
        /* 修改微信配置 Start */
        this.weChatShare.title = res.name;
        this.weChatShare.desc = res.name;
        this.weChatShare.imgUrl = res.cover;
        this.initWechat();
        /* 修改微信配置 End */

        if (!res.id) return;
        document.title = res === null || res === void 0 ? void 0 : res.name;
        if (res.content) res.content = (_res$content = res.content) === null || _res$content === void 0 ? void 0 : _res$content.replaceAll('<img', '<img style="max-width:100%;"');
        // res.content = res.content?.replaceAll('<strong', '<strong style="font-weight: bold;"')

        this.contentDetail = res;
        this.setScoreTime = Number(res.learn_time) || 0;
        this.setScoreViewTime = Number(res.learn_time) || 0;
        res.have_form && (this.detailForm = res.forms);
        !res.have_form && res.have_comments && (this.showModuleCheck = 1);
        let zanCheck = await this.$api.getZanCheck(res.id, res.type);
        let faverCheck = await this.$api.getFaverCheck(res.id, res.type);
        zanCheck.count > 0 && (this.isZan = true);
        faverCheck.count > 0 && (this.isFaver = true);
        this.isVideoType = this.contentDetail.content_type + '' == '1';
        // if (!this.isVideoType) {

        //   this.setBrowse()
        // }
        if (this.isSetLogFlag) {
          this.setBrowseView();
        }

        /* 获取表单填写后的信息 */
        this.getFormEchoInfo();
      } catch (error) {
        console.log(error, '==> error');
      }
    },
    pauseSetBrowse(endTime, finish) {
      clearInterval(this.setScoreTimer);
      this.setScoreTimer = null;
      if (finish && endTime > this.setScoreTime + 3) {
        endTime = this.setScoreTime;
        finish = false;
      }
      this.$store.dispatch('setScoreLog', {
        route: this.$route,
        action_type: 'LEARN',
        total_tims: this.setScoreTime,
        content_type: this.contentDetail.content_type,
        session_num: this.onlySessionNum,
        finish,
        time_type: 1
      });
    },
    setBrowse() {
      var _this$contentDetail;
      if ((_this$contentDetail = this.contentDetail) !== null && _this$contentDetail !== void 0 && _this$contentDetail.id) {
        this.setScoreTimer = setInterval(() => {
          this.setScoreTime += 3;
          this.$store.dispatch('setScoreLog', {
            route: this.$route,
            action_type: 'LEARN',
            total_tims: this.setScoreTime,
            content_type: this.contentDetail.content_type,
            session_num: this.onlySessionNum,
            time_type: 1
          });
        }, 3000);
      }
    },
    // ---
    pauseViewSetBrowse(endTime, finish) {
      clearInterval(this.setScoreViewTimer);
      this.setScoreViewTimer = null;
      if (finish && endTime > this.setScoreViewTime + 3) {
        endTime = this.setScoreViewTime;
        finish = false;
      }
      this.$store.dispatch('setScoreLog', {
        route: this.$route,
        action_type: 'LEARN',
        total_tims: this.setScoreViewTime,
        content_type: this.contentDetail.content_type,
        session_num: this.onlySessionNum,
        finish
      });
    },
    setBrowseView() {
      var _this$contentDetail2;
      if ((_this$contentDetail2 = this.contentDetail) !== null && _this$contentDetail2 !== void 0 && _this$contentDetail2.id) {
        this.setScoreViewTimer = setInterval(() => {
          this.setScoreViewTime += 3;
          this.$store.dispatch('setScoreLog', {
            route: this.$route,
            action_type: 'LEARN',
            total_tims: this.setScoreViewTime,
            content_type: this.contentDetail.content_type,
            session_num: this.onlySessionNum
          });
        }, 3000);
      }
    },
    // ---

    async getFormEchoInfo(fromBase) {
      let formEchoInfo = await this.$api.getFormEchoInfo({}, this.contentDetail.id, this.contentDetail.type);
      this.$store.commit('SET_LOADINGPOP', false);
      if (formEchoInfo !== null && formEchoInfo !== void 0 && formEchoInfo.errcode) {
        alert('getFormEchoInfo获取失败' + formEchoInfo.errmsg);
        return;
      }
      this.formEchoInfo = formEchoInfo;
    }
  }
});