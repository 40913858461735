"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$userInfo, _vm$userInfo2, _vm$userInfo3, _vm$userInfo4;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container-out"
  }, [_c("stickyHeader"), _vm._m(0), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isInintData,
      expression: "isInintData"
    }],
    staticStyle: {
      "text-align": "center",
      position: "relative",
      "z-index": "3"
    }
  }, [_c("div", {
    staticClass: "warningTitle"
  }, [_vm._v("联系我们")])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isInintData,
      expression: "isInintData"
    }],
    staticClass: "enterActivity-All"
  }, [(_vm$userInfo = _vm.userInfo) !== null && _vm$userInfo !== void 0 && _vm$userInfo.id ? _c("div", {
    key: "1",
    staticClass: "registerSuccess"
  }, [((_vm$userInfo2 = _vm.userInfo) === null || _vm$userInfo2 === void 0 ? void 0 : _vm$userInfo2.veeva) != 1 ? _c("div", {
    key: "11"
  }, [_vm._m(1), _c("div", {
    staticClass: "registerSuccessHandle flex align-center justify-center"
  }, [_c("div", {
    staticClass: "registerSuccessHandleNow flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$PUSH({
          path: "/veeva"
        });
      }
    }
  }, [_vm._v(" 立即认证 ")])])]) : _vm._e(), !_vm.callUs.qrcode && ((_vm$userInfo3 = _vm.userInfo) === null || _vm$userInfo3 === void 0 ? void 0 : _vm$userInfo3.veeva) == 1 ? _c("div", {
    key: "12",
    staticClass: "registerSuccessTip"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("暂无学术助手联系方式")])]) : _vm._e(), _vm.callUs.qrcode && ((_vm$userInfo4 = _vm.userInfo) === null || _vm$userInfo4 === void 0 ? void 0 : _vm$userInfo4.veeva) == 1 ? _c("div", {
    key: "13",
    staticClass: "registerSuccessTip"
  }, [_vm._m(2), _c("div", {
    staticClass: "ewmIcon"
  }, [_c("img", {
    attrs: {
      src: _vm.callUs.qrcode,
      alt: ""
    }
  })])]) : _vm._e()]) : _c("div", {
    key: "2",
    staticClass: "registerSuccess"
  }, [_vm._m(3), _c("div", {
    staticClass: "registerSuccessHandle flex align-center justify-center"
  }, [_c("div", {
    staticClass: "registerSuccessHandleNow flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$PUSH({
          path: "/register"
        });
      }
    }
  }, [_vm._v(" 立即注册 ")])])])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "interceptBG"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/question/interceptBG.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "registerSuccessTip"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("完成医疗卫生专业人士认证，即可查看联系方式")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "title"
  }, [_vm._v("请扫码添加学术助手 "), _c("br"), _vm._v("咨询相关问题")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "registerSuccessTip"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(" 抱歉，您还没有注册，请点击前往。")]), _c("p", {
    staticClass: "content"
  }, [_vm._v("注册后将获得30日免认证学习资格"), _c("br"), _vm._v("前往认证即可解锁永久权益！")])]);
}];
render._withStripped = true;