"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.string.replace-all.js");
var _listItem = _interopRequireDefault(require("@/components/activity/listItem.vue"));
var _ApprovalNo = _interopRequireDefault(require("@/components/common/ApprovalNo.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _activeShowOnceForm = _interopRequireDefault(require("@/views/activity/activeShowOnceForm.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    listItem: _listItem.default,
    ApprovalNo: _ApprovalNo.default,
    activeShowOnceForm: _activeShowOnceForm.default
  },
  props: {},
  data() {
    return {
      isShowActiveBtn: true,
      isShowNextPageFlag: false,
      urlQuery: {},
      updateBtnStaus: false,
      is_preview: null,
      sectionDetail: {},
      myIsZan: false,
      myIsFaver: false,
      loadZan: false,
      loadFaver: false
    };
  },
  watch: {},
  filter: {},
  async created() {
    this.urlQuery = this.$route.query;
    await this.initData();
    this.getUrlRedirectQuery();
  },
  async mounted() {
    this.is_preview = this.urlQuery.is_preview;
    if (this.userInfo.type == '2') {
      this.isShowNextPageFlag = true;
      await this.$api.putShowIntroduceAuth({}, this.urlQuery.id, this.urlQuery._typeMap || '5'); //更新弹窗展示状态
    }

    // if (!this.urlQuery.hotActive) { //从热门活动进入 展示介绍页 其它方式进入 直接展示答题页
    //   // this.isShowNextPageFlag = false;
    //   this.scrollToElement();
    // }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    isShowParentAddActiveBtn(data) {
      if ('[]' != data && this.sectionDetail.have_form == 1) {
        this.isShowActiveBtn = false;
      } else {
        this.isShowActiveBtn = true;
      }
    },
    upParentShowQuestionHandle() {
      if (this.userInfo.type == '1' && this.sectionDetail.have_form == 1) {
        this.isShowNextPageFlag = false;
        this.scrollToElement();
      } else {
        this.isShowNextPageFlag = true;
      }
    },
    scrollToElement() {
      const element = document.getElementById('formContainer');
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: 'smooth'
          });
        }, 200);
      }
    },
    getUrlRedirectQuery() {
      const urlString = window.location.href;

      // 创建一个URL对象
      let url = urlString.split('redirectUrl=');
      // 获取redirectUrl参数

      console.log('url----------', url);
      if (url) {
        // 解析redirectUrl

        // 将redirectUrl中的参数转换为字符串

        return url[1];
      }
      return null;
    },
    async redirectUrl() {
      let authShowPop = await this.$api.getIsShowIntroduceAuth({}, this.urlQuery._typeMap == '6' || this.urlQuery._typeMap == '7' ? this.urlQuery._contentId : this.urlQuery.id, this.urlQuery._typeMap || '5');
      let redirectUrl = window.sessionStorage.getItem('redirectUrl');
      if (redirectUrl && authShowPop.need_show_desc == 1) {
        this.$PUSH({
          path: redirectUrl
        });
        return;
      }
      if (this.urlQuery.section_id && this.urlQuery.section_id != 'undefined' && this.urlQuery.section_id != 'null' && this.urlQuery.section_id != '') {
        //展示内容
        if (this.urlQuery.content_type == 2) {
          const path = `/activity/richdetail`;
          const query = {
            id: this.urlQuery.section_id,
            campain_id: this.urlQuery.id,
            section_id: this.urlQuery.section_id,
            typeMap: 7,
            contentid: this.urlQuery._contentId
          };
          this.$PUSH({
            path,
            query
          });
        } else {
          this.$PUSH({
            path: `/activity/detail?id=${this.urlQuery.section_id}&typeMap=7&campain_id=${this.urlQuery.id}&contentid=${this.urlQuery._contentId}&section_id=${this.urlQuery.section_id}`
          });
        }
        return;
      }
      if (this.urlQuery.secondList == '1') {
        this.$PUSH({
          path: `/activity/secondList?id=${this.$route.query.id}`
        });
        return;
      }
      let sectionDetail = await this.$api.getCampainSectionDetail({}, this.urlQuery._contentId ? this.urlQuery._contentId : this.urlQuery.id);
      let res2 = await this.$api.getCampainSectionDetailAuth({}, sectionDetail.id);
      let authShowPop2 = await this.$api.getIsShowIntroduceAuth({}, sectionDetail.id, 6);
      console.log('sectionDetail', sectionDetail, this.urlQuery._contentId);
      if (authShowPop2.need_show_desc == 1 && this.urlQuery._typeMap != '7') {
        this.$PUSH({
          path: `/activity/detailenter?id=${sectionDetail.id}&campain_id=${res2.campain_id}`
        });
      } else {
        if (sectionDetail.content_type == 2) {
          if (this.urlQuery._typeMap == '7') {
            const path = `/activity/richdetail`;
            const query = {
              id: sectionDetail.id,
              campain_id: this.urlQuery.id,
              section_id: sectionDetail.id,
              typeMap: 7,
              contentid: this.urlQuery.threeId
            };
            this.$PUSH({
              path,
              query
            });
          } else {
            this.$PUSH({
              path: `/activity/richlist?id=${sectionDetail.id}&typeMap=6&campain_id=${res2.campain_id}`
            });
          }
          return;
        }
        this.$PUSH({
          path: `/activity/detail?id=${sectionDetail.id}&typeMap=7&campain_id=${res2.campain_id}&contentid=${this.urlQuery.threeId}&section_id=${sectionDetail.id}`
        });
      }
    },
    showFormatTime(im) {
      const start_time = this.$SPLITDATE(im.start_time, 'yyyy-mm-dd h:m');
      const end_time = this.$SPLITDATE(im.end_time, 'yyyy-mm-dd h:m');

      // const start_time_arr = start_time.split(" ")
      // const end_time_arr = end_time.split(" ")

      // if (start_time_arr[0] == end_time_arr[0]) {
      //   return start_time + ' - ' + end_time_arr[1]
      // }

      return start_time + ' - ' + end_time;
    },
    showDaysPop(res) {
      this.$instanceExperienceView.show({
        days: res.days || 0,
        //体验剩余天数
        isNeedBack: false,
        //是否重定向返回原页面
        callBack: ret => {
          if (ret.next == '/veeva') {
            //立即认证跳转
            this.$PUSH({
              path: ret.next
            });
          }
        }
      });
    },
    async isNeedTipsFunc(need_veeva) {
      if (need_veeva) {
        const resData = await this.$api.getShowPopDaysInfo({});
        if (resData.need_tips && resData.veeva == 0) {
          this.showDaysPop(resData);
          return;
        }
      }
      let authShowPop = await this.$api.getIsShowIntroduceAuth({}, this.urlQuery.id, this.urlQuery._typeMap || '5');
      if (this.sectionDetail.have_form == 0 && authShowPop.need_show_desc == 1) {
        await this.$api.putShowIntroduceAuth({}, this.urlQuery.id, this.urlQuery._typeMap || '5'); //更新弹窗展示状态
      }
    },
    async initData() {
      try {
        let res = await this.$api.getCampainListAuth({}, this.urlQuery.id);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCampainSectionDetail" + res.errmsg)
          return;
        }
        console.log('res:---', res);
        /* 修改微信配置 Start */
        this.weChatShare.title = res.name;
        this.weChatShare.desc = res.name;
        this.weChatShare.imgUrl = res.cover;
        this.initWechat();
        /* 修改微信配置 End */
        try {
          // 可能会抛出异常的代码
          if (res.desc) res.desc = res.content.replaceAll('<img', '<img style="max-width:100%;"');
        } catch (error) {
          // 处理异常的代码
          console.error('发生异常:', error);
        }
        this.sectionDetail = res;
        this.isNeedTipsFunc(res === null || res === void 0 ? void 0 : res.need_veeva);
        if (!this.sectionDetail.have_form) {
          this.isShowNextPageFlag = true;
        }
        console.log('need_show_desc', this.sectionDetail);
      } catch (error) {}
    },
    enterActivityHandleDelay() {
      this.$refs['customPop-tip'].close();
    },
    enterActivityHandleNow() {
      this.$refs['customPop-tip'].close();
      this.$PUSH({
        path: `/personal/task`
      });
    },
    async enterActivity() {
      /* 2023-02-09 ac说客户又不做等级限制了 */

      // if (this.sectionDetail.content_type == 1) {
      //   this.$PUSH({
      //     path: `/activity/detail?id=${this.sectionDetail.id}&typeMap=6&campain_id=${this.urlQuery.campain_id}`
      //   })
      //   return
      // }

      if (this.sectionDetail.have_form && this.userInfo.type == '1') {
        //需要填写表单
        // this.$PUSH({
        //   path: `/activity/activeShowOnceForm?id=${this.sectionDetail.id}&content=${this.sectionDetail.forms}`
        // })
        this.isShowNextPageFlag = false;
        this.scrollToElement();
        const childComponent = this.$refs.parentRef.getChildRef();
        if (childComponent) {
          childComponent.submit();
        }
      } else {
        //进入活动
        this.$PUSH({
          path: `/activity/secondList?id=${this.sectionDetail.id}&typeMap=6&campain_id=${this.urlQuery.campain_id}`
        });
      }
      return;
      if (this.userInfo.level >= 3) {
        this.$PUSH({
          path: `/activity/detail?id=${this.sectionDetail.id}`
        });
        return;
      }
      this.$refs['customPop-tip'].open();
    }
  }
});