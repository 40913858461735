"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _picLook = _interopRequireDefault(require("@/components/customPopview/picLook"));
var _default = exports.default = {
  components: {
    picLook: _picLook.default
  },
  inject: ['link'],
  props: {
    step: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      base64QRCode: ''
    };
  },
  computed: {
    callUs() {
      return this.$store.getters.callUs;
    }
  },
  mounted() {
    // console.log(this.callUs, '==>callUs -0-');
    this.$store.commit('SET_LOADINGPOP', false);
  },
  created() {
    this.$nextTick(() => {
      this.getBase64QRCode();
    });
  },
  methods: {
    scalePicHandle() {
      this.$refs.scalePic.open();
    },
    getBase64QRCode() {
      this.base64QRCode = this.$refs.QRCode.qrious.canvas.toDataURL();
      this.$store.commit('SET_LOADINGPOP', false);
    }
  }
};