"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authUrl = authUrl;
exports.chooseImage = void 0;
exports.getWechatInfo = getWechatInfo;
exports.starUrl = starUrl;
exports.wechatConfig = wechatConfig;
exports.wechatShare = wechatShare;
require("core-js/modules/es.array.push.js");
var _store = _interopRequireDefault(require("@/store"));
var _http = _interopRequireDefault(require("utils/http"));
var _config = require("@/config");
var _monitor = require("./monitor");
const wx = require('weixin-js-sdk');
const WXSDK = wx;
const chooseImage = type => {
  return new Promise((resolve, reject) => {
    WXSDK.chooseImage({
      count: type == 'multiple' ? 9 : 1,
      sizeType: ['compressed'],
      sourceType: ['album'],
      success: async chooseRes => {
        console.log(chooseRes, '==>chooseRes --chooseRes--chooseRes');
        let tempArr = [];
        const asyncFunc = im => {
          return new Promise((resolve, reject) => {
            WXSDK.getLocalImgData({
              localId: im.toString(),
              success: getImgData => {
                const localData = getImgData.localData;
                let imageBase64 = '';
                if (localData.indexOf('data:image') == 0) {
                  //苹果的直接赋值，默认生成'data:image/jpeg;base64,'的头部拼接
                  imageBase64 = localData;
                } else {
                  //此处是安卓中的唯一得坑！在拼接前需要对localData进行换行符的全局替换
                  //此时一个正常的base64图片路径就完美生成赋值到img的src中了
                  imageBase64 = 'data:image/jpeg;base64,' + localData.replace(/\n/g, '');
                }
                resolve(imageBase64);

                // resolve({errMsg: "getLocalImgData:ok", imageBase64})
              },
              fail: getImgFail => {
                reject(getImgFail);
              }
            });
          });
        };
        for (const im of chooseRes.localIds) {
          const result = await asyncFunc(im);
          console.log(result);
          tempArr.push(result);
        }
        console.log(tempArr, '==>tempArr --tempArr');
        if (type == 'multiple') {
          resolve({
            errMsg: "getLocalImgData:ok",
            imageBase64: tempArr
          });
        } else {
          resolve({
            errMsg: "getLocalImgData:ok",
            imageBase64: tempArr.join("")
          });
        }

        /* WXSDK.getLocalImgData({
          localId: chooseRes.localIds[0].toString(),
          success: getImgData => {
            const localData = getImgData.localData;
            let imageBase64 = '';
            if (localData.indexOf('data:image') == 0) {
              //苹果的直接赋值，默认生成'data:image/jpeg;base64,'的头部拼接
              imageBase64 = localData;
            } else {
              //此处是安卓中的唯一得坑！在拼接前需要对localData进行换行符的全局替换
              //此时一个正常的base64图片路径就完美生成赋值到img的src中了
              imageBase64 = 'data:image/jpeg;base64,' + localData.replace(/\n/g, '');
            }
                resolve({errMsg: "getLocalImgData:ok", imageBase64})
          },
          fail: getImgFail => {
            reject(getImgFail)
          }
        }) */
      },
      fail: chooseFail => {
        reject(chooseFail);
      }
    });
  });
};

// 中台授权 url
exports.chooseImage = chooseImage;
function authUrl() {
  const href = window.location.href;
  const symbol = href.includes('?') ? '&' : '?'; // 链接符号
  const uri = `${href}${symbol}token=${_config.middlePlatform.token}`; // 拼接参数
  const redirect_uri = encodeURIComponent(uri); // 回调链接

  return `${_config.middlePlatform.baseUrl}/api/platform/wechat/auth?website_url=${redirect_uri}`;
  // return `${middlePlatform.baseUrl}/api/platform/wechat/auth/oauth?appid=${appid}&website_url=${redirect_uri}&token=${middlePlatform.token}`
}

// 观星授权 url
function starUrl() {
  const href = window.location.href;
  const redirect_uri = encodeURIComponent(href); // 回调链接
  return `${_config.baseUrl.base}/api/platform/wechat/auth?website_url=${redirect_uri}`;
  // return `${lookstar}/api/platform/wechat/oauth?redirect_url=${redirect_uri}`
}

// 微信配置
function wechatConfig() {
  return new Promise((resolve, reject) => {
    let request_api = `${_config.middlePlatform.baseUrl}/api/platform/jssdk?token=${_config.middlePlatform.token}`;
    /* 蓝小SDK */
    // if (deploy.oauth === 'look-star') request_api = `${baseUrl.base}/api/platform/jssdk-bluedot`
    /* 拜耳SDK */
    if (_config.deploy.oauth === 'look-star') request_api = `${_config.baseUrl.base}${_config.wechatUrl}`;
    (0, _http.default)({
      url: request_api,
      custom: 1,
      params: {
        appid: _config.appid,
        js_api_list: _config.wechat.jsApiList.join(','),
        url: encodeURIComponent(window.location.href)
      }
    }).then(res => {
      wx.config({
        debug: false,
        appId: res.appId,
        // 必填，企业号的唯一标识，此处填写企业号corpid
        timestamp: res.timestamp,
        // 必填，生成签名的时间戳
        nonceStr: res.nonceStr,
        // 必填，生成签名的随机串
        signature: res.signature,
        // 必填，签名，见附录1
        jsApiList: _config.wechat.jsApiList
      });
      resolve();
      // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
      wx.ready(() => {
        wx.hideMenuItems({
          menuList: []
        });
      });
      wx.error(function () {
        /* 不利于开发查看log，暂时去掉 */
        // console.error(res)
      });
    });
  });
}

// 微信信息
function getWechatInfo() {
  return new Promise((resolve, reject) => {
    const openid = _store.default.getters.openid;
    (0, _http.default)({
      url: `${_config.middlePlatform.baseUrl}/api/platform/wechat/user/info`,
      custom: 1,
      method: 'get',
      params: {
        appid: _config.appid,
        openid
      }
    }).then(res => {
      // console.log('--- wechatInfo ---', res)
      _store.default.dispatch('setWechatInfo', res);
      resolve(res);
    });
  });
}

// 微信分享
function wechatShare({
  title,
  desc,
  link,
  imgUrl
}) {
  wx.ready(() => {
    const id = _store.default.getters.openid;
    link = splitUrl(id, link);

    // 二次分享
    if (link.indexOf('from=') !== -1) {
      const reg = new RegExp('from=', 'g');
      link = link.replace(reg, 'from2=');
    }

    // 分享到朋友圈（旧）
    wx.onMenuShareTimeline({
      title,
      link: `${link}&from=timeline`,
      imgUrl,
      success: function () {
        console.log('朋友圈（旧）设置成功');
        // monitorShare()
      }
    });
    // 分享到朋友圈（新）
    wx.updateTimelineShareData({
      title,
      link: `${link}&from=timeline`,
      imgUrl,
      success: function () {
        console.log('朋友圈（新）设置成功');
        // monitorShare()
      }
    });

    // 分享给朋友(旧)
    wx.onMenuShareAppMessage({
      title,
      desc,
      link: `${link}&from=appmessage`,
      imgUrl,
      success: function () {
        console.log('好友（旧）设置成功');
        // monitorShare()
      }
    });
    // 分享给朋友(新)
    wx.updateAppMessageShareData({
      title,
      desc,
      link: `${link}&from=appmessage`,
      imgUrl,
      success: function () {
        console.log('好友（新）设置成功');
        // monitorShare()
      }
    });
  });
}
function splitUrl(id, url) {
  /**
   * 拼接 source 用户标识
   * - 无参数，拼接好 source 后返回
   * - 有参数，两种情况
   *   1 有 source 属性，更新值
   *   2 无 source 属性，末尾拼接
   * */
  const urlArr = url.split('?');
  // 无参数
  if (!urlArr[1]) return url.indexOf('?') === -1 ? `${url}?source=${id}` : `${url}&source=${id}`;
  // 有参数
  const params = urlArr[1].split('&');
  let isHas = false;
  params.forEach((item, i) => {
    // 有 source
    if (item.indexOf('source=') !== -1) {
      item = `source=${id}`;
      isHas = true;
    }
    // encode 链接参数
    if (item.indexOf('http') !== -1) {
      const caption = getCaption(item);
      // item = `${caption[0]}=${caption[1]}`
      item = `${caption[0]}=${encodeURIComponent(caption[1])}`;
    }
    // 无 source
    if (i === params.length - 1 && !isHas) params.push(`source=${id}`);
  });
  return `${urlArr[0]}?${params.join('&')}`;
}
function getCaption(str) {
  const index = str.indexOf('=');
  const before = str.substring(0, index);
  const after = str.substring(index + 1, str.length);
  return [before, after];
}