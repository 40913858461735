"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectArea = selectArea;
exports.selectCity = selectCity;
exports.selectNumArea = selectNumArea;
exports.selectNumCtiy = selectNumCtiy;
exports.selectPrev = selectPrev;
const dataBases = require("./base");
const {
  dataBase
} = dataBases;
function selectPrev() {
  return dataBase.filter(im => {
    return im.AREACODE.length == 2;
  });
}
function selectCity() {
  return dataBase.filter(im => {
    return im.AREACODE.length == 4;
  });
}
function selectArea() {
  return dataBase.filter(im => {
    return im.AREACODE.length == 6;
  });
}
function selectNumCtiy(num) {
  return selectCity().filter(im => {
    return im.AREACODE.substring(0, 2) == num;
  });
}
function selectNumArea(num) {
  return selectArea().filter(im => {
    return im.AREACODE.substring(0, 4) == num;
  });
}