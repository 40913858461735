/** 需要修改部分 */
const needSet = {
  version: 'v20240111.1',
  title: '拜影云学院',
  type: 1,
  // 1 H5, 2 微信公众号, 3 小程序
  appid: '',
  // 'wx764d09f5e7e1d176', // 需 acount 对接
  env: 'dev',
  // dev 测试环境 pro 正式环境
  //proxy: 'https://dev-bayer.blue-dot.com.cn', // 开发环境的代理地址
  // proxy: 'http://lijian9766.l2.ttut.cc', // lijian本地测试地址
  proxy: 'https://newradacademy-q.wechat.bayer.cn',
  // 开发环境的代理地址
  // proxy: 'https://newradacademy.wechat.bayer.cn', // 开发环境的代理地址
  debug: false,
  // 是否开启 vconsole
  monitor: true,
  // 监测
  baseUrl: {
    base: ''
    // base: 'https://newradacademy.wechat.bayer.cn', // 拜耳测试域名
    // base: 'https://newradacademy-q.wechat.bayer.cn', // 拜耳测试域名
    // base: 'https://dev-bayer.blue-dot.com.cn', // 正式
    // baidu: 'https://www.baidu.com' // 搭配 http 对应的设置，在多域名情况下使用
  },
  middlePlatform: {
    token: 'test' // '88d65b51560de531', // 需跟后端人员对接 -> 目前是彩琳
  },
  crypto: {
    iv: '',
    key: '',
    publicKey: `RSA_PRIVATE_KEY="-----BEGIN PRIVATE KEY-----
    MIIJQgIBADANBgkqhkiG9w0BAQEFAASCCSwwggkoAgEAAoICAQC20EFe7hB7J3O1
    pGEiGUMl5mHkvHiktdEzFfHr+vWwbKNfUzjp+kuHWTWgtFNrjzg4i65nmmDTzOmv
    usaFn7u+u7TRZ80rRtMPJfVfF4GUqt4hym3O4Gncfwtjju2A6pY22nctbRWde3KY
    vgOPQ6fNK8PV9bmp6YQOdLcg9MX+S4tahCpEY3p2f14QVW8DPWzyiPWTjJgwjJxY
    p4B8Z+Ooq7ivDEyUJgvH8vt1MmkOR3F6yZleyoaHDcy4Qm8HpYTh2WEs9RY3cLcC
    6as1Q6F+1OC63mbPgWtvr/B1zkjMJUdxbrsFSfwXkJSnh9U1Nmf7XNuc9UOHU31D
    SgayEq/fiH3boCRYxmcRqx7uKse1xan1+4NmXPEF5LaJzPsB6xbiykmglatOJ2SX
    MG+vnMj5nj/TLQRvW8MsNwvF3UP/7AOU00YuSiWtVMCuf47nDeCtVJYtL8d8PFSh
    Dc5yE7vA18Ti0mko6GhvojvGBJ4DqabN1CNy/mojvqIP7eY9OS0XIriKetIwKHBW
    aD/zpXkcLOsTtZwdAazvCW2IjlyamKTmZutIJtuddapd7LWEw9jORO9Vd3fjoVv4
    e2QKyL46asTvg8W5F9qf03YniXAiDaAm1YElKmeSDQ04mvSIkJXLEbf+EEvrg3JQ
    8ByDjQJJHCZRw2C0eNw92cdJ9sisMwIDAQABAoICAD9CdTx6OK3FCx8x0ILSJxA4
    VmumZEDqwYdh8qLr5pS0IxxbWfhtKjGdxxbjD3LKznOSsjkbjKH96GdsxEF88qSf
    o4RK3Q46pdI6Glxbb/WX4uHigHjz4u3v8Gr1DG5tvqkgk9jeRHqIOzFrnyO2D5zJ
    piylqSeIrK+r56q5yMGkNoaN8WpU2g2jKVORnKaMXOTYS9JQ9mDmZzuuSqHB+V9m
    fLPmF6G0QKNiC22UkhHk2vnYRQAuKLsXJV4lbvJAbmkQpbW+4X8xu8KARURbMMkI
    tPM0/nKToC+3vAen6DzsMXqpDIJaBafJ2gbW1Apj1zt9+Gip3kI3u8LLHOIF4WFs
    ue6Gict4WoFaGiTN+ZkPm1D6239+7RsuczM3eTXIpQEQ3iaapWKw5UmpffTpwQPI
    5IyHK6d0udBHmMlKDtFri9I6qd5EtDjQlUhlMuwSO0c2oh78BJWtJ2k0Fj8qHjs5
    FTJFIjQm727ZxagzMujwXhA7MBQlPxtF5JR8kC/5UiS7oqaGvu8C20VQ8FU1W58W
    YahCLsIoOGBBpCzXvrYCmM1k/PKo7KCzKakiwQ+EylFqO18/CCSJFykFdmncYgt+
    /8vLjROYyw3lNEhl1g5x6q0z18niAbtCfzYEDfYObQDXBOBx3eTJ9C7Rrcb9KB37
    s5plj9T9puwGmgA5/GsRAoIBAQDe/2aJo9ZtcAlSJbeyv3YK5bgE50mz8zYAMzko
    Y3EXUPLZN6+evLkbP9ITQH0S+w3cBD2WXvoPx9oIihhEzBNLK8OndYTSiOvVzN3z
    3V9KQUjqeoj5mEGY1/ZInP6qjDOE1sTTCUOZAHQrtSem38dGWl42UTlp6UJePdOQ
    7mGoJBP3pqkQrKWwGDUMMHI0Tn7R4Xj797uoZxTrCIgCMe6Vhso8TSN0bxjGNzr+
    R6x1kwDqYrEABezpTysFj18JgV37oIRqTD2rOtNQX2Hj9LA/5g52WTC5BUQ+dlGH
    lOc7BfWDWZj31fX1AeH38Z2GDX3qaQAn5SOFs4Tg2spVt0mlAoIBAQDR3mr31sDF
    Zv65ERD9Y8OiEClkzXBHxLDx1DwLj4lmrChc6Ate4Ff5PzlPCd+GsIfsrHP5fHON
    2M/lkiv+j2kF7bgguoxzC9cAra35lUt4fm+op1RPbi1SOGPt82Uu6+V42sxyJoNh
    kA8L0kv+7hMrqFzRfw8ZD6awETdQyqcO4LZARsyPcynGgKGjs4C+47fQ1eslY/Eb
    kGVOL2Bdb/xk2BORKItD9oNJtsYLjRaeq8eoeeMLmhxF54ba6gOEZz1HB/Cat7yD
    3wGtiBcaSkqWd+6e/hPlGF9qGBrOyPkWIZlpMAzslwbrd8MjSGYp0ztEr65irS8h
    N48qTJ8xH8b3AoIBABaJ+fMvfBzTlh2Xclm+wE+ZqF/WgLyPeIXMp5pNcsDIjcMm
    vbltmcgHS1Rm0D164ou6Yf8w2lV9/aufSZSqi8itQ7lJR4OAE1YovsZHObpnntcU
    S4Puv7KTIXF1bMgQI6TpkDtEVHDCevtv8/IJ7DpQJ/WVgBgUW4Dq/tdHQNedzeba
    EXx99sE1sSbqPqDKzkaoY3IUOANtZ8/ZrOWL5nF2jjpKEatTD035hxzsYaIAmbcX
    AozeDHQIbHDDbPeo+7bTIo8b5Z/I8lLNUTeZI7dRqWSM3z/UKHGnw9XQVw7AK3B7
    PdrO5W0+I3jMEv/ZnUB0HG/r2QtKecPAWXMQc4ECggEAKkEY0luHpncNNB+uhwXu
    HpQYcXY3dAuzAI2VpVI5/NUOr1385BZRQBqtpC89tPWX+FvkYcff0CBmAzfB6dff
    8lShbN2CZ6trl3Rggc7ASjtSqec+v1tUfqhh3kfSCHdwVTvgS99bNmp1zRCDQomW
    jISyY2+sj4QqMGrW80pKBmHR7/ERQJSURaYiZT4/3q8klERturJ7mewhqXM6eLWS
    YI9sqR47o+xfFJlbGHpIopu9cDtSLkTSCnUKHstc9+x9Vu7PT6dMqlRdFx4+YImy
    qMbu4U4QnWCh3U+LHjUyiXUsWRRWlc/2kbQkMtdh0mv4E+U3PCDYS1zPU1nn91EJ
    kwKCAQEAgAu5WQ/pTYtdEKVNLnwGsuUi1s8FkMFM/0lJDVOHbiN8b3Siy7HpaTNv
    Yj6t0cjKPi8VnG0/vsusu/4venbEH0bd0qkouwrRmch0z7sGwlVRakggIR9YOCP4
    +J/nh9W+TCiQyQfcsnXncYBjntw3R2Tz5/32gQSVJd6XkAFljXytCbQPMQysR2+1
    zgoNWb/wMskwOcNp2wM3LQeUzupjDGfRnBzDT3u8WRWRCC68mivfrLrvBGhP3TOm
    3JSTqUbGCdATM74Le/hZyD4hLeAhBBIwewBMlRyS+H4loe1sEE6yrYa7dQIr3dbq
    8KuRxtEbKlycXttWKnkNWU3pc5B5MQ==
    -----END PRIVATE KEY-----"`
  },
  deploy: {
    name: 'bayer-rad-academy-shadowcloud-academy',
    // 项目对应名称
    gitee: 'bayer-rad-academy-shadowcloud-academy-html',
    // gitee 项目对应名称
    domain: 'wechat.stahl.cn',
    // 项目域名
    ssl: false,
    // https ssl 证书
    path: 'bayer-rad-academy-shadowcloud-academy-html',
    // 项目目录 blue-dot.cn/ {test-html} /index.html
    service: 'static',
    // static 固定服务器 || k8s
    oauth: 'look-star' // middle-platform 中台授权 look-star 观星授权 self 个人授权
  }
};
module.exports = needSet;