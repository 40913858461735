"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'App',
  created() {
    var _this$$store$getters, _this$$store$getters2;
    this.$store.dispatch("course/getCourseClass");
    ((_this$$store$getters = this.$store.getters) === null || _this$$store$getters === void 0 || (_this$$store$getters = _this$$store$getters.wechatInfo) === null || _this$$store$getters === void 0 ? void 0 : _this$$store$getters.token) && this.$store.dispatch("getUserInfo");
    ((_this$$store$getters2 = this.$store.getters) === null || _this$$store$getters2 === void 0 || (_this$$store$getters2 = _this$$store$getters2.wechatInfo) === null || _this$$store$getters2 === void 0 ? void 0 : _this$$store$getters2.token) && this.$store.dispatch("getAssistantsInfo");
    const setScreenTip = () => {
      const width = document.body.offsetWidth;
      const height = document.body.offsetHeight;
      if (height < width) {
        this.$store.commit("SET_SCREENTIP", true);
      } else {
        this.$store.commit("SET_SCREENTIP", false);
      }
    };
    setTimeout(() => {
      setScreenTip();
    }, 1000);
    window.addEventListener("resize", evt => {
      setScreenTip();
    }, false);
  }
};