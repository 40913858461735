"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/utils/index.js");
var _listItem = _interopRequireDefault(require("@/components/activity/listItem.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _monitor = require("@/utils/monitor");
var _activeShowOncePoster = _interopRequireDefault(require("@/views/activity/activeShowOncePoster.vue"));
var _ConfirmDialog = _interopRequireDefault(require("@/components/ConfirmDialog.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    listItem: _listItem.default,
    activeShowOncePoster: _activeShowOncePoster.default,
    ConfirmDialog: _ConfirmDialog.default
  },
  data() {
    return {
      isShowSignFlag: false,
      current: 1,
      pageSize: 10,
      urlQuery: {},
      sectionList: [],
      loadFinal: false,
      isReachBottom: false,
      noThenData: false,
      hasSHow: false
    };
  },
  watch: {},
  filter: {},
  created() {
    this.urlQuery = this.$route.query;
    this.initData();
    this.initReachBottom();
  },
  destroyed() {
    window.removeEventListener('reachBottom', this.reachBottomHandle);
  },
  mounted() {
    this.isShowSignPage();
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    closeAnswerPageHandle() {
      this.isShowSignFlag = false;
    },
    async isShowSignPage() {
      console.log('isShowSignPage');
      let res = await this.$api.getCampainListAuth({}, this.urlQuery.id);
      if (res.hasErrCode == '50001') return;
      console.log('res-------------', res);
      if (res.need_veeva && this.userInfo.veeva == 0 && this.userInfo.type == 1) {
        const resData = await this.$api.getShowPopDaysInfo({});
        if (resData.need_tips && resData.veeva == 0) {
          //是否展示体验认证弹框
          this.showDaysPop2(resData);
        }
      }
      console.log(res, '==>res>res>res');

      /* 修改微信卡片分享 */
      this.weChatShare.title = res.name;
      this.weChatShare.desc = res.name;
      this.weChatShare.imgUrl = res.cover;
      this.initWechat();
      this.hasSHow = true;
      let authShowPop = await this.$api.getIsShowIntroduceAuth({}, this.urlQuery.id, this.urlQuery.type || '5');
      if (authShowPop.need_show_desc == 1 && this.userInfo.type == 1 && res.have_form == 1) {
        // this.$PUSH({
        //       path: `/activity/activeShowOncePoster?id=${this.urlQuery.id}&_typeMap=${this.urlQuery.type}&secondList=1`
        //     })
        this.$refs.confirmDialog.open('浏览完整活动介绍，参与互动后，即可立即学习！感谢您的支持！', '').then(result => {
          this.$PUSH({
            path: `/activity/activeShowOncePoster?id=${this.urlQuery.id}&_typeMap=${this.urlQuery.type}&secondList=1`
          });
          // 执行确认后的逻辑
        }).catch(error => {
          console.log('用户取消了操作:', error);
          this.$PUSH({
            path: '/'
          });
          // 处理取消逻辑
        });

        // this.isShowSignFlag = true
      } else {
        // this.isShowSignFlag = false
      }
    },
    showFormatTime(im) {
      const start_time = this.$SPLITDATE(im.start_time, 'yyyy-mm-dd h:m');
      const end_time = this.$SPLITDATE(im.end_time, 'yyyy-mm-dd h:m');
      const start_time_arr = start_time.split(' ');
      const end_time_arr = end_time.split(' ');
      if (start_time_arr[0] == end_time_arr[0]) {
        return start_time + ' - ' + end_time_arr[1];
      }
      return start_time + ' - ' + end_time;
    },
    /* 分页 start */
    initReachBottom() {
      window.addEventListener('reachBottom', this.reachBottomHandle);
    },
    reachBottomHandle(evt) {
      const self = this;
      if (evt.detail.path != '/activity/secondList') {
        return;
      }
      if (self.isReachBottom) {
        return;
      }
      self.isReachBottom = true;
      self.initData();
    },
    /* 分页 end */

    async toDetail(im) {
      let authShowPop = await this.$api.getIsShowIntroduceAuth({}, im.id, im.type);
      console.log('authShowPop', authShowPop);
      if (im.need_veeva) {
        const resData = await this.$api.getShowPopDaysInfo({});
        if (resData.need_tips && resData.veeva == 0) {
          //是否展示体验认证弹框
          this.showDaysPop(resData, im, authShowPop);
        } else {
          this.goNextPath(authShowPop, im);
        }
      } else {
        this.goNextPath(authShowPop, im);
      }

      // if (im?.have_visite) {

      //   if(im.content_type == 2){
      //     this.$PUSH({ path: `/activity/richlist?id=${im.id}&typeMap=6&campain_id=${im.campain_id}` })
      //     return
      //   }

      //   this.$PUSH({ path: `/activity/detail?id=${im.id}&typeMap=6&campain_id=${im.campain_id}` })
      //   return
      // }
      // this.$PUSH({ path: `/activity/detailenter?id=${im.id}&campain_id=${im.campain_id}` })
    },
    goNextPath(authShowPop, im) {
      if (authShowPop.need_show_desc == 1) {
        //1需要展示活动介绍
        this.$PUSH({
          path: `/activity/detailenter?id=${im.id}&campain_id=${im.campain_id}`
        });
      } else {
        if (im.content_type == 2) {
          this.$PUSH({
            path: `/activity/richlist?id=${im.id}&typeMap=6&campain_id=${im.campain_id}&content_type=${im.content_type}`
          });
          return;
        }
        this.$PUSH({
          path: `/activity/detail?id=${im.id}&typeMap=6&campain_id=${im.campain_id}`
        });
        return;
      }
    },
    showDaysPop(res, im, authShowPop) {
      let days = res.days || 0;
      this.$instanceExperienceView.show({
        days: days,
        //体验剩余天数
        isNeedBack: 'cur',
        //是否重定向返回原页面
        callBack: ret => {
          if (ret.next == 'from') {
            if (days > 0) this.goNextPath(authShowPop, im);
          } else {
            //立即认证跳转
            this.$PUSH({
              path: ret.next
            });
          }
        }
      });
    },
    resetData() {
      this.current = 1;
      this.sectionList = [];
      let moniStr = `${document.title}:筛选-`;
      this.searchWords && (moniStr += `搜索-${this.searchWords}`);
      (0, _monitor.monitorClick)(moniStr);
      this.initData();
    },
    showDaysPop2(res) {
      let days = res.days || 0;
      this.$instanceExperienceView.show({
        days: days,
        //体验剩余天数
        isNeedBack: false,
        //是否重定向返回原页面
        callBack: ret => {
          if (ret.next != 'from') {
            this.$PUSH({
              path: ret.next
            });
          }
        }
      });
    },
    async initData() {
      this.loadFinal = false;
      try {
        let params = {
          current: this.current,
          pageSize: this.pageSize,
          campain_id: this.urlQuery.id
        };
        this.searchWords && (params['filter[name]'] = this.searchWords);
        let res = await this.$api.getCampainSectionList(params);
        this.isReachBottom = false;
        this.loadFinal = true;
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCampainSectionList" + res.errmsg)
          return;
        }
        const finalData = (0, _index.limitData)({
          interfaceData: res,
          baseData: this.sectionList,
          limit: params
        });
        this.sectionList = finalData.baseData;
        finalData.currentAdd && this.current++ && (this.noThenData = false);
        !finalData.currentAdd && (this.noThenData = true);
      } catch (error) {
        console.log('error fot initData', error);
      }
    },
    enterActivityHandleDelay() {
      this.$refs['customPop-tip'].close();
    },
    enterActivityHandleNow() {
      this.$refs['customPop-tip'].close();
    }
  }
});