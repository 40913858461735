"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$dataBase;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "meetingList marginAuto",
    attrs: {
      "event-key": `会议列表-${_vm.dataBase.name}`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: `/meetingdetail?id=${_vm.dataBase.id}&typeMap=${_vm.dataBase.type}`
        });
      }
    }
  }, [(_vm$dataBase = _vm.dataBase) !== null && _vm$dataBase !== void 0 && _vm$dataBase.need_veeva ? _c("div", {
    staticClass: "authIcon"
  }, [_c("img", {
    attrs: {
      alt: "",
      srcset: "",
      src: require("img/common/authIcon.png")
    }
  })]) : _vm._e(), _c("div", {
    staticClass: "meetingListTag flex align-center justify-center",
    style: {
      "--background": _vm.statusArr[_vm.dataBase.status - 1].bgcolor,
      "--color": _vm.statusArr[_vm.dataBase.status - 1].color
    }
  }, [_vm._v(" " + _vm._s(_vm.statusArr[_vm.dataBase.status - 1].word) + " ")]), _c("div", {
    staticClass: "meetingListTop"
  }, [_c("img", {
    attrs: {
      src: _vm.dataBase.cover,
      alt: ""
    }
  })]), _c("div", {
    staticClass: "meetingListBottom"
  }, [_c("div", {
    staticClass: "meetingListName hideWords-More3"
  }, [_vm._v(_vm._s(_vm.dataBase.name))]), _c("div", {
    staticClass: "meetingListBox"
  }, [_c("div", {
    staticClass: "meetingListTime"
  }, [_vm._v(_vm._s(_vm.showFormatTime(_vm.dataBase)))]), _c("div", {
    staticClass: "previewContent hideWords-More3"
  }, [_c("img", {
    staticClass: "previeImg",
    attrs: {
      src: require("img/common/browseCountOther.png"),
      alt: "",
      srcset: ""
    }
  }), _c("span", {
    staticClass: "previewNum"
  }, [_vm._v(_vm._s(_vm.dataBase.read_num))])])])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;