"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "uploadInfo"
  }, [_c("div", {
    staticClass: "uploadInfosLine marginAuto"
  }), _c("div", {
    staticClass: "uploadInfosLinePicList flex flex-wrap justify-between marginAuto"
  }, [_vm.veevaInfo.status == 3 ? _c("div", {
    staticClass: "HistpryuploadInfosLine HistpryuploadInfosLineAdd marginAuto"
  }, [_vm._v("补充材料")]) : _vm._e(), _vm.veevaInfo.status != 1 ? _c("div", {
    staticClass: "uploadInfosLinePicListItem uploadInfosLinePicListItemTipWord flex justify-center align-center flex-direction",
    on: {
      click: _vm.uploadImg
    }
  }, [_c("div", {
    staticClass: "addPlus"
  }, [_c("svg", {
    staticClass: "icon",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      t: "1693995161072",
      viewBox: "0 0 1024 1024",
      version: "1.1",
      xmlns: "http://www.w3.org/2000/svg",
      "p-id": "4012",
      width: "200",
      height: "200"
    }
  }, [_c("path", {
    attrs: {
      d: "M874.666667 469.333333H554.666667V149.333333c0-23.466667-19.2-42.666667-42.666667-42.666666s-42.666667 19.2-42.666667 42.666666v320H149.333333c-23.466667 0-42.666667 19.2-42.666666 42.666667s19.2 42.666667 42.666666 42.666667h320v320c0 23.466667 19.2 42.666667 42.666667 42.666666s42.666667-19.2 42.666667-42.666666V554.666667h320c23.466667 0 42.666667-19.2 42.666666-42.666667s-19.2-42.666667-42.666666-42.666667z",
      fill: "#b3b3b3",
      "p-id": "4013"
    }
  })])]), _c("div", {
    staticClass: "uploadInfosLinePicListItemWord"
  }, [_vm._v("请上传图片")])]) : _vm._e(), _vm._l(_vm.fileListTemp, function (im, ix) {
    var _vm$fileListError, _vm$fileListError2, _vm$fileListError3, _vm$fileListError4;
    return _c("div", {
      key: ix,
      staticClass: "uploadInfosLinePicListItem flex justify-center align-center flex-direction",
      class: [ix == 2 && "uploadInfosLinePicListItemBigTop", ix == 3 && "uploadInfosLinePicListItemBigTop", ix == 4 && "uploadInfosLinePicListItemBigTop"],
      on: {
        click: function ($event) {
          return _vm.checkImg(im);
        }
      }
    }, [_vm.fileListProgress[im.progress] != "100%" && ((_vm$fileListError = _vm.fileListError) === null || _vm$fileListError === void 0 || (_vm$fileListError = _vm$fileListError[im.progress]) === null || _vm$fileListError === void 0 ? void 0 : _vm$fileListError.fileStatus) != "fail" ? _c("div", {
      staticClass: "g-progress",
      style: `--progress:${_vm.fileListProgress[im.progress]}`
    }) : _vm._e(), _vm.fileListProgress[im.progress] != "100%" ? _c("div", {
      staticClass: "g-progress-draw flex justify-center align-center",
      class: _vm.fileListProgress[im.progress] != "100%" && ((_vm$fileListError2 = _vm.fileListError) === null || _vm$fileListError2 === void 0 || (_vm$fileListError2 = _vm$fileListError2[im.progress]) === null || _vm$fileListError2 === void 0 ? void 0 : _vm$fileListError2.fileStatus) != "fail" && "g-progress-draw-white"
    }, [((_vm$fileListError3 = _vm.fileListError) === null || _vm$fileListError3 === void 0 || (_vm$fileListError3 = _vm$fileListError3[im.progress]) === null || _vm$fileListError3 === void 0 ? void 0 : _vm$fileListError3.fileStatus) == "fail" ? _c("div", {
      staticClass: "progress-draw-words"
    }, [_vm._v(" 上传失败！ "), _c("div", [_vm._v("请删除该图片后重新上传")])]) : _vm._e()]) : _vm._e(), _c("div", {
      staticClass: "uploadInfosLinePicListItemClose",
      class: ((_vm$fileListError4 = _vm.fileListError) === null || _vm$fileListError4 === void 0 || (_vm$fileListError4 = _vm$fileListError4[im.progress]) === null || _vm$fileListError4 === void 0 ? void 0 : _vm$fileListError4.fileStatus) == "fail" && "bigZindex",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.removePic(ix);
        }
      }
    }, [_c("svg", {
      staticClass: "icon",
      attrs: {
        t: "1694158254698",
        viewBox: "0 0 1024 1024",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "p-id": "4156",
        width: "200",
        height: "200"
      }
    }, [_c("path", {
      attrs: {
        d: "M507.168 473.232L716.48 263.936a16 16 0 0 1 22.624 0l11.312 11.312a16 16 0 0 1 0 22.624L541.12 507.168 750.4 716.48a16 16 0 0 1 0 22.624l-11.312 11.312a16 16 0 0 1-22.624 0L507.168 541.12 297.872 750.4a16 16 0 0 1-22.624 0l-11.312-11.312a16 16 0 0 1 0-22.624l209.296-209.312-209.296-209.296a16 16 0 0 1 0-22.624l11.312-11.312a16 16 0 0 1 22.624 0l209.296 209.296z",
        fill: "#ffffff",
        "p-id": "4157"
      }
    })])]), _c("img", {
      staticClass: "uploadInfosLinePicListItemPic",
      attrs: {
        src: im.url,
        alt: ""
      }
    })]);
  }), _vm._l(2, function (i) {
    return _c("div", {
      key: i,
      staticClass: "uploadInfosLinePicListItemSaet"
    });
  })], 2), _vm.veevaInfo.status == 3 || _vm.veevaInfo.status == 1 ? _c("div", [_c("div", {
    staticClass: "HistpryuploadInfosLine HistpryuploadInfosLineBig marginAuto fontWeight"
  }, [_vm._v("历史证明材料")]), _vm._l(_vm.veevaInfo.old_imgs, function (km, kx) {
    return _c("div", {
      key: kx
    }, [_c("div", {
      staticClass: "HistpryuploadInfosLine HistpryuploadInfosLineSmall marginAuto"
    }, [_vm._v("第" + _vm._s(kx + 1) + "次上传材料：")]), _c("div", {
      staticClass: "HistprypicuploadInfosLine flex flex-wrap justify-between marginAuto"
    }, [_vm._l(km, function (im, ix) {
      return _c("div", {
        key: ix,
        staticClass: "HistprypicuploadInfosLineItem",
        on: {
          click: function ($event) {
            return _vm.$SHOWIMGPREVIEW(im);
          }
        }
      }, [_c("img", {
        staticClass: "uploadInfosLinePicListItemPic",
        attrs: {
          src: im,
          alt: ""
        }
      })]);
    }), _vm._l(2, function (i) {
      return _c("div", {
        key: i,
        staticClass: "uploadInfosLinePicListItemSaet"
      });
    })], 2)]);
  })], 2) : _vm._e(), _c("div", {
    staticClass: "baseInfosPrev flex align-center justify-center",
    on: {
      click: _vm.back
    }
  }, [_vm._v("上一步")]), _vm.veevaInfo.status == 1 ? _c("div", {
    staticClass: "baseInfosNext baseInfosSubmit flex align-center justify-center",
    on: {
      click: _vm.toPersonal
    }
  }, [_vm._v("返回个人中心")]) : _c("div", {
    staticClass: "baseInfosNext baseInfosSubmit flex align-center justify-center",
    on: {
      click: _vm.submit
    }
  }, [_vm._v("提交")]), _c("vantPopup", {
    model: {
      value: _vm.showPopLoading,
      callback: function ($$v) {
        _vm.showPopLoading = $$v;
      },
      expression: "showPopLoading"
    }
  }, [_c("vantLoading", {
    attrs: {
      type: "spinner",
      color: "#00617f"
    }
  }, [_vm._v("上传中")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;