"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _step = _interopRequireDefault(require("./components/step.vue"));
var _uploadInfo = _interopRequireDefault(require("./components/uploadInfo.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    VeevaStep: _step.default,
    VeevauploadInfo: _uploadInfo.default
  },
  data() {
    return {
      veevaInfo: {},
      isVeevaAuth: false
    };
  },
  watch: {},
  filter: {},
  created() {
    this.$store.commit("SET_LOADINGPOP", true);
    this.getVeevaInfo();
  },
  mounted() {
    setTimeout(() => {
      this.weChatShare.selfLink = `${window.location.origin}/bayer-rad-academy-shadowcloud-academy-html/veeva`;
      this.initWechat();
    }, 0);
  },
  computed: {},
  methods: {
    async getVeevaInfo() {
      let res = await this.$api.getVeevaInfo();
      if ((res === null || res === void 0 ? void 0 : res.errcode) == 10002) {
        this.isVeevaAuth = true;
        this.link = (res === null || res === void 0 ? void 0 : res.link) || "";
        return;
      }
      this.veevaInfo = res;
      if (!(res !== null && res !== void 0 && res.id)) {
        this.$refs.VeevauploadInfo.setStorageBase();
        return;
      }
      let storageVeevaBase = JSON.parse(localStorage.getItem('veevaInfo')) || {};
      let params = {
        ...res,
        ...storageVeevaBase
      };
      this.$refs.VeevauploadInfo.initData(params);
    }
  }
});