"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interaction = _interopRequireDefault(require("@/components/course/interaction.vue"));
var _interactionAnalysis = _interopRequireDefault(require("@/components/course/interactionAnalysis.vue"));
var _comment = _interopRequireDefault(require("@/components/course/comment.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _meetingPoster = _interopRequireDefault(require("@/components/meeting/meetingPoster.vue"));
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    interaction: _interaction.default,
    interactionAnalysis: _interactionAnalysis.default,
    comment: _comment.default,
    meetingPoster: _meetingPoster.default
  },
  name: "activityRichDetail",
  data() {
    return {
      contentDetail: {},
      /* 模块相关 */
      myIsFaver: false,
      myIsZan: false,
      loadZan: false,
      sectionDetail: {},
      showAll: false,
      loadFinish: false,
      /* ----------------- */

      /* 课程形式相关 */
      showModuleCheck: 0,
      formEchoInfo: {},
      detailForm: {},
      /* ----------------- */

      urlQuery: {},
      listCampainContent: [],
      listCampainContentIndex: 0,
      onlySessionNum: this.$GetSessionNum(),
      isReachBottom: false,
      setScoreTimeView: 0,
      thisTimeView: 0
    };
  },
  watch: {},
  filter: {},
  created() {
    this.urlQuery = this.$route.query;
    this.initData();
    this.initReachBottom();
  },
  mounted() {},
  computed: {},
  methods: {
    async initData() {
      let res = await this.$api.getPreviewCampainContentRich({}, this.urlQuery.id);
      console.log(res, '==> reess');
      if (res !== null && res !== void 0 && res.errcode) {
        return;
      }
      this.contentDetail = res;
      (res === null || res === void 0 ? void 0 : res.have_form) && (this.detailForm = res.forms);
    },
    initReachBottom() {
      console.log("asdasdasd");
      window.addEventListener("reachBottom", this.reachBottomHandle);
    },
    reachBottomHandle(evt) {
      var _this$contentDetail;
      console.log("asdasdasd");
      const self = this;
      if (evt.detail.path != "/activityrichdetail") {
        return;
      }
      if (self.isReachBottom) {
        return;
      }
      if (this.showModuleCheck == 1 && (_this$contentDetail = this.contentDetail) !== null && _this$contentDetail !== void 0 && _this$contentDetail.have_comments) {
        var _this$$refs;
        self.isReachBottom = true;
        (_this$$refs = this.$refs) === null || _this$$refs === void 0 || (_this$$refs = _this$$refs.mycomment) === null || _this$$refs === void 0 || _this$$refs.reachBottom();
      }
    }
  }
});