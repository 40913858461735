"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$rankInfo$ranks, _vm$rankHandle, _vm$rankInfo$ranks2, _vm$rankHandle2, _vm$rankInfo$ranks3, _vm$rankHandle3, _vm$rankInfo$ranks4, _vm$rankInfo$ranks5, _vm$rankInfo$ranks6, _vm$rankInfo$ranks7, _vm$rankInfo$ranks8, _vm$rankInfo$my_info, _vm$rankHandle5, _vm$rankHandle6, _vm$rankInfo$my_info2, _vm$rankInfo$my_info3, _vm$rankInfo$my_info4;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container-out"
  }, [_c("stickyHeader"), _c("div", {
    staticClass: "detailModuleCheckOut"
  }, [_c("div", {
    staticClass: "detailModuleCheck flex"
  }, [_c("div", {
    staticClass: "detailModuleCheckItem justify-center align-center flex",
    class: _vm.showModuleCheck == 0 ? "detailModuleCheckItemCheck" : "",
    on: {
      click: function ($event) {
        _vm.showModuleCheck = 0;
      }
    }
  }, [_vm._v("季度学术能量榜")]), _c("div", {
    staticClass: "detailModuleCheckItemLine"
  }), _c("div", {
    staticClass: "detailModuleCheckItem justify-center align-center flex",
    class: _vm.showModuleCheck == 1 ? "detailModuleCheckItemCheck" : "",
    on: {
      click: function ($event) {
        _vm.showModuleCheck = 1;
      }
    }
  }, [_vm._v("总学术能量榜")])])]), _c("div", {
    staticClass: "rankListOut"
  }, [_c("div", {
    staticClass: "rankListInner"
  }, [_c("div", {
    staticClass: "rankListInnerTopThree"
  }, [((_vm$rankInfo$ranks = _vm.rankInfo.ranks) === null || _vm$rankInfo$ranks === void 0 ? void 0 : _vm$rankInfo$ranks.length) > 0 ? _c("div", {
    staticClass: "rankListInnerTopThree1"
  }, [_c("div", {
    staticClass: "rankListInnerTopThreeAvatar rankListInnerTopThreeAvatar1"
  }, [_c("div", {
    staticClass: "rankListInnerTopThreeAvatarIcon"
  }, [_c("Picture", {
    attrs: {
      src: ((_vm$rankHandle = _vm.rankHandle(_vm.rankInfo.ranks[0])) === null || _vm$rankHandle === void 0 ? void 0 : _vm$rankHandle.avatar) || _vm.$DEFAULTAVATAR
    }
  })], 1), _c("img", {
    staticClass: "rankListInnerTopThreeAvatarRanking",
    attrs: {
      src: require("img/personal/rank/first.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "rankListInnerTopThreeName rankListInnerTopThreeName1"
  }, [_vm._v(" " + _vm._s(_vm.rankHandle(_vm.rankInfo.ranks[0]).name) + " ")]), _c("div", {
    staticClass: "rankListInnerTopThreeLevel rankListInnerTopThreeLevel1"
  }, [_vm._v(" Lv." + _vm._s(_vm.rankInfo.ranks[0].level) + " " + _vm._s(_vm.rankInfo.ranks[0].level_name) + " ")]), _c("div", {
    staticClass: "rankListInnerTopThreeCount rankListInnerTopThreeCount1"
  }, [_vm._v(" " + _vm._s(_vm.rankInfo.ranks[0].ranks_score) + " ")])]) : _vm._e(), ((_vm$rankInfo$ranks2 = _vm.rankInfo.ranks) === null || _vm$rankInfo$ranks2 === void 0 ? void 0 : _vm$rankInfo$ranks2.length) > 1 ? _c("div", {
    staticClass: "rankListInnerTopThree2"
  }, [_c("div", {
    staticClass: "rankListInnerTopThreeAvatar rankListInnerTopThreeAvatar2"
  }, [_c("div", {
    staticClass: "rankListInnerTopThreeAvatarIcon"
  }, [_c("Picture", {
    attrs: {
      src: ((_vm$rankHandle2 = _vm.rankHandle(_vm.rankInfo.ranks[1])) === null || _vm$rankHandle2 === void 0 ? void 0 : _vm$rankHandle2.avatar) || _vm.$DEFAULTAVATAR
    }
  })], 1), _c("img", {
    staticClass: "rankListInnerTopThreeAvatarRanking",
    attrs: {
      src: require("img/personal/rank/second.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "rankListInnerTopThreeName rankListInnerTopThreeName1"
  }, [_vm._v(" " + _vm._s(_vm.rankHandle(_vm.rankInfo.ranks[1]).name) + " ")]), _c("div", {
    staticClass: "rankListInnerTopThreeLevel rankListInnerTopThreeLevel1"
  }, [_vm._v(" Lv." + _vm._s(_vm.rankInfo.ranks[1].level) + " " + _vm._s(_vm.rankInfo.ranks[1].level_name) + " ")]), _c("div", {
    staticClass: "rankListInnerTopThreeCount rankListInnerTopThreeCount1"
  }, [_vm._v(" " + _vm._s(_vm.rankInfo.ranks[1].ranks_score) + " ")])]) : _vm._e(), ((_vm$rankInfo$ranks3 = _vm.rankInfo.ranks) === null || _vm$rankInfo$ranks3 === void 0 ? void 0 : _vm$rankInfo$ranks3.length) > 2 ? _c("div", {
    staticClass: "rankListInnerTopThree3"
  }, [_c("div", {
    staticClass: "rankListInnerTopThreeAvatar rankListInnerTopThreeAvatar3"
  }, [_c("div", {
    staticClass: "rankListInnerTopThreeAvatarIcon"
  }, [_c("Picture", {
    attrs: {
      src: ((_vm$rankHandle3 = _vm.rankHandle((_vm$rankInfo$ranks4 = _vm.rankInfo.ranks) === null || _vm$rankInfo$ranks4 === void 0 ? void 0 : _vm$rankInfo$ranks4[2])) === null || _vm$rankHandle3 === void 0 ? void 0 : _vm$rankHandle3.avatar) || _vm.$DEFAULTAVATAR
    }
  })], 1), _c("img", {
    staticClass: "rankListInnerTopThreeAvatarRanking",
    attrs: {
      src: require("img/personal/rank/three.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "rankListInnerTopThreeName rankListInnerTopThreeName1"
  }, [_vm._v(" " + _vm._s(_vm.rankHandle((_vm$rankInfo$ranks5 = _vm.rankInfo.ranks) === null || _vm$rankInfo$ranks5 === void 0 ? void 0 : _vm$rankInfo$ranks5[2]).name) + " ")]), _c("div", {
    staticClass: "rankListInnerTopThreeLevel rankListInnerTopThreeLevel1"
  }, [_vm._v(" Lv." + _vm._s((_vm$rankInfo$ranks6 = _vm.rankInfo.ranks) === null || _vm$rankInfo$ranks6 === void 0 ? void 0 : _vm$rankInfo$ranks6[2].level) + " " + _vm._s((_vm$rankInfo$ranks7 = _vm.rankInfo.ranks) === null || _vm$rankInfo$ranks7 === void 0 ? void 0 : _vm$rankInfo$ranks7[2].level_name) + " ")]), _c("div", {
    staticClass: "rankListInnerTopThreeCount rankListInnerTopThreeCount1"
  }, [_vm._v(" " + _vm._s((_vm$rankInfo$ranks8 = _vm.rankInfo.ranks) === null || _vm$rankInfo$ranks8 === void 0 ? void 0 : _vm$rankInfo$ranks8[2].ranks_score) + " ")])]) : _vm._e()]), _vm._l(_vm.rankInfo.ranks, function (im, ix) {
    var _vm$rankHandle4;
    return _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: ix > 2,
        expression: "ix > 2"
      }],
      key: ix,
      staticClass: "rankListInnerList"
    }, [_c("div", {
      staticClass: "rankListInnerListItem flex align-center justify-between"
    }, [_c("div", {
      staticClass: "flex align-center rankListInnerListItemLeft justify-between"
    }, [_c("div", {
      staticClass: "rankListInnerListItemSort"
    }, [_vm._v(_vm._s(ix + 1))]), _c("div", {
      staticClass: "rankListInnerListItemUserInfo flex align-center"
    }, [_c("div", {
      staticClass: "rankListInnerListItemUserInfoAvatar"
    }, [_c("Picture", {
      attrs: {
        src: ((_vm$rankHandle4 = _vm.rankHandle(im)) === null || _vm$rankHandle4 === void 0 ? void 0 : _vm$rankHandle4.avatar) || _vm.$DEFAULTAVATAR
      }
    })], 1), _c("div", {
      staticClass: "rankListInnerListItemUserInfoOther flex flex-direction justify-between"
    }, [_c("div", {
      staticClass: "rankListInnerListItemUserInfoOtherName"
    }, [_vm._v(_vm._s(_vm.rankHandle(im).name))]), _c("div", {
      staticClass: "rankListInnerListItemUserInfoOtherLevel"
    }, [_c("span", [_vm._v("Lv." + _vm._s(im.level))]), _vm._v(" " + _vm._s(im.level_name))])])])]), _c("div", {
      staticClass: "rankListInnerListItemRight"
    }, [_vm._v(_vm._s(im.ranks_score))])])]);
  })], 2), _c("div", {
    staticClass: "fixedSeat"
  })]), _vm.userInfo.type != 2 && _vm.isShowRank() ? _c("div", {
    staticClass: "rankListInnerListItem rankListInnerListItemFixed flex align-center justify-between"
  }, [(_vm.userInfo.veeva != 1 || _vm.userInfo.veeva_succ_tip != 1) && _vm.showModuleCheck == 0 ? _c("div", {
    staticClass: "rankTipPop"
  }, [_c("div", {
    staticClass: "rankTipPopInner marginAuto flex align-center justify-between"
  }, [_c("div", {
    staticClass: "rankTipPopInnerLeft"
  }, [_vm._v("您的季度排名")]), _c("div", {
    staticClass: "rankTipPopInnerCenter"
  }, [_vm._v("通过医疗卫生专业人士身份认证，即可解锁！")]), _c("div", {
    staticClass: "rankTipPopInnerRight",
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/veeva"
        });
      }
    }
  }, [_vm._v("点击前往")])])]) : _vm._e(), _c("div", {
    staticClass: "flex align-center rankListInnerListItemLeft justify-between"
  }, [_vm.showModuleCheck == 0 && _vm.userInfo.veeva != 1 ? _c("div", {
    staticClass: "rankListInnerListItemSort importantWhite"
  }, [_vm._v("?")]) : _c("div", {
    staticClass: "rankListInnerListItemSort importantWhite"
  }, [_vm._v(_vm._s((_vm$rankInfo$my_info = _vm.rankInfo.my_info) === null || _vm$rankInfo$my_info === void 0 ? void 0 : _vm$rankInfo$my_info.my_ranks))]), _c("div", {
    staticClass: "rankListInnerListItemUserInfo flex align-center"
  }, [_c("div", {
    staticClass: "rankListInnerListItemUserInfoAvatar"
  }, [_c("Picture", {
    attrs: {
      src: ((_vm$rankHandle5 = _vm.rankHandle(_vm.rankInfo.my_info)) === null || _vm$rankHandle5 === void 0 ? void 0 : _vm$rankHandle5.avatar) || _vm.$DEFAULTAVATAR
    }
  })], 1), _c("div", {
    staticClass: "rankListInnerListItemUserInfoOther flex flex-direction justify-between"
  }, [_c("div", {
    staticClass: "rankListInnerListItemUserInfoOtherName importantWhite flex algin-center"
  }, [_vm._v(" " + _vm._s((_vm$rankHandle6 = _vm.rankHandle(_vm.rankInfo.my_info)) === null || _vm$rankHandle6 === void 0 ? void 0 : _vm$rankHandle6.name) + " "), _vm.showModuleCheck == 1 ? _c("img", {
    staticClass: "rankListInnerListItemUserInfoOtherNameEdit",
    attrs: {
      src: require("img/personal/userInfoLeftProgressTip.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/rankinfo"
        });
      }
    }
  }) : _vm._e()]), _c("div", {
    staticClass: "rankListInnerListItemUserInfoOtherLevel importantWhite"
  }, [_c("span", [_vm._v("Lv." + _vm._s((_vm$rankInfo$my_info2 = _vm.rankInfo.my_info) === null || _vm$rankInfo$my_info2 === void 0 ? void 0 : _vm$rankInfo$my_info2.level))]), _vm._v(" " + _vm._s((_vm$rankInfo$my_info3 = _vm.rankInfo.my_info) === null || _vm$rankInfo$my_info3 === void 0 ? void 0 : _vm$rankInfo$my_info3.level_name))])])])]), _c("div", {
    staticClass: "rankListInnerListItemRight importantWhite"
  }, [_vm._v(_vm._s((_vm$rankInfo$my_info4 = _vm.rankInfo.my_info) === null || _vm$rankInfo$my_info4 === void 0 ? void 0 : _vm$rankInfo$my_info4.ranks_score))])]) : _vm._e(), _c("vanPopup", {
    attrs: {
      "close-on-click-overlay": false
    },
    model: {
      value: _vm.showUnlockPop,
      callback: function ($$v) {
        _vm.showUnlockPop = $$v;
      },
      expression: "showUnlockPop"
    }
  }, [_c("div", {
    staticClass: "showUnlockPop"
  }, [_c("div", {
    staticClass: "showUnlockPopImg"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/rank/unlockBG.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "showUnlockPopImgTitle"
  }, [_vm._v("认证成就达成！")]), _c("div", {
    staticClass: "showUnlockPopImgDesc"
  }, [_vm._v("可解锁学术能量季度排行榜")]), _c("div", {
    staticClass: "showUnlockPopBtn flex align-center justify-center",
    on: {
      click: _vm.lockVeeva
    }
  }, [_vm._v("立即解锁")])]), _c("div", {
    staticClass: "showUnlockPopCloseIcon",
    on: {
      click: function ($event) {
        return _vm.$GO(-1);
      }
    }
  }, [_c("svg", {
    staticClass: "icon",
    staticStyle: {
      fill: "#fff"
    },
    attrs: {
      viewBox: "0 0 1024 1024",
      version: "1.1",
      xmlns: "http://www.w3.org/2000/svg",
      "p-id": "3110"
    }
  }, [_c("path", {
    attrs: {
      d: "M810.666667 273.706667L750.293333 213.333333 512 451.626667 273.706667 213.333333 213.333333 273.706667 451.626667 512 213.333333 750.293333 273.706667 810.666667 512 572.373333 750.293333 810.666667 810.666667 750.293333 572.373333 512z",
      "p-id": "3111"
    }
  })])])])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;