"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$listCampainConten, _vm$listCampainConten2, _vm$listCampainConten3, _vm$listCampainConten4, _vm$listCampainConten5, _vm$listCampainConten6, _vm$listCampainConten7, _vm$listCampainConten8, _vm$listCampainConten9, _vm$listCampainConten10, _vm$listCampainConten11, _vm$listCampainConten12, _vm$listCampainConten13, _vm$formEchoInfo, _vm$listCampainConten14, _vm$listCampainConten15, _vm$listCampainConten16, _vm$listCampainConten17;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container page-container-outview"
  }, [_c("stickyHeader"), _vm.listCampainContent.length ? _c("div", {
    staticClass: "stickyBox"
  }, [_vm.listCampainContent.length ? _c("div", {
    staticClass: "handleList flex flex-direction justify-between"
  }, [_vm._m(0), _c("div", {
    staticClass: "handleListItem flex align-center justify-center"
  }, [_c("img", {
    staticClass: "handleListItemStarIcon",
    attrs: {
      src: require("img/common/starWhite.png"),
      alt: ""
    }
  }), _vm._v(" " + _vm._s(_vm.sectionDetail.faver || 0) + " ")]), _c("div", {
    staticClass: "handleListItem flex align-center justify-center"
  }, [_c("img", {
    staticClass: "handleListItemGiveIcon",
    attrs: {
      src: require("img/common/giveWhite.png"),
      alt: ""
    }
  }), _vm._v(" " + _vm._s(_vm.sectionDetail.zan || 0) + " ")])]) : _vm._e(), _c("div", {
    staticClass: "detailTitle"
  }, [_vm._v(_vm._s((_vm$listCampainConten = _vm.listCampainContent[_vm.listCampainContentIndex]) === null || _vm$listCampainConten === void 0 ? void 0 : _vm$listCampainConten.name))]), (_vm$listCampainConten2 = _vm.listCampainContent[_vm.listCampainContentIndex]) !== null && _vm$listCampainConten2 !== void 0 && _vm$listCampainConten2.speaker ? _c("div", {
    staticClass: "detailAuthor"
  }, [_vm._v("作者：" + _vm._s((_vm$listCampainConten3 = _vm.listCampainContent[_vm.listCampainContentIndex]) === null || _vm$listCampainConten3 === void 0 ? void 0 : _vm$listCampainConten3.speaker))]) : _vm._e(), (_vm$listCampainConten4 = _vm.listCampainContent[_vm.listCampainContentIndex]) !== null && _vm$listCampainConten4 !== void 0 && _vm$listCampainConten4.hospital ? _c("div", {
    staticClass: "detailAuthor"
  }, [_vm._v("讲者所在医院：" + _vm._s((_vm$listCampainConten5 = _vm.listCampainContent[_vm.listCampainContentIndex]) === null || _vm$listCampainConten5 === void 0 ? void 0 : _vm$listCampainConten5.hospital))]) : _vm._e(), _c("div", {
    staticClass: "detailHeaderTag flex flex-wrap"
  }, _vm._l((_vm$listCampainConten6 = _vm.listCampainContent[_vm.listCampainContentIndex]) === null || _vm$listCampainConten6 === void 0 ? void 0 : _vm$listCampainConten6.tag_name_list, function (im, ix) {
    return _c("div", {
      key: ix,
      staticClass: "detailHeaderTagItem"
    }, [_vm._v(_vm._s(im))]);
  }), 0), _c("div", {
    staticClass: "detailVideo fixedScale"
  }, [(_vm$listCampainConten7 = _vm.listCampainContent[_vm.listCampainContentIndex]) !== null && _vm$listCampainConten7 !== void 0 && _vm$listCampainConten7.video_url ? _c("customVideo", {
    ref: "customVideo",
    attrs: {
      listDetail: _vm.listCampainContent[_vm.listCampainContentIndex],
      src: (_vm$listCampainConten8 = _vm.listCampainContent[_vm.listCampainContentIndex]) === null || _vm$listCampainConten8 === void 0 ? void 0 : _vm$listCampainConten8.video_url
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "detailModuleCheck flex"
  }, [_c("div", {
    staticClass: "detailModuleCheckItem justify-center align-center flex",
    class: _vm.showModuleCheck == 0 ? "detailModuleCheckItemCheck" : "",
    on: {
      click: function ($event) {
        _vm.showModuleCheck = 0;
      }
    }
  }, [_vm._v("目录")]), _c("div", {
    staticClass: "detailModuleCheckItemLine"
  }), _c("div", {
    staticClass: "detailModuleCheckItem justify-center align-center flex",
    class: _vm.showModuleCheck == 1 ? "detailModuleCheckItemCheck" : "",
    on: {
      click: function ($event) {
        _vm.showModuleCheck = 1;
      }
    }
  }, [_vm._v("简介")]), (_vm$listCampainConten9 = _vm.listCampainContent[_vm.listCampainContentIndex]) !== null && _vm$listCampainConten9 !== void 0 && _vm$listCampainConten9.have_form ? _c("div", {
    staticClass: "detailModuleCheckItemLine"
  }) : _vm._e(), (_vm$listCampainConten10 = _vm.listCampainContent[_vm.listCampainContentIndex]) !== null && _vm$listCampainConten10 !== void 0 && _vm$listCampainConten10.have_form ? _c("div", {
    staticClass: "detailModuleCheckItem justify-center align-center flex",
    class: _vm.showModuleCheck == 2 ? "detailModuleCheckItemCheck" : "",
    on: {
      click: function ($event) {
        _vm.showModuleCheck = 2;
      }
    }
  }, [_vm._v("互动")]) : _vm._e(), (_vm$listCampainConten11 = _vm.listCampainContent[_vm.listCampainContentIndex]) !== null && _vm$listCampainConten11 !== void 0 && _vm$listCampainConten11.have_comments ? _c("div", {
    staticClass: "detailModuleCheckItemLine"
  }) : _vm._e(), (_vm$listCampainConten12 = _vm.listCampainContent[_vm.listCampainContentIndex]) !== null && _vm$listCampainConten12 !== void 0 && _vm$listCampainConten12.have_comments ? _c("div", {
    staticClass: "detailModuleCheckItem justify-center align-center flex",
    class: _vm.showModuleCheck == 3 ? "detailModuleCheckItemCheck" : "",
    on: {
      click: function ($event) {
        _vm.showModuleCheck = 3;
      }
    }
  }, [_vm._v("评论")]) : _vm._e()])]) : _vm._e(), _vm.listCampainContent.length ? _c("div", {
    staticClass: "folderList"
  }, [_vm.showModuleCheck == 0 ? _c("videoList", {
    attrs: {
      module: "activity",
      playIndex: _vm.listCampainContentIndex,
      dataBase: _vm.listCampainContent,
      getWords: "name"
    },
    on: {
      dataIndex: evt => _vm.listCampainContentIndex = evt
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.showModuleCheck == 1 && _vm.listCampainContent.length ? _c("div", {
    staticClass: "introductionContent ql-editor",
    domProps: {
      innerHTML: _vm._s(_vm.$VIDEOPLAYSINLINE(_vm.showEdtier((_vm$listCampainConten13 = _vm.listCampainContent[_vm.listCampainContentIndex]) === null || _vm$listCampainConten13 === void 0 ? void 0 : _vm$listCampainConten13.content)))
    },
    on: {
      click: _vm.$imageEnlargement
    }
  }) : _vm._e(), _vm.showModuleCheck == 2 && _vm.listCampainContent[_vm.listCampainContentIndex].have_form && !((_vm$formEchoInfo = _vm.formEchoInfo) !== null && _vm$formEchoInfo !== void 0 && _vm$formEchoInfo.id) ? _c("interaction", {
    attrs: {
      mode: "preview",
      formInfo: (_vm$listCampainConten14 = _vm.listCampainContent[_vm.listCampainContentIndex]) === null || _vm$listCampainConten14 === void 0 ? void 0 : _vm$listCampainConten14.forms
    }
  }) : _vm._e(), _vm.showModuleCheck == 2 && _vm.listCampainContent[_vm.listCampainContentIndex].have_form && _vm.formEchoInfo.id ? _c("interactionAnalysis", {
    attrs: {
      preview: true,
      dataBase: _vm.formEchoInfo
    }
  }) : _vm._e(), _vm.showModuleCheck == 989898 && (_vm$listCampainConten15 = _vm.listCampainContent[_vm.listCampainContentIndex]) !== null && _vm$listCampainConten15 !== void 0 && _vm$listCampainConten15.have_comments ? _c("comment", {
    ref: "mycomment",
    attrs: {
      preview: true
    }
  }) : _vm._e(), _vm.showModuleCheck == 3 ? _c("div", {
    staticStyle: {
      width: "100%",
      height: "30px"
    }
  }) : _vm._e(), (_vm$listCampainConten16 = _vm.listCampainContent[_vm.listCampainContentIndex]) !== null && _vm$listCampainConten16 !== void 0 && _vm$listCampainConten16.id ? _c("meetingPoster", {
    attrs: {
      dataBase: _vm.listCampainContent[_vm.listCampainContentIndex],
      posterType: 7,
      tagList: (_vm$listCampainConten17 = _vm.listCampainContent[_vm.listCampainContentIndex]) === null || _vm$listCampainConten17 === void 0 ? void 0 : _vm$listCampainConten17.tag_name_list
    }
  }) : _vm._e(), _c("customPop", {
    ref: "customPop",
    attrs: {
      tipWords: _vm.tipWords
    },
    on: {
      cancle: function ($event) {
        return _vm.$GO(-1);
      }
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "handleListItem flex align-center justify-center"
  }, [_c("img", {
    staticClass: "handleListItemShareIcon",
    attrs: {
      src: require("img/common/shareWhite.png"),
      alt: ""
    }
  }), _vm._v(" 分享 ")]);
}];
render._withStripped = true;