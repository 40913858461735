"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = _interopRequireDefault(require("utils/http"));
var _default = exports.default = {
  /* 内容-详情 */
  getContentDetail(params, contentId) {
    return (0, _http.default)({
      url: `/api/platform/content/${contentId}`,
      method: 'get'
    });
  },
  /* 全部内容-列表 */
  getAllContent(params, contentId) {
    return (0, _http.default)({
      url: `/api/platform/content`,
      method: 'get',
      params
    });
  },
  /* 全部内容-标签 */
  getAllContentTag(params, contentId) {
    return (0, _http.default)({
      url: `/api/platform/content?get_tag=1`,
      // url: `/api/platform/content/tags`,
      method: 'get',
      params
    });
  },
  /* 内容-收藏 */
  contentFaver(params) {
    return (0, _http.default)({
      url: `/api/platform/content/faver`,
      method: 'post',
      data: params
    });
  },
  /* 内容-取消收藏 */
  cancleContentFaver(params) {
    return (0, _http.default)({
      url: `/api/platform/content/faver-delete`,
      method: 'post',
      data: params
    });
  },
  /* 内容-点赞 */
  contentZan(params) {
    return (0, _http.default)({
      url: `/api/platform/content-zan-log`,
      method: 'post',
      data: params
    });
  },
  /* 内容-取消点赞 */
  cancleContentZan(params) {
    return (0, _http.default)({
      url: `/api/platform/content-zan-log-delete`,
      method: 'post',
      data: params
    });
  },
  /* 课程首页-banner */
  getCourseHomeBanner(params, content_id, type) {
    return (0, _http.default)({
      url: `/api/platform/lesson/banner`,
      method: 'get',
      params
    });
  },
  /* 课程首页-精讲堂 */
  getCourseHomeLessonList(params, content_id, type) {
    return (0, _http.default)({
      url: `/api/platform/lesson/lesson-list`,
      method: 'get',
      params
    });
  },
  /* 课程首页-训练营 */
  getCourseHomeCampainList(params, content_id, type) {
    return (0, _http.default)({
      url: `/api/platform/lesson/campain-list`,
      method: 'get',
      params
    });
  },
  /* 课程首页-列表（course/class?id=id） */
  getCourseLessonList(params, id) {
    return (0, _http.default)({
      url: `/api/platform/lesson/lesson`,
      method: 'get',
      params
    });
  },
  /* 课程二级页-标签集合（course/class?id=id） */
  getCourseLessonTagList(params, id) {
    return (0, _http.default)({
      url: `/api/platform/lesson/lesson?get_tag=1`,
      // url: `/api/platform/lesson/lesson/tag-select`,
      method: 'get',
      params
    });
  },
  /* 内容-表单-查询（用来回显） */
  getFormEchoInfo(params, content_id, type) {
    return (0, _http.default)({
      url: `/api/platform/content/form/${content_id}/${type}`,
      method: 'get',
      params
    });
  },
  /* 内容-表单-提交 */
  putFormInfo(params) {
    return (0, _http.default)({
      url: `/api/platform/content/form`,
      method: 'post',
      data: params
    });
  },
  /* 内容-收藏-检查是否收藏 */
  getFaverCheck(content_id, type) {
    return (0, _http.default)({
      url: `/api/platform/content/faver-check/${content_id}/${type}`,
      method: 'get'
    });
  },
  /* 内容-点赞-检查是否点赞 */
  getZanCheck(content_id, type) {
    return (0, _http.default)({
      url: `/api/platform/content-zan-log-check/${content_id}/${type}`,
      method: 'get'
    });
  },
  /* 评论-创建 */
  createComment(params) {
    return (0, _http.default)({
      url: `/api/platform/comments`,
      method: 'post',
      data: params
    });
  },
  /* 评论-查询列表 */
  getCommentList(params) {
    return (0, _http.default)({
      url: `/api/platform/comments`,
      method: 'get',
      params
    });
  },
  /* 评论-点赞 */
  setCommentZan(params) {
    return (0, _http.default)({
      url: `/api/platform/comments-zan-log`,
      method: 'post',
      data: params
    });
  },
  /* 评论-取消点赞 */
  delCommentZan(params) {
    return (0, _http.default)({
      url: `/api/platform/comments-zan-log-delete`,
      method: 'post',
      data: params
    });
  },
  /* 病例-列表 */
  getDisease(params, id) {
    return (0, _http.default)({
      url: `/api/platform/disease`,
      method: 'get',
      params
    });
  },
  /* 病例-标签 */
  getDiseaseTagList(params, id) {
    return (0, _http.default)({
      url: `/api/platform/disease?get_tag=1`,
      // url: `/api/platform/disease/tags`,
      method: 'get',
      params
    });
  }
};