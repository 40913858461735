"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.resetRouter = resetRouter;
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _vue = _interopRequireDefault(require("vue"));
var _store = _interopRequireDefault(require("@/store"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _router = require("./router.config");
var _wechat = require("utils/wechat");
var _http = _interopRequireDefault(require("utils/http"));
var _config = require("@/config");
var _register = _interopRequireDefault(require("@/api/modules/register.js"));
// hack router push callback
const originalPush = _vueRouter.default.prototype.push;
_vueRouter.default.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
_vue.default.use(_vueRouter.default);
const createRouter = () => new _vueRouter.default({
  mode: 'history',
  // 如果你是 history模式 需要配置vue.config.js publicPath
  base: process.env.BASE_URL,
  scrollBehavior: () => ({
    y: 0
  }),
  routes: _router.constantRouterMap
});
const router = createRouter();
const checkRouter = async (to, from, next) => {
  const {
    meta,
    query
  } = to;
  const openid = localStorage.getItem('openid');
  const wechatInfo = localStorage.getItem('wechatInfo');
  let isRegister = localStorage.getItem('isRegister');
  console.log(openid, '==>openid----------------');
  console.log(wechatInfo, '==>wechatInfo----------------');
  console.log(isRegister, '==>isRegister----------------');

  /* if (isRegister && meta.registerNoEnter) {
    next("/")
  }
    store.dispatch("getUserInfo") */

  if ((meta.checkUser || meta.getUser) && openid && wechatInfo && !isRegister) {
    const res = await _register.default.userInfo();
    if (res !== null && res !== void 0 && res.id) {
      localStorage.setItem('isRegister', 'true');
      isRegister = true;
    }
  }

  /* 针对于导入的老用户 */
  if (meta.checkUser && openid && wechatInfo && isRegister) {
    console.log(to, '==>to>to>to');
    if (to.name == 'importRegister' || to.name == 'hospitalLose') {
      next();
      throw '';
    }
    console.log('=============================================*******====================================');
    const res = await _register.default.userInfo();
    if (res !== null && res !== void 0 && res.id && !(res !== null && res !== void 0 && res.reconfirmation)) {
      _vue.default.prototype.$importRegisterView.show(ret => {
        if (ret.next == 'from') {
          next(from);
        } else {
          const strUrl = `${ret.next}?redirect=${encodeURIComponent(to.fullPath)}`;
          const toUrl = from.query.sharedata ? `${strUrl}&gotohome=true` : strUrl;
          next(toUrl);
        }
      }, !!from.query.sharedata, true);
      throw '';
    }
    if (res !== null && res !== void 0 && res.id && res !== null && res !== void 0 && res.re_perfect_type) {
      _vue.default.prototype.$hospitalLoseView.show(ret => {
        if (ret.next == 'from') {
          next(from);
        } else {
          // let strUrl = `${ret.next}?redirect=${encodeURIComponent(to.fullPath)}`
          const toUrl = `${ret.next}?gotohome=true`;
          console.log(toUrl, '==toUrl>toUrl>toUrl');
          next(toUrl);
        }
      }, !!from.query.sharedata, true);
      throw '';
    }
  }
  if (meta.checkUser && (!openid || !wechatInfo || !isRegister) && !meta.ingoreCheck) {
    _vue.default.prototype.$registerView.show(ret => {
      if (ret.next == 'from') {
        next(from);
      } else {
        const strUrl = `${ret.next}?redirect=${encodeURIComponent(to.fullPath)}`;
        const toUrl = from.query.sharedata ? `${strUrl}&gotohome=true` : strUrl;
        next(toUrl);
      }
    }, !!from.query.sharedata, true);
    // }, from.matched.length ? false : true, true)

    // return
    throw '';
  }
  console.log('aaaaaaaaaa========================================');
  console.log(isRegister, '==>isRegister-------------------------------------isRegisterisRegister');

  /* const openid = localStorage.getItem("openid")
  const wechatInfo = localStorage.getItem("wechatInfo")
  let isRegister = localStorage.getItem("isRegister") */

  // throw ""

  if (isRegister && meta.registerNoEnter) {
    next('/');
  }
  _store.default.dispatch('getUserInfo');
};

// 全局守卫
router.beforeEach(async (to, from, next) => {
  if (to.path == '/activity/detail' && !to.query.typeMap) {
    to.query['typeMap'] = 7;
  }
  if (to.path == '/meetingdetail' && !to.query.typeMap) {
    to.query['typeMap'] = 4;
  }
  const {
    meta,
    query
  } = to;
  document.title = meta.customTitle ? query.title : meta.title;
  _vue.default.prototype.$loading.show({
    color: '#666',
    styles: 'opacity'
  });
  /**
   * 页面进入流程
   * 1 是否需要授权（不需要授权直接进入页面场景）
   * 2 k8s || 固定服务器，分别不同的处理逻辑
   * 3 是否获取到授权信息（保存授权后返回的 openid, wechatInfo）
   * 4 是否已保存授权信息（没有授权信息，进行授权）
   * 5 进入页面
   */
  // 1 是否需要授权
  if (!meta.wechatAuth) {
    next();
    _vue.default.prototype.$loading.hide();
    return;
  }

  // k8s
  if (_config.deploy.oauth === 'middle-platform') {
    console.log('deploy.oauth', _config.deploy.oauth);
    // 3 返回的用户信息、openid
    const {
      openid
    } = query;
    if (openid) _store.default.dispatch('setWechatInfo', query); // 保存用户信息

    // 4 是否授权
    if (!_store.default.getters.openid) {
      const url = (0, _wechat.authUrl)();
      console.log('url', url);
      _store.default.dispatch('setRedirect', window.location.href); // 保存 url
      window.location.replace(url); // 请求中台授权
      return;
    }

    // 4 授权结束，替换 url
    const redirect = _store.default.getters.redirect;
    if (redirect) {
      console.log('redirect', redirect);
      _store.default.dispatch('removeRedirect'); // 清除 url
      window.location.replace(redirect); // 当前的 url 带着微信的参数，需要 replace 到原地址
      return;
    }
    if (openid) {
      const href = window.location.href;
      const url = new URL(href);

      // 定义需要删除的参数名
      const paramsToDelete = ['openid', 'token', 'unionid'];

      // 循环检查并删除参数
      paramsToDelete.forEach(param => {
        if (url.searchParams.has(param)) {
          url.searchParams.delete(param);
        }
      });

      // 使用修改后的URL替换当前页面地址
      window.location.replace(url.toString());
      console.log('openid', openid, url);
      return;
    }
    // 5 进入页面
    next();
    _vue.default.prototype.$loading.hide();
    return;
  }

  // 观星
  if (_config.deploy.oauth === 'look-star') {
    console.log('deploy.oauth', _config.deploy.oauth);
    // 3 返回的用户信息、openid
    const {
      openid,
      typeMap
    } = query;
    if (openid) _store.default.dispatch('setWechatInfo', query); // 保存用户信息

    // 4 是否授权
    if (!localStorage.getItem('openid') || !_store.default.getters.openid) {
      // if (!store.getters.openid) {
      const url = (0, _wechat.starUrl)();
      console.log('url', url);
      _store.default.dispatch('setRedirect', window.location.href); // 保存 url
      window.location.replace(url); // 请求授权
      return;
    }

    // 4 授权结束，替换 url
    const redirect = _store.default.getters.redirect;
    if (redirect) {
      console.log('redirect', redirect);
      _store.default.dispatch('removeRedirect'); // 清除 url
      window.location.replace(redirect); // 当前的 url 带着微信的参数，需要 replace 到原地址
      return;
    }

    // 兼容本地redirect丢失处理，如：企业微信打开/授权过程中清理缓存
    if (openid) {
      console.log('openid', openid);
      const href = window.location.href;
      const url = new URL(href);

      // 定义需要删除的参数名
      const paramsToDelete = ['openid', 'token', 'unionid'];

      // 循环检查并删除参数
      paramsToDelete.forEach(param => {
        if (url.searchParams.has(param)) {
          url.searchParams.delete(param);
        }
      });

      // 使用修改后的URL替换当前页面地址
      window.location.replace(url.toString());
      return;
    }
    await checkRouter(to, from, next);
    // 5 进入页面
    next();
    _vue.default.prototype.$loading.hide();
    return;
  }

  // 个人授权, 加个 if 方便折叠代码块
  if (_config.deploy.oauth === 'self') {
    if (_store.default.getters.openid) {
      next();
      _vue.default.prototype.$loading.hide();
      return;
    }

    // 判断 code
    const {
      code
    } = query;
    if (code) {
      (0, _http.default)({
        url: '/api/oauth',
        method: 'get',
        params: {
          code
        }
      }).then(res => {
        const redirect = _store.default.getters.redirect;
        if (redirect) {
          _store.default.dispatch('setWechatInfo', res); // 保存用户信息
          _store.default.dispatch('removeRedirect'); // 清除 url
          window.location.replace(redirect); // 当前的 url 带着微信的参数，需要 replace 到原地址
          return;
        }
        _vue.default.prototype.$loading.hide();
        next();
      });
    } else {
      // 获取 code
      const currentUrl = window.location.href;
      _store.default.dispatch('setRedirect', currentUrl); // 保存 url
    }
  }
});

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;