"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _index = require("@/utils/index.js");
var _index2 = _interopRequireDefault(require("@/components/customSelect/index.vue"));
var _pattern = require("@/utils/pattern.js");
var _hospital = _interopRequireDefault(require("@/utils/hospital.json"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    customSelect: _index2.default
  },
  data() {
    return {
      getSms: new _index.getSmsAfter(),
      tipWords: "",
      pageQuery: null,
      hospital: _hospital.default,
      formData: {
        privacy: false,
        // terms: false,
        firstNick: false,
        firstAvatar: false
      },
      hospitalOther: "",
      encryptionArray: ["name", "phone"],
      allHospitalSelectList: [],
      formDataRule: {
        name: [{
          required: true,
          message: "请填写姓名"
        }],
        phone: [{
          required: true,
          message: "请输入您的手机号码"
        }, {
          verify: true,
          verifyArr: [_pattern.isPhone],
          verifyArrMsg: ["手机号输入不正确"]
        }],
        /* code: [
          {
            required: true,
            message: "请填写验证码"
          }
        ], */
        province: [{
          required: true,
          message: "请选择省份"
        }],
        hospital: [{
          required: true,
          message: "请选择医院"
        }],
        department: [{
          required: true,
          message: "请选择科室"
        }],
        title: [{
          required: true,
          message: "请选择职称"
        }],
        privacy: [{
          required: true,
          message: "请浏览并同意签署《个人信息披露知情同意》"
        }]
        // terms: [
        //   {
        //     required: true,
        //     message: "请确认勾选隐私声明 及使用条款"
        //   }
        // ]
      },
      refArr: ["work_upgrades", "intrasting_sys", "intrasting_topic", "skill_area", "position"],
      isSubmiting: false,
      isLoaded: false
    };
  },
  watch: {
    "$store.getters.userInfo": {
      handler(newValue) {
        newValue.id && this.createFormData();
      },
      deep: true
    }
  },
  filter: {},
  created() {
    this.$store.commit("SET_LOADINGPOP", true);

    // this.formData = { ...this.formData, ...this.userInfo }
    this.$store.dispatch("getUserInfo");
  },
  mounted() {
    // setTimeout(() => {
    // this.$forceUpdate()
    // }, 2000);
  },
  computed: {
    /* 完善信息数据 Start */
    positionSelectList() {
      return this.$store.getters.positionSelectList;
    },
    skillAreaSelectList() {
      return this.$store.getters.skillAreaSelectList;
    },
    intrastingTopicSelectList() {
      return this.$store.getters.intrastingTopicSelectList;
    },
    workUpgradesSelectList() {
      return this.$store.getters.workUpgradesSelectList;
    },
    intrastingSysSelectList() {
      return this.$store.getters.intrastingSysSelectList;
    },
    /* 完善信息数据 End */

    departmentSelectList() {
      return this.$store.getters.departmentSelectList;
    },
    titleSelectList() {
      return this.$store.getters.titleSelectList;
    },
    provinceSelectList() {
      return this.$store.getters.provinceSelectList;
    },
    hospitalSelectList() {
      return this.$store.getters.hospitalSelectList;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    async fileInputInforup() {
      let res = await this.$chooseImage();
      if (res !== null && res !== void 0 && res.imageBase64) {
        this.fileInputChangeInfor(res.imageBase64);
      }
    },
    createFormData() {
      var _this$formData$shows, _this$formData$shows2, _this$formData$shows3, _this$formData$shows4, _this$formData;
      this.formData = {
        ...this.formData,
        ...this.userInfo
      };
      if ((_this$formData$shows = this.formData.shows) !== null && _this$formData$shows !== void 0 && _this$formData$shows.includes("2") || (_this$formData$shows2 = this.formData.shows) !== null && _this$formData$shows2 !== void 0 && _this$formData$shows2.includes(2)) {
        this.formData.firstNick = true;
      }
      if ((_this$formData$shows3 = this.formData.shows) !== null && _this$formData$shows3 !== void 0 && _this$formData$shows3.includes("1") || (_this$formData$shows4 = this.formData.shows) !== null && _this$formData$shows4 !== void 0 && _this$formData$shows4.includes(1)) {
        this.formData.firstAvatar = true;
      }
      this.formData.shows = [];
      if (this.encryptionArray.length) {
        const deCrypt = new _index.Crypt();
        this.encryptionArray.forEach(im => {
          this.formData[im] = deCrypt.decrypt(this.formData[im]);
        });
      }
      if ((_this$formData = this.formData) !== null && _this$formData !== void 0 && _this$formData.hospital) {
        this.hospitalOther = this.formData.hospital_other;
      }
      this.isLoaded = true;
      this.$store.commit("SET_LOADINGPOP", false);
    },
    closeAllRef(refName) {
      this.refArr.forEach(im => {
        var _this$$refs$im;
        if (im == refName) return;
        (_this$$refs$im = this.$refs[im]) === null || _this$$refs$im === void 0 || _this$$refs$im.closePop();
      });
    },
    testAlone(key) {
      this.formDataRule[key].forEach((im, ix) => {
        var _this$formData$key;
        if (im.required && (!this.formData[key] || this.formData[key] instanceof String && !((_this$formData$key = this.formData[key]) !== null && _this$formData$key !== void 0 && _this$formData$key.trim()))) {
          // this.errorTipWords = im.message
          // this.$refs[`customPop-error`].open()

          this.$toatsView.show({
            tipWords: im.message
          });
          throw im.message;
        }
        if (im.verify) {
          im.verifyArr.forEach((km, kx) => {
            if (!km(this.formData[key])) {
              // this.errorTipWords = im.verifyArrMsg[kx]
              // this.$refs[`customPop-error`].open()

              this.$toatsView.show({
                tipWords: im.verifyArrMsg[kx]
              });
              throw km.message;
            }
          });
        }
      });
      return true;
    },
    async getCode() {
      if (this.getSms.timer) {
        this.$toatsView.show({
          tipWords: `验证码已发送`
        });
        return;
      }
      const key = "phone";
      if (this.testAlone(key)) {
        const myCrypt = new _index.Crypt();
        let params = {};
        params[key] = myCrypt.encrypt(this.formData[key]);
        params["crypt_key"] = myCrypt.rsa_key();
        this.getSms.countDown();
        let res = await this.$api.smsSend(params);
        if (res !== null && res !== void 0 && res.errcode) {
          /* this.errorTipWords = `发送失败，${res.errmsg}`
          this.$refs[`customPop-error`].open() */

          this.$toatsView.show({
            tipWords: `发送失败：${res.errmsg}`
          });
          this.getSms.clearStatus();
          return;
        }
        this.$toatsView.show({
          tipWords: `验证码发送成功`
        });
        setTimeout(() => {
          this.$toatsView.close();
        }, 1500);

        /* this.errorTipWords = `验证码发送成功`
        this.$refs[`customPop-error`].open() */
      }
    },
    /* 文件上传 Start */
    async uploadImg() {
      // this.$refs["fileInput"].click()
      let res = await this.$chooseImage();
      if (res !== null && res !== void 0 && res.imageBase64) {
        this.fileInputChange(res.imageBase64);
      }
    },
    async fileInputChange(base64) {
      const self = this;
      const selectFileInfo = self.$refs.fileInput.files;
      let params = new FormData();
      params.append("file", base64);
      let res = await this.$api.imageuploadBase64(params);
      if (res !== null && res !== void 0 && res.errcode) {
        // alert("文件上传失败")
        this.$toatsView.show({
          tipWords: `文件上传失败，请重试`
        });
        return;
      }
      this.formData.avatar = res.url;
      this.$forceUpdate();
    },
    async fileInputChangeInfor(base64) {
      const self = this;
      const selectFileInfo = self.$refs.fileInputInfor.files;
      let params = new FormData();
      params.append("file", base64);
      let res = await this.$api.imageuploadBase64(params);
      if (res !== null && res !== void 0 && res.errcode) {
        // alert("文件上传失败")
        this.$toatsView.show({
          tipWords: `文件上传失败，请重试`
        });
        return;
      }
      this.formData.avatar_virtual = res.url;
      this.$forceUpdate();
    },
    /* 文件上传 End */
    testValue() {
      for (let key in this.formDataRule) {
        this.formDataRule[key].forEach((im, ix) => {
          var _this$formData$key2;
          if (im.required && (!this.formData[key] || this.formData[key] instanceof String && !((_this$formData$key2 = this.formData[key]) !== null && _this$formData$key2 !== void 0 && _this$formData$key2.trim()))) {
            /* this.errorTipWords = im.message
            this.$refs[`customPop-error`].open() */
            this.$toatsView.show({
              tipWords: im.message
            });
            throw im.message;
          }
          if (im.verify) {
            im.verifyArr.forEach((km, kx) => {
              if (!km(this.formData[key])) {
                /* this.errorTipWords = im.verifyArrMsg[kx]
                this.$refs[`customPop-error`].open() */

                this.$toatsView.show({
                  tipWords: im.verifyArrMsg[kx]
                });
                throw km.message;
              }
            });
          }
        });
      }
      return true;
    },
    checkValue(params) {
      this.formData[params.field] = params.check;
      if (params.field == "province") {
        var _this$formData2;
        console.log(this.hospital, '==>this.hospital --this.hospital--this.hospital');
        this.allHospitalSelectList = [...this.hospital[(_this$formData2 = this.formData) === null || _this$formData2 === void 0 ? void 0 : _this$formData2.province], ...this.hospitalSelectList];
        if (this.allHospitalSelectList.includes(this.formData['hospital'])) {
          this.$refs.hospitalComponent.inputFilterWords = this.formData['hospital'];
          return;
        }
        this.$refs.hospitalComponent.check = null;
        this.$refs.hospitalComponent.inputFilterWords = "";
        // this.$refs.hospitalComponent.resetCheck()
      }

      // if (params.field == "hospital"){
      //   this.allHospitalSelectList
      // }
    },
    to(value) {},
    async registerSubmit() {
      if (this.testValue()) {
        if (!this.formData.privacy) {
          this.tipWords = "请浏览并同意签署《个人信息披露知情同意》";
          this.$refs["customPop-tip"].open();
          return;
        }
        let params = JSON.parse(JSON.stringify(this.formData));
        if (params.hospital == '其他') {
          if (!this.hospitalOther) {
            this.tipWords = `请输入医院名称`;
            this.$refs[`customPop-tip`].open();
            return;
          }
          if (this.hospitalOther) {
            params.hospital_other = this.hospitalOther;
          }
        }
        if (params.firstAvatar || params.firstNick) {
          params["shows"] = [];
        }
        if (params.firstAvatar) {
          params["shows"].push('1');
        }
        if (params.firstNick) {
          params["shows"].push('2');
        }
        if (params.firstNick && !this.formData.nickname) {
          this.$toatsView.show({
            tipWords: "您还没有填写昵称"
          });
          return;
        }
        if (params.firstAvatar && !this.formData.avatar_virtual) {
          this.$toatsView.show({
            tipWords: "您还没有上传虚拟头像"
          });
          return;
        }
        delete params["privacy"];
        // delete params["terms"]
        delete params["firstAvatar"];
        delete params["firstNick"];
        if (params["shows"].length < 1) {
          params["shows"] = ["99"];
        }
        /* flag用于区分是基础信息和完善信息     0：基础信息 1：完善信息 */
        params['flag'] = 0;
        if (this.encryptionArray.length) {
          const myCrypt = new _index.Crypt();
          for (const key in params) {
            if (this.encryptionArray.includes(key)) {
              params[key] = myCrypt.encrypt(params[key]);
            }
          }
          params["crypt_key"] = myCrypt.rsa_key();
        }
        if (this.isSubmiting) return;
        this.isSubmiting = true;
        delete params["sync_scrm_flag"];
        let res = await this.$api.updateUserInfo(params);
        setTimeout(() => {
          this.isSubmiting = false;
        }, 500);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("提交失败：" + res.errmsg)
          this.$toatsView.show({
            tipWords: "提交失败：" + res.errmsg
          });
          return;
        }
        this.$store.dispatch("getUserInfo");
        if (Number(res === null || res === void 0 ? void 0 : res.score) > 0) {
          setTimeout(() => {
            this.$toatsView.show({
              tipWords: "提交成功"
            });
            setTimeout(() => {
              this.$GO(-1);
            }, 1500);
          }, 2500);
          return;
        }
        this.$toatsView.show({
          tipWords: "提交成功"
        });
        setTimeout(() => {
          this.$GO(-1);
        }, 1500);

        // this.$refs["customPop-cus"].open()
        // this.$refs.customPop.open()
      }
    },
    customPopCancle() {},
    customPopConfirm() {},
    registerSuccessHandleDelay() {
      this.$refs['customPop-cus'].close();
    },
    registerSuccessHandleNow() {
      this.$refs['customPop-cus'].close();
    }
  }
});