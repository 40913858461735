"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _index = _interopRequireDefault(require("@/components/customSelect/index.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    customSelect: _index.default
  },
  data() {
    return {
      tipWords: "",
      formData: {
        privacy: false,
        // terms: false,
        firstNick: false,
        firstAvatar: false
      },
      hospitalOther: ""
    };
  },
  watch: {},
  filter: {},
  created() {
    var _this$formData;
    this.formData = {
      ...this.formData,
      ...this.userInfo
    };
    if ((_this$formData = this.formData) !== null && _this$formData !== void 0 && _this$formData.hospital) {
      let hospitalCount = 0;
      for (const key in this.hospitalSelectList) {
        hospitalCount++;
        if (this.formData.hospital == this.hospitalSelectList[key]) {
          return;
        }
      }
      if (hospitalCount == this.hospitalSelectList.length) {
        this.hospitalOther = this.formData.hospital;
        this.formData.hospital = `其他`;
      }
    }
  },
  mounted() {
    // setTimeout(() => {
    // this.$forceUpdate()
    // }, 2000);
  },
  computed: {
    departmentSelectList() {
      return this.$store.getters.departmentSelectList;
    },
    titleSelectList() {
      return this.$store.getters.titleSelectList;
    },
    provinceSelectList() {
      return this.$store.getters.provinceSelectList;
    },
    hospitalSelectList() {
      return this.$store.getters.hospitalSelectList;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    /* 文件上传 Start */
    async uploadImg() {
      // this.$refs["fileInput"].click()
      let res = await this.$chooseImage();
      if (res !== null && res !== void 0 && res.imageBase64) {
        this.fileInputChange(res.imageBase64);
      }
    },
    async fileInputChange(base64) {
      const self = this;
      const selectFileInfo = self.$refs.fileInput.files;
      console.log(selectFileInfo, '==> selectFileInfo');
      let params = new FormData();
      params.append("file", base64);
      let res = await this.$api.imageuploadBase64(params);
      if (res !== null && res !== void 0 && res.errcode) {
        // alert("文件上传失败")
        this.$toatsView.show({
          tipWords: `文件上传失败，请重试`
        });
        return;
      }
      this.formData.avatar = res.url;
      this.$forceUpdate();

      /* Object.keys(selectFileInfo).forEach(key => {
        const file = selectFileInfo[key]
        
        param.append('file', file, fileName)
        }); */
    },
    /* 文件上传 End */

    checkValue(params) {
      console.log(params, '==> checkValue(params)');
      this.formData[params.field] = params.check;
    },
    to(value) {
      console.log(value, '==>value');
    },
    registerSubmit() {
      if (!this.formData.privacy) {
        this.tipWords = "请浏览并同意签署《个人信息披露知情同意》";
        this.$refs["customPop-tip"].open();
        return;
      }
      let params = JSON.parse(JSON.stringify(this.formData));
      if (params.firstAvatar || params.firstNick) {
        params["shows"] = [];
      }
      if (params.firstAvatar) {
        params["shows"].push(1);
      }
      if (params.firstNick) {
        params["shows"].push(2);
      }
      delete params["privacy"];
      // delete params["terms"]
      delete params["firstAvatar"];
      delete params["firstNick"];
      console.log(params, '==> params');
      // this.$refs["customPop-cus"].open()
      // this.$refs.customPop.open()
    },
    customPopCancle() {},
    customPopConfirm() {},
    registerSuccessHandleDelay() {
      this.$refs['customPop-cus'].close();
    },
    registerSuccessHandleNow() {
      this.$refs['customPop-cus'].close();
    }
  }
});