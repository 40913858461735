"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'NavBar',
  components: {},
  props: {
    hideBack: [String, Number, Boolean],
    hideBorder: [String, Number, Boolean],
    hideShadow: [String, Number, Boolean]
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  filters: {},
  methods: {}
};