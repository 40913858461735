"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container-component"
  }, [_c("div", {
    staticClass: "swiper mySwiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden"
  }, [_c("div", {
    staticClass: "swiper-wrapper",
    attrs: {
      id: "swiper-wrapper-d99c096aec4ecd1e",
      "aria-live": "polite"
    }
  }, _vm._l(_vm.dataBase, function (im, ix) {
    var _im$meeting, _im$meeting2, _im$meeting3, _im$meeting4;
    return _c("div", {
      directives: [{
        name: "monitor",
        rawName: "v-monitor"
      }],
      key: ix,
      staticClass: "swiper-slide flex-direction",
      class: [ix == 0 && _vm.nowIndex == 0 ? "swiper-slide-first" : "", ix == 1 && _vm.nowIndex == 0 ? "swiper-slide-second" : ""],
      attrs: {
        "event-key": (_im$meeting = im.meeting) === null || _im$meeting === void 0 ? void 0 : _im$meeting.name
      },
      on: {
        click: function ($event) {
          return _vm.$PUSH({
            path: `/meetingdetail?id=${im.meeting_id}&typeMap=${im.meeting.type}`
          });
        }
      }
    }, [(_im$meeting2 = im.meeting) !== null && _im$meeting2 !== void 0 && _im$meeting2.need_veeva ? _c("div", {
      staticClass: "authIcon"
    }, [_c("img", {
      attrs: {
        alt: "",
        srcset: "",
        src: require("img/common/authIcon.png")
      }
    })]) : _vm._e(), _c("div", {
      staticClass: "liveRecommendSwiperTop"
    }, [_c("img", {
      attrs: {
        src: (_im$meeting3 = im.meeting) === null || _im$meeting3 === void 0 ? void 0 : _im$meeting3.cover,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "meetingListTag flex align-center justify-center",
      style: {
        "--background": _vm.statusArr[im.meeting.status - 1].bgcolor,
        "--color": _vm.statusArr[im.meeting.status - 1].color
      }
    }, [_vm._v(" " + _vm._s(_vm.statusArr[im.meeting.status - 1].word) + " ")]), _c("div", {
      staticClass: "liveRecommendSwiperBottom"
    }, [_c("div", {
      staticClass: "liveRecommendSwiperBottomTitle hideWords-More2"
    }, [_vm._v(_vm._s((_im$meeting4 = im.meeting) === null || _im$meeting4 === void 0 ? void 0 : _im$meeting4.name))]), _c("div", {
      staticClass: "previewBox"
    }, [_c("div", {
      staticClass: "liveRecommendSwiperBottomTime"
    }, [_vm._v(_vm._s(_vm.showFormatTime(im.meeting)))]), _c("div", {
      staticClass: "previewContent"
    }, [_c("img", {
      staticClass: "previeImg",
      attrs: {
        src: require("img/common/browseCountOther.png"),
        alt: "",
        srcset: ""
      }
    }), _c("span", {
      staticClass: "previewNum"
    }, [_vm._v(_vm._s(im.meeting.read_num || 0))])])])])]);
  }), 0), _c("span", {
    staticClass: "swiper-notification",
    attrs: {
      "aria-live": "assertive",
      "aria-atomic": "true"
    }
  })])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;