"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatSecondsAsMinutes = formatSecondsAsMinutes;
exports.numberScroll = numberScroll;
exports.staggerAni = staggerAni;
require("core-js/modules/es.array.push.js");
var _gsap = require("gsap");
function formatSecondsAsMinutes(seconds) {
  // 将秒转换为分钟
  let minutes = seconds / 60;

  // 使用 Intl.NumberFormat 格式化数字，并保留一位小数
  return {
    formatTime: new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(minutes),
    time: minutes
  };
}
function staggerAni({
  className,
  animationDirectionStart = 'top-bottom',
  dur = '0.5',
  gap = '0.2',
  distance = 50,
  deep = false
}, callBack) {
  if (!className) return console.error('缺少className');
  let parentNode = document.getElementsByClassName(className)[0];
  let nodeArr = [];
  function preGetChildList(node) {
    console.log(node);
    if (!node || !node.childNodes || node.childNodes.length <= 0) return;
    if (!deep) return nodeArr.push(...node.childNodes);
    for (let i = 0; i < node.childNodes.length; i++) {
      const childNode = node.childNodes[i];
      if (childNode.nodeType !== 1) continue; // 跳过非元素节点
      nodeArr.push(childNode);
      preGetChildList(childNode);
    }
  }
  preGetChildList(parentNode);
  const directionStartMapping = {
    'left-right': {
      direction: 'top',
      start: 'left'
    },
    'bottom-top': {
      direction: 'top',
      start: 'right'
    },
    'right-left': {
      direction: 'bottom',
      start: 'left'
    },
    'top-bottom': {
      direction: 'bottom',
      start: 'right'
    }
  };
  const {
    direction,
    start
  } = directionStartMapping[animationDirectionStart];
  let index = 0;
  const values = {
    top: '+=',
    bottom: '-=',
    left: 'x',
    right: 'y'
  };
  _gsap.TweenMax.staggerFromTo(nodeArr, dur, {
    [values[start]]: `${values[direction]}${distance}`,
    opacity: 0
  }, {
    [values[start]]: 0,
    opacity: 1,
    onCompleteAll: function () {
      index++;
      if (nodeArr.length <= index) {
        callBack && callBack();
      }
    }
  }, gap);
}
function numberScroll({
  className,
  time = 0.5
}) {
  if (!className) return console.error('缺少className');
  let node = document.getElementsByClassName(className)[0];
  const num = parseFloat(node.innerText) || 0;
  const pointNumLength = countDecimalPlaces(num);
  var initNum = {
    score: 0
  };
  _gsap.TweenLite.to(initNum, time, {
    score: num,
    onUpdate: showScore
  });

  //在tween update时通过这个function输出.
  function showScore() {
    node.innerHTML = initNum.score.toFixed(pointNumLength);
  }
  function countDecimalPlaces(number) {
    // 将数字转换为字符串
    const numberString = number.toString();
    // 使用正则表达式匹配小数部分
    const decimalPart = numberString.match(/\.\d+$/);
    if (decimalPart) {
      // 如果匹配到小数部分，返回小数点后面的位数
      return decimalPart[0].length - 1;
    } else {
      // 如果没有小数部分，返回 0
      return 0;
    }
  }
}