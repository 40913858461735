"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _aiGap = _interopRequireDefault(require("aui/components/ai-gap"));
var _aiImg = _interopRequireDefault(require("aui/components/ai-img"));
var _aiAlert = _interopRequireDefault(require("aui/senior/ai-alert"));
var _aiPopup = _interopRequireDefault(require("aui/senior/ai-popup"));
var _aiConfirm = _interopRequireDefault(require("aui/senior/ai-confirm"));
var _aiLoading = _interopRequireDefault(require("aui/senior/ai-loading"));
var _aiForm = _interopRequireDefault(require("aui/components/ai-form"));
/* 基础组件 */

/* 高级组件 */

const install = Vue => {
  Vue.component('ai-gap', _aiGap.default);
  Vue.component('ai-img', _aiImg.default);
  Vue.component('ai-form', _aiForm.default);
  Vue.prototype.$alert = _aiAlert.default.install;
  Vue.prototype.$popup = _aiPopup.default.install;
  Vue.prototype.$confirm = _aiConfirm.default.install;
  Vue.prototype.$loading = _aiLoading.default.install;
};
var _default = exports.default = {
  install
};