"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "alert-container"
  }, [_c("div", [_vm._v("我是传入的参数：" + _vm._s(_vm.data))]), _c("p", [_vm._v("自动居中")]), _c("p", [_vm._v("自定义样式")]), _c("p", [_vm._v("支持回调方法")]), _c("p", {
    on: {
      click: function ($event) {
        return _vm.close();
      }
    }
  }, [_vm._v("点击关闭")]), _c("p", {
    on: {
      click: function ($event) {
        return _vm.callback();
      }
    }
  }, [_vm._v("关闭前可自定义逻辑")]), _c("p", {
    on: {
      click: function ($event) {
        return _vm.cancel();
      }
    }
  }, [_vm._v("取消")]), _c("p", {
    on: {
      click: function ($event) {
        return _vm.confirm();
      }
    }
  }, [_vm._v("确定")])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;