"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.isShow ? _c("div", {
    staticClass: "collectTipsBox"
  }, [_c("img", {
    staticClass: "tipsBg",
    attrs: {
      src: require("img/home/tipsBg.png"),
      alt: "",
      srcset: ""
    }
  }), _c("img", {
    staticClass: "tipsPonit",
    attrs: {
      src: require("img/home/ponit.png"),
      alt: "",
      srcset: ""
    }
  }), _c("div", {
    staticClass: "goGZHPath",
    on: {
      click: _vm.goWxGZH
    }
  }), _c("img", {
    staticClass: "close",
    attrs: {
      src: require("img/common/closeWrite.png"),
      alt: ""
    },
    on: {
      click: _vm.close
    }
  })]) : _vm._e();
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;