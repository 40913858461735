"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/popup/style");
var _popup = _interopRequireDefault(require("vant/es/popup"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _historyList = _interopRequireDefault(require("@/components/course/historyList.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    vanPopup: _popup.default,
    listItem: _historyList.default
  },
  data() {
    return {
      showPowerBoxTip: false,
      historyList: {},
      dateMap: {
        "today": "今天",
        "yesterday": "昨天",
        "earlier": "更早"
      },
      loadFinal: false
    };
  },
  watch: {},
  filter: {},
  created() {
    this.initData();
  },
  mounted() {},
  computed: {},
  methods: {
    async initData() {
      let res = await this.$api.getLearnLog();
      if (res !== null && res !== void 0 && res.errcode) {
        // alert("getLearnLog" + res.errmsg)
        return;
      }
      this.historyList = res;
      let count = 0;
      for (const key in this.historyList) {
        console.log(key, '==> ket');
        count += this.historyList[key].length;
      }
      this.loadFinal = count < 1 ? true : false;
    }
  }
});