"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container-out"
  }, [_c("stickyHeader"), _vm._m(0), _vm._m(1), _c("div", {
    staticClass: "warningContent"
  }, [_vm._v(" 如需报告使用拜耳产品后出现不适或产生品质问题，可与您的医生药师或本地药品监管部门进行联系，也可以通过以下方式直接联系我们。 ")]), _c("div", {
    staticClass: "warningContact"
  }, [_vm._v("邮箱：AE _China@bayer.com")])], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "centerLogo"
  }, [_c("img", {
    attrs: {
      src: require("img/logo.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("div", {
    staticClass: "warningTitle"
  }, [_vm._v("药物警戒声明")])]);
}];
render._withStripped = true;