"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/popup/style");
var _popup = _interopRequireDefault(require("vant/es/popup"));
var _index = require("@/utils/index.js");
var _cerImg = _interopRequireDefault(require("@/components/personal/cerImg.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    vanPopup: _popup.default,
    cerImg: _cerImg.default
  },
  name: "achieveDetail",
  data() {
    return {
      type: "badge",
      detail: {},
      isGet: false
    };
  },
  watch: {},
  filter: {},
  created() {
    this.$store.commit("SET_LOADINGPOP", true);
    this.type = this.$route.query.type;
    this.init();
  },
  mounted() {
    this.weChatShare.title = `拜耳影像学苑`;
    this.weChatShare.desc = `拜耳影像学苑`;
    this.weChatShare.selfLink = `${window.location.origin}/bayer-rad-academy-shadowcloud-academy-html`;
    this.initWechat();
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    async getNow() {
      const {
        query
      } = this.$route;
      let res = this.type == 'badge' ? await this.$api.putDrawMedal({
        medal_id: query.id
      }) : await this.$api.putDrawCertificate({
        certificate_id: query.id
      });
      if (res.result) {
        this.isGet = true;
      }
    },
    async init() {
      const {
        query
      } = this.$route;
      let res = this.type == 'badge' ? await this.$api.getMedalDetail(query.id) : await this.$api.getCertificateDetail(query.id);
      this.detail = res;
      setTimeout(() => {
        this.$store.commit("SET_LOADINGPOP", false);
      }, 2300);
    },
    handleName(words) {
      const deCrypt = new _index.Crypt();
      return deCrypt.decrypt(words);
    }
  }
});