"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/popup/style");
var _popup = _interopRequireDefault(require("vant/es/popup"));
var _index = require("@/utils/index.js");
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    vanPopup: _popup.default
  },
  data() {
    return {
      showPowerBoxTip: false,
      showModuleCheck: 0,
      /* 0 按季度 1 总积分 */
      rankInfo: {},
      showpop: true
    };
  },
  watch: {
    showModuleCheck() {
      this.initData();
    }
  },
  filter: {},
  created() {
    this.initData();
  },
  mounted() {},
  computed: {
    showUnlockPop() {
      return this.userInfo.veeva == 1 && this.showpop && this.userInfo.veeva_succ_tip != 1;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    isShowRank() {
      if (this.userInfo.veeva_succ_tip == 0) {
        /* if(!this.showpop){
            return true
        } */
        if (this.userInfo.veeva == 1) {
          return false;
        }
        return true;
      }
      if (this.userInfo.veeva_succ_tip == 1) {
        if (this.userInfo.veeva == 1) {
          return true;
        }
      }
      return true;
    },
    async lockVeeva() {
      await this.$api.updateUserInfo({
        veeva_succ_tip: 1,
        flag: 1
      });
      this.$store.dispatch("getUserInfo");
      this.showpop = false;
    },
    async initData() {
      this.$store.commit("SET_LOADINGPOP", true);
      let res = await this.$api.getRankingList(this.showModuleCheck);
      if (res !== null && res !== void 0 && res.errcode) {
        // alert("getRankingList:" + res.errmsg)
        return;
      }
      this.rankInfo = res;
      setTimeout(() => {
        this.$store.commit("SET_LOADINGPOP", false);
      }, 500);
    },
    rankHandle(im) {
      var _im$shows, _im$shows2;
      const deCrypt = new _index.Crypt();
      let params = {
        name: deCrypt.decrypt(im.name),
        avatar: im.avatar
      };
      if (im.nickname_rankings) {
        params.name = im.nickname_rankings;
      }
      if (im.avatar_rankings) {
        params.avatar = im.avatar_rankings;
      }
      if (im !== null && im !== void 0 && (_im$shows = im.shows) !== null && _im$shows !== void 0 && _im$shows.includes("1") && !im.avatar_rankings) {
        params["avatar"] = im.avatar_virtual;
      }
      if (im !== null && im !== void 0 && (_im$shows2 = im.shows) !== null && _im$shows2 !== void 0 && _im$shows2.includes("2") && !im.nickname_rankings) {
        params["name"] = im.nickname;
      }
      return params;
    }
  }
});