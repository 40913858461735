"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container-out"
  }, [_c("stickyHeader"), _c("div", {
    staticClass: "outScreen"
  }, [_c("qriously", {
    ref: "QRCode",
    attrs: {
      value: _vm.createValue(),
      foreground: "#000",
      size: 200,
      backgroundAlpha: 0
    }
  })], 1), _c("img", {
    staticStyle: {
      width: "100vw"
    },
    attrs: {
      src: _vm.capUrl,
      alt: ""
    }
  }), _vm._m(0), _c("div", {
    staticClass: "posterView outScreen"
  }, [_c("div", {
    staticClass: "posterBg"
  }, [_c("div", {
    staticClass: "posterBgUser flex align-center"
  }, [_c("img", {
    attrs: {
      crossOrigin: "anonymous",
      src: _vm.renderImgPath(_vm.userAvatr),
      alt: ""
    }
  }), _vm._v(" " + _vm._s(_vm.userName) + " ")]), _vm.type == "cer" ? _c("div", [_c("div", {
    staticClass: "achieveDetailContentCertop"
  }, [_c("img", {
    staticClass: "achieveDetailContentBadgeStar3 achieveDetailContentBadgeStar",
    attrs: {
      src: require("img/personal/ach/star3.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "achieveDetailContentBadgeStar2 achieveDetailContentBadgeStar",
    attrs: {
      src: require("img/personal/ach/star2.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "achieveDetailContentBadgeStar1 achieveDetailContentBadgeStar",
    attrs: {
      src: require("img/personal/ach/star1.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "achieveDetailContentCertopCer"
  }, [_c("img", {
    staticClass: "achieveDetailContentCertopCerShadowImg",
    attrs: {
      src: require("img/personal/ach/cerImgShadow.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "achieveDetailContentCertopCerImg",
    attrs: {
      crossOrigin: "anonymous",
      src: _vm.renderImgPath(_vm.detail.path),
      alt: ""
    }
  }), _vm.isShowDate() ? _c("div", {
    staticClass: "cerTime",
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.detail.send_at, "yyyy年MM月")))]) : _vm._e(), _c("div", {
    staticClass: "achieveDetailContentCertopCerWords"
  }, [_vm._v(_vm._s(_vm.userName))])])]), _c("div", {
    staticClass: "achieveDetailContentCerName"
  }, [_vm._v(_vm._s(_vm.detail.name))]), _c("div", {
    staticClass: "achieveDetailContentCerTime"
  }, [_vm._v(_vm._s(_vm.seTime(_vm.detail.get_time)))])]) : _vm._e(), _vm.type == "badge" ? _c("div", [_c("div", {
    staticClass: "achieveDetailContentBadge"
  }, [_c("img", {
    staticClass: "achieveDetailContentBadgeIcon",
    attrs: {
      crossOrigin: "anonymous",
      src: _vm.renderImgPath(_vm.detail.path),
      alt: ""
    }
  }), _c("img", {
    staticClass: "achieveDetailContentBadgeStar3 achieveDetailContentBadgeStar",
    attrs: {
      src: require("img/personal/ach/star3.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "achieveDetailContentBadgeStar2 achieveDetailContentBadgeStar",
    attrs: {
      src: require("img/personal/ach/star2.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "achieveDetailContentBadgeStar1 achieveDetailContentBadgeStar",
    attrs: {
      src: require("img/personal/ach/star1.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "achieveDetailContentBadgeName"
  }, [_vm._v(_vm._s(_vm.detail.name))]), _c("div", {
    staticClass: "achieveDetailContentBadgeDesc",
    domProps: {
      innerHTML: _vm._s(_vm.$VIDEOPLAYSINLINE(_vm.handleIntroduction(_vm.detail.introduction)))
    }
  }), _c("div", {
    staticClass: "achieveDetailContentBadgeTime"
  }, [_vm._v(_vm._s(_vm.seTime(_vm.detail.get_time)))])]) : _vm._e()]), _c("div", {
    staticClass: "shareBottomQCode flex align-center justify-between"
  }, [_vm._m(1), _c("img", {
    staticClass: "shareBottomQCodeRight",
    attrs: {
      src: _vm.base64QRCode,
      alt: ""
    }
  })])]), _c("vanPopup", {
    model: {
      value: _vm.sharePop,
      callback: function ($$v) {
        _vm.sharePop = $$v;
      },
      expression: "sharePop"
    }
  }, [_c("div", {
    staticClass: "sharePop",
    on: {
      click: function ($event) {
        _vm.sharePop = false;
      }
    }
  }, [_c("img", {
    staticClass: "shareArrow",
    attrs: {
      src: require("img/personal/ach/shareArrow.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "sharePopWords flex align-center justify-center"
  }, [_vm._v(" 点击 "), _vm._l(3, function (i) {
    return _c("div", {
      staticClass: "sharePopWordsDesc"
    });
  }), _vm._v(" 分享给好友 ")], 2)])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "posterTip flex align-center justify-center"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/ach/waitReceiveBg/downTip.png"),
      alt: ""
    }
  }), _vm._v(" 长按保存图片 ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "flex align-center"
  }, [_c("div", {
    staticClass: "shareBottomQCodeLeft flex align-center justify-center"
  }, [_c("img", {
    staticClass: "logo",
    attrs: {
      src: require("img/logo.png")
    }
  })]), _c("div", {
    staticClass: "shareBottomQCodeLefttWord"
  }, [_vm._v("拜耳影像学苑")])]);
}];
render._withStripped = true;