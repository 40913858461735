"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  props: {
    renderModule: {
      type: String,
      default: ''
    },
    playIndex: {
      type: Number,
      default: 0
    },
    curtype: {
      type: Number,
      default: -1
    },
    module: {
      type: String,
      default: ''
    },
    /* 取值字段 */
    getWords: {
      type: String,
      default: ''
    },
    dataBase: {
      type: Array,
      default: () => []
    },
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    },
    /* 
      展示形式
        words： 文字提示
        slot  插槽
    */
    showType: {
      type: String,
      default: 'words'
    },
    /* 提示文字 */
    tipWords: {
      type: String,
      default: '请传入提示文字'
    }
  },
  data() {
    return {
      showPop: false,
      dataIndex: this.playIndex || 0
    };
  },
  watch: {
    dataIndex() {
      this.$emit('dataIndex', this.dataIndex);
    }
  },
  filter: {},
  created() {},
  mounted() {
    console.log('im----------', this.dataBase);
  },
  computed: {},
  methods: {
    renderDur(im, ix) {
      let right = '';
      const mins = Math.ceil((im.video_dur / 60).toFixed(2));
      // const mins = Math.round((im.video_dur / 60).toFixed(2))
      // const mins = (im.video_dur / 60).toFixed(2)
      const left = mins + '分钟';
      if (this.playIndex == ix) {
        right = '正在播放';
      }
      if (this.playIndex != ix && parseInt(Number(im.learn_time)) + 6 < parseInt(Number(im.video_dur)) * 0.5) {
        right = '待学习';
      }
      if (this.playIndex != ix && parseInt(Number(im.learn_time)) + 11 >= parseInt(Number(im.video_dur)) * 0.5) {
        right = '已学习';
      }
      return `${left} / ${right}`;
    },
    open() {
      this.showPop = true;
    },
    close() {
      this.popFullClose();
    },
    maskHandle() {
      if (this.mask) {
        this.popFullClose();
      }
    },
    popFullClose() {
      this.showPop = false;
      this.$emit('cancle');
    }
  }
};