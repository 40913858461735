"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/sticky/style");
var _sticky = _interopRequireDefault(require("vant/es/sticky"));
var _preview = _interopRequireDefault(require("@/router/modules/preview.js"));
var _index = _interopRequireDefault(require("@/config/index.js"));
var _default = exports.default = {
  components: {
    vanSticky: _sticky.default
  },
  data() {
    return {};
  },
  watch: {
    $route(newValue) {
      console.log(newValue, '==>  newValue');
    }
  },
  filter: {},
  created() {},
  mounted() {},
  computed: {},
  methods: {
    pageBack() {
      console.log(this.$route);
      console.log(document.referrer);
      this.$GO(-1);

      /*  setTimeout(() => {
                 console.log(111111111111111111111111111111111111111111111);
                 this.$REDIRECT({
                     path: "/"
                 })
             }, 500); */
    },
    callUs() {
      const isPreview = _preview.default.findIndex(im => im.path == this.$route.path);
      if (isPreview == -1) {
        this.$store.commit('SET_CALLUSSHOW', true);
      }
    },
    reload() {
      const isPreview = _preview.default.findIndex(im => im.path == this.$route.path);
      if (isPreview == -1) {
        window.location.replace('/' + _index.default.deploy.path);
      }
    }
  }
};