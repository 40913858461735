"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.showImgFlag ? _c("div", {
    staticClass: "imgBox"
  }, [_c("img", {
    staticClass: "close",
    attrs: {
      src: require("img/common/popClose.png"),
      alt: "",
      srcset: ""
    },
    on: {
      click: _vm.close
    }
  }), _c("div", {
    staticClass: "picBox"
  }, [_c("img", {
    staticClass: "imgWarp",
    attrs: {
      src: _vm.url,
      alt: "",
      srcset: ""
    }
  }), _c("p", [_vm._v("长按可识别二维码")])])]) : _vm._e();
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;