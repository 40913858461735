"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.string.replace-all.js");
var _listItem = _interopRequireDefault(require("@/components/activity/listItem.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    listItem: _listItem.default
  },
  data() {
    return {
      urlQuery: {},
      sectionDetail: {},
      myIsZan: false,
      myIsFaver: false,
      loadZan: false,
      loadFaver: false
    };
  },
  watch: {},
  filter: {},
  created() {
    this.urlQuery = this.$route.query;
    this.initData();
  },
  mounted() {},
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    showFormatTime(im) {
      const start_time = this.$SPLITDATE(im.start_time, 'yyyy-mm-dd h:m');
      const end_time = this.$SPLITDATE(im.end_time, 'yyyy-mm-dd h:m');
      const start_time_arr = start_time.split(" ");
      const end_time_arr = end_time.split(" ");
      if (start_time_arr[0] == end_time_arr[0]) {
        return start_time + ' - ' + end_time_arr[1];
      }
      return start_time + ' - ' + end_time;
    },
    async zanHandle(type) {
      if (this.loadZan) return;
      this.loadZan = true;
      type && this.$store.dispatch("setScoreLog", {
        route: this.$route,
        action_type: "ZAN"
      });
      try {
        let res = await this.$api[type ? 'contentZan' : 'cancleContentZan']({
          content_id: this.urlQuery.id,
          type: 6
        });
        this.loadZan = false;
        if (type) {
          this.myIsZan = true;
          this.sectionDetail.zan++;
          this.$emit("setIsZan", true);
        } else {
          this.sectionDetail.zan--;
          this.myIsZan = false;
          this.$emit("setIsZan", false);
        }
      } catch (error) {}
    },
    async faverHandle(type) {
      if (this.loadFaver) return;
      this.loadFaver = true;
      type && this.$store.dispatch("setScoreLog", {
        route: this.$route,
        action_type: "FAVER"
      });
      try {
        let res = await this.$api[type ? 'contentFaver' : 'cancleContentFaver']({
          content_id: this.urlQuery.id,
          type: 6
        });
        this.loadFaver = false;
        if (type) {
          this.myIsFaver = true;
          this.sectionDetail.faver++;
          this.$emit("setIsFaver", true);
        } else {
          this.myIsFaver = false;
          this.sectionDetail.faver--;
          this.$emit("setIsFaver", false);
        }
      } catch (error) {}
    },
    async getZanCheck() {
      let res = await this.$api.getZanCheck(this.urlQuery.id, 6);
      if (res.errcode) {
        alert("getZanCheck(this.urlQuery.id, 6)" + res.errmsg);
        return;
      }
      if (res.count) {
        this.myIsZan = true;
        return;
      }
      this.myIsZan = false;
    },
    async getFaverCheck() {
      let res = await this.$api.getZanCheck(this.urlQuery.id, 6);
      if (res.errcode) {
        alert("getZanCheck(this.urlQuery.id, 6)" + res.errmsg);
        return;
      }
      if (res.count) {
        this.myIsFaver = true;
        return;
      }
      this.myIsFaver = false;
    },
    async initData() {
      /* this.getZanCheck()
      this.getFaverCheck() */

      try {
        let res = await this.$api.getPreviewCampainSection({}, this.urlQuery.id);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getPreviewCampainSection" + res.errmsg)
          return;
        }
        if (res.content) res.content = res.content.replaceAll('<img', '<img style="max-width:100%;"');
        this.sectionDetail = res;
      } catch (error) {}
    },
    enterActivityHandleDelay() {
      this.$refs['customPop-tip'].close();
    },
    enterActivityHandleNow() {
      this.$refs['customPop-tip'].close();
      this.$PUSH({
        path: `/personal/task`
      });
    },
    enterActivity() {
      this.$PUSH({
        path: `/activity/detail?id=${this.sectionDetail.id}`
      });
      return;
      if (this.userInfo.level >= 3) {
        this.$PUSH({
          path: `/activity/detail?id=${this.sectionDetail.id}`
        });
        return;
      }
      this.$refs['customPop-tip'].open();
    }
  }
});