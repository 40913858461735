"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$dataBase;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "listItem",
    attrs: {
      "event-key": _vm.dataBase.name
    },
    on: {
      click: function ($event) {
        return _vm.toDetail(_vm.dataBase);
      }
    }
  }, [(_vm$dataBase = _vm.dataBase) !== null && _vm$dataBase !== void 0 && _vm$dataBase.need_veeva ? _c("div", {
    staticClass: "authIcon"
  }, [_c("img", {
    attrs: {
      alt: "",
      srcset: "",
      src: require("img/common/authIcon.png")
    }
  })]) : _vm._e(), _c("img", {
    attrs: {
      src: _vm.dataBase.cover,
      alt: ""
    }
  }), _c("div", {
    staticClass: "previewBox"
  }, [_c("div", {
    staticClass: "listItemWords hideWords-More3"
  }, [_vm._v(_vm._s(_vm.dataBase.name))]), _c("div", {
    staticClass: "previewContent hideWords-More3"
  }, [_c("img", {
    staticClass: "previeImg",
    attrs: {
      src: require("img/common/browseCountWhite.png"),
      alt: "",
      srcset: ""
    }
  }), _c("span", {
    staticClass: "previewNum"
  }, [_vm._v(_vm._s(_vm.dataBase.read_num))])])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;