"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _router = _interopRequireDefault(require("@/router"));
var _register = _interopRequireDefault(require("@/api/modules/register.js"));
var _common = _interopRequireDefault(require("@/api/modules/common.js"));
var _personal = _interopRequireDefault(require("@/api/modules/personal.js"));
// import { userInfo } from "@/api/modules/register.js"
const state = {
  test: false,
  tabIndex: '0',
  loadingShow: false,
  userInfo: {},
  callUs: {},
  callUsShow: false,
  tologin: false,
  projectAuthStatus: false,
  showSaveBtn: false,
  integralInfo: {
    show: false,
    integral: null
  },
  showClaim: false,
  screenTip: false
};
const mutations = {
  SET_SCREENTIP(state, value) {
    state.screenTip = value;
  },
  SET_SHOWCLAIM(state, value) {
    state.showClaim = value;
  },
  SET_SHOWSAVEBTN(state, value) {
    state.showSaveBtn = value;
  },
  SET_TEST(state, value) {
    state.test = value;
  },
  SET_TABINDEX(state, value) {
    state.tabIndex = value;
  },
  SET_LOADINGPOP(state, value) {
    state.loadingShow = value;
  },
  SET_USERINFO(state, value) {
    state.userInfo = value;
  },
  SET_CALLUS(state, value) {
    state.callUs = value;
  },
  SET_CALLUSSHOW(state, value) {
    state.callUsShow = value;
  },
  SET_TOLOGIN(state, value) {
    state.tologin = value;
  },
  SET_SHOWAUTHPOP(state, value) {
    state.projectAuthStatus = value;
  },
  SET_INTEGRAL(state, value) {
    state.integralInfo = value;
  }
};
const actions = {
  setTest({
    commit
  }, value) {
    commit('SET_TEST', value);
  },
  setTabIndex({
    commit
  }, value) {
    commit('SET_TABINDEX', value);
  },
  async getUserInfo({
    commit,
    dispatch
  }, value) {
    let res = await _register.default.userInfo();
    if (res !== null && res !== void 0 && res.errcode) {
      // alert("个人信息请求失败:" + res.errmsg)
      return;
    }
    if (res !== null && res !== void 0 && res.id) {
      localStorage.setItem("isRegister", "true");
    }
    commit('SET_USERINFO', res);
    dispatch("getAssistantsInfo");
    dispatch("getClaimStatus");
  },
  async getAssistantsInfo({
    commit
  }, value) {
    let res = await _common.default.getAssistantsInfo();
    if (res !== null && res !== void 0 && res.errcode) {
      // alert("请求失败")
      return;
    }
    commit('SET_CALLUS', res);
  },
  async setScoreLog({
    state
  }, value) {
    // console.log("-------------------setScoreLog");

    if (!state.userInfo.id) {
      return;
    }
    const {
      route
    } = value;
    const {
      query
    } = route;
    if (query.typeMap == 4 && (value === null || value === void 0 ? void 0 : value.type) != 'click') return;
    const params = {
      content_id: value.content_id || query.id,
      type: query.typeMap,
      action_type: value.action_type || "VIEW",
      page_url: window.location.href,
      page_param: query,
      content_type: value.content_type,
      campain_id: value.campain_id || query.campain_id || 0,
      section_id: value.section_id || query.section_id || 0,
      /* 学习时长类型：1：页面停留时长类型 2：主体为视频的学习时长类型 */
      time_type: value.time_type || 2
    };
    if (value.action_type == "LEARN") {
      params["total_tims"] = value.total_tims;
      params["session_num"] = value.session_num;
      if (!value.total_tims) {
        return;
      }
    }
    if (value.finish) {
      params['finish'] = value.finish;
    }
    if (value !== null && value !== void 0 && value.is_tw) {
      params['is_tw'] = value.is_tw;
    }
    if (value.action_type == "ZAN") {
      params["comment_id"] = value.comment_id;
    }
    if (value.needBack) {
      return _common.default.setScoreLog(params);
    }
    _common.default.setScoreLog(params);
  },
  async setLearnTimeLog({
    state
  }, value) {
    // console.log("-------------------setLearnTimeLog");

    if (!state.userInfo.id) {
      return;
    }
    const {
      route
    } = value;
    const {
      query
    } = route;
    const params = {
      content_id: value.content_id || query.id,
      type: query.typeMap,
      campain_id: value.campain_id || query.campain_id || 0,
      section_id: value.section_id || query.section_id || 0,
      times: value.total_tims,
      openid: state.userInfo.openid,
      unionid: state.userInfo.unionid,
      /* 学习时长类型：1：页面停留时长类型 2：主体为视频的学习时长类型 */
      time_type: value.time_type || 2
    };
    _common.default.setLearnTimeLog(params);
  },
  async getClaimStatus({
    state,
    commit
  }, value) {
    if (!state.userInfo.id) {
      return;
    }
    let res = await _common.default.claimStatus();

    /* 检测是否有站内信 */
    let num = await _personal.default.getMessagecneterCount();
    let show = false;
    num.num > 0 && (show = true);
    commit('SET_SHOWCLAIM', res.result || show);
  }
};
var _default = exports.default = {
  state,
  mutations,
  actions
};