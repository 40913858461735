"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  name: "customVideo",
  props: {
    filterMap: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  watch: {},
  filter: {},
  created() {},
  mounted() {},
  computed: {},
  methods: {
    filterSelect(kx) {
      this.filterMap.value = kx;
      this.filterMap.check = false;
      this.$emit("valueBack", this.filterMap.selectData[kx]);
      this.$emit("valueBackType", this.filterMap.selectData[kx].value);
    }
  }
};