"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _default = exports.default = [{
  path: '/register',
  name: 'Register',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/user/register'))),
  meta: {
    title: '用户注册',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜用户注册',
      desc: ''
    },
    hideTabbar: true,
    registerNoEnter: true,
    getUser: true
  }
}, {
  path: '/welcome',
  name: 'Welcome',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/entry/welcome'))),
  meta: {
    title: '拜耳影像学苑',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑',
      desc: ''
    },
    hideTabbar: true
  }
}, {
  path: '/staffregister',
  name: 'staffRegister',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/user/staffRegister'))),
  meta: {
    title: '内部员工注册',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜内部员工注册',
      desc: ''
    },
    hideTabbar: true,
    registerNoEnter: true,
    getUser: true
  }
}, {
  path: '/staffinfoedit',
  name: 'staffInfoEdit',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/user/staffInfoEdit'))),
  meta: {
    title: '编辑资料',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    hideTabbar: true,
    checkUser: true
  }
}, {
  path: '/userinfoedit',
  name: 'userInfoEdit',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/user/userInfoEdit'))),
  meta: {
    title: '编辑资料',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    hideTabbar: true,
    checkUser: true
  }
}, {
  path: '/userInformationedit',
  name: 'userInformationEdit',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/user/userInformationEdit'))),
  meta: {
    title: '编辑资料',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    hideTabbar: true,
    checkUser: true
  }
}, {
  path: '/information',
  name: 'information',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/user/information'))),
  meta: {
    title: '完善信息',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    hideTabbar: true,
    checkUser: true
  }
}, {
  path: '/rankinfo',
  name: 'rankinfo',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/user/rankInfo'))),
  meta: {
    title: '积分排行榜',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜学术能量排行榜',
      desc: ''
    },
    hideTabbar: true,
    checkUser: true
  }
}, /* 导入用户的完善信息 -- 相当于注册 */
{
  path: '/importregister',
  name: 'importRegister',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/user/importRegister'))),
  meta: {
    title: '检验信息',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    hideTabbar: true,
    checkUser: true
  }
}, /* 用户医院填写问题 */
{
  path: '/hospitallose',
  name: 'hospitalLose',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/user/hospitalLose'))),
  meta: {
    title: '检验信息',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    hideTabbar: true,
    checkUser: true
  }
}];