"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  name: 'customVideo',
  props: {
    filterMap: {
      type: Object,
      default: () => {}
    },
    dataBase: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  watch: {},
  filter: {},
  created() {},
  mounted() {},
  computed: {},
  methods: {
    async toDetail(im) {
      let authShowPop = await this.$api.getIsShowIntroduceAuth({}, im.id, im.type);
      if (im.need_veeva) {
        const resData = await this.$api.getShowPopDaysInfo({});
        if (resData.need_tips && resData.veeva == 0) {
          //是否展示体验认证弹框
          this.showDaysPop(resData, im, authShowPop);
          return;
        } else {
          this.goNextPath(authShowPop, im);
        }
      } else {
        this.goNextPath(authShowPop, im);
      }

      /* 2023-02-09 ac说客户又不做等级限制了  && this.userInfo.level >= 3 */
      // if (im?.have_visite) {
      //   this.$PUSH({ path: `/activity/secondList?id=${im.id}` });
      //   return
      // }
      // this.$PUSH({ path: `/activity/activeShowOncePoster?id=${im.id}&campain_id=${im.campain_id}` });  // 测试 ?id=365&campain_id=260
    },
    goNextPath(authShowPop, im) {
      if (authShowPop.need_show_desc == 1) {
        this.$PUSH({
          path: `/activity/activeShowOncePoster?id=${im.id}&hotActive=1&secondList=1`
        });
      } else {
        this.$PUSH({
          path: `/activity/secondList?id=${im.id}`
        });
      }
    },
    showDaysPop(res, im, authShowPop) {
      let days = res.days || 0;
      this.$instanceExperienceView.show({
        days: days,
        //体验剩余天数
        isNeedBack: 'cur',
        //是否重定向返回原页面
        callBack: ret => {
          if (ret.next == 'from') {
            if (days > 0) this.goNextPath(authShowPop, im);
          } else {
            //立即认证跳转
            this.$PUSH({
              path: ret.next
            });
          }
        }
      });
    }
  }
};