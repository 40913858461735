"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container-out page-container-view"
  }, [_c("stickyHeader"), _c("div", {
    staticClass: "courseList"
  }, [_vm._l(_vm.historyList, function (im, ix) {
    return _c("div", {
      key: ix,
      staticClass: "courseListInner"
    }, [im.length ? _c("div", {
      staticClass: "courseListInnerTime"
    }, [_vm._v(_vm._s(_vm.dateMap[ix]))]) : _vm._e(), _vm._l(im, function (km, kx) {
      return _c("listItem", {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: km.type != 7,
          expression: "km.type != 7"
        }],
        key: kx,
        attrs: {
          showBoxTag: true,
          moduleType: km.type,
          dataBase: km,
          history: true,
          showListType: true
        }
      });
    })], 2);
  }), _vm.loadFinal ? _c("div", {
    staticClass: "meetingEmpty flex align-center justify-center"
  }, [_vm._v(" 暂无更多数据 ")]) : _vm._e()], 2)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;