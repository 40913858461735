"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  props: {
    dataBase: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      base64QRCode: ""
    };
  },
  created() {
    this.$nextTick(() => {
      this.getBase64QRCode();
    });
  },
  methods: {
    getBase64QRCode() {
      this.base64QRCode = this.$refs.QRCode.qrious.canvas.toDataURL();
    }
  }
};