"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/popup/style");
var _popup = _interopRequireDefault(require("vant/es/popup"));
var _config = require("@/config");
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _index = require("@/utils/index.js");
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _filter = require("@/filters/filter.js");
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    vanPopup: _popup.default
  },
  name: 'achieveDetail',
  data() {
    return {
      sharePop: false,
      base64QRCode: '',
      capUrl: '',
      type: 'badge',
      detail: {}
    };
  },
  watch: {},
  filter: {},
  created() {
    this.type = this.$route.query.type;
    this.$store.commit('SET_LOADINGPOP', true);
    this.init();
    this.$nextTick(() => {
      this.getBase64QRCode();
    });
  },
  mounted() {
    this.weChatShare.title = `拜耳影像学苑`;
    this.weChatShare.desc = `拜耳影像学苑`;
    this.weChatShare.selfLink = `${window.location.origin}/bayer-rad-academy-shadowcloud-academy-html`;
    this.initWechat();
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    userAvatr() {
      var _this$userInfo;
      return (_this$userInfo = this.userInfo) !== null && _this$userInfo !== void 0 && (_this$userInfo = _this$userInfo.shows) !== null && _this$userInfo !== void 0 && _this$userInfo.includes('1') ? this.userInfo.avatar_virtual : this.userInfo.avatar;
    },
    userName() {
      var _this$userInfo2;
      return (_this$userInfo2 = this.userInfo) !== null && _this$userInfo2 !== void 0 && (_this$userInfo2 = _this$userInfo2.shows) !== null && _this$userInfo2 !== void 0 && _this$userInfo2.includes('2') ? this.userInfo.nickname : this.handleName(this.userInfo.name);
    }
  },
  methods: {
    formatDate: _filter.formatDate,
    isShowDate() {
      let upLineTime = '2024-01-23 22:00:00';
      return (0, _filter.compareDates)(this.detail.certificate_created_at, upLineTime);
    },
    renderImgPath(path) {
      console.log(path, '==>path');
      const hasSym = path === null || path === void 0 ? void 0 : path.indexOf('?');
      if (hasSym > -1) {
        return `${path}&v=${new Date().getTime()}`;
      }
      return `${path}?v=${new Date().getTime()}`;
    },
    seTime(time) {
      const tempTime = this.$SPLITDATE(time);
      return tempTime === null || tempTime === void 0 ? void 0 : tempTime.split('-').join('.');
    },
    async init() {
      const {
        query
      } = this.$route;
      let res = this.type == 'badge' ? await this.$api.getMedalDetail(query.id) : await this.$api.getCertificateDetail(query.id);
      this.detail = res;
      setTimeout(() => {
        this.myShare();
      }, 1000);
      let params = {
        type: this.type == 'badge' ? 1 : 2,
        openid: this.userInfo.openid
      };
      params[this.type == 'badge' ? 'medal_id' : 'certificate_id'] = query.id;
      this.$api.setMedalcertificatelog(params);
    },
    /* 生成分享海报 */
    myShare() {
      const self = this;
      const detailPosterInner = document.querySelector('.posterView');
      (0, _html2canvas.default)(detailPosterInner, {
        useCORS: true
      }).then(canvas => {
        self.capUrl = canvas.toDataURL();
        self.$store.commit('SET_LOADINGPOP', false);
      });
    },
    createValue() {
      const location = window.location;
      const urlParams = this.$route.query;
      return `${location.origin}/${_config.deploy.path}?sourceuser=${this.userInfo.openid}&type=${this.type == 'badge' ? 1 : 2}&id=${urlParams.id}`;
    },
    getBase64QRCode() {
      this.base64QRCode = this.$refs.QRCode.qrious.canvas.toDataURL();
    },
    handleName(words) {
      const deCrypt = new _index.Crypt();
      return deCrypt.decrypt(words);
    },
    handleIntroduction(im) {
      var _im;
      im = (_im = im) === null || _im === void 0 ? void 0 : _im.replace(/['↵'\n\r]/g, '<br />');
      return im;
    }
  }
});