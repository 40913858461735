"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = _interopRequireDefault(require("utils/http"));
var _default = exports.default = {
  /* 短信发送 */
  smsSend(params) {
    return (0, _http.default)({
      url: `/api/platform/sms/send`,
      method: 'post',
      data: params
    });
  },
  /* 邮件发送 */
  mailSend(params) {
    return (0, _http.default)({
      url: `/api/platform/mail/send`,
      method: 'post',
      data: params
    });
  },
  /* 普通用户 - 注册 */
  memberRegister(params) {
    return (0, _http.default)({
      url: `/api/platform/member/member-register`,
      method: 'post',
      data: params
    });
  },
  /* 员工 - 注册 */
  staffRegister(params) {
    return (0, _http.default)({
      url: `/api/platform/member/staff-register`,
      method: 'post',
      data: params
    });
  },
  /* 普通用户 / 员工 - 个人信息 */
  userInfo() {
    return (0, _http.default)({
      url: `/api/platform/member/me`,
      method: 'get'
    });
  },
  /* 普通用户 - 编辑 */
  updateUserInfo(params) {
    return (0, _http.default)({
      url: `/api/platform/member/member-update`,
      method: 'put',
      data: params
    });
  },
  /* 员工 - 编辑 */
  updateStaffInfo(params) {
    return (0, _http.default)({
      url: `/api/platform/member/staff-update`,
      method: 'put',
      data: params
    });
  }
};