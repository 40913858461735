"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/swipe-item/style");
var _swipeItem = _interopRequireDefault(require("vant/es/swipe-item"));
require("vant/es/swipe/style");
var _swipe = _interopRequireDefault(require("vant/es/swipe"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _step = _interopRequireDefault(require("./components/step.vue"));
var _baseInfos = _interopRequireDefault(require("./components/baseInfos.vue"));
var _veevaPass = _interopRequireDefault(require("./components/veevaPass.vue"));
var _ApprovalNo = _interopRequireDefault(require("@/components/common/ApprovalNo.vue"));
var _index = require("@/utils/index.js");
var _swiper = _interopRequireDefault(require("swiper"));
// import 'swiper/css/swiper.css'
var _default = exports.default = window.$initPage({
  provide() {
    return {
      link: this.getLink
    };
  },
  components: {
    stickyHeader: _header.default,
    VeevaStep: _step.default,
    BaseInfos: _baseInfos.default,
    VeevaPass: _veevaPass.default,
    Swipe: _swipe.default,
    SwipeItem: _swipeItem.default,
    ApprovalNo: _ApprovalNo.default
  },
  data() {
    return {
      isVeevaAuth: false,
      link: '',
      veevaInfo: {},
      mySwiper2: '',
      mySwiper3: '',
      showSeat: false,
      mySwiper1: null,
      noSwiping: false,
      nextStatus: false
    };
  },
  watch: {},
  filter: {},
  created() {
    this.$store.commit("SET_LOADINGPOP", true);
    this.getVeevaInfo();
  },
  mounted() {},
  computed: {
    userInfo() {
      console.log(this.$store.getters.userInfo, '==>this.$store.getters.userInfo----');
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    setSwiperTouch() {
      this.noSwiping = !this.noSwiping;
      this.mySwiper1.params.noSwiping = this.noSwiping;
      this.mySwiper1.update();
      this.mySwiper3.params.noSwiping = this.noSwiping;
      this.mySwiper3.update();
    },
    initSwiper() {
      console.log("======initSwiper==initSwiper==initSwiper==initSwiper");
      let _this = this;
      const tempVeevaInfo = localStorage.getItem("veevaInfo");
      const tempRecord = localStorage.getItem("record");
      try {
        _this.mySwiper2 = new _swiper.default('#swiper-container2', {
          //子swiper
          direction: 'vertical',
          nested: true,
          //resistanceRatio: 0,
          slidesPerView: 'auto',
          freeMode: true
        });
        _this.mySwiper3 = new _swiper.default('#swiper-container3', {
          //子swiper
          direction: 'vertical',
          nested: true,
          //resistanceRatio: 0,
          slidesPerView: 'auto',
          freeMode: true,
          noSwiping: _this.noSwiping,
          noSwipingClass: "customSelectFilterListssss"
        });
        var mySwiper1 = new _swiper.default('#swiper-container1', {
          direction: 'vertical',
          on: {
            slideChange: function () {
              //if (this.activeIndex == 1) {
              _this.mySwiper2.update();
              _this.mySwiper3.update();
              //}
              console.log(this.activeIndex);
              _this.showSeat = this.activeIndex == 0 ? false : true;
            }
          },
          noSwiping: _this.noSwiping,
          noSwipingClass: "customSelectFilterListssss"
        });
        if (!this.nextStatus && (tempVeevaInfo || tempRecord)) {
          console.log("=========this.nextStatus && tempVeevaInfo=============");
          mySwiper1.slideNext();
          this.nextStatus = true;
        }
        _this.mySwiper1 = mySwiper1;
        /* _this.mySwiper2 = new Swiper('#swiper-container2', {
          //子swiper
          direction: 'vertical',
          nested: true,
          //resistanceRatio: 0,
          slidesPerView: 'auto',
          freeMode: true,
        })
        _this.mySwiper3 = new Swiper('#swiper-container3', {
          //子swiper
          direction: 'vertical',
          nested: true,
          //resistanceRatio: 0,
          slidesPerView: 'auto',
          freeMode: true,
            noSwiping: _this.noSwiping,
          noSwipingClass: "customSelectFilterListssss"
        }) */
      } catch (error) {
        console.log(error, '==error --error --error');
        setTimeout(() => {
          this.initSwiper();
        }, 500);
      }
    },
    getLink() {
      return this.link;
    },
    async getVeevaInfo() {
      let res = await this.$api.getVeevaInfo();
      if ((res === null || res === void 0 ? void 0 : res.errcode) == 10002) {
        this.isVeevaAuth = true;
        this.link = (res === null || res === void 0 ? void 0 : res.link) || '';
        return;
      }
      this.veevaInfo = res;
      this.$nextTick(() => {
        this.initSwiper();
      });
      const tempVeevaInfo = localStorage.getItem("veevaInfo");
      console.log(tempVeevaInfo, '==>tempVeevaInfo--tempVeevaInfo');
      if (tempVeevaInfo) {
        this.$refs.baseInfos.initformData(JSON.parse(tempVeevaInfo));
        return;
      }
      if (!(res !== null && res !== void 0 && res.status)) {
        const deCrypt = new _index.Crypt();
        this.userInfo.id && this.$refs.baseInfos.initformData({
          ...this.userInfo,
          name: deCrypt.decrypt(this.userInfo['name'])
        });
        return;
      }
      this.$refs.baseInfos.initformData(res);
    }
  }
});