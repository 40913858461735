"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "nav-bar-container"
  }, [_c("div", {
    staticClass: "nav-bar",
    class: {
      border: !_vm.hideBorder,
      shadow: !_vm.hideShadow
    }
  }, [_vm._m(0), _c("div", {
    staticClass: "mid"
  }, [_vm._v(" midmid ")]), _c("div", {
    staticClass: "right"
  })]), _c("ai-gap", {
    attrs: {
      height: "145"
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "left"
  }, [_c("img", {
    staticClass: "logo",
    attrs: {
      src: require("img/logo.png")
    }
  })]);
}];
render._withStripped = true;