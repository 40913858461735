"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/popup/style");
var _popup = _interopRequireDefault(require("vant/es/popup"));
var _monitor = require("@/utils/monitor");
var _index = require("@/utils/index.js");
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _list = _interopRequireDefault(require("@/components/course/list.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    vanPopup: _popup.default,
    listItem: _list.default,
    courseList: _list.default
  },
  data() {
    return {
      noThenData: false,
      myMeetingList: [],
      current: 1,
      /* 1 未开始 2进行中 3回放中 */
      status: 1,
      pageSize: 10,
      showModuleCheck: 1,
      showPowerBoxTip: false,
      courseTagList: [{
        title: "标签1标签1标签1",
        check: false,
        value: 0
      }, {
        title: "标签2标签2",
        check: false,
        value: 1
      }, {
        title: "标签3标签3标签3标签3标签3",
        check: false,
        value: 2
      }, {
        title: "标签4标签4",
        check: false,
        value: 3
      }, {
        title: "标签5",
        check: false,
        value: 4
      }, {
        title: "标签6标签6",
        check: false,
        value: 5
      }],
      isReachBottom: false,
      loadFinal: false
    };
  },
  watch: {
    showModuleCheck() {
      (0, _monitor.monitorClick)(`${document.title}:会议报名列表筛选-${this.showModuleCheck}`);
      this.myMeetingList = [];
      this.current = 1;
      this.initData();
    }
  },
  destroyed() {
    window.removeEventListener("reachBottom", this.reachBottomHandle);
  },
  filter: {},
  created() {
    this.initData();
    this.initReachBottom();
  },
  mounted() {},
  computed: {},
  methods: {
    /* 分页 start */
    initReachBottom() {
      window.addEventListener("reachBottom", this.reachBottomHandle);
    },
    reachBottomHandle(evt) {
      console.log("WCNM");
      const self = this;
      if (evt.detail.path != "/personal/signup") {
        return;
      }
      if (self.isReachBottom) {
        return;
      }
      self.isReachBottom = true;
      this.initData();
    },
    /* 分页 end */
    async initData() {
      this.loadFinal = false;
      try {
        let params = {
          current: this.current,
          pageSize: this.pageSize,
          status: this.showModuleCheck
        };
        let res = await this.$api.getMyMeeting(params);
        this.isReachBottom = false;
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getMyMeeting" + res.errmsg)
          return;
        }
        const finalData = (0, _index.limitData)({
          interfaceData: res,
          baseData: this.myMeetingList,
          limit: params
        });
        this.myMeetingList = finalData.baseData;
        finalData.currentAdd && this.current++ && (this.noThenData = false);
        !finalData.currentAdd && (this.noThenData = true);
        this.loadFinal = true;

        // this.myMeetingList = res
      } catch (error) {}
    }
  }
});