"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("stickyHeader"), _c("div", {
    staticClass: "imgListBox"
  }, [_c("div", {
    staticClass: "imgList",
    attrs: {
      id: "posterId"
    }
  }, [_c("img", {
    attrs: {
      id: "posterImage",
      src: _vm.posterImg,
      alt: "",
      srcset: ""
    }
  }), _c("img", {
    attrs: {
      src: require("img/bindStep/M_01.png?v=1.0.0"),
      alt: "",
      srcset: ""
    }
  }), _c("img", {
    attrs: {
      src: require("img/bindStep/M_02.png?v=1.0.0"),
      alt: "",
      srcset: ""
    }
  }), _c("img", {
    attrs: {
      src: require("img/bindStep/M_03.png?v=1.0.0"),
      alt: "",
      srcset: ""
    }
  }), _c("img", {
    attrs: {
      src: require("img/bindStep/M_04.png?v=1.0.0"),
      alt: "",
      srcset: ""
    }
  }), _c("div", {
    staticClass: "veevaPath veevaPath1",
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: `/veeva`
        });
      }
    }
  }), _c("div", {
    staticClass: "veevaPath veevaPath2",
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: `/veeva`
        });
      }
    }
  })])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;