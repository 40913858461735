"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ConfirmDialog = _interopRequireDefault(require("@/components/ConfirmDialog.vue"));
var _default = exports.default = {
  components: {
    ConfirmDialog: _ConfirmDialog.default
  },
  props: {
    history: {
      type: Boolean,
      default: false
    },
    needIntercept: {
      type: Boolean,
      default: false
    },
    showListType: {
      type: Boolean,
      default: true
    },
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    },
    /* 
      展示形式
        words： 文字提示
        slot  插槽
    */
    showType: {
      type: String,
      default: 'words'
    },
    /* 提示文字 */
    tipWords: {
      type: String,
      default: '请传入提示文字'
    },
    /* 是否展示Boxtag */
    showBoxTag: {
      type: Boolean,
      default: false
    },
    /* 
      模块类型、
        1：课程
      2：病例
      3：其他
      4：会议
      5：活动
        */
    moduleType: {
      type: Number,
      default: 1
    },
    dataBase: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      resolve: null,
      reject: null,
      showPop: false,
      tagNameMap: {
        1: '课程',
        2: '病例',
        3: '其它',
        4: '会议',
        5: '活动',
        6: '活动',
        7: '活动'
      },
      moduleUrlMap: {
        1: '/course/detail',
        2: '/course/detail',
        3: '/course/detail',
        4: '/meetingdetail',
        5: '/activity/secondList',
        6: '/activity/detail'
        // '6': "/activity/detailenter",
        // '7': "/activity/detail",
      },
      campainUrlMapArr: ['campain', 'campain_section', 'campain_content'],
      campainUrlMap: ['/activity/secondList', '/activity/detailenter', '/activity/detail']
    };
  },
  watch: {},
  filter: {},
  created() {},
  mounted() {},
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    showComponent() {
      var _this$dataBase, _this$dataBase$conten;
      const tagInDataBase = ((_this$dataBase = this.dataBase) === null || _this$dataBase === void 0 ? void 0 : _this$dataBase.need_veeva) === 1;
      const tagInContent = ((_this$dataBase$conten = this.dataBase.content) === null || _this$dataBase$conten === void 0 ? void 0 : _this$dataBase$conten.need_veeva) === 1;
      return tagInDataBase || tagInContent;
    }
  },
  methods: {
    monitorKey() {
      return `${document.title}${this.dataBase.name}`;
    },
    async toUrl() {
      if (this.history || this.needIntercept) {
        const nowMis = Date.now();
        const dataMis = this.$GETNOWMILLISECOND(this.dataBase.end_time);
        if (nowMis > dataMis || this.dataBase.published != 1) {
          if (this.dataBase.type != 4) {
            this.$toatsView.show({
              tipWords: `该${this.tagNameMap[this.dataBase.type]}已不在有效期`
            });
          }
          if (this.dataBase.type == 4) {
            this.$toatsView.show({
              tipWords: `该会议已被关闭`
            });
          }
          setTimeout(() => {
            this.$toatsView.hide();
          }, 1500);
          return;
        }
      }
      if (this.moduleType + '' == '6' || this.moduleType + '' == '5' || this.moduleType + '' == '7') {
        if (this.dataBase.id) {
          let _isAuth = await this.$api.getCheckPermissions({}, this.moduleType, this.dataBase.id);
          if ((_isAuth === null || _isAuth === void 0 ? void 0 : _isAuth.hasErrCode) == '50001') {
            return;
          }
        }
        let authShowPop = await this.$api.getIsShowIntroduceAuth({}, this.dataBase.campain_id ? this.dataBase.campain_id : this.dataBase.id, 5);
        let formsData = await this.$api.getCampainListAuth({}, this.dataBase.campain_id ? this.dataBase.campain_id : this.dataBase.id);
        if (authShowPop.need_show_desc == 1 && this.userInfo.type == 1 && formsData.have_form == 1) {
          //检测 模块 模块详情  活动 123级是否需要区报名
          if (this.moduleType + '' == '5') {
            this.$PUSH({
              path: `/activity/activeShowOncePoster?id=${this.dataBase.id}&_typeMap=${this.dataBase.type}&secondList=1`
            });
          } else {
            if (this.moduleType + '' == '6' || this.moduleType + '' == '7') {
              console.log('this.dataBase', this.dataBase);
              this.$PUSH({
                path: `/activity/activeShowOncePoster?id=${this.dataBase.campain_id}&homeType=QBSS&_contentId=${this.dataBase.id}&_typeMap=${this.dataBase.type}&content_type=${this.dataBase.content_type}&section_id=${this.dataBase.section_id}`
              });
            } else {
              this.$PUSH({
                path: `/activity/activeShowOncePoster?id=${this.dataBase.id}&_typeMap=${this.dataBase.type}&secondList=1`
              });
            }
          }
          return;
        } else if (this.moduleType + '' == '5' && authShowPop.need_show_desc == 1 && (formsData.have_form == 1 || formsData.have_form == 0)) {
          //点击的是活动 并且需要展示活动 有无表单都可以
          this.$PUSH({
            path: `/activity/activeShowOncePoster?id=${this.dataBase.id}&_typeMap=${this.dataBase.type}&secondList=1`
          });
          return;
        } else if (this.moduleType + '' == '5' && authShowPop.need_show_desc == 0 && (formsData.have_form == 1 || formsData.have_form == 0)) {
          this.$PUSH({
            path: `/activity/secondList?id=${this.dataBase.id}`
          });
          return;
        }
        let authShowPop2 = await this.$api.getIsShowIntroduceAuth({}, this.dataBase.id, this.moduleType);
        let hasIndex = this.campainUrlMapArr.indexOf(this.dataBase.list_type);
        if (hasIndex != -1) {
          if (this.moduleType + '' == '6') {
            //模块
            if (authShowPop2.need_show_desc == 1) {
              this.$PUSH({
                path: `/activity/detailenter?id=${this.dataBase.id}&campain_id=${this.dataBase.campain_id}`
              });
            } else {
              if (this.dataBase.content_type == 2) {
                this.$PUSH({
                  path: `/activity/richlist?id=${this.dataBase.id}&typeMap=6&campain_id=${this.dataBase.campain_id}`
                });
              } else {
                this.$PUSH({
                  path: `/activity/detail?id=${this.dataBase.id}&typeMap=6&campain_id=${this.dataBase.campain_id}`
                });
              }
            }
            return;
          } else if (this.moduleType + '' == '7') {
            this.$PUSH({
              path: `${this.dataBase.content_type == 2 ? '/activity/richdetail' : '/activity/detail'}`,
              query: {
                id: this.dataBase.section_id,
                typeMap: 7,
                campain_id: this.dataBase.campain_id,
                section_id: this.dataBase.section_id,
                contentid: this.dataBase.id
              }
            });
            return;
          }
          if (hasIndex == 1) {
            if (authShowPop2.need_show_desc == 0) {
              //展示过详情 就跳转到详情
              this.$PUSH({
                path: `${this.dataBase.content_type == 2 ? '/activity/richdetail' : this.campainUrlMap[hasIndex]}`,
                query: {
                  id: this.dataBase.section_id,
                  typeMap: 6,
                  campain_id: this.dataBase.campain_id,
                  section_id: this.dataBase.section_id,
                  contentid: this.dataBase.id
                }
              });
            } else {
              //展示详情
              this.$PUSH({
                path: `/activity/detailenter?id=${this.dataBase.id}&campain_id=${this.dataBase.campain_id}`
              });
            }
            this.$PUSH({
              path: this.campainUrlMap[hasIndex],
              query: {
                id: this.dataBase.id,
                campain_id: this.dataBase.campain_id
              }
            });
            return;
          } else if (hasIndex == 1 && authShowPop2.need_show_desc == 0) {
            if (this.moduleType == '6') {
              if (this.dataBase.content_type == 2) {
                this.$PUSH({
                  path: `/activity/richlist?id=${this.dataBase.id}&typeMap=6&campain_id=${this.dataBase.campain_id}`
                });
              } else {}
              this.$PUSH({
                path: `/activity/detail?id=${sectionDetail.id}&typeMap=6&campain_id=${res2.campain_id}`
              });
            }
            this.$PUSH({
              path: `${this.dataBase.content_type == 2 ? '/activity/richdetail' : this.campainUrlMap[2]}`,
              query: {
                id: this.dataBase.id,
                typeMap: 6,
                campain_id: this.dataBase.campain_id,
                section_id: this.dataBase.section_id,
                contentid: this.dataBase.id
              }
            });
            return;
          }
          this.$PUSH({
            path: this.campainUrlMap[hasIndex],
            query: {
              id: this.dataBase.id
            }
          });
          return;
        }

        /* this.campainUrlMap */

        this.$PUSH({
          path: `${this.dataBase.content_type == 2 ? '/activity/richlist' : this.moduleUrlMap[this.moduleType]}?id=${this.dataBase.id}`
        });
        return;
      }
      this.$PUSH({
        path: `${this.moduleUrlMap[this.moduleType]}?id=${this.dataBase.id}&typeMap=${this.dataBase.type}`
      });
    },
    open() {
      this.showPop = true;
    },
    close() {
      this.popFullClose();
    },
    maskHandle() {
      if (this.mask) {
        this.popFullClose();
      }
    },
    popFullClose() {
      this.showPop = false;
      this.$emit('cancle');
    }
  }
};