"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.string.replace-all.js");
require("vant/es/image-preview/style");
var _imagePreview = _interopRequireDefault(require("vant/es/image-preview"));
require("vant/es/sticky/style");
var _sticky = _interopRequireDefault(require("vant/es/sticky"));
var _monitor = require("@/utils/monitor.js");
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _index = require("@/utils/index.js");
var _meetingPoster = _interopRequireDefault(require("@/components/meeting/meetingPoster.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _customVideo = _interopRequireDefault(require("@/components/common/customVideo.vue"));
var _interaction = _interopRequireDefault(require("@/components/course/interaction.vue"));
var _interactionAnalysis = _interopRequireDefault(require("@/components/course/interactionAnalysis.vue"));
var _comment = _interopRequireDefault(require("@/components/course/comment.vue"));
var _meetingVideo = _interopRequireDefault(require("@/components/meeting/meetingVideo.vue"));
var _monitor2 = require("@/utils/monitor");
var _ConfirmDialog = _interopRequireDefault(require("@/components/ConfirmDialog.vue"));
// import activeShowOncePoster from '@/views/activity/activeShowOncePoster.vue'
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    customVideo: _customVideo.default,
    interaction: _interaction.default,
    comment: _comment.default,
    interactionAnalysis: _interactionAnalysis.default,
    vanSticky: _sticky.default,
    videoList: _meetingVideo.default,
    meetingPoster: _meetingPoster.default,
    ConfirmDialog: _ConfirmDialog.default
    // activeShowOncePoster
  },
  data() {
    return {
      resolve: null,
      reject: null,
      parentId: null,
      isShowSignFlag: false,
      showModuleCheck: 0,
      urlQuery: {},
      pageSize: 999,
      current: 1,
      listCampainContent: [],
      listCampainContentIndex: 0,
      myIsZan: false,
      myIsFaver: false,
      formEchoInfo: {},
      sectionDetail: {},
      loadZan: false,
      loadFaver: false,
      tipWords: '',
      isReachBottom: false,
      setScoreTimer: null,
      setScoreTime: 0,
      onlySessionNum: this.$GetSessionNum(),
      /* 本次学习时长 */
      thisTime: 0,
      thisTimer: null,
      /* 20230518新需求-- 首页推荐退转进入后没权限就隐藏整个页面 */
      showAll: false,
      loadFinish: false,
      /* 页面停留时长监测相关部分 Start */
      thisTimeView: 0,
      thisTimerView: null,
      setScoreTimerView: null,
      setScoreTimeView: 0,
      /* 页面停留时长监测相关部分 End */

      detailAuthData: null,
      fristGetAuthFlag: false,
      onceGetAuthApplyFlag: false,
      //未报名弹窗只弹一次
      isSetLogFlag: true,
      isShowTxt: true //兼容文字显示时机
    };
  },
  watch: {
    showModuleCheck() {
      let monitorStr = `${document.title}:table栏-`;
      const showModuleCheckMap = {
        0: '目录',
        1: '简介',
        2: '互动',
        3: '评论'
      };
      monitorStr += `${showModuleCheckMap[this.showModuleCheck]}`;
      (0, _monitor2.monitorClick)(monitorStr);
    },
    closeLoading() {
      if (this.closeLoading) {
        this.$store.commit('SET_LOADINGPOP', false);
      }
    },
    listCampainContentIndex() {
      let shareParams = {
        name: this.listCampainContent[this.listCampainContentIndex].name,
        cover: this.listCampainContent[this.listCampainContentIndex].cover,
        contentid: this.listCampainContent[this.listCampainContentIndex].id
      };
      this.setWeChatShare(shareParams);

      /* 停掉上一个内容的页面停留  启动当前内容的页面停留 */

      /* -------------------------------------------- */

      this.getFormEchoInfo();
      console.log('postMonitor--------------');
    }
  },
  filter: {},
  async created() {
    this.$store.commit('SET_LOADINGPOP', true);
    this.urlQuery = this.$route.query;
    this.parentId = this.urlQuery.campain_id;
    await this.getModuleDetail();

    //  this.isShowSignPage()
  },
  destroyed() {
    this.pauseSetBrowse();
    /* 清除页面停留时长监测 */
    this.pauseLearnTimeLogView();
    this.pauseSetBrowseView();
    window.removeEventListener('reachBottom', this.reachBottomHandle);
  },
  mounted() {},
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    contentDetail() {
      return this.listCampainContent[this.listCampainContentIndex];
    },
    closeLoading() {
      return this.loadFinish && this.showAll;
    }
  },
  methods: {
    closeAnswerPageHandle() {
      this.isShowSignFlag = false;
    },
    async isApplyStatus() {
      let formsData = await this.$api.getCampainListAuth({}, this.detailAuthData.campain_id);
      let authShowPop = await this.$api.getIsShowIntroduceAuth({}, this.detailAuthData.campain_id, '5');
      this.fristGetAuthFlag = authShowPop.need_show_desc == 1 && this.userInfo.type == 1 && formsData.have_form == 1;
    },
    async isShowSignPage() {
      var _this$detailAuthData;
      console.log('isShowSignPage');

      // let res = await this.$api.getCampainSectionDetailAuth({}, this.urlQuery.id)
      // console.log(res, '==>res>res>res -------------------------')
      if ((_this$detailAuthData = this.detailAuthData) !== null && _this$detailAuthData !== void 0 && _this$detailAuthData.errcode) return;
      if (this.fristGetAuthFlag) {
        this.$refs.confirmDialog.open('浏览完整活动介绍，参与互动后，即可立即学习！感谢您的支持！', '').then(() => {
          this.goApplyRedirect();
          // 执行确认后的逻辑
        }).catch(error => {
          console.log('用户取消了操作:', error);
          this.$PUSH({
            path: '/'
          });
          // 处理取消逻辑
        });
      }
    },
    async goApplyRedirect() {
      let res = await this.$api.getCampainSectionDetailAuth({}, this.urlQuery.id);
      this.$PUSH({
        path: `/activity/activeShowOncePoster?id=${res.campain_id}&_contentId=${res.id}&_typeMap=${res.type}`
      });
    },
    async getContentForId() {
      const res = await this.$api.getCampainContentId({}, this.urlQuery.contentid);
      console.log(res, '==.');
      if (res.errcode == 50002) {
        this.$toatsView.show({
          tipWords: '抱歉！您暂无法查看该内容，请查看该活动下的其他内容'
        });
      }
    },
    /* 本次学习时长 Start */

    setLearnTimeLog() {
      this.thisTime = 0;
      this.thisTimer = setInterval(() => {
        this.thisTime += 3;
        this.$store.dispatch('setLearnTimeLog', {
          route: {
            ...this.$route,
            query: {
              ...this.$route.query,
              typeMap: 7
            }
          },
          action_type: 'LEARN',
          total_tims: 3,
          content_id: this.contentDetail.id,
          content_type: 1,
          campain_id: this.contentDetail.campain_id,
          section_id: this.contentDetail.section_id,
          session_num: this.onlySessionNum,
          time_type: 1
        });
      }, 3000);
    },
    pauseLearnTimeLog() {
      if (this.thisTimer) {
        this.$store.dispatch('setLearnTimeLog', {
          route: {
            ...this.$route,
            query: {
              ...this.$route.query,
              typeMap: 7
            }
          },
          action_type: 'LEARN',
          total_tims: 3,
          content_id: this.contentDetail.id,
          content_type: 1,
          campain_id: this.contentDetail.campain_id,
          section_id: this.contentDetail.section_id,
          session_num: this.onlySessionNum,
          time_type: 1
        });
      }
      clearInterval(this.thisTimer);
      this.thisTimer = null;
      this.thisTime += 3;

      // this.$store.dispatch("setLearnTimeLog", { route: { ...this.$route, query: { ...this.$route.query, typeMap: 7 } }, action_type: "LEARN", total_tims: 3, content_id: this.contentDetail.id, content_type: 1, campain_id: this.contentDetail.campain_id, section_id: this.contentDetail.section_id, session_num: this.onlySessionNum })
    },
    /* 本次学习时长 End */

    /* 本次学习时长-页面停留 Start */

    setLearnTimeLogView() {
      this.thisTimeView = 0;
      this.thisTimerView = setInterval(() => {
        this.thisTimeView += 3;
        this.$store.dispatch('setLearnTimeLog', {
          route: {
            ...this.$route,
            query: {
              ...this.$route.query,
              typeMap: 7
            }
          },
          action_type: 'LEARN',
          total_tims: 3,
          content_id: this.contentDetail.id,
          content_type: 1,
          campain_id: this.contentDetail.campain_id,
          section_id: this.contentDetail.section_id,
          session_num: this.onlySessionNum
        });
      }, 3000);
    },
    pauseLearnTimeLogView() {
      if (this.thisTimerView && this.isSetLogFlag) {
        this.$store.dispatch('setLearnTimeLog', {
          route: {
            ...this.$route,
            query: {
              ...this.$route.query,
              typeMap: 7
            }
          },
          action_type: 'LEARN',
          total_tims: 3,
          content_id: this.contentDetail.id,
          content_type: 1,
          campain_id: this.contentDetail.campain_id,
          section_id: this.contentDetail.section_id,
          session_num: this.onlySessionNum
        });
      }
      clearInterval(this.thisTimerView);
      this.thisTimerView = null;
      this.thisTimeView += 3;
    },
    /* 本次学习时长-页面停留 End */

    setWeChatShare(params) {
      this.weChatShare.title = params.name;
      this.weChatShare.desc = params.name;
      this.weChatShare.imgUrl = params.cover;
      (params === null || params === void 0 ? void 0 : params.contentid) && (this.weChatShare.contentid = params.contentid);
      this.initWechat();
    },
    //切换视频
    async changeVideo(evt) {
      // this.$refs.videoList.onpauseFn()
      await this.$refs.customVideo.activitvResetVideo();
      this.listCampainContentIndex = evt;
      this.isCurVideoDaysAuth();
      this.postMonitor();
    },
    setScoreLog(action_type, comment_id) {
      const detail = this.listCampainContent[this.listCampainContentIndex];
      let setScoreLogParams = {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            id: detail.id,
            typeMap: 7
          }
        },
        campain_id: detail.campain_id,
        section_id: detail.section_id,
        action_type: 'COMMENT'
      };
      if (action_type) {
        action_type && (setScoreLogParams.action_type = action_type);
        comment_id && (setScoreLogParams.comment_id = comment_id);
      }
      this.$store.dispatch('setScoreLog', setScoreLogParams);
    },
    /* 浏览积分-页面停留时长 Start */
    pauseSetBrowseView(endTime, finish) {
      // this.pauseLearnTimeLog()

      clearInterval(this.setScoreTimerView);
      this.setScoreTimerView = null;
      if (finish && endTime > this.setScoreTimeView + 3) {
        endTime = this.setScoreTimeView + 3;
        finish = false;
      }

      /* endTime || 
        修改前：
          endTime为视频播放完成后传递的视频时长   会覆盖掉历史时长，所以去掉此字段 仅传递记录时长
      */
      this.$store.dispatch('setScoreLog', {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            typeMap: 7
          }
        },
        action_type: 'LEARN',
        total_tims: this.setScoreTimeView,
        content_id: this.contentDetail.id,
        content_type: 1,
        campain_id: this.contentDetail.campain_id,
        section_id: this.contentDetail.section_id,
        session_num: this.onlySessionNum,
        finish
      });
      endTime && (this.listCampainContent[this.listCampainContentIndex].learn_time = endTime);
    },
    setBrowseView() {
      // this.setLearnTimeLog()

      console.log(this.contentDetail, '==>this.contentDetail');
      /* 应该赋值为返回数据中的时长，接口未完善 先写0 */
      this.setScoreTimeView = Number(this.contentDetail.view_learn_time) || 0;
      this.setScoreTimerView = setInterval(() => {
        this.setScoreTimeView += 3;
        this.$store.dispatch('setScoreLog', {
          route: {
            ...this.$route,
            query: {
              ...this.$route.query,
              typeMap: 7
            }
          },
          action_type: 'LEARN',
          total_tims: this.setScoreTimeView,
          content_id: this.contentDetail.id,
          content_type: 1,
          campain_id: this.contentDetail.campain_id,
          section_id: this.contentDetail.section_id,
          session_num: this.onlySessionNum
        });
      }, 3000);
    },
    /* 浏览积分-页面停留时长 End */

    /* 浏览积分 Start */
    pauseSetBrowse(endTime, finish) {
      this.pauseLearnTimeLog();
      clearInterval(this.setScoreTimer);
      this.setScoreTimer = null;
      if (finish && endTime > this.setScoreTime + 3) {
        endTime = this.setScoreTime + 3;
        finish = false;
      }

      /* endTime || 
        修改前：
          endTime为视频播放完成后传递的视频时长   会覆盖掉历史时长，所以去掉此字段 仅传递记录时长
      */
      this.$store.dispatch('setScoreLog', {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            typeMap: 7
          }
        },
        action_type: 'LEARN',
        total_tims: this.setScoreTime,
        content_id: this.contentDetail.id,
        content_type: 1,
        campain_id: this.contentDetail.campain_id,
        section_id: this.contentDetail.section_id,
        session_num: this.onlySessionNum,
        finish,
        time_type: 1
      });
      endTime && (this.listCampainContent[this.listCampainContentIndex].learn_time = endTime);
    },
    setBrowse() {
      this.setLearnTimeLog();
      console.log(this.contentDetail, '==>this.contentDetail');
      /* 应该赋值为返回数据中的时长，接口未完善 先写0 */
      this.setScoreTime = Number(this.contentDetail.learn_time) || 0;
      this.setScoreTimer = setInterval(() => {
        this.setScoreTime += 3;
        this.$store.dispatch('setScoreLog', {
          route: {
            ...this.$route,
            query: {
              ...this.$route.query,
              typeMap: 7
            }
          },
          action_type: 'LEARN',
          total_tims: this.setScoreTime,
          content_id: this.contentDetail.id,
          content_type: 1,
          campain_id: this.contentDetail.campain_id,
          section_id: this.contentDetail.section_id,
          session_num: this.onlySessionNum,
          time_type: 1
        });
      }, 3000);
    },
    /* 浏览积分 End */

    /* 分页 start */
    initReachBottom() {
      window.addEventListener('reachBottom', this.reachBottomHandle);
    },
    reachBottomHandle(evt) {
      var _this$listCampainCont;
      const self = this;
      if (evt.detail.path != '/activity/detail') {
        return;
      }
      if (self.isReachBottom) {
        return;
      }
      if (this.showModuleCheck == 3 && (_this$listCampainCont = this.listCampainContent[this.listCampainContentIndex]) !== null && _this$listCampainCont !== void 0 && _this$listCampainCont.have_comments) {
        var _this$$refs;
        self.isReachBottom = true;
        (_this$$refs = this.$refs) === null || _this$$refs === void 0 || (_this$$refs = _this$$refs.mycomment) === null || _this$$refs === void 0 || _this$$refs.reachBottom();
      }
    },
    /* 分页 end */

    async zanHandle(type) {
      if (this.loadZan) return;
      type = !this.myIsZan;
      this.loadZan = true;
      type && this.$store.dispatch('setScoreLog', {
        route: this.$route,
        action_type: 'ZAN',
        section_id: this.urlQuery.id
      });
      try {
        let res = await this.$api[type ? 'contentZan' : 'cancleContentZan']({
          content_id: this.urlQuery.id,
          type: 6
        });
        this.loadZan = false;
        if (type) {
          this.myIsZan = true;
          this.sectionDetail.zan++;
          this.$emit('setIsZan', true);
        } else {
          this.sectionDetail.zan--;
          this.myIsZan = false;
          this.$emit('setIsZan', false);
        }
      } catch (error) {}
    },
    async faverHandle(type) {
      if (this.loadFaver) return;
      type = !this.myIsFaver;
      this.loadFaver = true;
      type && this.$store.dispatch('setScoreLog', {
        route: this.$route,
        action_type: 'FAVER',
        section_id: this.urlQuery.id
      });
      try {
        let res = await this.$api[type ? 'contentFaver' : 'cancleContentFaver']({
          content_id: this.urlQuery.id,
          type: 6
        });
        this.loadFaver = false;
        if (type) {
          this.myIsFaver = true;
          this.sectionDetail.faver++;
          this.$emit('setIsFaver', true);
        } else {
          this.myIsFaver = false;
          this.sectionDetail.faver--;
          this.$emit('setIsFaver', false);
        }
      } catch (error) {}
    },
    async getZanCheck() {
      let res = await this.$api.getZanCheck(this.urlQuery.id, 6);
      if (res.errcode) {
        alert('getZanCheck(this.urlQuery.id, 6)' + res.errmsg);
        return;
      }
      if (res.count) {
        this.myIsZan = true;
        return;
      }
      this.myIsZan = false;
    },
    async getFaverCheck() {
      let res = await this.$api.getFaverCheck(this.urlQuery.id, 6);
      if (res.errcode) {
        alert('getZanCheck(this.urlQuery.id, 6)' + res.errmsg);
        return;
      }
      if (res.count) {
        this.myIsFaver = true;
        return;
      }
      this.myIsFaver = false;
    },
    /* 获取模块详情 用于点赞收藏 */
    async getModuleDetail() {
      this.getZanCheck();
      this.getFaverCheck();
      try {
        var _this$detailAuthData2, _this$detailAuthData3;
        this.detailAuthData = await this.$api.getCampainSectionDetailAuth({}, this.urlQuery.id);
        this.initData();
        this.initReachBottom();
        console.log(this.detailAuthData, '==>this.detailAuthData>this.detailAuthData>this.detailAuthData -------------------------');
        if (((_this$detailAuthData2 = this.detailAuthData) === null || _this$detailAuthData2 === void 0 ? void 0 : _this$detailAuthData2.hasErrCode) != 50001) {
          var _this$urlQuery;
          this.showAll = true;
          if ((_this$urlQuery = this.urlQuery) !== null && _this$urlQuery !== void 0 && _this$urlQuery.source) {
            /* 查询当前内容有没有权限 */
            this.getContentForId();
          }
          await this.isApplyStatus();
        }
        if ((_this$detailAuthData3 = this.detailAuthData) !== null && _this$detailAuthData3 !== void 0 && _this$detailAuthData3.errcode) {
          // alert("getCampainSectionDetail" + this.detailAuthData.errmsg)
          return;
        }
        /* 修改微信配置 Start */
        /* 修改为根据目录走 */
        /* let shareParams = {
          name: this.detailAuthData.name,
          cover: this.detailAuthData.cover
        }
        setWeChatShare(shareParams) */
        /* 修改微信配置 End */

        if (this.detailAuthData.content) this.detailAuthData.content = this.detailAuthData.content.replaceAll('<img', '<img style="max-width:100%;"');
        this.sectionDetail = this.detailAuthData;
      } catch (error) {}
    },
    /* 发送监测 */
    oncePostMonitor() {
      (0, _monitor.dataStatistics)({
        event_type: 'view',
        // view = 浏览  click = 点击   share = 分享
        page_title: `拜影云学院:${this.listCampainContent[this.listCampainContentIndex].name}`,
        firstParams: true,
        typeMap: 6,
        content_id: this.listCampainContent[this.listCampainContentIndex].section_id,
        campain_id: this.listCampainContent[this.listCampainContentIndex].campain_id
      });
    },
    postMonitor() {
      (0, _monitor.dataStatistics)({
        event_type: 'view',
        // view = 浏览  click = 点击   share = 分享
        page_title: `拜影云学院:${this.listCampainContent[this.listCampainContentIndex].name}`,
        typeMap: 7,
        firstParams: true,
        content_id: this.listCampainContent[this.listCampainContentIndex].id,
        section_id: this.listCampainContent[this.listCampainContentIndex].section_id,
        campain_id: this.listCampainContent[this.listCampainContentIndex].campain_id
      });
    },
    /* 生成分享海报 */
    myShare() {
      const self = this;
      const detailPosterInner = document.querySelector('.detailPosterInnerOut');
      (0, _html2canvas.default)(detailPosterInner, {
        useCORS: true
      }).then(canvas => {
        self.capUrl = canvas.toDataURL();
        (0, _imagePreview.default)({
          images: [self.capUrl],
          closeable: true,
          onClose() {
            self.$store.commit('SET_SHOWSAVEBTN', false);
          }
        });
        self.$store.commit('SET_SHOWSAVEBTN', true);
        setTimeout(() => {
          // self.$store.commit('SET_INTEGRAL', { show: true, integral: 10 })
          self.$store.dispatch('setScoreLog', {
            route: self.$route,
            action_type: 'SHARE',
            section_id: self.urlQuery.id
          });
        }, 2000);
      });
    },
    /* 富文本插入 */
    showEdtier(im) {
      let item = im === null || im === void 0 ? void 0 : im.replaceAll('<img', '<img style="max-width:100%;"');
      return item;
    },
    initData() {
      this.getCampainContent();
    },
    async isCurVideoDaysAuth() {
      this.isSetLogFlag = false;
      this.detailAuthData.id = this.listCampainContent[this.listCampainContentIndex].id;
      if (this.listCampainContent[this.listCampainContentIndex].need_veeva && this.userInfo.veeva == 0) {
        const resData = await this.$api.getShowPopDaysInfo({});
        this.isSetLogFlag = resData.veeva == 1 || resData.days > 0;
        this.isShowTxt = this.isSetLogFlag;
        console.log('this.isSetLogFlag2', this.isSetLogFlag);
        if (resData.need_tips && resData.veeva == 0) {
          //是否展示体验认证弹框
          this.showDaysPop(resData, '', this.fristGetAuthFlag);
        } else {
          this.isShowSignPage(); //优化每次判断是否报名
        }
      } else {
        this.isSetLogFlag = true;
        this.isShowTxt = true;
        this.isShowSignPage(); //优化每次判断是否报名
      }
      this.pauseLearnTimeLogView();
      this.pauseSetBrowseView();
      if (this.isSetLogFlag) {
        this.setLearnTimeLogView();
        this.setBrowseView();
      }
    },
    showDaysPop(res, resBackData, isApply) {
      let days = res.days || 0;
      this.$instanceExperienceView.show({
        days: days,
        //体验剩余天数
        isNeedBack: 'cur',
        //是否重定向返回原页面
        callBack: ret => {
          if (ret.next == 'from') {
            if (days > 0 && isApply) {
              //有体验权限 并且未报名
              this.isShowSignPage(); //优化每次判断是否报名
            }
            // if (isApply) {
            //   this.goApplyRedirect(resBackData)
            //   return
            // }
          } else {
            //立即认证跳转
            this.$PUSH({
              path: ret.next
            });
          }
        }
      });
    },
    /* 获取目录列表 */
    async getCampainContent() {
      try {
        var _this$listCampainCont2;
        let params = {
          section_id: this.urlQuery.id,
          current: this.current,
          pageSize: this.pageSize
        };
        let res = await this.$api.getCampainContent(params);
        this.loadFinish = true;
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCampainContent" + res.errmsg)
          return;
        }
        const finalData = (0, _index.limitData)({
          interfaceData: res,
          baseData: this.listCampainContent,
          limit: params
        });
        finalData.currentAdd && this.current++;
        this.listCampainContent = finalData.baseData;
        if (((_this$listCampainCont2 = this.listCampainContent) === null || _this$listCampainCont2 === void 0 ? void 0 : _this$listCampainCont2.length) < 1) {
          this.tipWords = '暂无数据';
          this.$refs.customPop.open();
          return;
        }
        const fIndex = this.listCampainContent.findIndex(im => im.id == this.urlQuery.contentid);
        this.listCampainContentIndex = fIndex > -1 ? fIndex : 0;
        await this.isCurVideoDaysAuth();
        if (this.isSetLogFlag) {
          let setScoreLogParams = {
            route: {
              ...this.$route,
              query: {
                ...this.$route.query,
                typeMap: 6
              }
            },
            section_id: this.detailAuthData.id,
            action_type: 'VIEW'
          };
          this.$store.dispatch('setScoreLog', setScoreLogParams);
        }
        let shareParams = {
          name: this.listCampainContent[this.listCampainContentIndex].name,
          cover: this.listCampainContent[this.listCampainContentIndex].cover,
          contentid: this.listCampainContent[this.listCampainContentIndex].id
        };
        this.setWeChatShare(shareParams);
        console.log('postMonitor22222--------------');
        /* 开始发送页面停留时长 */

        this.postMonitor();

        // this.oncePostMonitor()

        this.getFormEchoInfo();
      } catch (error) {}
    },
    /* 根据目录列表当前选中的数据，获取互动数据（获取的是已经填写过的 用于展示结果。未填写的在目录列表是数据中） */
    async getFormEchoInfo() {
      let formEchoInfo = await this.$api.getFormEchoInfo({}, this.listCampainContent[this.listCampainContentIndex].id, this.listCampainContent[this.listCampainContentIndex].type);
      if (formEchoInfo !== null && formEchoInfo !== void 0 && formEchoInfo.errcode) {
        alert('getFormEchoInfo获取失败' + formEchoInfo.errmsg);
        return;
      }
      this.formEchoInfo = formEchoInfo;
    }
  }
});