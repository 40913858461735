"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _default = exports.default = [{
  path: '/course',
  name: 'course',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/course/index'))),
  meta: {
    title: '学习',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜拜耳学习',
      desc: ''
    },
    tabIndex: '2'
  }
}, {
  path: '/course/detail',
  name: 'courseDetail',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/course/detail'))),
  meta: {
    title: '详情',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜学习详情',
      desc: '学习详情'
    },
    reachBottom: true,
    checkUser: true,
    tabIndex: '2',
    hideApproval: true
  }
}, {
  path: '/course/class',
  name: 'courseClass',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/course/class'))),
  meta: {
    title: '学习列表',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜拜耳学习',
      desc: ''
    },
    tabIndex: '2',
    reachBottom: true
  }
}, {
  path: '/course/search',
  name: 'courseSeach',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('views/course/search'))),
  meta: {
    title: '学习搜索',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜拜耳学习',
      desc: ''
    },
    tabIndex: '2',
    reachBottom: true
  }
}];