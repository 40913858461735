"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$userInfo, _vm$handleLevel, _vm$handleLevel2, _vm$handleLevel3, _vm$handleLevel4, _vm$handleLevel5, _vm$handleLevel6, _vm$handleLevel7, _vm$handleLevel8, _vm$handleLevel9;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container"
  }, [(_vm$userInfo = _vm.userInfo) !== null && _vm$userInfo !== void 0 && _vm$userInfo.id ? _c("div", {
    staticClass: "userLevelCardCom"
  }, [_c("div", {
    staticClass: "userLevelCardLevel flex align-end"
  }, [_vm._v(" Lv." + _vm._s(_vm.userInfo.level) + " "), _c("span", [_vm._v(_vm._s((_vm$handleLevel = _vm.handleLevel()) === null || _vm$handleLevel === void 0 ? void 0 : _vm$handleLevel.title))]), _c("div", {
    staticClass: "userLevelCardLevelCount flex align-center justify-center"
  }, [_vm._v(_vm._s(_vm.userInfo.score || 0) + "点")])]), _c("div", {
    staticClass: "userLevelCardProgress"
  }, [((_vm$handleLevel2 = _vm.handleLevel()) === null || _vm$handleLevel2 === void 0 ? void 0 : _vm$handleLevel2.progress) != 100 ? _c("div", {
    staticClass: "userLevelCardProgressInner",
    style: {
      width: `calc(${(_vm$handleLevel3 = _vm.handleLevel()) === null || _vm$handleLevel3 === void 0 ? void 0 : _vm$handleLevel3.progress}% - 9px)`
    }
  }) : _c("div", {
    staticClass: "userLevelCardProgressInner",
    style: {
      width: `calc(${(_vm$handleLevel4 = _vm.handleLevel()) === null || _vm$handleLevel4 === void 0 ? void 0 : _vm$handleLevel4.progress}%)`
    }
  }), ((_vm$handleLevel5 = _vm.handleLevel()) === null || _vm$handleLevel5 === void 0 ? void 0 : _vm$handleLevel5.progress) != 100 ? _c("div", {
    staticClass: "userLevelCardProgressSpot",
    style: {
      left: `calc(${(_vm$handleLevel6 = _vm.handleLevel()) === null || _vm$handleLevel6 === void 0 ? void 0 : _vm$handleLevel6.progress}% - 16px)`
    }
  }) : _c("div", {
    staticClass: "userLevelCardProgressSpot",
    style: {
      left: `calc(${(_vm$handleLevel7 = _vm.handleLevel()) === null || _vm$handleLevel7 === void 0 ? void 0 : _vm$handleLevel7.progress}%)`
    }
  }), _c("div", {
    staticClass: "userLevelCardProgressLeftNumber flex align-center"
  }, [_vm._v("Lv." + _vm._s(_vm.userInfo.level) + " ")]), ((_vm$handleLevel8 = _vm.handleLevel()) === null || _vm$handleLevel8 === void 0 ? void 0 : _vm$handleLevel8.progress) != 100 ? _c("div", {
    staticClass: "userLevelCardProgressRightNumber flex align-center"
  }, [_vm._v("Lv." + _vm._s((_vm$handleLevel9 = _vm.handleLevel()) === null || _vm$handleLevel9 === void 0 ? void 0 : _vm$handleLevel9.nextLevel))]) : _vm._e()]), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "userLevelCardGoPromote flex align-center justify-center",
    attrs: {
      "event-key": `去提升`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/personal/task"
        });
      }
    }
  }, [_vm._v(" 去提升 ")]), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "catRank flex align-center justify-center",
    attrs: {
      "event-key": `查看排行榜`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/personal/rank"
        });
      }
    }
  }, [_vm._v("查看排行榜 "), _c("div", {
    staticClass: "arrowRight"
  }, [_c("svg", {
    staticClass: "icon",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      t: "1694073212976",
      viewBox: "0 0 1024 1024",
      version: "1.1",
      xmlns: "http://www.w3.org/2000/svg",
      "p-id": "4004",
      width: "200",
      height: "200"
    }
  }, [_c("path", {
    attrs: {
      d: "M731.733333 480l-384-341.333333c-17.066667-14.933333-44.8-14.933333-59.733333 4.266666-14.933333 17.066667-14.933333 44.8 4.266667 59.733334L640 512 292.266667 821.333333c-17.066667 14.933333-19.2 42.666667-4.266667 59.733334 8.533333 8.533333 19.2 14.933333 32 14.933333 10.666667 0 19.2-4.266667 27.733333-10.666667l384-341.333333c8.533333-8.533333 14.933333-19.2 14.933334-32s-4.266667-23.466667-14.933334-32z",
      fill: "#ffffff",
      "p-id": "4005"
    }
  })])])])]) : _c("div", {
    staticClass: "userLevelCardCom"
  }, [_c("div", {
    staticClass: "userLevelCardLevel"
  }, [_vm._v(" Lv. - ")]), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "userLevelCardGoPromote flex align-center justify-center",
    attrs: {
      "event-key": `去提升`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/personal/task"
        });
      }
    }
  }, [_vm._v(" 去提升 ")]), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "catRank flex align-center justify-center",
    attrs: {
      "event-key": `查看排行榜`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/personal/rank"
        });
      }
    }
  }, [_vm._v("查看排行榜 "), _c("img", {
    attrs: {
      src: require("img/common/arrowRight.png"),
      alt: ""
    }
  })])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;