"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/utils/index.js");
var _index2 = _interopRequireDefault(require("@/components/customSelect/index.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    customSelect: _index2.default
  },
  data() {
    return {
      errorTipWords: "",
      encryptionArray: ["email", "name"],
      formData: {
        privacy: false
        // terms: false
      },
      formDataRule: {
        name: [{
          required: true,
          message: "请填写姓名"
        }],
        email: [{
          required: true,
          message: "请填写邮箱"
        }, {
          crypt: true
        }],
        code: [{
          required: true,
          message: "请填写验证码"
        }],
        privacy: [{
          required: true,
          message: "请浏览并同意签署《个人信息披露知情同意》"
        }]
        /* terms: [
          {
            required: true,
            message: "请确认勾选隐私声明 及使用条款"
          }
        ] */
      },
      getEmailCode: new _index.getSmsAfter(),
      isSubmiting: false,
      codeSending: false
    };
  },
  watch: {},
  filter: {},
  created() {},
  mounted() {},
  computed: {},
  methods: {
    async sendEmailCode() {
      if (this.getEmailCode.timer) {
        this.errorTipWords = `验证码已发送`;
        this.$refs[`customPop-error`].open();
        setTimeout(() => {
          this.$refs[`customPop-error`].close();
        }, 1500);
        return;
      }
      if (!this.formData.email) {
        this.errorTipWords = `请输入邮箱`;
        this.$refs[`customPop-error`].open();
        return;
      }
      const self = this;

      /* 邮箱发送接口 */
      const myCrypt = new _index.Crypt();
      let params = {
        email: myCrypt.encrypt(self.formData.email + "@bayer.com")
      };
      params["crypt_key"] = myCrypt.rsa_key();
      this.getEmailCode.countDown();
      if (this.codeSending) return;
      this.codeSending = true;
      let res = await this.$api.mailSend(params);
      setTimeout(() => {
        this.codeSending = false;
      }, 500);
      if (res !== null && res !== void 0 && res.errcode) {
        this.errorTipWords = `发送失败：${res.errmsg}`;
        this.$refs[`customPop-error`].open();
        this.getEmailCode.clearStatus();
        return;
      }
      this.errorTipWords = `验证码发送成功`;
      this.$refs[`customPop-error`].open();
      setTimeout(() => {
        this.$refs[`customPop-error`].close();
      }, 1500);
    },
    checkValue(params) {
      this.formData[params.field] = params.check;
    },
    to(value) {
      console.log(value, '==>value');
    },
    checkValue() {
      for (let key in this.formDataRule) {
        this.formDataRule[key].forEach((im, ix) => {
          var _this$formData$key;
          if (im.required && (!this.formData[key] || this.formData[key] instanceof String && !((_this$formData$key = this.formData[key]) !== null && _this$formData$key !== void 0 && _this$formData$key.trim()))) {
            this.errorTipWords = im.message;
            this.$refs[`customPop-error`].open();
            throw im.message;
          }
        });
      }
      return true;
    },
    async registerSubmit() {
      const {
        query
      } = this.$route;
      if (this.checkValue()) {
        let params = JSON.parse(JSON.stringify(this.formData));
        if (this.encryptionArray.length) {
          const myCrypt = new _index.Crypt();
          for (const key in params) {
            if (this.encryptionArray.includes(key)) {
              if (key == "email") {
                params[key] = params[key] + '@bayer.com';
              }
              params[key] = myCrypt.encrypt(params[key]);
            }
          }
          params["crypt_key"] = myCrypt.rsa_key();
        }
        delete params['privacy'];
        // delete params['terms']

        console.log(params, '==>this.formData');
        if (this.isSubmiting) return;
        this.isSubmiting = true;
        if (query !== null && query !== void 0 && query.channel) {
          params["channel"] = query === null || query === void 0 ? void 0 : query.channel;
        }
        let res = await this.$api.staffRegister(params);
        this.isSubmiting = false;
        if (res !== null && res !== void 0 && res.errcode) {
          this.errorTipWords = `注册失败：${res.errmsg}`;
          this.$refs[`customPop-error`].open();
          return;
        }
        this.errorTipWords = `注册成功，即将跳转至首页`;
        this.$store.dispatch("getUserInfo");
        this.$refs[`customPop-error`].open();
        let isH5Ruter = window.sessionStorage.getItem('isResterH5');
        if (isH5Ruter) {
          setTimeout(() => {
            this.$PUSH({
              path: '/annual/task'
            });
            window.sessionStorage.setItem('isResterH5', '');
          }, 1500);
        } else {
          setTimeout(() => {
            this.$PUSH({
              path: '/'
            });
          }, 1500);
        }

        // this.$refs["customPop-cus"].open()
      }

      // this.$refs.customPop.open()
    },
    customPopCancle() {},
    customPopConfirm() {},
    registerSuccessHandleDelay() {
      this.$refs['customPop-cus'].close();
    },
    registerSuccessHandleNow() {
      this.$refs['customPop-cus'].close();
    }
  }
});