"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dataStatistics = dataStatistics;
exports.monitorClick = monitorClick;
exports.monitorShare = monitorShare;
exports.monitorView = monitorView;
var _vue = _interopRequireDefault(require("vue"));
var _router = _interopRequireDefault(require("@/router"));
var _store = _interopRequireDefault(require("@/store"));
var _config = require("@/config");
var _http = _interopRequireDefault(require("utils/http"));
var _index = require("./index");
const whiteMonitor = ["/coursedetail", "/previewmeetingdetail", "/activitymoduledetail", "/activitycontentdetail"];

// 监测 api 文档 https://middle-platform.blue-dot.cn/docs/analysis
function dataStatistics({
  page_title,
  event_type,
  event_key,
  typeMap,
  content_id,
  campain_id,
  section_id,
  firstParams
}, json) {
  page_title = page_title || `${_config.title}:${document.title}`;
  /** 通用参数配置 */
  const wechatInfo = _store.default.getters.wechatInfo;
  if (!wechatInfo) return;
  const {
    openid,
    unionid
  } = wechatInfo;
  const href = window.location.href;
  const query = (0, _index.getUrlParams)(); // 获取页面参数

  const url = window.location.href;
  whiteMonitor.forEach(im => {
    console.log(url, '==?> url -------------');
    console.log(im, '==?> im -------------');
    if (url.indexOf(im) != -1) {
      console.log("======================================");
      throw "";
    }
  });

  /* if(whiteMonitor.includes(value.route.path)){
    return
  } */

  console.log(url, '==> monitor url ');

  // 未遇到 keywords 的情况
  let _keywords = '';
  if (json && json.keywords) {
    _keywords = json.keywords || [];
    if (_keywords.length > 0) {
      _keywords = _keywords.map(item => item.tag_id);
    } else {
      _keywords = '';
    }
  }
  const user_id = '';
  const params = {
    /* 原本是config中的配置，现在修改为自定义的 */
    type: query.typeMap || typeMap,
    content_id: content_id || query.id,
    user_id,
    // 用户id
    unionid,
    openid,
    page_title,
    // 页面标题
    // page_description: '', // 页面描述
    page_url: encodeURIComponent(href),
    // 页面 url
    page_referer_url: '',
    // 页面来源 (从哪里跳转过来)
    page_param: query,
    // 页面参数
    keywords: _keywords,
    // 标签 id (---目前未接触---)
    page_event_key: event_key,
    page_event_type: event_type,
    // view 浏览, click 点击, share 分享
    campain_id: campain_id
  };
  if (query !== null && query !== void 0 && query.typeMap || typeMap) {
    params["monior_type"] = 1;
  }
  if (section_id) {
    params['section_id'] = section_id;
  }
  if (firstParams) {
    params["type"] = typeMap;
  }
  if (typeMap + '' == '6') {
    params["campain_id"] = query.campain_id;
  }
  if (query.fromsource) {
    params["fromsource"] = query.fromsource;
  }
  if (query.opentype) {
    params["opentype"] = query.opentype;
  }
  // if(url.indexOf("/personal/history") != -1){
  //   params["monior_type"] = 1
  // }

  const isRegister = localStorage.getItem("isRegister");
  if (!isRegister && !(query !== null && query !== void 0 && query.fromsource || query !== null && query !== void 0 && query.channel)) return;
  let request_api = `${_config.baseUrl.base}/api/platform/monitor`; // 中台域名
  (0, _http.default)({
    url: request_api,
    custom: 1,
    method: 'post',
    params: (0, _index.handleParmas)(params)
  }).then(res => {});
}
function monitorView() {
  dataStatistics({
    event_type: 'view',
    // view = 浏览  click = 点击   share = 分享
    page_title: `${_config.title}:${document.title}`
  });
}
function monitorClick(event_key) {
  dataStatistics({
    event_type: 'click',
    event_key,
    page_title: `${_config.title}:${document.title}`
  });
}
function monitorShare() {
  dataStatistics({
    event_type: 'share'
  });
}

// 注册指令
(function () {
  if (!_store.default.getters.monitor) {
    _store.default.dispatch('directiveMonitor');
    _vue.default.directive('monitor', {
      bind: function (el, binding, vnode) {
        function clickHandler(e) {
          if (!_config.monitor.open_state) return;
          // 判断点击的元素是否是本身，提交监测
          if (el.contains(e.target)) {
            const eventKey = el.getAttribute("event-key");
            const pate_title = _router.default.app._route.meta.title || document.title;
            const value = binding.value || `点击:${pate_title}:${eventKey}`;
            monitorClick(value);
          }
        }
        el.__vueClickOutside__ = clickHandler;
        document.addEventListener('click', clickHandler);
      },
      unbind(el, binding, vnode) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
      }
    });
  }
})();