"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.isVisible ? _c("div", {
    staticClass: "dialog-overlay",
    class: {
      active: _vm.backgroundStyle
    }
  }, [_c("div", {
    staticClass: "dialog"
  }, [_c("div", {
    staticClass: "dialog-header"
  }, [_vm._v(_vm._s(_vm.title))]), _c("div", {
    staticClass: "dialog-body"
  }, [_vm._v(_vm._s(_vm.message))]), _c("div", {
    staticClass: "dialog-footer"
  }, [_c("button", {
    staticClass: "dialog-button confirm",
    on: {
      click: _vm.confirm
    }
  }, [_vm._v("参与互动")]), _c("button", {
    staticClass: "dialog-button cancel",
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("返回首页")])])])]) : _vm._e();
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;