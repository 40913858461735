"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "veevaCodeWords"
  }, [_c("div", {
    staticClass: "veevaCodeWordsWords"
  }, [_vm._v("您已获得医疗卫生专业人士认证，点击查看权益")]), _c("div", {
    staticClass: "veevaCodeWordsBtn",
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/veeva"
        });
      }
    }
  }, [_vm._v(" 前往查看 ")])]), false ? _c("div", {
    staticClass: "veevaCode"
  }, [_c("div", {
    staticClass: "veevaCodeWords"
  }, [_vm._v("您已获得医疗卫生专业人士认证，还差一步就可以参加拜耳学术会议了，快来扫码绑定吧！")]), _c("div", {
    staticClass: "veevaCodeQr"
  }, [_c("img", {
    attrs: {
      src: _vm.base64QRCode,
      alt: ""
    }
  })]), _c("div", {
    staticClass: "outScreen"
  }, [_c("qriously", {
    ref: "QRCode",
    attrs: {
      value: _vm.dataBase.link,
      foreground: "#000000",
      size: 200,
      backgroundAlpha: 0
    }
  })], 1)]) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;