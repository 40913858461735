"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _modules = _interopRequireDefault(require("./modules"));
const install = Vue => {
  Vue.prototype.$api = _modules.default;
};
var _default = exports.default = {
  install
};