"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _aiConfirmVue = _interopRequireDefault(require("aui/senior/ai-confirm-vue"));
const AiConfirm = _vue.default.extend(_aiConfirmVue.default);
_aiConfirmVue.default.install = function (data) {
  const instance = new AiConfirm({
    data
  }).$mount();
  document.body.appendChild(instance.$el);
  _vue.default.nextTick(() => {
    instance.show = true;
  });
};
var _default = exports.default = _aiConfirmVue.default;