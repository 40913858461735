"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$sectionDetail, _vm$sectionDetail2;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "activityRichList"
  }, [_c("stickyHeader"), _c("div", {
    staticClass: "activityRichListView marginAuto"
  }, _vm._l(_vm.listCampainContent, function (im, ix) {
    return _c("div", {
      directives: [{
        name: "monitor",
        rawName: "v-monitor"
      }],
      key: ix,
      staticClass: "activityRichListViewItem flex justify-between",
      attrs: {
        "event-key": `活动模块图文列表-${im.name}`
      },
      on: {
        click: function ($event) {
          return _vm.toPath(im);
        }
      }
    }, [_c("div", {
      staticClass: "activityRichListViewItemLeft"
    }, [_c("img", {
      attrs: {
        src: im.cover,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "activityRichListViewItemRight"
    }, [_c("div", {
      staticClass: "activityRichListViewItemRightTitle hideWords-More4"
    }, [_vm._v(_vm._s(im.name))]), _c("div", {
      staticClass: "activityRichListViewItemRightBottom flex align-center"
    }, [_c("div", {
      staticClass: "activityRichListViewItemRightBottomTag"
    }, [_vm._v(_vm._s(_vm.$MAPTYPE(im.content_type)))]), _c("div", {
      staticClass: "activityRichListViewItemRightBottomTime"
    }, [_vm._v(_vm._s(_vm.studyState(im)))])])])]);
  }), 0), (_vm$sectionDetail = _vm.sectionDetail) !== null && _vm$sectionDetail !== void 0 && _vm$sectionDetail.id ? _c("meetingPoster", {
    attrs: {
      dataBase: _vm.sectionDetail,
      posterType: 7,
      tagList: (_vm$sectionDetail2 = _vm.sectionDetail) === null || _vm$sectionDetail2 === void 0 ? void 0 : _vm$sectionDetail2.tag_name_list
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;