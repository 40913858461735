"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/utils/index.js");
var _default = exports.default = {
  components: {},
  props: {},
  data() {
    return {
      isSign: false,
      showPop: false,
      showSign: false,
      complete_level: {
        1: '低',
        2: '中',
        3: '高'
      },
      submiting: false,
      status: 2
    };
  },
  watch: {},
  filter: {},
  created() {
    this.init();
  },
  mounted() {},
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    toregister() {
      const openid = localStorage.getItem("openid");
      const wechatInfo = localStorage.getItem("wechatInfo");
      const isRegister = localStorage.getItem("isRegister");
      if (!openid || !wechatInfo || !isRegister) {
        this.$registerView.show(ret => {
          if (ret.next == "from") {
            this.$store.commit("SET_CALLUSSHOW", false);
          } else {
            this.$store.commit("SET_CALLUSSHOW", false);
            this.$PUSH({
              path: `/register`,
              query: {
                redirect: encodeURIComponent(this.$route.fullPath)
              }
            });
          }
        });
        return;
      }
    },
    async init() {
      let res = await this.$api.loginChech();
      this.showSign = true;
      if (res !== null && res !== void 0 && res.errcode) {
        // alert("查询签到失败：" + res.errmsg)
        return;
      }
      if (res.have) {
        this.isSign = true;
      }
      if (!res.have) {
        this.isSign = false;
      }
    },
    async setSign() {
      if (this.submiting == true) {
        return;
      }
      this.submiting = true;
      let res = await this.$api.setLogin();
      setTimeout(() => {
        this.submiting = false;
      }, 500);
      if (res !== null && res !== void 0 && res.errcode) {
        // alert("签到失败：" + res.errmsg)
        this.$toatsView.show({
          tipWords: "签到失败，请重试"
        });
        // this.$toatsView.show({tipWords: "签到失败：" + res.errmsg})

        return;
      }
      // this.$toatsView.show({tipWords: "签到成功"})
      // this.$store.commit('SET_INTEGRAL', { show: true, integral: 10 })
      this.init();
      this.$store.dispatch("getUserInfo");
    },
    showInfo() {
      var _this$userInfo, _this$userInfo2;
      /* this.userInfo.show: 1：头像 2：昵称 */
      const deCrypt = new _index.Crypt();
      let params = {
        title: deCrypt.decrypt(this.userInfo.name),
        avatar: this.userInfo.avatar
      };
      if (this.userInfo.type == '2') {
        return params;
      }
      if ((_this$userInfo = this.userInfo) !== null && _this$userInfo !== void 0 && (_this$userInfo = _this$userInfo.shows) !== null && _this$userInfo !== void 0 && _this$userInfo.includes("1")) {
        params["avatar"] = this.userInfo.avatar_virtual;
      }
      if ((_this$userInfo2 = this.userInfo) !== null && _this$userInfo2 !== void 0 && (_this$userInfo2 = _this$userInfo2.shows) !== null && _this$userInfo2 !== void 0 && _this$userInfo2.includes("2")) {
        params["title"] = this.userInfo.nickname;
      }
      return params;
    }
  }
};