"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$listDetail, _vm$listDetail2;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container-components"
  }, [!_vm.isPlay ? _c("div", {
    staticClass: "playBtn flex align-center justify-center"
  }, [(_vm$listDetail = _vm.listDetail) !== null && _vm$listDetail !== void 0 && _vm$listDetail.name || _vm.videoName ? _c("div", {
    staticClass: "playerTitle"
  }, [_vm._v(_vm._s(((_vm$listDetail2 = _vm.listDetail) === null || _vm$listDetail2 === void 0 ? void 0 : _vm$listDetail2.name) || _vm.videoName))]) : _vm._e(), _c("img", {
    attrs: {
      src: require("img/common/whitePlay.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        return _vm.$refs.VideoPlayer.player.play();
      }
    }
  })]) : _vm._e(), _c("video-player", {
    ref: "VideoPlayer",
    staticClass: "vjs-custom-skin videoPlayer",
    attrs: {
      options: _vm.playerOptions,
      playsinline: true
    },
    on: {
      play: function ($event) {
        return _vm.onplayFn();
      },
      pause: function ($event) {
        return _vm.onpauseFn();
      },
      ended: _vm.onPlayerEnd,
      error: function ($event) {
        return _vm.onpauseFn();
      },
      ready: _vm.onPlayerReady
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;