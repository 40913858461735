"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("div", {
    staticClass: "playVideListOut marginAuto"
  }, _vm._l(_vm.dataBase, function (im, ix) {
    var _im$cover;
    return _c("div", {
      key: ix,
      staticClass: "playVideList marginAuto flex",
      on: {
        click: function ($event) {
          _vm.dataIndex = ix;
        }
      }
    }, [_vm._m(0, true), _c("div", {
      staticClass: "playVideListRight flex align-center"
    }, [im !== null && im !== void 0 && im.need_veeva ? _c("div", {
      staticClass: "authIcon"
    }, [_c("img", {
      attrs: {
        alt: "",
        srcset: "",
        src: require("img/common/onlyAuthIcon.png")
      }
    })]) : _vm._e(), _c("div", {
      staticClass: "playVideListRightIcon"
    }, [_c("img", {
      attrs: {
        src: (im === null || im === void 0 || (_im$cover = im.cover) === null || _im$cover === void 0 ? void 0 : _im$cover.url) || (im === null || im === void 0 ? void 0 : im.coverurl) || (im === null || im === void 0 ? void 0 : im.cover) || _vm.$DEFAULTAVATAR,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "playVideListRightWordOut"
    }, [_c("div", {
      staticClass: "playVideListRightWord",
      class: _vm.renderModule == "activity" ? "hideWords-More3" : "hideWords-More4"
    }, [_vm._v(" " + _vm._s(im["name"]) + " ")]), _vm.renderModule == "activity" ? _c("div", {
      staticClass: "playVideListRightWordOutDur"
    }, [_vm._v(_vm._s(_vm.renderDur(im, ix)))]) : _vm._e()]), _c("div", {
      staticClass: "playVideListRighIcon"
    }, [_vm.playIndex == ix ? _c("img", {
      staticClass: "videoPlaying",
      attrs: {
        src: require("img/common/videoPlaying.png"),
        alt: ""
      }
    }) : _c("img", {
      staticClass: "videoPlayWait",
      attrs: {
        src: require("img/common/videoPlayWait.png"),
        alt: ""
      }
    }), _c("div", {
      staticClass: "previewBox"
    }, [_vm.curtype != 4 ? _c("div", {
      staticClass: "previewContent"
    }, [_c("img", {
      staticClass: "previeImg",
      attrs: {
        src: require("img/common/browseCountOther.png"),
        alt: "",
        srcset: ""
      }
    }), _c("span", {
      staticClass: "previewNum"
    }, [_vm._v(_vm._s(_vm.$NUMF(im.read_num || 0)))])]) : _vm._e()])])])]);
  }), 0)]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "playVideListLeft"
  }, [_c("div", {
    staticClass: "playVideListLeftInner"
  }, [_c("div", {
    staticClass: "playVideListLeftInnerInner"
  })])]);
}];
render._withStripped = true;