"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "veevaHandle"
  }, [_c("stickyHeader"), !_vm.isVeevaAuth && _vm.veevaInfo.status == 1 ? _c("div", {
    staticClass: "authInfos authPendding"
  }, [_vm._v(" 已收到您的认证资料，预计在1-2周内完成处理，请耐心等待 ")]) : _vm._e(), !_vm.isVeevaAuth && _vm.veevaInfo.status == 3 ? _c("div", {
    staticClass: "authInfos authError"
  }, [_vm._v(" 很遗憾！您没有通过医疗卫生专业人士身份认证，您可以修改个人信息并补充认证资料后，重新申请认证 ")]) : _vm._e(), !_vm.isVeevaAuth ? _c("div", {
    staticClass: "swiper-container",
    attrs: {
      id: "swiper-container1"
    }
  }, [_c("div", {
    staticClass: "swiper-wrapper"
  }, [_vm._m(0), _c("div", {
    staticClass: "swiper-slide"
  }, [_c("div", {
    staticClass: "swiper-container",
    attrs: {
      id: "swiper-container3"
    }
  }, [_c("div", {
    staticClass: "swiper-wrapper"
  }, [_c("div", {
    staticClass: "swiper-slide customSelectFilterListssss"
  }, [_c("div", {
    staticClass: "authInfosSeat",
    class: _vm.veevaInfo.status == 1 ? "authInfosSeatSmall" : _vm.veevaInfo.status == 3 ? "authInfosSeatBig" : ""
  }), _c("VeevaStep", {
    attrs: {
      step: 1
    }
  }), _c("div", {
    staticClass: "authInfotips"
  }, [_vm._v("*您在此处填写的认证信息将同步至个人中心的基本信息处，请知悉")]), _c("BaseInfos", {
    ref: "baseInfos",
    on: {
      setSwiperTouch: _vm.setSwiperTouch
    }
  }), _c("ApprovalNo")], 1)]), _c("div", {
    staticClass: "swiper-scrollbar"
  })])])]), _c("div", {
    staticClass: "swiper-pagination"
  })]) : _vm._e(), _vm.isVeevaAuth ? _c("div", [_c("VeevaPass")], 1) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "swiper-slide"
  }, [_c("div", {
    staticClass: "swiper-container",
    attrs: {
      id: "swiper-container2"
    }
  }, [_c("div", {
    staticClass: "swiper-wrapper"
  }, [_c("div", {
    staticClass: "swiper-slide"
  }, [_c("div", {
    staticClass: "veevaInfos"
  }, [_c("img", {
    staticClass: "veevainfoImg",
    attrs: {
      src: require("img/personal/auth/veevainfo.png"),
      alt: ""
    }
  })])])]), _c("div", {
    staticClass: "swiper-scrollbar"
  })])]);
}];
render._withStripped = true;