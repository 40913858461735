"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _default = exports.default = {
  monitor: state => state.defaultData.monitor,
  openid: state => state.defaultData.openid || localStorage.getItem('openid') || _vue.default.$cookies.get('openid'),
  wechatInfo: state => {
    if (state.defaultData.wechatInfo) return state.defaultData.wechatInfo;
    const wechatInfo = localStorage.getItem('wechatInfo') || _vue.default.$cookies.get('wechatInfo');
    if (typeof wechatInfo === 'object') return wechatInfo;
    return JSON.parse(wechatInfo);
  },
  redirect: state => state.defaultData.redirect || _vue.default.$cookies.get('redirect')
};