"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  props: {
    backgroundStyle: [String, Boolean]
  },
  data() {
    return {
      isVisible: false,
      title: '',
      message: '',
      resolve: null,
      reject: null
    };
  },
  methods: {
    open(title, message) {
      this.title = title;
      this.message = message;
      this.isVisible = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      if (this.resolve) {
        this.resolve('confirmed');
      }
      this.close();
    },
    cancel() {
      if (this.reject) {
        this.reject('cancelled');
      }
      this.close();
    },
    close() {
      this.isVisible = false;
      this.resolve = null;
      this.reject = null;
    }
  }
};