"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$badgeList, _vm$tempBadgeInfo, _vm$tempBadgeInfo2, _vm$tempBadgeInfo3, _vm$tempBadgeInfo4, _vm$tempBadgeInfo5, _vm$tempBadgeInfo6, _vm$tempBadgeInfo7;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container-out"
  }, [_c("stickyHeader"), _vm._m(0), _c("div", {
    staticClass: "getBadgeCount flex"
  }, [_vm._v("已获得 "), _c("span", [_vm._v(_vm._s(_vm.badgeList.received_))]), _vm._v(" 枚勋章")]), _vm._m(1), _c("div", {
    staticClass: "getBadgeList flex flex-wrap justify-between"
  }, [(_vm$badgeList = _vm.badgeList) !== null && _vm$badgeList !== void 0 && (_vm$badgeList = _vm$badgeList.unclaimed_medal) !== null && _vm$badgeList !== void 0 && _vm$badgeList.length ? _c("div", {
    staticClass: "waitGet"
  }, [_c("div", {
    staticClass: "waitGetTop"
  }, [_vm._v(" 您有" + _vm._s(_vm.badgeList.unclaimed_count) + "个徽章待领取 ")]), _c("div", {
    staticClass: "waitGetList flex flex-wrap justify-around"
  }, _vm._l(_vm.badgeList.unclaimed_medal, function (im, ix) {
    return _c("div", {
      directives: [{
        name: "monitor",
        rawName: "v-monitor"
      }],
      staticClass: "getBadgeListItem",
      attrs: {
        "event-key": `查看勋章-${im.medal_name}`
      },
      on: {
        click: function ($event) {
          return _vm.showBadgeInfo(im, false);
        }
      }
    }, [_c("div", {
      staticClass: "getBadgeListItemImg"
    }, [_c("img", {
      staticClass: "gray",
      attrs: {
        src: im.path,
        alt: ""
      }
    }), _c("div", {
      staticClass: "getBadgeListItemImgNewTip flex justify-center align-center"
    }, [_vm._v("NEW")])]), _c("div", {
      staticClass: "getBadgeListItemTitle"
    }, [_vm._v(_vm._s(im.medal_name))]), _c("div", {
      directives: [{
        name: "monitor",
        rawName: "v-monitor"
      }],
      staticClass: "getBadgeListItemGetBtn flex justify-center align-center",
      attrs: {
        "event-key": `领取勋章-${im.medal_name}`
      },
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.$PUSH({
            path: `/personal/achievedetail?type=badge&id=${im.id}`
          });
        }
      }
    }, [_vm._v("立即领取")])]);
  }), 0)]) : _vm._e(), _vm._l(_vm.badgeList.received_medal, function (im, ix) {
    return _c("div", {
      directives: [{
        name: "monitor",
        rawName: "v-monitor"
      }],
      staticClass: "getBadgeListItem",
      attrs: {
        "event-key": `查看勋章-${im.medal_name}`
      },
      on: {
        click: function ($event) {
          return _vm.showBadgeInfo(im, true);
        }
      }
    }, [_c("div", {
      staticClass: "getBadgeListItemImg"
    }, [_c("img", {
      attrs: {
        src: im.path,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "getBadgeListItemTitle"
    }, [_vm._v(_vm._s(im.medal_name))])]);
  }), _vm._l(_vm.badgeList.medal, function (im, ix) {
    return _c("div", {
      directives: [{
        name: "monitor",
        rawName: "v-monitor"
      }],
      staticClass: "getBadgeListItem",
      class: true ? "gray" : "",
      attrs: {
        "event-key": `查看勋章-${im.name}`
      },
      on: {
        click: function ($event) {
          return _vm.showBadgeInfo(im);
        }
      }
    }, [_c("div", {
      staticClass: "getBadgeListItemImg"
    }, [_c("img", {
      attrs: {
        src: im.path,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "getBadgeListItemTitle"
    }, [_vm._v(_vm._s(im.name))])]);
  }), _vm._l(3, function (i) {
    return _c("div", {
      key: i,
      staticClass: "getBadgeListItemSeat"
    });
  })], 2), _c("BadgePop", {
    ref: "customPop-badgeInfo",
    attrs: {
      background: "rgba(0, 0, 0, 0.8)",
      transparent: true,
      mask: true,
      showType: "slot"
    },
    on: {
      cancle: function ($event) {
        _vm.tempBadgeInfo = {};
      }
    }
  }, [_c("div", {
    staticClass: "popbadgeInfos",
    on: {
      click: _vm.hideBadgeInfo
    }
  }, [_c("div", {
    staticClass: "popbadgeInfo"
  }, [_c("div", {
    staticClass: "popbadgeInfoStar popbadgeInfoStar1"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/ach/star1.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "popbadgeInfoStar popbadgeInfoStar2"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/ach/star2.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "popbadgeInfoStar popbadgeInfoStar3"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/ach/star3.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "popbadgeInfoIcon",
    class: !((_vm$tempBadgeInfo = _vm.tempBadgeInfo) !== null && _vm$tempBadgeInfo !== void 0 && _vm$tempBadgeInfo.isGray) && "gray"
  }, [_c("img", {
    staticClass: "popbadgeInfoShadow",
    staticStyle: {
      opacity: "0.6"
    },
    attrs: {
      src: require("img/personal/ach/badgeShadow.png"),
      alt: ""
    }
  }), _c("img", {
    staticStyle: {
      "z-index": "5",
      position: "static"
    },
    attrs: {
      src: (_vm$tempBadgeInfo2 = _vm.tempBadgeInfo) === null || _vm$tempBadgeInfo2 === void 0 ? void 0 : _vm$tempBadgeInfo2.path,
      alt: ""
    }
  })])]), _c("div", {
    staticClass: "popbadgeInfoTitle"
  }, [_vm._v(" " + _vm._s(((_vm$tempBadgeInfo3 = _vm.tempBadgeInfo) === null || _vm$tempBadgeInfo3 === void 0 ? void 0 : _vm$tempBadgeInfo3.name) || ((_vm$tempBadgeInfo4 = _vm.tempBadgeInfo) === null || _vm$tempBadgeInfo4 === void 0 ? void 0 : _vm$tempBadgeInfo4.medal_name)) + " ")]), _c("div", {
    staticClass: "popbadgeInfoDesc",
    domProps: {
      innerHTML: _vm._s(_vm.$VIDEOPLAYSINLINE(_vm.handleIntroduction(_vm.tempBadgeInfo.introduction)))
    },
    on: {
      click: _vm.$imageEnlargement
    }
  }), (_vm$tempBadgeInfo5 = _vm.tempBadgeInfo) !== null && _vm$tempBadgeInfo5 !== void 0 && _vm$tempBadgeInfo5.isGray ? _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "achieveDetailContentBtn achieveDetailContentBtnShare flex align-center justify-center",
    attrs: {
      "event-key": `分享勋章-${((_vm$tempBadgeInfo6 = _vm.tempBadgeInfo) === null || _vm$tempBadgeInfo6 === void 0 ? void 0 : _vm$tempBadgeInfo6.name) || ((_vm$tempBadgeInfo7 = _vm.tempBadgeInfo) === null || _vm$tempBadgeInfo7 === void 0 ? void 0 : _vm$tempBadgeInfo7.medal_name)}`
    },
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$PUSH({
          path: `/personal/achieveshare?type=badge&id=${_vm.tempBadgeInfo.id}`
        });
      }
    }
  }, [_vm._v("分享勋章")]) : _vm._e()])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "pageImg"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/ach/pageImg.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "getBadgeTip flex align-center justify-center"
  }, [_c("img", {
    attrs: {
      src: require("img/personal/ach/smallTip.png"),
      alt: ""
    }
  }), _vm._v(" 点击可查看详情及进行分享 ")]);
}];
render._withStripped = true;