"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _vue = _interopRequireDefault(require("vue"));
var _default = exports.default = {
  components: {},
  props: {
    isActiveFlag: {
      type: [Boolean, Object],
      default: false
    },
    is_preview: {
      type: [String, Object],
      default: null
    },
    mode: {
      type: String,
      default: ''
    },
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    },
    /* 
          展示形式
            words： 文字提示
            slot  插槽
        */
    showType: {
      type: String,
      default: 'words'
    },
    /* 提示文字 */
    tipWords: {
      type: String,
      default: '请传入提示文字'
    },
    /* 表单信息 */
    formInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      errorTipWords: null,
      letterArr: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'],
      showPop: false,
      /* 问卷数据结构
                
                    {
                        "title": "这事表单名称",
                        "contents": [
                            {
                                "score": "5",
                                "answer": [
                                    {
                                        "options": "中国",
                                        "is_right": true
                                    },
                                    {
                                        "options": "日本",
                                        "is_right": true
                                    },
                                    {
                                        "options": "美国"
                                    }
                                ],
                                "comments": "这是股价",
                                "answer_type": 2,
                                "question_type": 1,
                                "question_title": "亚洲国家"
                            }
                        ]
                    }
                      question_type Map
                label:'互动-选择题value: 1,
                label: 互动-问答题value: 2,
                label:问卷-选择value: 3
                label:问卷-问答题value: 4
                      answer_type Map
                label:'单选 value: 1
                label:多选'，value: 2
                  */
      questionnaireData: {
        title: '这事表单名称',
        contents: [{
          score: '5',
          answer: [{
            options: '中国',
            is_right: true
          }, {
            options: '日本',
            is_right: true
          }, {
            options: '美国'
          }],
          comments: '这是股价',
          answer_type: 1,
          question_type: 2,
          question_title: '亚洲国家1'
        }, {
          score: '5',
          answer: [{
            options: '中国',
            is_right: true
          }, {
            options: '日本',
            is_right: true
          }, {
            options: '美国'
          }],
          comments: '这是股价',
          answer_type: 1,
          question_type: 1,
          question_title: '亚洲国家2'
        }, {
          score: '5',
          answer: [{
            options: '中国',
            is_right: true
          }, {
            options: '日本',
            is_right: true
          }, {
            options: '美国'
          }],
          comments: '这是股价',
          answer_type: 2,
          question_type: 1,
          question_title: '亚洲国家3'
        }, {
          score: '5',
          answer: [{
            options: '中国',
            is_right: true
          }, {
            options: '日本',
            is_right: true
          }, {
            options: '美国'
          }],
          comments: '这是股价',
          answer_type: 2,
          question_type: 4,
          question_title: '亚洲国家4'
        }]
      },
      questionnaireCheck: {},
      isSubmiting: false
    };
  },
  watch: {},
  filter: {},
  created() {
    this.dataFormat();
  },
  mounted() {},
  computed: {},
  methods: {
    dataFormat() {
      console.log('==>dataFormat>dataFormat');
      this.questionnaireCheck = {};
      if (this.formInfo && this.formInfo.contents) {
        this.formInfo.contents.forEach((im, ix) => {
          ;
          (!im.is_required || im.is_required == 1) && (this.questionnaireCheck[im.question_title + `-AaBbCcDdEe=${ix}`] = false);
          if (im.answer_check) return;
          if (im.question_type == 1 || im.question_type == 3) {
            _vue.default.set(im, 'answer_check', []);
            return;
          }
          _vue.default.set(im, 'answer_check', '');
        });
        this.testCheck();
      }
    },
    testCheck() {
      this.formInfo.contents.forEach((im, ix) => {
        if (im.question_type == 1 || im.question_type == 3) {
          if (im.answer_check.length) {
            this.questionnaireCheck[im.question_title + `-AaBbCcDdEe=${ix}`] = true;
          }
          return;
        }
        if (im.answer_check) {
          this.questionnaireCheck[im.question_title + `-AaBbCcDdEe=${ix}`] = true;
        }
      });
    },
    showTip(im) {
      let words = ``;
      if (im.question_type == 1 || im.question_type == 3) {
        words = '';
        /* im.answer_type+'' == '1' ? "单选题" : im.answer_type+'' == '2' ? "多选题" */
        if (im.answer_type + '' == '1') {
          words += '单选题';
        }
        if (im.answer_type + '' == '2') {
          words += '多选题';
        }
        if (im.question_type + '' == '1' && (im.answer_type + '' == '2' || im.answer_type + '' == '1')) {
          words += `，${im.score}分`;
        }
      } else {
        words = '问答题';
      }
      words += !im.is_required || im.is_required == 1 ? '，必填' : '，非必填';
      return words;
    },
    async submit() {
      var _this$formInfo;
      if (this.isSubmiting) return;
      const {
        contentDetail
      } = this.$parent;
      console.log(this.questionnaireCheck, '==>questionnaireCheck>questionnaireCheck');
      for (let key in this.questionnaireCheck) {
        if (!this.questionnaireCheck[key]) {
          this.errorTipWords = `请完成问题 "${key.split('-AaBbCcDdEe=')[0]}"`;
          this.$refs[`customPop-error`].open();
          setTimeout(() => {
            if (this.isActiveFlag) {
              this.$refs[`customPop-error`].close();
            }
          }, 2000);
          return;
        }
      }
      this.isSubmiting = true;
      let params = {
        answer: (_this$formInfo = this.formInfo) === null || _this$formInfo === void 0 ? void 0 : _this$formInfo.contents,
        content_id: contentDetail.id,
        type: contentDetail.type
      };
      params.answer.forEach((im, ix) => {
        if (im.answer_check instanceof Array && !im.answer_check.length) {
          im['is_answer'] = 1;
          return;
        }
        if (!im.answer_check) {
          im['is_answer'] = 1;
          return;
        }
        im['is_answer'] = 2;
      });
      let res = await this.$api.putFormInfo(params);
      setTimeout(() => {
        this.isSubmiting = false;
        if (this.isActiveFlag) {
          this.$refs[`customPop-error`].close();
        }
      }, 1000);
      if (res !== null && res !== void 0 && res.errcode) {
        // alert("提交失败：" + res.errmsg)
        this.$toatsView.show({
          tipWords: `提交失败，请重试`
        });
        return;
      }
      localStorage.setItem('isSubmitFinish', true);
      this.$parent.getFormEchoInfo('子组件 触发');
    },
    open() {
      this.showPop = true;
    },
    close() {
      this.popFullClose();
    },
    maskHandle() {
      if (this.mask) {
        this.popFullClose();
      }
    },
    popFullClose() {
      this.showPop = false;
      this.$emit('cancle');
    },
    toUC(kx) {
      return this.letterArr[kx].toUpperCase();
    },
    selectAnswerClick(im, km, kx, ix) {
      if (this.mode == 'preview') return;
      if (im.answer_type == 1) {
        im.answer_check = [kx];
      }
      if (im.answer_type == 2) {
        const index = im.answer_check.indexOf(kx);
        if (index == -1) {
          im.answer_check.push(kx);
        } else {
          im.answer_check.splice(index, 1);
        }
      }
      console.log(im.answer_check, '==> im.answer_check');
      this.questionnaireCheck[im.question_title + `-AaBbCcDdEe=${ix}`] = true;
      this.$forceUpdate();
    },
    interactionListAnswerLineCheckCurr(im, km, kx) {
      var _im$answer_check;
      return (im === null || im === void 0 || (_im$answer_check = im.answer_check) === null || _im$answer_check === void 0 ? void 0 : _im$answer_check.indexOf(kx)) > -1 ? 'interactionListAnswerLineCheckCurr' : '';
    },
    selectAnswerKeypress(im, ix) {
      if (!im.is_required || im.is_required == 1) {
        if (im.answer_check) {
          this.questionnaireCheck[im.question_title + `-AaBbCcDdEe=${ix}`] = true;
          return;
        }
        this.questionnaireCheck[im.question_title + `-AaBbCcDdEe=${ix}`] = false;
      }
    }
  }
};