"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("stickyHeader"), _c("div", {
    staticClass: "pageCenter marginAuto"
  }, [_c("div", {
    staticClass: "pageCenterTitle fontWeight"
  }, [_vm._v("内部员工")]), _c("div", {
    staticClass: "pageCenterForm"
  }, [_c("div", {
    staticClass: "pageCenterFormList flex align-center"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.formData.name,
      expression: "formData.name",
      modifiers: {
        trim: true
      }
    }],
    attrs: {
      type: "text",
      placeholder: "* 姓名"
    },
    domProps: {
      value: _vm.formData.name
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "name", $event.target.value.trim());
      },
      blur: function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _c("div", {
    staticClass: "pageCenterFormList flex align-center justify-between"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.formData.email,
      expression: "formData.email",
      modifiers: {
        trim: true
      }
    }],
    attrs: {
      type: "text",
      placeholder: "*请输入邮箱账号"
    },
    domProps: {
      value: _vm.formData.email
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "email", $event.target.value.trim());
      },
      blur: function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _c("span", {
    staticClass: "pageCenterFormListBefore",
    staticStyle: {
      color: "#06070b"
    }
  }, [_vm._v("@bayer.com")])]), _c("div", {
    staticClass: "pageCenterFormList flex align-center justify-between"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.formData.code,
      expression: "formData.code",
      modifiers: {
        trim: true
      }
    }],
    attrs: {
      type: "text",
      placeholder: "*请输入邮箱验证码"
    },
    domProps: {
      value: _vm.formData.code
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "code", $event.target.value.trim());
      },
      blur: function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _c("div", {
    staticClass: "pageCenterFormListGetCode",
    on: {
      click: _vm.sendEmailCode
    }
  }, [_vm._v(_vm._s(_vm.getEmailCode.words))])])]), _c("div", {
    staticClass: "pageCenterAgree marginAuto flex align-center justify-between"
  }, [_c("div", {
    staticClass: "pageCenterAgreeLeft flex align-center",
    on: {
      click: function ($event) {
        _vm.formData.privacy = !_vm.formData.privacy;
      }
    }
  }, [_c("div", {
    staticClass: "pageCenterAgreeCheckAgree"
  }, [_vm.formData.privacy ? _c("img", {
    attrs: {
      src: require("img/home/checkFilter.png"),
      alt: ""
    }
  }) : _vm._e()]), _c("div", {
    staticStyle: {
      "line-height": "1.3",
      flex: "1"
    }
  }, [_vm._v(" 如果您希望得到该平台学术服务，请浏览并同意签署 "), _c("span", {
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$privacyInstanceView.show();
      }
    }
  }, [_vm._v("《个人信息披露知情同意》 ")])])])]), _c("div", {
    staticClass: "registerBtn handleBtn flex align-center justify-center",
    on: {
      click: _vm.registerSubmit
    }
  }, [_vm._v("注册")]), _c("div", {
    staticClass: "cancleBtn handleBtn flex align-center justify-center",
    on: {
      click: function ($event) {
        return _vm.$GO(-1);
      }
    }
  }, [_vm._v("取消")])]), _c("customPop", {
    ref: "customPop-error",
    attrs: {
      tipWords: _vm.errorTipWords
    },
    on: {
      cancle: _vm.customPopCancle,
      confirm: _vm.customPopConfirm
    }
  }), _c("customPop", {
    ref: "customPop-cus",
    attrs: {
      showType: "slot"
    }
  }, [_c("div", {
    staticClass: "registerSuccess"
  }, [_c("div", {
    staticClass: "registerSuccessTip"
  }, [_vm._v("注册成功！"), _c("br"), _vm._v(" 可前往完善信息")]), _c("div", {
    staticClass: "registerSuccessHandle flex align-center justify-between"
  }, [_c("div", {
    staticClass: "registerSuccessHandleNow flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: _vm.registerSuccessHandleNow
    }
  }, [_vm._v("立即完善")]), _c("div", {
    staticClass: "registerSuccessHandleDelay flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: _vm.registerSuccessHandleDelay
    }
  }, [_vm._v("稍后")])])])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;