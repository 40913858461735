"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/popup/style");
var _popup = _interopRequireDefault(require("vant/es/popup"));
var _index = require("@/utils/index.js");
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _list = _interopRequireDefault(require("@/components/course/list.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    vanPopup: _popup.default,
    listItem: _list.default
  },
  data() {
    return {
      showPowerBoxTip: false,
      current: 1,
      pageSize: 10,
      courseList: [],
      searchWords: '',
      errorTipWords: "请输入名称搜索",
      urlQuery: {},
      loadFinal: false,
      noMoreData: false
    };
  },
  watch: {},
  filter: {},
  created() {
    var _this$urlQuery;
    this.urlQuery = this.$route.query;
    this.searchWords = (_this$urlQuery = this.urlQuery) === null || _this$urlQuery === void 0 ? void 0 : _this$urlQuery.searchWords;
    this.initData();
  },
  mounted() {},
  computed: {},
  methods: {
    toSearch() {
      if (!this.searchWords) {
        this.$refs["customPop-error"].open();
        return;
      }

      /* for (const key in this.filterMap) {
        this.filterMap[key].value = null
      }
      this.clearList(true) */

      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          searchWords: this.searchWords
        }
      });
      this.courseList = [];
      this.initData();
    },
    async initData() {
      this.loadFinal = false;
      try {
        let params = {
          // current: this.current,
          // pageSize: this.pageSize,

          name: this.searchWords
        };
        let res = await this.$api.homeSearch(params);
        this.loadFinal = true;
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("homeSearch请求失败：" + res.errmsg)
          return;
        }
        this.courseList = res;
        this.noMoreData = true;
      } catch (error) {
        console.log(error, '==>> error');
      }
    }
  }
});