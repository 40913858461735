"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
require("vant/es/image-preview/style");
var _imagePreview = _interopRequireDefault(require("vant/es/image-preview"));
require("core-js/modules/es.array.push.js");
require("core-js/stable");
require("regenerator-runtime/runtime");
var _vue = _interopRequireDefault(require("vue"));
var _App = _interopRequireDefault(require("./App.vue"));
var _router = _interopRequireDefault(require("./router"));
var _store = _interopRequireDefault(require("./store"));
require("@/utils/monitor.js");
var _wechat = require("@/utils/wechat.js");
var _toRegister = _interopRequireDefault(require("@/utils/toRegister.js"));
var _vueQriously = _interopRequireDefault(require("vue-qriously"));
require("@/plugins/vant");
require("lib-flexible/flexible");
require("./filters");
require("@/utils/route.js");
require("@/utils/version.js");
require("swiper/css/swiper.min.css");
require("@/utils/banSetSize.js");
var _tabbar = _interopRequireDefault(require("@/components/common/tabbar.vue"));
var _index = _interopRequireDefault(require("@/components/customPopview/index.vue"));
var _Picture = _interopRequireDefault(require("@/components/common/Picture.vue"));
var _defaultSetting = _interopRequireDefault(require("./defaultSetting"));
var _http = _interopRequireDefault(require("utils/http"));
// 兼容 IE
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#babelpolyfill

/* 监测 用于统计 */

_vue.default.prototype.$chooseImage = _wechat.chooseImage;

/* 挂入全局登录弹层 */

_vue.default.use(_toRegister.default);

/* 生成二维码 */

_vue.default.use(_vueQriously.default);

// vant 按需引入
// 移动端适配
// 全局过滤器

/* 对比版本 清除缓存 */

/* swiper样式 */

/* 禁止手动缩放字体 */

/* 列表内容类型映射 */
_vue.default.prototype.$MAPTYPE = val => {
  return _store.default.getters.contentType[val] || "MAPTYPE未定义：" + val;
};
_vue.default.prototype.$NUMF = val => {
  if (typeof val !== 'number') {
    return val;
  }
  if (val >= 10000) {
    return (val / 10000).toFixed(1) + 'w';
  }
  return val.toString();
};
_vue.default.prototype.$DEFAULTAVATAR = require("@/assets/img/personal/avatarUserNull.png");
_vue.default.prototype.$STAFFVEEVAICON = require("@/assets/img/personal/veevapass/noIcon.png");

/* 转换时间戳 */
_vue.default.prototype.$GETNOWMILLISECOND = value => {
  return new Date(value.replace(/\-/g, "/")).getTime();
};
function test(html) {
  const wechatInfo = JSON.parse(localStorage.getItem("wechatInfo") || "{}");
  var re = /<a[^>]*href=['"]([^"]*)['"][^>]*>(.*?)<\/a>/g;
  var str = html;
  var arr = [];
  var arr1 = [];
  while (re.exec(str) != null) {
    arr.push({
      href: RegExp.$1,
      //链接
      msg: RegExp.$2 //内容
    }); //如果是RegExp.$1那么匹配的就是href里的属性了!
  }
  let newhtml = html;
  for (var i = 0; i < arr.length; i++) {
    let hrefhtml = arr[i]['href'];
    // let gohref = "/bayer-rad-academy-shadowcloud-academy-html/personal/intercept?link=" + encodeURIComponent(hrefhtml) + "&title=" + arr[i]['msg']
    let gohref;
    if (hrefhtml.indexOf("radbayer.wjx.cn") != -1) {
      gohref = hrefhtml.indexOf("?") != -1 ? `${hrefhtml}&sojumpparm=${wechatInfo === null || wechatInfo === void 0 ? void 0 : wechatInfo.unionid}` : `${hrefhtml}?sojumpparm=${wechatInfo === null || wechatInfo === void 0 ? void 0 : wechatInfo.unionid}`;
    } else {
      gohref = hrefhtml;
    }
    newhtml = newhtml.replace(hrefhtml, gohref);
  }
  console.log(arr, '==>arr---');
  console.log(newhtml);
  return newhtml;
}

/* ios视频播放不脱离文本流 */
_vue.default.prototype.$VIDEOPLAYSINLINE = value => {
  let handleStr = value ? value.replace(/<video/g, `<video webkit-playsinline="true" playsinline="true"`) : value;
  // handleStr = handleStr ? handleStr.replace(/<a.*href="/g, `<a href="/bayer-rad-academy-shadowcloud-academy-html/personal/intercept?link=`) : handleStr

  handleStr = test(handleStr);
  return handleStr;
};

/* 放大图片预览 */

_vue.default.prototype.$imageEnlargement = evt => {
  const nodeName = evt.target.nodeName;
  if (nodeName == 'IMG') {
    console.log(evt.target.currentSrc, '==> .target.currentSrc');
    (0, _imagePreview.default)({
      images: [evt.target.currentSrc],
      closeable: true
    });
  }
};
_vue.default.prototype.$SHOWIMGPREVIEW = url => {
  (0, _imagePreview.default)({
    images: [url],
    closeable: true
  });
};

/* 时间截取到日期 */
_vue.default.prototype.$SPLITDATE = (val, type) => {
  const tempTimeArr = val === null || val === void 0 ? void 0 : val.split(" ");
  if (type == "yyyy-mm-dd h:m") {
    const tempSecArr = tempTimeArr[1].split(":");
    const tempSecStr = tempSecArr[0] + ":" + tempSecArr[1];
    return (tempTimeArr === null || tempTimeArr === void 0 ? void 0 : tempTimeArr[0]) + " " + tempSecStr;
  }
  return (tempTimeArr === null || tempTimeArr === void 0 ? void 0 : tempTimeArr[0]) || val;
};

/* 列表类型映射 */
_vue.default.prototype.$MAPLISTTYPE = val => {
  return _store.default.getters.listType[val] || "MAPLISTTYPE未定义：" + val;
};
_vue.default.component("tabbar", _tabbar.default);
_vue.default.component("customPop", _index.default);
_vue.default.component("Picture", _Picture.default);

/* 后台使用quill--富文本编辑器的css样式 */
// import "quill/dist/quill.core.css"

/* 默认配置 */

_vue.default.use(_defaultSetting.default);

/**
 * 以上为默认引入
 * 以下为自定义内容
 * */
// import some from 'some'
// Vue.use(some)

_vue.default.prototype.$hasRegister = callback => {
  if (_store.default.getters.hasRegister) {
    callback();
    return;
  }
  // 请求接口
  (0, _http.default)({
    url: `/api/wap/empty-user`,
    method: 'get',
    params: {},
    origin: 1
  }).then(res => {
    if (res.code === 1000 || res.code === '1000') {
      _store.default.dispatch('setRegister', true);
      callback();
      return;
    }
    _router.default.push('/register');
  });
};
_vue.default.prototype.$getUser = callback => {};
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: h => h(_App.default)
});