"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container-register"
  }, [_c("transition", {
    attrs: {
      name: "popFull",
      appear: ""
    }
  }, [_vm.showPop ? _c("div", {
    staticClass: "popFull",
    on: {
      click: _vm.maskHandle
    }
  }, [_c("div", {
    staticClass: "popFullInner",
    on: {
      click: function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c("img", {
    staticClass: "popClose",
    attrs: {
      src: require("img/common/popClose.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.popFullClose.apply(null, arguments);
      }
    }
  }), _c("div", {
    staticClass: "popFullInnerContent"
  }, [_c("div", {
    staticClass: "popFullInnerContentInner"
  }, [_c("h4", {
    staticClass: "fontWeight"
  }, [_vm._v("个人信息披露知情同意")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 拜耳医药保健有限公司（“拜耳”）深知个人信息对您而言十分重要，并将尽最大努力保护您的个人信息。我们致力于维护您对我们的信任，我们将严格遵守适用的中国法律法规，依据合法、正当和必要的原则，明确告知您我们收集和使用您的个人信息的规则、目的、方法和范围，并获取您相应的同意。请仔细阅读本知情同意书，并根据您的需要询问任意事项。 ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 本文件旨在向您告知有关拜耳医药保健有限公司（“拜耳”）在收集、使用和保护个人信息方面的政策，并且，一旦签 署，您将允许拜耳使用您的个 人信息。请仔细阅读本知情同意，并根据您的需要询问任何事顶。 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v(" 收集个人信息：")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 您已被告知，并且您已同意拜耳将收集和使用您所提供的如下个人信息，包括：（1）您的姓名，（2）您工作所在的医疗机构，（3）您工作所在的科室，（4）您的职称。拜耳的员工将拜访您，并收集前述个人信息。（5）您的联系方式（电子邮箱、联系电话，微信号和头像），（6）拜访电话的录音（保存2年）。我们不会主动收集您的联系方式，只有当您希望拜耳主动联系您时，您可以留下您的联系方式。 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v("使用个人信息：")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 基于您愿意与拜耳开展合作，以接受拜耳提供的科学信息和产品信息，并参加相关意见调查，拜耳将为以下目的使用您的个人信息：（1）拜耳与您之间的学术交流；（2）邀请您参加学术会议；（3）邀请您参加意见调查；（4）发送文件或材料。（5）提高我们的服务质量。 ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 我们将严格遵守中国关于个人信息保护的相关法律法规（例如：《网络安全法》，《个人信息保护法》等），仅为限定的目的在必要的情况下收集、使用您的个人信息： ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" • 已经收集到的个人信息，拜耳将妥善保存并承诺仅用 于您同意参与的活动或目的； ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" • 对于后续在您参与活动中不时提供的个人信息，当您 同意参与本知情同意书所规定的活动或使用目的时， 拜耳将收集您的相关个人信息。在签署本知情同意书 后，为了避免您重复签署，每次您向拜耳提供您的个 人信息时，拜耳将视为您同意本知情同意书的内容， 从而严格按照本知情同意书的规定收集使用您所提供 的个人信息。 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v(" 与拜耳集团其他公司分享/传输数据")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v(" （个人信息跨境传输和存储）")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 由于我们是一家总部位于德国的跨国企业，为在全球范围内集中管理我们的合作伙伴和项目参与方，您理解并同意，我们可能会与拜耳集团其他公司分享您的个人信息。在适用法律要求的情况下，您的个人数据可能被您所在国家/地区之外的国家/地区进行处理、访问或存储。我们会依据法律法规和监管部门的规定，通过协议或产品页面向您告知境外接收方的姓名或名称、联系方式、处理目的、处理方式、涉及的个人信息种类以及您向境外接收方行使合法权利的方式和程序，并取得您的单独同意，法律规定不需要取得个人同意的除外。我们会采取签订协议、安全审计等必要措施，要求境外机构为所获得的您的个人信息保密。 ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 拜耳集团公司是一家根据德国法律成立的公司。一般而言，个人信息境外信息接收方为拜耳股份有限公司 Bayer AG，公司联系地址：Kaiser-Wilhelm-Allee 1 51373 Leverkusen勒沃库森, Germany德国；联系电话：+49 (0)214 30-1，有关拜耳集团其他公司的信息，您可通过我们拜耳集团的官方网站查看https://www.bayer.com/我们仅在必要时才分享信息，且仅限于本声明所示的目的。如果任何拜耳集团公司想改变所示目的，都需要另行获得您的同意。 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v(" 向第三方披露个人信息：")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 为了本声明中所述的数据处理之目的，我们可能会使用专业服务承包商并与之分享我们收集的数据。该等服务承包商由我们精心挑选定期受我们监督。我们郑重承诺，我们会遵守法律法规关于委托处理的相关规定，并在共享您的信息之前，我们将按照法律法规的规定进行评估，通过与服务承包商签订法律文件、采取必要的安全措施等方式来保障您的个人信息安全。根据相关委托协议，服务承包商需合法合规地处理您的个人信息，且仅可依据我们的指令并严格按照我们的指示处理个人信息，禁止其将这些信息用于未经您授权的用途，在处理目的完成或委托关系结束后等不再保存您的相关信息。 ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 我们仅在必要时才分享信息且分享限于本声明所示的目的。如果改变了处理目的，则需要另行获得您的同意。 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v(" 个人信息保存： ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 我们仅在为实现上述目的的必要期限或法律、行政法规规定的存储期限（以较长者为准）内保存您的个人信息，到期后我们将会删除您的个人信息。 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v(" 个人信息保护： ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v("拜耳已制定了相关政策并釆取了相关安全措施，以保护您的个人信息免受未经授权的访问、篡改、泄露、毀损或丢失。 拜耳将与接收您个人信息的任何授权第三方订立书面合同，确保该第三方承担有关釆取安全措施保护您个人信息的合同义务。您有权在 任何时间通过拨打拜耳热线电话4008100360，撤销您所作出的有关拜耳使用您个人信息的同意，或要求拜耳停止向您发送任何信息或材料。 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v(" 您享有的权利 ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" • 对我们存储的您的个人信息享有的知情权； ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" • 要求查询、复制您个人信息的权利； ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" • 要求更正或限制处理您个人信息的权利； ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" • 对于基于您的同意进行个人信息处理的事项，您有权 通过以下隐私邮箱（DP.China@bayer.com）联系我 们，撤销您所作出的有关拜耳收集和使用您个人信息 的同意，或要求拜耳停止向您发送任何信息或材料。 但该等撤回不影响撤回前基于您同意已进行的个人信 息处理活动的效力。 ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" • 根据法律法规要求，在以下情况，我们将无法响应 您。的请求： ")]), _c("div", {
    staticClass: "content",
    staticStyle: {
      "text-indent": "2em"
    }
  }, [_vm._v(" o 事关国家安全 ")]), _c("div", {
    staticClass: "content",
    staticStyle: {
      "text-indent": "2em"
    }
  }, [_vm._v(" o 事关公共安全、公共健康、重大公共利益 ")]), _c("div", {
    staticClass: "content",
    staticStyle: {
      "text-indent": "2em"
    }
  }, [_vm._v(" o 事关犯罪调查、控诉和审判 ")]), _c("div", {
    staticClass: "content",
    staticStyle: {
      "text-indent": "2em"
    }
  }, [_vm._v(" o 能够证明您滥用您的权利 ")]), _c("div", {
    staticClass: "content",
    staticStyle: {
      "text-indent": "2em"
    }
  }, [_vm._v(" o 响应您的请求会严重损害您或其他个人或组织的 合法权利 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v(" 联系方式： ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 如果您在将来任何时间针对您的个人信息有任何问题或投诉，也请拨打拜耳热线电话4008100360，或者发送邮件到DP.China@bayer.com。我们将在15个工作日内予以相应。 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v(" 医疗卫生专业人士同意声明 ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 本人已阅读本知情同意中的信息。本人己获得机会询问相关问题。本人同意提供本人的个人信息，以供拜耳根据本知情同意使用，并且同意拜耳可以根据本知情同意向经授权的第三方披露本人的个人信息，本人声明和保证，本人在向拜耳提供个人信息前，已获得本人所在单位的相关批准或许可 (如需要），并且本人提供个人信息的行为不会违反适用的法律法规以及本人所在单位的规章制度。 ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 本文件旨在向您告知有关拜耳医药保健有限公司（“拜耳”）在收集、使用和保护个人信息方面的政策，并且，一旦签 署，您将允许拜耳使用您的个 人信息。请仔细阅读本知情同意，并根据您的需要询问任何事顶。 ")])])])])]) : _vm._e()])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;