"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "warps"
  }, [_c("div", {
    staticClass: "page-container page-container-view"
  }, [_c("stickyHeader"), _vm.hasSHow ? _c("div", {
    staticClass: "pageHeaderSearch flex align-center"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.searchWords,
      expression: "searchWords",
      modifiers: {
        trim: true
      }
    }],
    attrs: {
      type: "text",
      placeholder: "请输入名称",
      "placeholder-style": "color: #57585a;"
    },
    domProps: {
      value: _vm.searchWords
    },
    on: {
      keydown: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.resetData.apply(null, arguments);
      },
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.searchWords = $event.target.value.trim();
      },
      blur: function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _c("img", {
    attrs: {
      src: require("img/home/searchIcon.png"),
      alt: ""
    },
    on: {
      click: _vm.resetData
    }
  })]) : _vm._e(), _c("div", {
    staticClass: "activityList"
  }, [_vm._l(_vm.sectionList, function (im, ix) {
    return _c("div", {
      directives: [{
        name: "monitor",
        rawName: "v-monitor",
        value: im.name,
        expression: "im.name"
      }],
      key: ix,
      staticClass: "activityListItem",
      on: {
        click: function ($event) {
          return _vm.toDetail(im);
        }
      }
    }, [im !== null && im !== void 0 && im.need_veeva ? _c("div", {
      staticClass: "authIcon smallAuthIcon samllAuthPos"
    }, [_c("img", {
      attrs: {
        alt: "",
        srcset: "",
        src: require("img/common/authIcon.png")
      }
    })]) : _vm._e(), _c("div", {
      staticClass: "activityListItemTop flex"
    }, [_c("div", {
      staticClass: "activityListItemTopLeft"
    }, [_c("img", {
      attrs: {
        src: im.cover,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "activityListItemTopRighgt hideWords-More4"
    }, [_vm._v(_vm._s(im.name))]), _c("div", {
      staticClass: "previewBox"
    }, [_c("div", {
      staticClass: "previewContent hideWords-More3"
    }, [_c("img", {
      staticClass: "previeImg",
      attrs: {
        src: require("img/common/browseCountOther.png"),
        alt: "",
        srcset: ""
      }
    }), _c("span", {
      staticClass: "previewNum"
    }, [_vm._v(_vm._s(im.read_num || 0))])])])]), _c("div", {
      staticClass: "activityListItemBottom flex align-center justify-between"
    }, [_c("div", {
      staticClass: "activityListItemBottomLeft"
    }, [_vm._v(_vm._s(_vm.showFormatTime(im)))]), _c("div", {
      staticClass: "activityListItemBottomRight flex align-center justify-center"
    }, [_vm._v("去参加")])])]);
  }), !_vm.sectionList.length && _vm.loadFinal ? _c("div", {
    staticClass: "meetingEmpty flex align-center justify-center"
  }, [_vm._v(" 暂无数据 ")]) : _vm._e(), _vm.sectionList.length && _vm.noThenData ? _c("div", {
    staticClass: "meetingEmpty flex align-center justify-center"
  }, [_vm._v(" 暂无更多数据 ")]) : _vm._e()], 2), _c("ConfirmDialog", {
    ref: "confirmDialog",
    attrs: {
      backgroundStyle: true
    }
  })], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;