"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  props: {
    src: String,
    width: [String, Number],
    height: [String, Number],
    radius: [String, Number],
    showLoading: {
      type: [Boolean, String, Number],
      default: true
    },
    showError: {
      type: [Boolean, String, Number],
      default: true
    }
  },
  data() {
    return {
      img: null,
      loading: true,
      error: false,
      color: '#eeeeee'
    };
  },
  created() {
    if (this.src) {
      const newImg = new Image();
      newImg.src = this.src;
      newImg.onload = () => {
        setTimeout(t => {
          this.img = newImg.src;
          this.loading = false;
        }, 2000);
      };
      newImg.onerror = () => {
        this.error = true;
        this.loading = false;
      };
    }
  },
  mounted() {},
  computed: {
    w() {
      const W = this.width;
      return W ? `${W / 8.28}vw` : '100%';
    },
    h() {
      const H = this.height;
      return H ? `${H / 8.28}vw` : 'auto';
    },
    r() {
      const R = this.radius;
      return R ? `${R / 8.28}vw` : 0;
    },
    wrapHeight() {
      const H = this.height;
      return H ? `${H / 8.28}vw` : `${100 / 8.28}vw`;
    }
  },
  filters: {},
  methods: {}
};