"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default
  },
  data() {
    return {
      posterImg: null
    };
  },
  watch: {},
  filter: {},
  created() {},
  destroyed() {},
  mounted() {
    setTimeout(() => {
      this.cutImage(); //截图
    }, 2000);
  },
  computed: {},
  methods: {
    async cutImage() {
      const detailPosterInner = document.getElementById(`posterId`);
      (0, _html2canvas.default)(detailPosterInner, {
        useCORS: true,
        scale: 3,
        width: detailPosterInner.offsetWidth,
        height: detailPosterInner.offsetHeight
      }).then(canvas => {
        let url = canvas.toDataURL();
        this.posterImg = url;
      });
      //   const detailPosterInner = document.getElementById(`posterId`)
      //  let res = await html2canvas(detailPosterInner, { useCORS: true, scale:3 });
      //   this.posterImg  = res.toDataURL("image/jpg", 1);
    }
  }
});