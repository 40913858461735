"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
const state = {
  courseClass: [],
  courseClassIdArr: []
};
const mutations = {
  SET_TEST(state, value) {
    state.test = value;
  },
  SET_COURSECLASS(state, value) {
    state.courseClass = value;
  },
  SET_COURSECLASSIDARR(state, value) {
    state.courseClassIdArr = value;
  }
};
const actions = {
  setTest({
    commit
  }, value) {
    commit('SET_TEST', value);
  },
  async getCourseClass({
    commit
  }, value) {
    let resDataIdArr = [];
    const resData = [{
      "id": 1,
      "name": "分类1",
      "cover": null,
      "sort": 0,
      "parent_id": 0,
      "member_group_flag": 0,
      "member_group_ids": null,
      "created_at": "2022-12-21 18:02:24",
      "updated_at": "2022-12-21 18:02:24",
      "deleted_at": null,
      "level": 1,
      "children": [{
        "id": 4,
        "name": "子分类1",
        "cover": "https:\/\/gore-minisite.oss-cn-beijing.aliyuncs.com\/minisite\/20221222\/rc-upload-1671703725384-2.png",
        "sort": 0,
        "parent_id": 1,
        "member_group_flag": 0,
        "member_group_ids": null,
        "created_at": "2022-12-22 18:10:42",
        "updated_at": "2022-12-22 18:10:42",
        "deleted_at": null,
        "level": 2
      }]
    }, {
      "id": 2,
      "name": "分类2",
      "cover": null,
      "sort": 0,
      "parent_id": 0,
      "member_group_flag": 1,
      "member_group_ids": [1, 2],
      "created_at": "2022-12-21 18:02:41",
      "updated_at": "2022-12-21 18:02:41",
      "deleted_at": null,
      "level": 1
    }, {
      "id": 3,
      "name": "分类3",
      "cover": null,
      "sort": 0,
      "parent_id": 0,
      "member_group_flag": 2,
      "member_group_ids": [3],
      "created_at": "2022-12-21 18:02:53",
      "updated_at": "2022-12-21 18:02:53",
      "deleted_at": null,
      "level": 1
    }];
    resData.forEach(im => {
      resDataIdArr.push(im.id);
    });
    commit('SET_COURSECLASS', resData);
    commit('SET_COURSECLASSIDARR', resDataIdArr);
  }
};
const namespaced = true;
var _default = exports.default = {
  state,
  mutations,
  actions,
  namespaced
};