"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$formInfo;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container-interaction"
  }, [_vm._l((_vm$formInfo = _vm.formInfo) === null || _vm$formInfo === void 0 ? void 0 : _vm$formInfo.contents, function (im, ix) {
    var _im$question_cover;
    return _c("div", {
      key: ix,
      staticClass: "interactionList"
    }, [_c("div", {
      staticClass: "interactionListQues"
    }, [!im.is_required || im.is_required == 1 ? _c("span", {
      staticStyle: {
        color: "#f00"
      }
    }, [_vm._v("*")]) : _vm._e(), _vm._v(" " + _vm._s(ix + 1) + ". " + _vm._s(im.question_title) + " ")]), _c("div", {
      staticClass: "interactionListQuesTip"
    }, [_vm._v("（" + _vm._s(_vm.showTip(im)) + "）")]), im !== null && im !== void 0 && (_im$question_cover = im.question_cover) !== null && _im$question_cover !== void 0 && _im$question_cover.length ? _c("div", {
      staticClass: "interactionListQuesCover"
    }, [_c("div", {
      staticClass: "interactionListQuesCoverPos flex align-center"
    }, _vm._l(im.question_cover, function (km, kx) {
      return _c("div", {
        key: kx,
        staticClass: "interactionListQuesCoverItem flex flex-direction align-center",
        on: {
          click: function ($event) {
            return _vm.$SHOWIMGPREVIEW(km);
          }
        }
      }, [_c("img", {
        staticClass: "interactionListQuesCoverItemImg",
        attrs: {
          src: km,
          alt: ""
        }
      }), _vm._v(" 图" + _vm._s(kx + 1) + " ")]);
    }), 0)]) : _vm._e(), im.question_type == 1 || im.question_type == 3 ? _c("div", {
      staticClass: "interactionListAnswer"
    }, _vm._l(im.answer, function (km, kx) {
      var _km$cover;
      return _c("div", {
        key: kx,
        staticClass: "interactionListAnswerLine",
        on: {
          click: function ($event) {
            return _vm.selectAnswerClick(im, km, kx, ix);
          }
        }
      }, [_c("div", {
        staticClass: "flex align-center justify-between"
      }, [_c("div", {
        staticClass: "interactionListAnswerLineLeft"
      }, [_vm._v(_vm._s(_vm.toUC(kx)) + ". " + _vm._s(km.options))]), _c("div", {
        staticClass: "interactionListAnswerLineCheck",
        class: _vm.interactionListAnswerLineCheckCurr(im, km, kx)
      })]), km !== null && km !== void 0 && (_km$cover = km.cover) !== null && _km$cover !== void 0 && _km$cover.length ? _c("div", {
        staticClass: "interactionListQuesCover",
        on: {
          click: function ($event) {
            $event.stopPropagation();
          }
        }
      }, [_c("div", {
        staticClass: "interactionListQuesCoverPos flex align-center"
      }, _vm._l(km.cover, function (jm, jx) {
        return _c("div", {
          key: jx,
          staticClass: "interactionListQuesCoverItem flex flex-direction align-center",
          on: {
            click: function ($event) {
              return _vm.$SHOWIMGPREVIEW(jm);
            }
          }
        }, [_c("img", {
          staticClass: "interactionListQuesCoverItemImg",
          attrs: {
            src: jm,
            alt: ""
          }
        }), _vm._v(" 图" + _vm._s(jx + 1) + " ")]);
      }), 0)]) : _vm._e()]);
    }), 0) : _c("div", {
      staticClass: "interactionListAnswerText"
    }, [_c("textarea", {
      directives: [{
        name: "model",
        rawName: "v-model.trim",
        value: im.answer_check,
        expression: "im.answer_check",
        modifiers: {
          trim: true
        }
      }],
      attrs: {
        placeholder: "请输入您的答案"
      },
      domProps: {
        value: im.answer_check
      },
      on: {
        change: function ($event) {
          return _vm.selectAnswerKeypress(im, ix);
        },
        input: function ($event) {
          if ($event.target.composing) return;
          _vm.$set(im, "answer_check", $event.target.value.trim());
        },
        blur: function ($event) {
          return _vm.$forceUpdate();
        }
      }
    })])]);
  }), _vm.isActiveFlag != true && _vm.mode != "preview" && _vm.is_preview != 1 ? _c("div", {
    staticClass: "interactionSubmit flex align-center justify-center",
    on: {
      click: _vm.submit
    }
  }, [_vm._v(" 提交 ")]) : _vm._e(), _c("customPop", {
    ref: "customPop-error",
    attrs: {
      tipWords: _vm.errorTipWords
    }
  })], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;