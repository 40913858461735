"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.string.replace-all.js");
require("vant/es/image-preview/style");
var _imagePreview = _interopRequireDefault(require("vant/es/image-preview"));
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _meetingPoster = _interopRequireDefault(require("@/components/meeting/meetingPoster.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _customVideo = _interopRequireDefault(require("@/components/common/customVideo.vue"));
var _interaction = _interopRequireDefault(require("@/components/course/interaction.vue"));
var _interactionAnalysis = _interopRequireDefault(require("@/components/course/interactionAnalysis.vue"));
var _comment = _interopRequireDefault(require("@/components/course/comment.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    customVideo: _customVideo.default,
    interaction: _interaction.default,
    comment: _comment.default,
    interactionAnalysis: _interactionAnalysis.default,
    meetingPoster: _meetingPoster.default
  },
  data() {
    return {
      showModuleCheck: 0,
      contentDetail: {},
      detailForm: {},
      tipWords: '',
      isReachBottom: false,
      isZan: false,
      isFaver: false,
      formEchoInfo: {},
      capUrl: '',
      loadZan: false,
      loadFaver: false
    };
  },
  watch: {
    isReachBottom() {
      console.log(this.isReachBottom, '==> isReachBottom');
    }
  },
  destroyed() {
    window.removeEventListener("reachBottom", this.reachBottomHandle);
  },
  filter: {},
  created() {
    this.$store.commit("SET_LOADINGPOP", true);
    if (this.$route.query.typeMap + '' == '2') {
      document.title = "病例详情";
    }
    this.initData();
    this.initReachBottom();
  },
  mounted() {},
  computed: {},
  methods: {
    myShare() {
      const detailPosterInner = document.querySelector(".detailPosterInnerOut");
      (0, _html2canvas.default)(detailPosterInner, {
        useCORS: true
      }).then(canvas => {
        this.capUrl = canvas.toDataURL();
        (0, _imagePreview.default)({
          images: [this.capUrl],
          closeable: true
        });
        setTimeout(() => {
          this.$store.dispatch("setScoreLog", {
            route: this.$route,
            action_type: "SHARE"
          });
        }, 2000);
      });
    },
    initReachBottom() {
      window.addEventListener("reachBottom", this.reachBottomHandle);
    },
    reachBottomHandle(evt) {
      const self = this;
      if (evt.detail.path != "/course/detail") {
        return;
      }
      if (self.isReachBottom) {
        return;
      }
      if (this.showModuleCheck == 1) {
        var _this$$refs;
        self.isReachBottom = true;
        (_this$$refs = this.$refs) === null || _this$$refs === void 0 || (_this$$refs = _this$$refs.comment) === null || _this$$refs === void 0 || _this$$refs.reachBottom();
      }
    },
    /* initReachBottom() {
      const self = this
      window.addEventListener("reachBottom", evt => {
          if (self.isReachBottom) return
        if (self.showModuleCheck == 1) {
          self.isReachBottom = true
          self.$refs?.comment?.reachBottom()
        }
      })
    }, */

    async zanHandle(type) {
      if (this.loadZan) return;
      this.loadZan = true;
      type && this.$store.dispatch("setScoreLog", {
        route: this.$route,
        action_type: "ZAN"
      });
      try {
        let res = await this.$api[type ? 'contentZan' : 'cancleContentZan']({
          content_id: this.contentDetail.id,
          type: this.contentDetail.type
        });
        this.loadZan = false;

        // this.tipWords = type ? '点赞成功' : "取消点赞成功"
        (res === null || res === void 0 ? void 0 : res.errcode) && (this.tipWords = res === null || res === void 0 ? void 0 : res.errmsg);
        (res === null || res === void 0 ? void 0 : res.errcode) && this.$refs.customPop.open();
        if (type) {
          this.contentDetail.zan++;
          this.isZan = true;
        } else {
          this.contentDetail.zan--;
          this.isZan = false;
        }
      } catch (error) {}
    },
    async faverHandle(type) {
      if (this.loadFaver) return;
      this.loadFaver = true;
      type && this.$store.dispatch("setScoreLog", {
        route: this.$route,
        action_type: "FAVER"
      });
      try {
        let res = await this.$api[type ? 'contentFaver' : 'cancleContentFaver']({
          content_id: this.contentDetail.id,
          type: this.contentDetail.type
        });
        this.loadFaver = false;

        // this.tipWords = type ? '收藏成功' : "取消收藏成功"
        (res === null || res === void 0 ? void 0 : res.errcode) && (this.tipWords = res === null || res === void 0 ? void 0 : res.errmsg);
        (res === null || res === void 0 ? void 0 : res.errcode) && this.$refs.customPop.open();
        if (type) {
          this.contentDetail.faver++;
          this.isFaver = true;
        } else {
          this.contentDetail.faver--;
          this.isFaver = false;
        }
      } catch (error) {}
    },
    async initData() {
      try {
        var _res$content;
        let res = await this.$api.getPreviewCourseContent({}, this.$route.query.id);
        if (res.content) res.content = (_res$content = res.content) === null || _res$content === void 0 ? void 0 : _res$content.replaceAll('<img', '<img style="max-width:100%;"');
        !res.have_form && res.have_comments && (this.showModuleCheck = 1);
        this.contentDetail = res;
        res.have_form && (this.detailForm = res.forms);
        this.$store.commit("SET_LOADINGPOP", false);

        // let zanCheck = await this.$api.getZanCheck(res.id, res.type)
        // let faverCheck = await this.$api.getFaverCheck(res.id, res.type)

        // zanCheck.count > 0 && (this.isZan = true)
        // faverCheck.count > 0 && (this.isFaver = true)

        /* 获取表单填写后的信息 */
        // this.getFormEchoInfo()
      } catch (error) {
        console.log(error, '==> error');
      }
    },
    async getFormEchoInfo(fromBase) {
      console.log(fromBase, '==> fromBase');
      let formEchoInfo = await this.$api.getFormEchoInfo({}, this.contentDetail.id, this.contentDetail.type);
      this.$store.commit("SET_LOADINGPOP", false);
      if (formEchoInfo !== null && formEchoInfo !== void 0 && formEchoInfo.errcode) {
        alert("getFormEchoInfo获取失败" + formEchoInfo.errmsg);
        return;
      }
      this.formEchoInfo = formEchoInfo;
      console.log(formEchoInfo, '==> formEchoInfo');
    }
  }
});