"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = window.$initPage({
  components: {},
  data() {
    return {};
  },
  watch: {
    "$store.getters.userInfo": {
      handler(newValue) {
        this.handlerJump(newValue);
      },
      deep: true
    }
  },
  filter: {},
  created() {
    const {
      query
    } = this.$route;
    localStorage.setItem("userFrom", query.source);
    this.$store.dispatch("getUserInfo");
  },
  mounted() {},
  computed: {
    callUs() {
      return this.$store.getters.callUs;
    }
  },
  methods: {
    ewmpopClose() {
      this.$REDIRECT({
        path: '/'
      });
    },
    handlerJump(im) {
      if (im !== null && im !== void 0 && im.id) {
        /* this.$REDIRECT({
            path: "/importregister?handle=backtohome&poptype=qrcode&from=welcome"
        }) */

        this.weChatShare.selfLink = `${window.location.origin}/bayer-rad-academy-shadowcloud-academy-html/welcome?source=SubscribeQRCode`;
        this.initWechat();
        this.$refs["customPop-ewm"].open();
        return;
      }
      this.$REDIRECT({
        path: "/register?handle=backtohome&poptype=qrcode"
      });
    }
  }
});