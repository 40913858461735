"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container-out page-container-view"
  }, [_c("stickyHeader"), _vm.faverList.length ? _c("div", {
    staticClass: "courseList"
  }, _vm._l(_vm.faverList, function (im, ix) {
    return _c("listItem", {
      key: ix,
      attrs: {
        needIntercept: true,
        showBoxTag: true,
        showListType: true,
        moduleType: im.lessons && im.lessons["type"],
        dataBase: im.lessons
      }
    });
  }), 1) : _vm._e(), !_vm.faverList.length && _vm.loadFinal ? _c("div", {
    staticClass: "meetingEmpty flex align-center justify-center"
  }, [_vm._v(" 暂无内容 ")]) : _vm._e(), _vm.faverList.length && _vm.noThenData ? _c("div", {
    staticClass: "meetingEmpty flex align-center justify-center"
  }, [_vm._v(" 暂无更多内容 ")]) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;