"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isCar = isCar;
exports.isChinese = isChinese;
exports.isEmail = isEmail;
exports.isEmpty = isEmpty;
exports.isId = isId;
exports.isNumber = isNumber;
exports.isPassword = isPassword;
exports.isPhone = isPhone;
exports.isQq = isQq;
exports.isUrl = isUrl;
exports.isUser = isUser;
exports.isWechat = isWechat;
exports.pattern = void 0;
/**
 * --- 正则索引 ---
 * user: 用户名：4到16位（字母，数字，下划线，减号）
 * password: 密码强度：最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符
 * phone: 手机号
 * email: 邮箱号
 * id: 身份证
 * url: https ftp file
 * qq: qq号码
 * wechat: 微信号
 * car: 车牌号
 * chinese: 中文
 * number: 数字
 * empty: 空、空格、换行
 */

const pattern = exports.pattern = {
  user: /^[a-zA-Z0-9_-]{4,16}$/,
  password: /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/,
  phone: /^(13[0-9]|14[01456789]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
  email: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
  id: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
  url: /^((https?|ftp|file):\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
  qq: /^[1-9][0-9]{4,10}$/,
  wechat: /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/,
  car: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/,
  chinese: /^[\u4e00-\u9fa5]+$/,
  number: /^.*[^\d].*$/,
  empty: /^\s*$/
};

/**
 * 用户名
 * 用户名正则，4到16位（字母，数字，下划线，减号）
 */
function isUser(value) {
  return pattern.user.test(value);
}

/**
 * 密码强度
 * 密码强度正则，最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符
 */
function isPassword(value) {
  return pattern.password.test(value);
}

/** 手机号码 */
function isPhone(value) {
  return pattern.phone.test(value);
}

/** 邮箱验证 */
function isEmail(value) {
  return pattern.email.test(value);
}

/** 身份证（18位） */
function isId(value) {
  return pattern.id.test(value);
}

/** URL */
function isUrl(value) {
  return pattern.url.test(value);
}

/** QQ号码 */
function isQq(value) {
  return pattern.qq.test(value);
}

/** 微信号 */
function isWechat(value) {
  return pattern.wechat.test(value);
}

/** 车牌号 */
function isCar(value) {
  return pattern.car.test(value);
}

/** 验证中文 */
function isChinese(value) {
  return pattern.chinese.test(value);
}

/** 验证数字 */
function isNumber(value) {
  return pattern.chinese.test(value);
}

/** 空、空格、换行 */
function isEmpty(value) {
  return pattern.empty.test(value);
}