"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container-cerImg"
  }, [_c("div", {
    staticClass: "getCerListItemGeted outScreen",
    class: [`getCerListItemGeted${_vm.dataIx}`]
  }, [_c("div", {
    staticClass: "cerImg"
  }, [_c("img", {
    attrs: {
      crossOrigin: "anonymous",
      src: _vm.renderImgPath(_vm.dataBase.path),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "cerTitle"
  }, [_vm._v(_vm._s(_vm.handleName(_vm.userInfo.name)))]), _vm.isShowDate() ? _c("div", {
    staticClass: "cerTime",
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.dataBase.send_at, "yyyy年MM月")) + " ")]) : _vm._e()]), _c("img", {
    staticClass: "drawDataImg",
    class: [_vm.getCer ? "getCer" : ""],
    attrs: {
      src: _vm.drawData,
      alt: ""
    }
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;