"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$listCampainConten, _vm$listCampainConten2, _vm$sectionDetail, _vm$sectionDetail2, _vm$listCampainConten3, _vm$listCampainConten4, _vm$sectionDetail3, _vm$listCampainConten5, _vm$listCampainConten6, _vm$sectionDetail4, _vm$listCampainConten7, _vm$listCampainConten8, _vm$listCampainConten9, _vm$formEchoInfo, _vm$listCampainConten10, _vm$listCampainConten11, _vm$sectionDetail5, _vm$sectionDetail6;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.showAll ? _c("div", {
    staticClass: "page-container hasMinheight"
  }, [_c("stickyHeader"), _vm.listCampainContent.length ? _c("div", {
    staticClass: "stickyBox"
  }, [_c("div", {
    staticClass: "detailVideo"
  }, [_c("div", {
    staticClass: "handleList flex flex-direction justify-between"
  }, [!_vm.isSetLogFlag ? _c("div", {
    staticClass: "handleListMask"
  }) : _vm._e(), _c("div", {
    staticClass: "handleListItem flex align-center justify-center",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.myShare.apply(null, arguments);
      }
    }
  }, [_c("img", {
    staticClass: "handleListItemShareIcon",
    attrs: {
      src: require("img/common/shareWhite.png"),
      alt: ""
    }
  }), _vm._v(" 分享 ")]), _c("div", {
    staticClass: "handleListItem flex align-center justify-center",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.faverHandle.apply(null, arguments);
      }
    }
  }, [!_vm.myIsFaver ? _c("img", {
    staticClass: "handleListItemStarIcon",
    attrs: {
      src: require("img/common/starWhite.png"),
      alt: ""
    }
  }) : _c("img", {
    staticClass: "handleListItemStarIcon",
    attrs: {
      src: require("img/common/starWhiteFill.png"),
      alt: ""
    }
  }), _vm._v(" " + _vm._s(_vm.sectionDetail.faver || 0) + " ")]), _c("div", {
    staticClass: "handleListItem flex align-center justify-center",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.zanHandle.apply(null, arguments);
      }
    }
  }, [!_vm.myIsZan ? _c("img", {
    staticClass: "handleListItemGiveIcon",
    attrs: {
      src: require("img/common/giveWhite.png"),
      alt: ""
    }
  }) : _c("img", {
    staticClass: "handleListItemGiveIcon",
    attrs: {
      src: require("img/common/giveWhiteFill.png"),
      alt: ""
    }
  }), _vm._v(" " + _vm._s(_vm.sectionDetail.zan || 0) + " ")])]), _vm.listCampainContent.length ? _c("div", {
    staticClass: "detailVideoInner fixedScale"
  }, [!_vm.isSetLogFlag ? _c("div", {
    staticClass: "mask"
  }, [!_vm.isShowTxt ? _c("span", [_vm._v("视频暂无权限观看")]) : _vm._e()]) : _vm._e(), (_vm$listCampainConten = _vm.listCampainContent[_vm.listCampainContentIndex]) !== null && _vm$listCampainConten !== void 0 && _vm$listCampainConten.video_url ? _c("customVideo", {
    ref: "customVideo",
    attrs: {
      listDetail: _vm.listCampainContent[_vm.listCampainContentIndex],
      src: (_vm$listCampainConten2 = _vm.listCampainContent[_vm.listCampainContentIndex]) === null || _vm$listCampainConten2 === void 0 ? void 0 : _vm$listCampainConten2.video_url,
      isPlayNoPaused: true
    }
  }) : _vm._e()], 1) : _vm._e()]), _c("div", {
    staticClass: "detailModuleCheck flex"
  }, [!_vm.isSetLogFlag ? _c("div", {
    staticClass: "tabMask"
  }) : _vm._e(), _c("div", {
    staticClass: "detailModuleCheckItem justify-center align-center flex",
    class: _vm.showModuleCheck == 0 ? "detailModuleCheckItemCheck" : "",
    on: {
      click: function ($event) {
        _vm.showModuleCheck = 0;
      }
    }
  }, [_vm._v(" " + _vm._s(((_vm$sectionDetail = _vm.sectionDetail) === null || _vm$sectionDetail === void 0 || (_vm$sectionDetail = _vm$sectionDetail.menu) === null || _vm$sectionDetail === void 0 || (_vm$sectionDetail = _vm$sectionDetail[0]) === null || _vm$sectionDetail === void 0 ? void 0 : _vm$sectionDetail.directory) || "目录") + " ")]), _c("div", {
    staticClass: "detailModuleCheckItemLine"
  }), _c("div", {
    staticClass: "detailModuleCheckItem justify-center align-center flex",
    class: _vm.showModuleCheck == 1 ? "detailModuleCheckItemCheck" : "",
    on: {
      click: function ($event) {
        _vm.showModuleCheck = 1;
      }
    }
  }, [_vm._v(" " + _vm._s(((_vm$sectionDetail2 = _vm.sectionDetail) === null || _vm$sectionDetail2 === void 0 || (_vm$sectionDetail2 = _vm$sectionDetail2.menu) === null || _vm$sectionDetail2 === void 0 || (_vm$sectionDetail2 = _vm$sectionDetail2[0]) === null || _vm$sectionDetail2 === void 0 ? void 0 : _vm$sectionDetail2.introduction) || "简介") + " ")]), (_vm$listCampainConten3 = _vm.listCampainContent[_vm.listCampainContentIndex]) !== null && _vm$listCampainConten3 !== void 0 && _vm$listCampainConten3.have_form ? _c("div", {
    staticClass: "detailModuleCheckItemLine"
  }) : _vm._e(), (_vm$listCampainConten4 = _vm.listCampainContent[_vm.listCampainContentIndex]) !== null && _vm$listCampainConten4 !== void 0 && _vm$listCampainConten4.have_form ? _c("div", {
    staticClass: "detailModuleCheckItem justify-center align-center flex",
    class: _vm.showModuleCheck == 2 ? "detailModuleCheckItemCheck" : "",
    on: {
      click: function ($event) {
        _vm.showModuleCheck = 2;
        _vm.$refs["interaction"].dataFormat();
      }
    }
  }, [_vm._v(" " + _vm._s(((_vm$sectionDetail3 = _vm.sectionDetail) === null || _vm$sectionDetail3 === void 0 || (_vm$sectionDetail3 = _vm$sectionDetail3.menu) === null || _vm$sectionDetail3 === void 0 || (_vm$sectionDetail3 = _vm$sectionDetail3[0]) === null || _vm$sectionDetail3 === void 0 ? void 0 : _vm$sectionDetail3.interact) || "互动") + " ")]) : _vm._e(), (_vm$listCampainConten5 = _vm.listCampainContent[_vm.listCampainContentIndex]) !== null && _vm$listCampainConten5 !== void 0 && _vm$listCampainConten5.have_comments ? _c("div", {
    staticClass: "detailModuleCheckItemLine"
  }) : _vm._e(), (_vm$listCampainConten6 = _vm.listCampainContent[_vm.listCampainContentIndex]) !== null && _vm$listCampainConten6 !== void 0 && _vm$listCampainConten6.have_comments ? _c("div", {
    staticClass: "detailModuleCheckItem justify-center align-center flex",
    class: _vm.showModuleCheck == 3 ? "detailModuleCheckItemCheck" : "",
    on: {
      click: function ($event) {
        _vm.showModuleCheck = 3;
      }
    }
  }, [_vm._v(" " + _vm._s(((_vm$sectionDetail4 = _vm.sectionDetail) === null || _vm$sectionDetail4 === void 0 || (_vm$sectionDetail4 = _vm$sectionDetail4.menu) === null || _vm$sectionDetail4 === void 0 || (_vm$sectionDetail4 = _vm$sectionDetail4[0]) === null || _vm$sectionDetail4 === void 0 ? void 0 : _vm$sectionDetail4.comment) || "评论") + " ")]) : _vm._e()])]) : _c("div", {
    staticClass: "tipEmpty flex align-center justify-center"
  }, [_vm._v("暂无内容")]), _c("div", {
    staticClass: "stickyBoxSea"
  }), _vm.listCampainContent.length ? _c("div", {
    staticClass: "folderList"
  }, [_vm.showModuleCheck == 0 ? _c("videoList", {
    ref: "videoList",
    attrs: {
      renderModule: "activity",
      playIndex: _vm.listCampainContentIndex,
      dataBase: _vm.listCampainContent,
      getWords: "name"
    },
    on: {
      dataIndex: _vm.changeVideo
    }
  }) : _vm._e()], 1) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showModuleCheck == 1 && _vm.listCampainContent.length,
      expression: "showModuleCheck == 1 && listCampainContent.length"
    }],
    staticClass: "introductionContent ql-editor",
    domProps: {
      innerHTML: _vm._s(_vm.$VIDEOPLAYSINLINE(_vm.showEdtier((_vm$listCampainConten7 = _vm.listCampainContent[_vm.listCampainContentIndex]) === null || _vm$listCampainConten7 === void 0 ? void 0 : _vm$listCampainConten7.content)))
    },
    on: {
      click: _vm.$imageEnlargement
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showModuleCheck == 1 && _vm.listCampainContent.length,
      expression: "showModuleCheck == 1 && listCampainContent.length"
    }],
    staticClass: "detailHeaderTag flex flex-wrap"
  }, [(_vm$listCampainConten8 = _vm.listCampainContent[_vm.listCampainContentIndex]) !== null && _vm$listCampainConten8 !== void 0 && _vm$listCampainConten8.need_veeva ? _c("div", {
    ref: "courseTagInnerItem",
    staticClass: "detailHeaderTagItem cursmallCourseTagItem"
  }, [_vm._v("认证专享")]) : _vm._e(), _vm._l((_vm$listCampainConten9 = _vm.listCampainContent[_vm.listCampainContentIndex]) === null || _vm$listCampainConten9 === void 0 ? void 0 : _vm$listCampainConten9.tag_name_list, function (im, ix) {
    return _c("div", {
      key: ix,
      staticClass: "detailHeaderTagItem"
    }, [_vm._v(" " + _vm._s(im) + " ")]);
  })], 2), _c("interaction", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showModuleCheck == 2 && _vm.listCampainContent[_vm.listCampainContentIndex].have_form && !((_vm$formEchoInfo = _vm.formEchoInfo) !== null && _vm$formEchoInfo !== void 0 && _vm$formEchoInfo.id),
      expression: "showModuleCheck == 2 && listCampainContent[listCampainContentIndex].have_form && !formEchoInfo?.id"
    }],
    ref: "interaction",
    attrs: {
      formInfo: (_vm$listCampainConten10 = _vm.listCampainContent[_vm.listCampainContentIndex]) === null || _vm$listCampainConten10 === void 0 ? void 0 : _vm$listCampainConten10.forms
    }
  }), _vm.showModuleCheck == 2 && _vm.listCampainContent[_vm.listCampainContentIndex].have_form && _vm.formEchoInfo.id ? _c("interactionAnalysis", {
    attrs: {
      dataBase: _vm.formEchoInfo,
      isActivity: true
    }
  }) : _vm._e(), _vm.showModuleCheck == 3 && (_vm$listCampainConten11 = _vm.listCampainContent[_vm.listCampainContentIndex]) !== null && _vm$listCampainConten11 !== void 0 && _vm$listCampainConten11.have_comments ? _c("comment", {
    ref: "mycomment",
    attrs: {
      renderModule: "activity"
    }
  }) : _vm._e(), (_vm$sectionDetail5 = _vm.sectionDetail) !== null && _vm$sectionDetail5 !== void 0 && _vm$sectionDetail5.id ? _c("meetingPoster", {
    attrs: {
      dataBase: _vm.sectionDetail,
      posterType: 7,
      tagList: (_vm$sectionDetail6 = _vm.sectionDetail) === null || _vm$sectionDetail6 === void 0 ? void 0 : _vm$sectionDetail6.tag_name_list
    }
  }) : _vm._e(), _c("ConfirmDialog", {
    ref: "confirmDialog",
    attrs: {
      backgroundStyle: true
    }
  }), _c("customPop", {
    ref: "customPop",
    attrs: {
      tipWords: _vm.tipWords
    },
    on: {
      cancle: function ($event) {
        return _vm.$GO(-1);
      }
    }
  })], 1) : _c("div");
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;