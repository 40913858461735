"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container-picture"
  }, [_c("img", {
    attrs: {
      src: _vm.src,
      alt: ""
    }
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;