"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  props: {
    /* 数据 */
    dataBase: {
      type: Object,
      default: () => {}
    },
    need_veeva: {
      type: Object,
      default: ''
    },
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    },
    /* 
      展示形式
        words： 文字提示
        slot  插槽
    */
    showType: {
      type: String,
      default: 'words'
    },
    /* 提示文字 */
    tipWords: {
      type: String,
      default: '请传入提示文字'
    },
    preview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPop: false,
      loadZan: false,
      loadFaver: false
    };
  },
  watch: {},
  filter: {},
  created() {},
  mounted() {},
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    checkUserInfo() {
      var _this$userInfo, _this$userInfo2;
      const self = this;
      const openid = localStorage.getItem('openid');
      const wechatInfo = localStorage.getItem('wechatInfo');
      const isRegister = localStorage.getItem('isRegister');
      const {
        query,
        fullPath
      } = this.$route;
      console.log(this.$route, '==>this.$route');
      if (!openid || !wechatInfo || !isRegister) {
        this.$registerView.show(ret => {
          if (ret.next == 'from') {
            this.$store.commit('SET_CALLUSSHOW', false);
          } else {
            this.$store.commit('SET_CALLUSSHOW', false);
            this.$PUSH({
              path: `/register`,
              query: {
                redirect: encodeURIComponent(this.$route.fullPath)
              }
            });
          }
        });
        return Promise.resolve('stop');
      }
      if (!((_this$userInfo = this.userInfo) !== null && _this$userInfo !== void 0 && _this$userInfo.reconfirmation)) {
        this.$importRegisterView.show(ret => {
          if (ret.next == 'from') {
            // next(from)
          } else {
            let strUrl = `${ret.next}?redirect=${encodeURIComponent(fullPath)}`;
            const toUrl = query.sharedata ? `${strUrl}&gotohome=true` : strUrl;
            // next(toUrl)
            self.$PUSH({
              path: toUrl
            });
          }
        }, query.sharedata ? true : false, true);
        return Promise.resolve('stop');
      }
      if ((_this$userInfo2 = this.userInfo) !== null && _this$userInfo2 !== void 0 && _this$userInfo2.re_perfect_type) {
        this.$hospitalLoseView.show(ret => {
          if (ret.next == 'from') {
            // next(from)
          } else {
            let strUrl = `${ret.next}?redirect=${encodeURIComponent(fullPath)}`;
            // const toUrl = `${ret.next}?gotohome=true`
            // console.log(toUrl, '==toUrl>toUrl>toUrl');
            self.$PUSH({
              path: strUrl
            });
            // next(toUrl)
          }
        }, query.sharedata ? true : false, true);
        return Promise.resolve('stop');
      }
      return Promise.resolve('next');
    },
    showFormatTime(im) {
      console.log('im----------------', im);
      if (im.id == 116) {
        //临时准备会议id为116显示固定时间，会议分发为了分流处理，后续取消日期可以把if else去掉即可 只返回return start_time + ' - ' + end_time
        return '2024-01-10 09:00 - 12:30';
      }
      const start_time = this.$SPLITDATE(im.start_time, 'yyyy-mm-dd h:m');
      const end_time = this.$SPLITDATE(im.end_time, 'yyyy-mm-dd h:m');
      const start_time_arr = start_time.split(' ');
      const end_time_arr = end_time.split(' ');
      if (start_time_arr[0] == end_time_arr[0]) {
        return start_time + ' - ' + end_time_arr[1];
      }
      return start_time + ' - ' + end_time;
    },
    async zanHandle(type) {
      if (this.preview) return;
      const openid = localStorage.getItem('openid');
      const wechatInfo = localStorage.getItem('wechatInfo');
      const isRegister = localStorage.getItem('isRegister');
      if (!openid || !wechatInfo || !isRegister) {
        this.$registerView.show(ret => {
          if (ret.next == 'from') {
            this.$store.commit('SET_CALLUSSHOW', false);
          } else {
            this.$store.commit('SET_CALLUSSHOW', false);
            this.$PUSH({
              path: `/register`,
              query: {
                redirect: encodeURIComponent(this.$route.fullPath)
              }
            });
          }
        });
        return;
      }
      let checkStatus = await this.checkUserInfo();
      if (checkStatus == 'stop') return;
      if (this.loadZan) return;
      this.loadZan = true;
      type && this.$store.dispatch('setScoreLog', {
        route: this.$route,
        action_type: 'ZAN',
        type: 'click'
      });
      try {
        let res = await this.$api[type ? 'contentZan' : 'cancleContentZan']({
          content_id: this.dataBase.id,
          type: this.dataBase.type
        });
        this.loadZan = false;
        // alert(type ? '点赞成功' : "取消点赞成功")

        if (type) {
          // this.dataBase.zan++
          this.$emit('setIsZan', 'true');
        } else {
          // this.dataBase.zan--
          this.$emit('setIsZan', 'false');
        }
      } catch (error) {}
    },
    async faverHandle(type) {
      if (this.preview) return;
      const openid = localStorage.getItem('openid');
      const wechatInfo = localStorage.getItem('wechatInfo');
      const isRegister = localStorage.getItem('isRegister');
      if (!openid || !wechatInfo || !isRegister) {
        this.$registerView.show(ret => {
          if (ret.next == 'from') {
            this.$store.commit('SET_CALLUSSHOW', false);
          } else {
            this.$store.commit('SET_CALLUSSHOW', false);
            this.$PUSH({
              path: `/register`,
              query: {
                redirect: encodeURIComponent(this.$route.fullPath)
              }
            });
          }
        });
        return;
      }
      let checkStatus = await this.checkUserInfo();
      if (checkStatus == 'stop') return;
      if (this.loadFaver) return;
      this.loadFaver = true;
      type && this.$store.dispatch('setScoreLog', {
        route: this.$route,
        action_type: 'FAVER',
        type: 'click'
      });
      try {
        let res = await this.$api[type ? 'contentFaver' : 'cancleContentFaver']({
          content_id: this.dataBase.id,
          type: this.dataBase.type
        });
        this.loadFaver = false;

        // alert(type ? '收藏成功' : "取消收藏成功")

        if (type) {
          // this.dataBase.faver++
          this.$emit('setIsFaver', 'true');
        } else {
          this.$emit('setIsFaver', 'false');
          // this.dataBase.faver--
        }
      } catch (error) {}
    },
    open() {
      this.showPop = true;
    },
    close() {
      this.popFullClose();
    },
    maskHandle() {
      if (this.mask) {
        this.popFullClose();
      }
    },
    popFullClose() {
      this.showPop = false;
      this.$emit('cancle');
    }
  }
};