"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c("div", {
    staticClass: "fixedBg"
  }), _c("div", {
    staticClass: "container"
  }, [_vm.pageFlag.dataPage ? _c("div", {
    staticClass: "swiper mySwiper2"
  }, [_c("div", {
    staticClass: "swiper-wrapper"
  }, [_c("div", {
    staticClass: "swiper-slide flex-direction"
  }, [_c("div", {
    staticClass: "yearBackPage pageBg"
  }, [_vm.infoData ? _c("div", {
    staticClass: "yearBackSynopsis posCenter",
    domProps: {
      innerHTML: _vm._s(_vm.infoData.is_new_user ? _vm.yearBackTxt.newUser : _vm.yearBackTxt.oldUser)
    }
  }) : _vm._e(), _vm.isShowDownIcon ? _c("div", {
    staticClass: "downIcon"
  }) : _vm._e(), _c("div", {
    staticClass: "bgIcon3"
  })])]), _c("div", {
    staticClass: "swiper-slide flex-direction"
  }, [_vm.infoData ? _c("div", {
    staticClass: "dataPage pageBg"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.infoData.is_new_user,
      expression: "!infoData.is_new_user"
    }],
    staticClass: "oldDataBox"
  }, [_vm._m(0), _c("div", {
    staticClass: "dataPageItem swiper_item2"
  }, [_c("div", {
    staticClass: "o-data-studyTime o-data-studyTime1"
  }, [_c("div", {
    staticClass: "o-timeBox"
  }, [_c("span", [_vm._v("去年你的学习时长达：")]), _c("span", {
    staticClass: "o-time ff"
  }, [_vm._v(_vm._s(_vm.studyTime.formatTime || 0) + " min")])]), _c("div", {
    staticClass: "o-line o-line1"
  })]), _c("div", {
    staticClass: "o-txt1 o-txt"
  }, [_vm._v(_vm._s(_vm.studyTimeTxt))])]), _c("div", {
    staticClass: "dataPageItem swiper_item2"
  }, [_vm.rankTxtObj && _vm.userInfo.type == 1 ? _c("div", {
    staticClass: "o-data-studyTime"
  }, [_c("div", {
    staticClass: "o-timeBox"
  }, [_c("span", [_vm._v("你在学术能量排行榜中：")]), _c("span", {
    staticClass: "o-time ff"
  }, [_vm._v(_vm._s(_vm.rankTxtObj.lv || 0))])]), _c("div", {
    staticClass: "o-line o-line2"
  })]) : _vm._e(), _vm.rankTxtObj && _vm.userInfo.type == 1 ? _c("div", {
    staticClass: "o-txt2 o-txt",
    domProps: {
      innerHTML: _vm._s(_vm.rankTxtObj.surpass + _vm.rankTxtObj.txt)
    }
  }) : _vm._e()]), _c("div", {
    staticClass: "dataPageItem swiper_item2"
  }, [_vm.calssName ? _c("div", {
    staticClass: "o-class-name"
  }, [_c("div", {
    staticClass: "o-class-name-txt",
    style: _vm.dynamicFontSizeStyle
  }, [_vm._v(" " + _vm._s(_vm.calssName) + " ")])]) : _vm._e()]), _c("div", {
    staticClass: "dataPageItem swiper_item2"
  }, [_c("div", {
    staticClass: "o-txt3 o-txt"
  }, [_vm.calssName ? _c("span", [_vm._v(" 是你学习时间最长的内容"), _c("br"), _vm._v(" 持续深入研究，你将发光发热")]) : _c("span", [_vm._v(" 去年，你暂未在「拜耳影像学苑」中学习，"), _c("br"), _vm._v("丰富学术资源就在这里，待你探索。 ")])])]), _c("div", {
    staticClass: "dataPageItem swiper_item2"
  }, [_c("div", {
    staticClass: "o-badgeBox"
  }, [_vm.infoData.medal_num || _vm.infoData.certificate_num ? _c("div", {
    staticClass: "haveBadge"
  }, [_c("div", {
    staticClass: "o-data-studyTime"
  }, [_c("div", {
    staticClass: "o-timeBox"
  }, [_c("span", [_vm._v("你获得了：")]), _c("span", {
    staticClass: "o-time ff"
  }, [_c("span", [_vm._v(_vm._s(_vm.infoData.medal_num) + "个勋章，")]), _c("span", [_vm._v(_vm._s(_vm.infoData.certificate_num) + "个证书")])])]), _c("div", {
    staticClass: "o-line o-line3"
  })])]) : _c("div", {
    staticClass: "notHaveBage"
  }, [_vm._v("你暂未获得学苑成就，新的一年再接再厉。")])])])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.infoData.is_new_user,
      expression: "infoData.is_new_user"
    }],
    staticClass: "newDataBox"
  }, [_c("div", {
    staticClass: "n-data-title swiper_item2"
  }, [_vm._v("欢迎加入到「拜耳影像学苑」")]), _vm._m(1)]), _c("div", {
    staticClass: "dataStudy-start-btn btnAni",
    on: {
      click: _vm.goQusetionPage
    }
  }, [_vm._v(" 蓄满能量的你"), _c("br"), _vm._v(" 在2023乘风破浪 ")]), _c("div", {
    staticClass: "bgIcon1 moveAni"
  }), _vm._m(2)]) : _vm._e()])]), _c("span", {
    staticClass: "swiper-notification",
    attrs: {
      "aria-live": "assertive",
      "aria-atomic": "true"
    }
  })]) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pageFlag.question,
      expression: "pageFlag.question"
    }],
    staticClass: "questionPage pageBg"
  }, [_c("div", {
    staticClass: "q-box"
  }, [_vm._m(3), _vm.curQuestionData ? _c("div", {
    staticClass: "q-answer-content"
  }, [_c("div", {
    staticClass: "q-answer-index-img"
  }, [_c("img", {
    attrs: {
      src: require(`@/assets/img/annual/question/q${_vm.currentQuestion}.png`),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "q-answer-title",
    domProps: {
      innerHTML: _vm._s(_vm.curQuestionData.title)
    }
  }), _c("div", {
    staticClass: "q-answer-list questionAni"
  }, _vm._l(_vm.curQuestionData.options, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "q-answer-item",
      class: {
        active: item.id == _vm.curItemIndex
      },
      on: {
        click: function ($event) {
          return _vm.chooseQuestionItem(item.id);
        }
      }
    }, [_c("span", {
      class: [_vm.currentQuestion == 1 ? "isFirstQusetion" : ""]
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0)]) : _vm._e(), _c("div", {
    staticClass: "question-start-btn",
    on: {
      click: _vm.answerHandle
    }
  }, [_vm.currentQuestion >= 2 ? _c("span", [_vm._v("生成我的年度影像"), _c("br"), _vm._v(" 2024继续医路航行")]) : _c("span", [_vm._v("下一步")])])]), _c("div", {
    staticClass: "leftIcon"
  }), _c("div", {
    staticClass: "rightIcon"
  }), _c("div", {
    staticClass: "road"
  })]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pageFlag.poster,
      expression: "pageFlag.poster"
    }],
    staticClass: "posterPage pageBg",
    class: _vm.textColor
  }, [_c("img", {
    attrs: {
      id: "posterImage",
      src: _vm.posterImg,
      alt: "",
      srcset: ""
    }
  }), _c("div", {
    staticClass: "posterIcon"
  }), _c("div", {
    staticClass: "headBox"
  }, [_vm._m(4), _c("div", {
    staticClass: "userInfo"
  }, [_c("div", {
    staticClass: "userHead"
  }, [_vm.showInfo().avatar ? _c("img", {
    attrs: {
      src: _vm.showInfo().avatar || _vm.$DEFAULTAVATAR,
      alt: "",
      srcset: ""
    }
  }) : _vm._e()]), _vm.infoData ? _c("span", {
    staticClass: "nickName"
  }, [_vm._v("@" + _vm._s(_vm.showInfo().title))]) : _vm._e()])]), _c("div", {
    staticClass: "p-bg"
  }), _c("div", {
    staticClass: "p-content"
  }, [_c("div", {
    staticClass: "p-year"
  }, [_vm._v("2024")]), _c("div", {
    staticClass: "p-dataFlex"
  }, [_c("div", {
    staticClass: "p-title"
  }, [_vm._v("你的「影像」关键词 :")]), _c("img", {
    staticClass: "p-tags",
    attrs: {
      src: require(`@/assets/img/annual/poster/tag${_vm.posterResult}.png`),
      alt: "",
      srcset: ""
    }
  }), _c("div", {
    staticClass: "p-title2"
  }, [_vm._v("你的未来「影像」:")]), _c("div", {
    staticClass: "p-desc"
  }, [_c("img", {
    attrs: {
      src: require(`@/assets/img/annual/poster/desc${_vm.posterResult}.png`),
      alt: "",
      srcset: ""
    }
  })]), _vm.infoData ? _c("div", {
    staticClass: "p-data-box"
  }, [_vm.infoData.is_new_user === 0 && _vm.userInfo.type == 2 && _vm.infoData.learn_times >= 5000 || _vm.infoData.is_new_user === 0 && _vm.userInfo.type == 1 && (_vm.infoData.learn_times >= 5000 || _vm.infoData.rank <= 10000) ? _c("div", {
    staticClass: "p-data-content"
  }, [_vm.infoData.learn_times >= 5000 ? _c("div", {
    staticClass: "p-data-time"
  }, [_c("p", [_vm._v("去年你的学习时长达：")]), _c("p", {
    staticClass: "p-time-num ff"
  }, [_vm._v(_vm._s(_vm.studyTime.formatTime) + " min")])]) : _vm._e(), _vm.infoData.learn_times >= 5000 && _vm.infoData.rank <= 10000 && _vm.userInfo.type == 1 ? _c("div", {
    staticClass: "line"
  }) : _vm._e(), _vm.rankTxtObj && _vm.infoData.rank <= 10000 && _vm.userInfo.type == 1 ? _c("div", {
    staticClass: "p-data-time"
  }, [_c("p", [_vm._v("你在学术能量排行榜中：")]), _c("p", {
    staticClass: "p-time-num ff"
  }, [_vm._v(_vm._s(_vm.rankTxtObj.lv))])]) : _vm._e()]) : _c("div", {
    staticClass: "newUserPosterTxt"
  }, [_vm._v(" 在医海里航行，总会遇到礁石险滩"), _c("br"), _vm._v(" 和拜耳影像学苑一起，坚定学习，持续进阶"), _c("br"), _vm._v(" 终有一天会发现“轻舟已过万重山” ")]), _c("div", {
    staticClass: "shadowBg"
  })]) : _vm._e()])]), _c("div", {
    staticClass: "bottomBox"
  }, [_vm._m(5), _c("div", {
    staticClass: "peopleBg"
  }, [_c("img", {
    attrs: {
      src: require(`@/assets/img/annual/poster/peopleBg${_vm.posterResult}.png`),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "bottom-content"
  }, [_c("div", {
    staticClass: "contentFlexBox"
  }, [_c("div", {
    staticClass: "qrcodeBox"
  }, [_c("img", {
    staticClass: "qrcodeTips",
    attrs: {
      src: require("img/annual/poster/qrcodeTips.png"),
      alt: "",
      srcset: ""
    }
  }), _c("div", {
    staticClass: "qrcode"
  }, [_c("img", {
    attrs: {
      src: _vm.base64QRCode,
      alt: ""
    }
  })])]), _vm._m(6)])])]), _c("div", {
    staticClass: "btnGroup btnGroupPos"
  }, [_c("div", {
    staticClass: "backHome",
    on: {
      click: _vm.goBackTaskPage
    }
  }, [_vm._v("返回首页")]), _c("div", {
    staticClass: "longPressTipsBtn"
  }, [_vm._v("长按保存海报")])])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pageFlag.poster,
      expression: "pageFlag.poster"
    }],
    staticClass: "posterPage2 pageBg",
    class: _vm.textColor,
    attrs: {
      id: "posterId"
    }
  }, [_c("div", {
    staticClass: "outScreen"
  }, [_c("qriously", {
    ref: "QRCode",
    attrs: {
      value: _vm.createValue(),
      foreground: "#000000",
      size: 200,
      backgroundAlpha: 0
    }
  })], 1), _c("div", {
    staticClass: "posterIcon"
  }), _c("div", {
    staticClass: "headBox"
  }, [_vm._m(7), _c("div", {
    staticClass: "userInfo"
  }, [_c("div", {
    staticClass: "userHead"
  }, [_vm.showInfo().avatar ? _c("img", {
    attrs: {
      src: _vm.showInfo().avatar || _vm.$DEFAULTAVATAR,
      alt: "",
      srcset: ""
    }
  }) : _vm._e()]), _vm.infoData ? _c("span", {
    staticClass: "nickName"
  }, [_vm._v("@" + _vm._s(_vm.showInfo().title))]) : _vm._e()])]), _c("div", {
    staticClass: "p-bg"
  }), _c("div", {
    staticClass: "p-content"
  }, [_c("div", {
    staticClass: "p-year"
  }, [_vm._v("2024")]), _c("div", {
    staticClass: "p-dataFlex"
  }, [_c("div", {
    staticClass: "p-title"
  }, [_vm._v("你的「影像」关键词 :")]), _c("img", {
    staticClass: "p-tags",
    attrs: {
      src: require(`@/assets/img/annual/poster/tag${_vm.posterResult}.png`),
      alt: "",
      srcset: ""
    }
  }), _c("div", {
    staticClass: "p-title2"
  }, [_vm._v("你的未来「影像」:")]), _c("div", {
    staticClass: "p-desc"
  }, [_c("img", {
    attrs: {
      src: require(`@/assets/img/annual/poster/desc${_vm.posterResult}.png`),
      alt: "",
      srcset: ""
    }
  })]), _vm.infoData ? _c("div", {
    staticClass: "p-data-box"
  }, [_vm.infoData.is_new_user === 0 && _vm.userInfo.type == 2 && _vm.infoData.learn_times >= 5000 || _vm.infoData.is_new_user === 0 && _vm.userInfo.type == 1 && (_vm.infoData.learn_times >= 5000 || _vm.infoData.rank <= 10000) ? _c("div", {
    staticClass: "p-data-content"
  }, [_vm.infoData.learn_times >= 5000 ? _c("div", {
    staticClass: "p-data-time"
  }, [_c("p", [_vm._v("去年你的学习时长达：")]), _c("p", {
    staticClass: "p-time-num ff"
  }, [_vm._v(_vm._s(_vm.studyTime.formatTime) + " min")])]) : _vm._e(), _vm.infoData.learn_times >= 5000 && _vm.infoData.rank <= 10000 && _vm.userInfo.type == 1 ? _c("div", {
    staticClass: "line"
  }) : _vm._e(), _vm.rankTxtObj && _vm.infoData.rank <= 10000 && _vm.userInfo.type == 1 ? _c("div", {
    staticClass: "p-data-time"
  }, [_c("p", [_vm._v("你在学术能量排行榜中：")]), _c("p", {
    staticClass: "p-time-num ff"
  }, [_vm._v(_vm._s(_vm.rankTxtObj.lv))])]) : _vm._e()]) : _c("div", {
    staticClass: "newUserPosterTxt"
  }, [_vm._v(" 在医海里航行，总会遇到礁石险滩"), _c("br"), _vm._v(" 和拜耳影像学苑一起，坚定学习，持续进阶"), _c("br"), _vm._v(" 终有一天会发现“轻舟已过万重山” ")]), _c("div", {
    staticClass: "shadowBg"
  })]) : _vm._e()])]), _c("div", {
    staticClass: "bottomBox"
  }, [_vm._m(8), _c("div", {
    staticClass: "peopleBg"
  }, [_c("img", {
    attrs: {
      src: require(`@/assets/img/annual/poster/peopleBg${_vm.posterResult}.png`),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "bottom-content"
  }, [_c("div", {
    staticClass: "contentFlexBox"
  }, [_c("div", {
    staticClass: "qrcodeBox"
  }, [_c("img", {
    staticClass: "qrcodeTips",
    attrs: {
      src: require("img/annual/poster/qrcodeTips.png"),
      alt: "",
      srcset: ""
    }
  }), _c("div", {
    staticClass: "qrcode"
  }, [_c("img", {
    attrs: {
      src: _vm.base64QRCode,
      alt: "",
      srcset: ""
    }
  })])])])])])]), _c("div", {
    staticClass: "serialNumber"
  }, [_vm._v("PP-GAD-CN-1373-1，仅供与医疗卫生专业人士学术沟通使用。")])])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "o-data-title swiper_item2"
  }, [_vm._v(" 你知道吗？"), _c("br"), _vm._v(" 「拜耳影像学苑」陪你走过2023 ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "n-data-txt swiper_item2"
  }, [_c("div", {
    staticClass: "n-data-txt-blod"
  }, [_vm._v("HEY~恭喜解锁「拜耳影像学苑」")]), _c("div", {
    staticClass: "n-data-txt-desc"
  }, [_vm._v(" 成为影像大家庭中的一员，和医学同行一起，在学海里积蓄专业能量"), _c("br"), _c("br"), _c("br"), _vm._v("我们与你一路相伴，始终为你应援，也期待你在崭新的2024里，在影像医海里尽兴冲浪 ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "bgIcon2 moveAni"
  }, [_c("div", {
    staticClass: "people2 moveAni"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "o-data-title"
  }, [_vm._v(" 这一年留下的，"), _c("br"), _vm._v(" 还有属于影像人的鲜活记忆 ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "logo"
  }, [_c("img", {
    attrs: {
      src: require("img/annual/home/logo.png"),
      alt: "",
      srcset: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "p-data-icon"
  }, [_c("img", {
    attrs: {
      src: require("img/annual/poster/bgIcon.png"),
      alt: "",
      srcset: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "btnGroup1"
  }, [_c("div", {}), _c("div", {})]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "logo"
  }, [_c("img", {
    attrs: {
      src: require("img/annual/home/logo.png"),
      alt: "",
      srcset: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "p-data-icon"
  }, [_c("img", {
    attrs: {
      src: require("img/annual/poster/bgIcon.png"),
      alt: "",
      srcset: ""
    }
  })]);
}];
render._withStripped = true;