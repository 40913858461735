"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.string.replace-all.js");
require("vant/es/image-preview/style");
var _imagePreview = _interopRequireDefault(require("vant/es/image-preview"));
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _interaction = _interopRequireDefault(require("@/components/course/interaction.vue"));
var _interactionAnalysis = _interopRequireDefault(require("@/components/course/interactionAnalysis.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    interaction: _interaction.default,
    interactionAnalysis: _interactionAnalysis.default
  },
  props: {
    isShowEndQuestion: [Boolean, String],
    parentId: [String, Number],
    sectionDetail: [Object, String]
  },
  data() {
    return {
      is_preview: null,
      urlQuery: null,
      showModuleCheck: 0,
      // sectionDetail: {},
      contentDetail: {},
      detailForm: {},
      tipWords: '',
      isReachBottom: false,
      isZan: false,
      isFaver: false,
      formEchoInfo: {},
      capUrl: '',
      loadZan: false,
      loadFaver: false,
      isVideoType: false,
      setScoreTimer: null,
      onlySessionNum: this.$GetSessionNum(),
      setScoreTime: 0,
      /* 本次学习时长 */
      thisTime: 0,
      thisTimer: null,
      setScoreViewTimer: null,
      setScoreViewTime: 0,
      isShowEndQuestionCopy: this.isShowEndQuestion
    };
  },
  watch: {
    isReachBottom() {},
    sectionDetail(newVal) {
      this.sectionDetail = newVal;
      console.log("sectionDetail------", newVal);
      this.contentDetail = newVal;
      this.initData();
    }
  },
  destroyed() {
    this.pauseSetBrowse();
    this.pauseViewSetBrowse();
    if (this.thisTimer) {
      this.pauseLearnTimeLog();
    }
    window.removeEventListener('reachBottom', this.reachBottomHandle);
  },
  filter: {},
  created() {
    this.urlQuery = this.$route.query;
    this.$store.commit('SET_LOADINGPOP', true);
    this.is_preview = this.urlQuery.is_preview;
    this.initData();
    this.initReachBottom();
  },
  mounted() {
    // console.log("sectionDetail------",this.sectionDetail);
  },
  computed: {},
  methods: {
    /* 本次学习时长 Start */
    getChildRef() {
      return this.$refs.childRef;
    },
    setLearnTimeLog() {
      this.thisTime = 0;
      this.thisTimer = setInterval(() => {
        this.thisTime += 3;
        this.$store.dispatch('setLearnTimeLog', {
          route: this.$route,
          action_type: 'LEARN',
          total_tims: 3,
          content_type: this.sectionDetail.content_type,
          session_num: this.onlySessionNum,
          time_type: 1
        });
      }, 3000);
    },
    pauseLearnTimeLog() {
      clearInterval(this.thisTimer);
      this.thisTimer = null;
      this.thisTime += 3;
      this.$store.dispatch('setLearnTimeLog', {
        route: this.$route,
        action_type: 'LEARN',
        total_tims: 3,
        content_type: this.sectionDetail.content_type,
        session_num: this.onlySessionNum,
        time_type: 1
      });
    },
    /* 本次学习时长 End */

    myShare() {
      const self = this;
      const detailPosterInner = document.querySelector('.detailPosterInnerOut');
      (0, _html2canvas.default)(detailPosterInner, {
        useCORS: true
      }).then(canvas => {
        self.capUrl = canvas.toDataURL();
        (0, _imagePreview.default)({
          images: [self.capUrl],
          closeable: true,
          onClose() {
            self.$store.commit('SET_SHOWSAVEBTN', false);
          }
        });
        self.$store.commit('SET_SHOWSAVEBTN', true);
        setTimeout(() => {
          self.$store.dispatch('setScoreLog', {
            route: self.$route,
            action_type: 'SHARE'
          });
        }, 2000);
      });
    },
    initReachBottom() {
      window.addEventListener('reachBottom', this.reachBottomHandle);
    },
    reachBottomHandle(evt) {
      const self = this;
      if (evt.detail.path != '/course/detail') {
        return;
      }
      if (self.isReachBottom) {
        return;
      }
      if (this.showModuleCheck == 1) {
        var _this$$refs;
        self.isReachBottom = true;
        (_this$$refs = this.$refs) === null || _this$$refs === void 0 || (_this$$refs = _this$$refs.comment) === null || _this$$refs === void 0 || _this$$refs.reachBottom();
      }
    },
    async zanHandle(type) {
      if (this.loadZan) return;
      this.loadZan = true;
      type && this.$store.dispatch('setScoreLog', {
        route: this.$route,
        action_type: 'ZAN'
      });
      try {
        let res = await this.$api[type ? 'contentZan' : 'cancleContentZan']({
          content_id: this.sectionDetail.id,
          type: this.sectionDetail.type
        });
        this.loadZan = false;

        // this.tipWords = type ? '点赞成功' : "取消点赞成功"
        (res === null || res === void 0 ? void 0 : res.errcode) && (this.tipWords = res === null || res === void 0 ? void 0 : res.errmsg);
        (res === null || res === void 0 ? void 0 : res.errcode) && this.$refs.customPop.open();
        if (type) {
          this.sectionDetail.zan++;
          this.isZan = true;
        } else {
          this.sectionDetail.zan--;
          this.isZan = false;
        }
      } catch (error) {}
    },
    async faverHandle(type) {
      if (this.loadFaver) return;
      this.loadFaver = true;
      type && this.$store.dispatch('setScoreLog', {
        route: this.$route,
        action_type: 'FAVER'
      });
      try {
        let res = await this.$api[type ? 'contentFaver' : 'cancleContentFaver']({
          content_id: this.sectionDetail.id,
          type: this.sectionDetail.type
        });
        this.loadFaver = false;

        // this.tipWords = type ? '收藏成功' : "取消收藏成功"
        (res === null || res === void 0 ? void 0 : res.errcode) && (this.tipWords = res === null || res === void 0 ? void 0 : res.errmsg);
        (res === null || res === void 0 ? void 0 : res.errcode) && this.$refs.customPop.open();
        if (type) {
          this.sectionDetail.faver++;
          this.isFaver = true;
        } else {
          this.sectionDetail.faver--;
          this.isFaver = false;
        }
      } catch (error) {}
    },
    async initData() {
      try {
        var _this$sectionDetail, _this$sectionDetail$c;
        // let res = await this.$api.getCampainListAuth({}, this.parentId ? this.parentId : this.$route.query.id)

        /* 修改微信配置 Start */
        this.weChatShare.title = this.sectionDetail.name;
        this.weChatShare.desc = this.sectionDetail.name;
        this.weChatShare.imgUrl = this.sectionDetail.cover;
        this.initWechat();
        /* 修改微信配置 End */

        if (!this.sectionDetail.id) return;
        document.title = (_this$sectionDetail = this.sectionDetail) === null || _this$sectionDetail === void 0 ? void 0 : _this$sectionDetail.name;
        if (this.sectionDetail.content) this.sectionDetail.content = (_this$sectionDetail$c = this.sectionDetail.content) === null || _this$sectionDetail$c === void 0 ? void 0 : _this$sectionDetail$c.replaceAll('<img', '<img style="max-width:100%;"');
        // this.sectionDetail.content = this.sectionDetail.content?.replaceAll('<strong', '<strong style="font-weight: bold;"')

        // this.sectionDetail = this.sectionDetail

        this.setScoreTime = Number(this.sectionDetail.learn_time) || 0;
        this.setScoreViewTime = Number(this.sectionDetail.learn_time) || 0;
        this.sectionDetail.have_form && (this.detailForm = this.sectionDetail.forms);
        !this.sectionDetail.have_form && this.sectionDetail.have_comments && (this.showModuleCheck = 1);
        let zanCheck = await this.$api.getZanCheck(this.sectionDetail.id, this.sectionDetail.type);
        let faverCheck = await this.$api.getFaverCheck(this.sectionDetail.id, this.sectionDetail.type);
        zanCheck.count > 0 && (this.isZan = true);
        faverCheck.count > 0 && (this.isFaver = true);
        this.isVideoType = this.sectionDetail.content_type + '' == '1';
        // if (!this.isVideoType) {

        //   this.setBrowse()
        // }

        this.setBrowseView();

        /* 获取表单填写后的信息 */
        this.getFormEchoInfo();
      } catch (error) {
        console.log(error, '==> error');
      }
    },
    pauseSetBrowse(endTime, finish) {
      clearInterval(this.setScoreTimer);
      this.setScoreTimer = null;
      if (finish && endTime > this.setScoreTime + 3) {
        endTime = this.setScoreTime;
        finish = false;
      }
      this.$store.dispatch('setScoreLog', {
        route: this.$route,
        action_type: 'LEARN',
        total_tims: this.setScoreTime,
        content_type: this.sectionDetail.content_type,
        session_num: this.onlySessionNum,
        finish,
        time_type: 1
      });
    },
    setBrowse() {
      var _this$sectionDetail2;
      if ((_this$sectionDetail2 = this.sectionDetail) !== null && _this$sectionDetail2 !== void 0 && _this$sectionDetail2.id) {
        this.setScoreTimer = setInterval(() => {
          this.setScoreTime += 3;
          this.$store.dispatch('setScoreLog', {
            route: this.$route,
            action_type: 'LEARN',
            total_tims: this.setScoreTime,
            content_type: this.sectionDetail.content_type,
            session_num: this.onlySessionNum,
            time_type: 1
          });
        }, 3000);
      }
    },
    // ---
    pauseViewSetBrowse(endTime, finish) {
      clearInterval(this.setScoreViewTimer);
      this.setScoreViewTimer = null;
      if (finish && endTime > this.setScoreViewTime + 3) {
        endTime = this.setScoreViewTime;
        finish = false;
      }
      this.$store.dispatch('setScoreLog', {
        route: this.$route,
        action_type: 'LEARN',
        total_tims: this.setScoreViewTime,
        content_type: this.sectionDetail.content_type,
        session_num: this.onlySessionNum,
        finish
      });
    },
    setBrowseView() {
      var _this$sectionDetail3;
      if ((_this$sectionDetail3 = this.sectionDetail) !== null && _this$sectionDetail3 !== void 0 && _this$sectionDetail3.id) {
        this.setScoreViewTimer = setInterval(() => {
          this.setScoreViewTime += 3;
          this.$store.dispatch('setScoreLog', {
            route: this.$route,
            action_type: 'LEARN',
            total_tims: this.setScoreViewTime,
            content_type: this.sectionDetail.content_type,
            session_num: this.onlySessionNum
          });
        }, 3000);
      }
    },
    // ---
    goSecondHandle() {
      // if (this.$parent && this.$parent.$parent && typeof this.$parent.$parent.closeAnswerPageHandle === 'function') {
      //   this.$parent.$parent.closeAnswerPageHandle();
      // }
      this.$parent.redirectUrl();
    },
    async getFormEchoInfo(fromBase) {
      let formEchoInfo = await this.$api.getFormEchoInfo({}, this.sectionDetail.id, this.sectionDetail.type);
      this.$store.commit('SET_LOADINGPOP', false);
      if (formEchoInfo !== null && formEchoInfo !== void 0 && formEchoInfo.errcode) {
        alert('getFormEchoInfo获取失败' + formEchoInfo.errmsg);
        return;
      }
      console.log('fffff', JSON.stringify(formEchoInfo));
      if ('[]' != JSON.stringify(formEchoInfo)) {
        this.$parent.upParentShowQuestionHandle();
        await this.$api.putShowIntroduceAuth({}, this.sectionDetail.id, this.sectionDetail.type); //更新弹窗展示状态
        //  setTimeout(()=>{
        //   const element = document.getElementById("activeBtn");
        //       if (element) {
        //         element.scrollIntoView({ behavior: "smooth" });
        //       }
        //  },1000)

        // setTimeout(() => {
        // this.$parent.redirectUrl();
        // this.$PUSH({ path: `/activity/secondList?id=${this.$route.query.id}` })
        // }, 1000)
      }
      this.$emit("isShowParentAddActiveBtn", JSON.stringify(formEchoInfo));
      this.formEchoInfo = formEchoInfo;
      // setTimeout(()=>{
      //   this.$PUSH({ path: `/activity/secondList?id=${this.$route.query.id}` });
      // },1000)
    }
  }
});