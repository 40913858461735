"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "veevaCode"
  }, [_c("div", {
    staticClass: "veevaCodeWords"
  }, [!_vm.isBeforeSendMsg ? _c("span", [_vm._v("您已获得医疗卫生专业人士认证，")]) : _vm._e(), _vm._v("还差一步就可以参加拜耳学术会议了，快来扫码绑定吧！")]), _c("div", {
    staticClass: "veevaCodeQr",
    on: {
      click: _vm.scalePicHandle
    }
  }, [_c("img", {
    attrs: {
      src: _vm.base64QRCode,
      alt: ""
    }
  })]), _c("div", {
    staticClass: "outScreen"
  }, [_c("qriously", {
    ref: "QRCode",
    attrs: {
      value: _vm.dataBase.link,
      foreground: "#000000",
      size: 200,
      backgroundAlpha: 0
    }
  })], 1)]), _c("picLook", {
    ref: "scalePic",
    attrs: {
      url: _vm.base64QRCode
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;