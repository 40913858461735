"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  props: {
    option: Object,
    form: Array,
    buttonText: {
      type: String,
      default: '提交'
    }
  },
  data() {
    return {
      defaultWidth: 620,
      defaultHeight: 346,
      // 错误提示前缀
      prefixTip: '请输入',
      // 类型枚举
      typeEnum: {
        'textarea': 'textarea',
        'email': 'input',
        // 邮箱
        'mailbox': 'input',
        // 企业邮箱
        'phone': 'input',
        // 手机
        'tel': 'input',
        // 座机 + 手机
        'code': 'double',
        // 验证码
        'select': 'select',
        'upload': 'file',
        // 上传类型
        'file': 'file' // 上传类型
      },
      // 字符长度
      lengthEnum: {
        'textarea': 2000,
        'email': 200,
        // 邮箱
        'mailbox': 200,
        // 企业邮箱
        'phone': 11,
        // 手机
        'tel': 15,
        // 座机 + 手机
        'code': 6 // 验证码
      },
      // 验证码文案
      canSend: false,
      // 控制按钮
      showSendButton: false,
      // 控制按钮
      codeText: '获取验证码',
      codeNum: 60,
      codeNumMax: 60,
      timer: null,
      // select 选项
      showOptions: false,
      optionAct: 0,
      // file 配置
      fileMaxLength: 3,
      fileMaxSize: 5000,
      // kb
      // 自主报错
      errorIndex: -1,
      errorMessage: '',
      errorVisiable: false
    };
  },
  created() {},
  mounted() {},
  computed: {
    w() {
      let val = this.defaultWidth;
      if (this.data && this.data.width) val = this.data.width;
      return this.$rem(val);
    },
    h() {
      let val = this.defaultHeight;
      if (this.data && this.data.height) val = this.data.height;
      return this.$rem(val);
    },
    countShowCodeButton() {
      if (this.option.hideShowHide) return this.showSendButton;
      return true;
    }
  },
  filters: {},
  methods: {
    getItem(prop) {
      const index = this.form.findIndex(item => item.prop === prop);
      if (index > -1) return {
        data: this.form[index],
        index
      };
      return false;
    },
    commonType(type) {
      if (!type) return 'input';
      return this.typeEnum[type];
    },
    validate(value, rule, item) {
      if (!item.required) return true;
      if (!value.trim()) return false;

      // 验证手机
      if (item.type === 'phone') return this.checkPhone(value);
      // 验证座机 + 手机
      if (item.type === 'tel') {
        if (this.checkHead(value)) return this.checkPhone(value);
        return this.checkTel(value);
      }

      // 验证邮箱
      if (item.type === 'email') return this.checkEmail(value);
      if (item.type === 'mailbox') {
        if (this.checkEmail(value)) return this.checkMailBox(value);
        return false;
      }

      // 验证选择框
      if (item.type === 'select') return value && value !== '请选择';
    },
    message(value, rule, item) {
      let prefixTip = this.prefixTip;
      const label = item.reLabel || item.label;
      if (item.type === 'select') prefixTip = '请选择';
      if (!value.trim()) return `${prefixTip}${label}`;

      // 手机提示
      if (item.type === 'phone') return this.checkPhone(value) ? true : `${prefixTip}正确的${label}`;
      // 座机 + 手机通用提示
      if (item.type === 'tel') {
        if (this.checkHead(value)) {
          return this.checkPhone(value) ? true : `${prefixTip}正确的${label}`;
        }
        return this.checkTel(value) ? true : `${prefixTip}正确的${label}`;
      }

      // 邮箱提示
      if (item.type === 'email') return this.checkEmail(value) ? true : `${prefixTip}正确的${label}`;
      if (item.type === 'mailbox') {
        if (this.checkEmail(value)) {
          return this.checkMailBox(value) ? true : `暂不支持个人邮箱`;
        }
        return `${prefixTip}正确的${label}`;
      }

      // 选择框提示
      if (item.type === 'select') {
        if (value && value !== '请选择') return true;
        return `${prefixTip}${label}`;
      }
    },
    checkTel(value) {
      if (value.length >= 7 && value.length <= 12) return true;
      return false;
    },
    checkHead(value) {
      const head = String(value).substring(0, 3);
      const patternHead = /^(13[0-9]|14[01456789]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])/;
      return patternHead.test(head);
    },
    checkPhone(value) {
      const patternPhone = /^(13[0-9]|14[01456789]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      return patternPhone.test(value);
    },
    checkEmail(value) {
      const emailPattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      return emailPattern.test(value);
    },
    checkMailBox(value) {
      const pattern = /\w[-\w.+]*@(qq|163|126|188|yeah|sina|gmail|foxmail|outlook|live)\.+[A-Za-z]{2,4}/;
      return !pattern.test(value);
    },
    input(value, item) {
      if (this.errorIndex > -1) {
        this.errorVisiable = false;
        this.errorMessage = '';
        this.errorIndex = -1;
      }
      if (!item) return;
      // 绑定验证码
      const bindItem = this.form.find(item => item.bind);
      if (bindItem && bindItem.bind === item.prop) {
        this.canSend = this.validate(value, '', item);

        // hide show hide
        if (this.option.hideShowHide) this.showSendButton = true;
      }
    },
    blur() {},
    onConfirm({
      text
    }, item) {
      item.value = text;
      this.showOptions = false;
    },
    // 老版 select 改用 vant picker
    /* clickSelect() {
      this.showOptions = !this.showOptions
    },
    selectOption(option, o, item, i) {
      this.optionAct = o
      this.showOptions = false
      this.form[i].value = option.text
      this.$refs.form.validate(item.prop)
    },*/
    checkCodeNeed(current) {
      const index = this.form.findIndex(item => item.type === current.bind);
      if (index > -1) return {
        data: this.form[index],
        index
      };
      return false;
    },
    // 倒计时
    countDown() {
      if (this.timer) return;
      this.codeNum--;
      this.codeText = `${this.codeNum}s重新获取`;
      this.timer = setInterval(t => {
        if (this.codeNum <= 1) {
          this.codeNum = this.codeNumMax;
          this.codeText = '重新送验证码';
          clearInterval(this.timer);
          this.timer = null;
          return;
        }
        this.codeNum--;
        this.codeText = `${this.codeNum}s重新获取`;
      }, 1000);
    },
    sendCode(item) {
      // 遍历表单手机号
      const bindData = this.checkCodeNeed(item);
      if (bindData) {
        this.$refs.form.validate(bindData.data.prop).then(success => {
          // 发送请求
          if (this.timer) {
            this.$emit('send-code', {
              value: bindData.data.value,
              countDown: false
            });
          } else {
            this.$emit('send-code', {
              value: bindData.data.value,
              countDown: this.countDown
            });
          }
        }).catch(failed => {
          console.log('校验失败');
        });
      } else {
        console.log('绑定节点查询失败');
      }
    },
    onFailed() {},
    onSubmit(form) {
      this.$emit('submit', form);
    },
    showError({
      prop,
      message
    }) {
      const data = this.getItem(prop);
      if (data) {
        this.errorVisiable = true;
        this.errorIndex = data.index;
        this.errorMessage = message;
      }
    }
  }
};