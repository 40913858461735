"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$homeRecommend;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container page-container-view"
  }, [_c("van-sticky", {
    attrs: {
      "offset-top": 0
    }
  }, [_c("div", {
    staticClass: "pageHeader flex align-center"
  }, [_c("div", {
    staticClass: "pageHeaderLeft flex align-center justify-center"
  }, [_c("img", {
    staticClass: "logo",
    attrs: {
      src: require("img/logo.png")
    }
  })]), _vm.showSearchArea ? _c("div", {
    staticClass: "pageHeaderSearch flex align-center"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchWords,
      expression: "searchWords"
    }],
    attrs: {
      type: "text",
      placeholder: "请输入相关内容名称",
      "placeholder-style": "color: #57585a;"
    },
    domProps: {
      value: _vm.searchWords
    },
    on: {
      keydown: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.$PUSH({
          path: `/allcontent?searchWords=${_vm.searchWords}`
        });
      },
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.searchWords = $event.target.value;
      }
    }
  }), _c("img", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    attrs: {
      src: require("img/home/searchIcon.png"),
      alt: "",
      "event-key": `搜索`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: `/allcontent?searchWords=${_vm.searchWords}`
        });
      }
    }
  })]) : _vm._e()])]), _c("div", {
    staticClass: "linearLine"
  }), _vm.showBanner ? _c("div", {
    staticClass: "pageSwiper marginAuto"
  }, [_c("van-swipe", {
    staticClass: "my-swipe",
    staticStyle: {
      height: "100%"
    },
    attrs: {
      autoplay: 3000,
      "indicator-color": "white"
    }
  }, _vm._l(_vm.bannerList, function (im, ix) {
    return _c("van-swipe-item", {
      directives: [{
        name: "monitor",
        rawName: "v-monitor"
      }],
      key: ix,
      attrs: {
        "event-key": `首页banner-${im.url}`
      },
      on: {
        click: function ($event) {
          return _vm.toLink(im);
        }
      }
    }, [_c("img", {
      staticStyle: {
        width: "100%",
        height: "100%"
      },
      attrs: {
        src: im.cover,
        alt: ""
      }
    })]);
  }), 1)], 1) : _vm._e(), _vm.showPageEntry ? _c("div", {
    staticClass: "pageEntry flex align-center justify-center"
  }, [_c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "pageEntrys flex align-center flex-direction",
    attrs: {
      "event-key": "全部内容"
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/allcontent"
        });
      }
    }
  }, [_c("img", {
    attrs: {
      src: require("img/home/allContent.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "pageEntrysWord"
  }, [_vm._v("全部内容")])]), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "pageEntrys flex align-center flex-direction",
    attrs: {
      "event-key": "热门活动"
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/activity"
        });
      }
    }
  }, [_c("img", {
    attrs: {
      src: require("img/home/hotActive.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "pageEntrysWord"
  }, [_vm._v("热门活动")]), _c("div", {
    staticClass: "pageEntrysWordTip flex align-center justify-center"
  }, [_vm._v("训练营")])]), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "pageEntrys flex align-center flex-direction",
    attrs: {
      "event-key": "病例专区"
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/casearea"
        });
      }
    }
  }, [_c("img", {
    attrs: {
      src: require("img/home/blzq.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "pageEntrysWord"
  }, [_vm._v("病例专区")])]), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "pageEntrys flex align-center flex-direction",
    attrs: {
      "event-key": "联系我们"
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/contactUs"
        });
      }
    }
  }, [_c("img", {
    attrs: {
      src: require("img/home/callUs.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "pageEntrysWord"
  }, [_vm._v("联系我们")])])]) : _vm._e(), _vm.showhomeRecommend && (_vm$homeRecommend = _vm.homeRecommend) !== null && _vm$homeRecommend !== void 0 && _vm$homeRecommend.id ? _c("div", {
    staticClass: "recommend marginAuto"
  }, [_c("div", {
    staticClass: "recommendTitle"
  }, [_vm._v("今日推荐")]), _c("div", {
    staticClass: "yearEndActive",
    on: {
      click: _vm.goYearBackH5
    }
  }, [!_vm.infoData.is_new_user && _vm.infoData.learn_times > 5000 ? _c("div", {
    staticClass: "yearEndTxtOldUser"
  }) : _c("div", {
    staticClass: "yearEndTxtNewUser"
  })]), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "recommendContent",
    attrs: {
      "event-key": `今日推荐${_vm.homeRecommend.title}`
    },
    on: {
      click: function ($event) {
        return _vm.recommendGo(_vm.homeRecommend);
      }
    }
  }, [_vm.homeRecommend.need_veeva ? _c("div", {
    staticClass: "authIcon smallAuthIcon"
  }, [_c("img", {
    attrs: {
      alt: "",
      srcset: "",
      src: require("img/common/authIcon.png")
    }
  })]) : _vm._e(), _c("img", {
    staticClass: "recommendBG",
    attrs: {
      src: require("img/home/recommendBG.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "recommendContentInner flex align-center"
  }, [_c("div", {
    staticClass: "recommendContentInnerLeft"
  }, [_c("img", {
    attrs: {
      src: _vm.homeRecommend.cover,
      alt: ""
    }
  })]), _c("div", {
    staticClass: "recommendContentInnerRight"
  }, [_c("div", {
    staticClass: "recommendContentInnerRightTitle fontWeight"
  }, [_vm._v(_vm._s(_vm.homeRecommend.title))]), _c("div", {
    staticClass: "recommendContentInnerRightContent hideWords-More2"
  }, [_vm._v(_vm._s(_vm.homeRecommend.description))])])])])]) : _vm._e(), _vm.showMetting && _vm.meetingList.length ? _c("div", {
    staticClass: "liveRecommend marginAuto"
  }, [_c("div", {
    staticClass: "liveRecommendTitle flex align-center justify-between"
  }, [_vm._v(" 会议推荐 "), _c("img", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    attrs: {
      src: require("img/home/rightArrow.png"),
      "event-key": `直播推荐`,
      alt: ""
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/meeting"
        });
      }
    }
  })])]) : _vm._e(), _vm.showMetting && _vm.meetingList.length ? _c("div", {
    staticClass: "liveRecommendSwiper"
  }, [_c("selfSwiper", {
    attrs: {
      dataBase: _vm.meetingList
    }
  })], 1) : _vm._e(), _vm.showCourse ? _c("div", {
    staticClass: "recommendList marginAuto",
    class: (_vm.recommendListNeedTop || !_vm.meetingList.length) && "recommendListNeedTop"
  }, [_c("div", {
    staticClass: "recommendListTitle flex align-center justify-between"
  }, [_c("div", [_c("span", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    class: _vm.contentShowType ? "" : "currRecommendListTitle",
    attrs: {
      "event-key": `最新`
    },
    on: {
      click: function ($event) {
        $event.stopPropagation();
        _vm.contentShowType = 0;
      }
    }
  }, [_vm._v("最新")]), _c("span", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    class: _vm.contentShowType ? "currRecommendListTitle" : "",
    attrs: {
      "event-key": `最热`
    },
    on: {
      click: function ($event) {
        $event.stopPropagation();
        _vm.contentShowType = 1;
      }
    }
  }, [_vm._v("最热")])]), _c("img", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    attrs: {
      src: require("img/home/rightArrow.png"),
      alt: "",
      "event-key": `${_vm.contentShowType == 0 ? "最新" : "最热"}更多`
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/course"
        });
      }
    }
  })]), _c("div", {
    staticClass: "recommendListInner"
  }, [_vm._l(_vm.homeContentList, function (im, ix) {
    return _c("div", {
      directives: [{
        name: "monitor",
        rawName: "v-monitor"
      }],
      key: ix,
      staticClass: "recommendListInnerList flex",
      attrs: {
        "event-key": `${_vm.contentShowType == 0 ? "最新" : "最热"}-${im.name}`
      },
      on: {
        click: function ($event) {
          return _vm.toDetail(im);
        }
      }
    }, [im !== null && im !== void 0 && im.need_veeva ? _c("div", {
      staticClass: "authIcon smallAuthIcon samllAuthPos"
    }, [_c("img", {
      attrs: {
        alt: "",
        srcset: "",
        src: require("img/common/authIcon.png")
      }
    })]) : _vm._e(), _c("div", {
      staticClass: "recommendListInnerListLeft"
    }, [_c("img", {
      attrs: {
        src: im.cover,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "recommendListInnerListRight"
    }, [_c("div", {
      staticClass: "recommendListInnerListRightTitle hideWords-More4"
    }, [_vm._v(_vm._s(im.name))]), _c("div", {
      staticClass: "recommendListInnerListRightBottom flex align-center"
    }, [_c("div", {
      staticClass: "flex align-center"
    }, [_c("div", {
      staticClass: "recommendListInnerListRightBottomTag"
    }, [_vm._v(_vm._s(_vm.$MAPTYPE(im.content_type)))]), _c("div", {
      staticClass: "recommendListInnerListRightBottomTime"
    }, [_vm._v(_vm._s(_vm.$SPLITDATE(im.start_time)))])]), _c("div", {
      staticClass: "previewBox"
    }, [_c("div", {
      staticClass: "previewContent"
    }, [_c("img", {
      staticClass: "previeImg",
      attrs: {
        src: require("img/common/browseCountOther.png"),
        alt: "",
        srcset: ""
      }
    }), _c("span", {
      staticClass: "previewNum"
    }, [_vm._v(_vm._s(_vm.$NUMF(im.read_num || 0)))])])])])])]);
  }), !_vm.homeContentList.length && _vm.loadFinal ? _c("div", {
    staticClass: "meetingEmpty flex align-center justify-center"
  }, [_vm._v(" 暂无数据 ")]) : _vm._e()], 2), _c("ConfirmDialog", {
    ref: "confirmDialog"
  })], 1) : _vm._e(), _c("collectTips", {
    ref: "collectTips",
    attrs: {
      isShow: true
    }
  }), _c("veevaAuth", {
    ref: "veevaAuth"
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;