"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("stickyHeader"), _c("input", {
    ref: "fileInput",
    staticClass: "fileInput",
    attrs: {
      type: "file"
    },
    on: {
      change: _vm.fileInputChange
    }
  }), _c("div", {
    staticClass: "pageCenter marginAuto"
  }, [_c("div", {
    staticClass: "pageCenterSetAvatar marginAuto flex align-center justify-center flex-direction",
    on: {
      click: _vm.uploadImg
    }
  }, [_vm.formData.avatar_rankings ? _c("img", {
    staticClass: "pageCenterSetAvatarImg",
    attrs: {
      src: _vm.formData.avatar_rankings,
      alt: ""
    }
  }) : _vm._e(), _c("div", {
    staticClass: "backRgba"
  }), _vm._m(0)]), _c("div", {
    staticClass: "pageCenterForm"
  }, [_c("div", {
    staticClass: "pageCenterFormList flex align-center"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.nickname_rankings,
      expression: "formData.nickname_rankings"
    }],
    attrs: {
      type: "text",
      maxlength: "10",
      placeholder: "请输入排行榜昵称"
    },
    domProps: {
      value: _vm.formData.nickname_rankings
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "nickname_rankings", $event.target.value);
      }
    }
  })]), _c("div", {
    staticClass: "pageCenterFormTips"
  }, [_vm._v(" *此页面资料仅限在积分排行榜展示 ")])]), _c("div", {
    staticClass: "registerBtn handleBtn flex align-center justify-center",
    on: {
      click: _vm.registerSubmit
    }
  }, [_vm._v("保存")]), _c("div", {
    staticClass: "cancleBtn handleBtn flex align-center justify-center",
    on: {
      click: function ($event) {
        return _vm.$refs["customPop-back"].open();
      }
    }
  }, [_vm._v("取消")])]), _c("customPop", {
    ref: "customPop-tip",
    attrs: {
      tipWords: _vm.tipWords
    },
    on: {
      cancle: _vm.customPopCancle,
      confirm: _vm.customPopConfirm
    }
  }), _c("customPop", {
    ref: "customPop-cus",
    attrs: {
      showType: "slot"
    }
  }, [_c("div", {
    staticClass: "registerSuccess"
  }, [_c("div", {
    staticClass: "registerSuccessTip"
  }, [_vm._v("注册成功！"), _c("br"), _vm._v(" 可前往完善信息")]), _c("div", {
    staticClass: "registerSuccessHandle flex align-center justify-between"
  }, [_c("div", {
    staticClass: "registerSuccessHandleNow flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: _vm.registerSuccessHandleNow
    }
  }, [_vm._v("立即完善")]), _c("div", {
    staticClass: "registerSuccessHandleDelay flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: _vm.registerSuccessHandleDelay
    }
  }, [_vm._v("稍后")])])])]), _c("customPop", {
    ref: "customPop-back",
    attrs: {
      showType: "slot"
    }
  }, [_c("div", {
    staticClass: "registerSuccess"
  }, [_c("div", {
    staticClass: "registerSuccessTip"
  }, [_vm._v("您修改的资料还没有保存")]), _c("div", {
    staticClass: "registerSuccessHandle flex align-center justify-between"
  }, [_c("div", {
    staticClass: "registerSuccessHandleNow flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: function ($event) {
        return _vm.$refs["customPop-back"].close();
      }
    }
  }, [_vm._v("去保存")]), _c("div", {
    staticClass: "registerSuccessHandleDelay flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: function ($event) {
        return _vm.$GO(-1);
      }
    }
  }, [_vm._v("返回排行榜")])])])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "setAvatarOut flex align-center justify-center flex-direction"
  }, [_c("img", {
    staticClass: "setAvatar",
    attrs: {
      src: require("img/common/setAvatar.png"),
      alt: ""
    }
  }), _vm._v(" 设置头像 ")]);
}];
render._withStripped = true;