"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("vant/es/popup/style");
var _popup = _interopRequireDefault(require("vant/es/popup"));
require("vant/es/swipe-item/style");
var _swipeItem = _interopRequireDefault(require("vant/es/swipe-item"));
require("vant/es/swipe/style");
var _swipe = _interopRequireDefault(require("vant/es/swipe"));
var _index = require("@/utils/index.js");
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _list = _interopRequireDefault(require("@/components/course/list.vue"));
var _monitor = require("@/utils/monitor");
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    vanSwipe: _swipe.default,
    vanSwipeItem: _swipeItem.default,
    courseList: _list.default,
    vanPopup: _popup.default
  },
  data() {
    return {
      need_veeva: "",
      noThenData: false,
      searchWords: "",
      isFirstRequest: true,
      urlQuery: {},
      courseClassUseType: null,
      courseClassSecUseType: null,
      showDrawer: true,
      courseClass: [],
      current: 1,
      pageSize: 20,
      courseList: [],
      category_id: null,
      courseLessonTagList: [],
      courseLessonTagListIdArr: [],
      isReachBottom: false,
      showTags: false,
      loadFinal: false,
      isLoadAllData: false
    };
  },
  watch: {
    courseClassUse(newValue) {
      var _this$courseClass$thi, _this$courseClass$thi2, _this$courseClass$thi3;
      if (!((_this$courseClass$thi = this.courseClass[this.courseClassUse[0]]) !== null && _this$courseClass$thi !== void 0 && (_this$courseClass$thi = _this$courseClass$thi.children) !== null && _this$courseClass$thi !== void 0 && _this$courseClass$thi.length)) {
        this.category_id = this.courseClass[this.courseClassUse[0]].id;
        return;
      }
      if ((_this$courseClass$thi2 = this.courseClass[this.courseClassUse[0]]) !== null && _this$courseClass$thi2 !== void 0 && (_this$courseClass$thi2 = _this$courseClass$thi2.children) !== null && _this$courseClass$thi2 !== void 0 && _this$courseClass$thi2.length && this.courseClassUse[1] == null) {
        return;
      }
      if (this.courseClassUse[1] == -1) {
        this.category_id = this.courseClass[this.courseClassUse[0]].id;
        return;
      }
      this.category_id = (_this$courseClass$thi3 = this.courseClass[this.courseClassUse[0]]) === null || _this$courseClass$thi3 === void 0 ? void 0 : _this$courseClass$thi3.children[this.courseClassUse[1]].id;
      // this.category_id = this.courseClassUse[1]
      // this.getData()
    },
    category_id() {
      this.resetData();
    }
  },
  filter: {},
  created() {
    this.urlQuery = this.$route.query;
    this.category_id = this.urlQuery.id;
    // this.$store.commit("SET_LOADINGPOP", true)

    this.initData();
    this.initReachBottom();
  },
  mounted() {},
  computed: {
    courseClassUse() {
      return [this.courseClassUseType, this.courseClassSecUseType];
    }
  },
  destroyed() {
    window.removeEventListener("reachBottom", this.reachBottomHandle);
  },
  methods: {
    chooseNeedVeevaTags() {
      if (!this.loadFinal) return;
      this.need_veeva = this.need_veeva ? '' : 1;
      this.clearList();
    },
    initReachBottom() {
      window.addEventListener("reachBottom", this.reachBottomHandle);
    },
    reachBottomHandle(evt) {
      const self = this;
      if (evt.detail.path != "/casearea") {
        return;
      }
      if (self.isReachBottom) {
        return;
      }
      if (self.isLoadAllData) {
        return;
      }
      self.isReachBottom = true;
      self.getDisease();
      self.getDiseaseTagList();
    },
    /* 级别为第二级，需要清除列表数据 标签数据 直接调用reset */
    searchHandle() {
      this.need_veeva = "";
      this.resetData();
    },
    resetData() {
      // this.$store.commit("SET_LOADINGPOP", true)

      this.current = 1;
      this.pageSize = 20;
      this.courseList = [];
      this.noThenData = false;
      this.isReachBottom = false;
      this.isLoadAllData = false;
      this.courseLessonTagList = [];
      this.courseLessonTagListIdArr = [];
      let params = {};
      this.searchWords && (params["filter[name]"] = this.searchWords);
      if (this.courseLessonTagListIdArr.length) {
        params["tag_ids"] = this.courseLessonTagListIdArr.join(",");
      }
      let moniStr = `${document.title}:筛选-`;
      this.searchWords && (params["filter[name]"] = this.searchWords);
      for (const key in params) {
        moniStr += `${key}=${params[key]}-`;
      }
      moniStr += `category_id=${this.category_id}`;
      (0, _monitor.monitorClick)(moniStr);
      this.getDisease();
      this.getDiseaseTagList();
    },
    tagHandle(im) {
      if (!this.loadFinal) return;
      im.check = !im.check;
      const idIndex = this.courseLessonTagListIdArr.indexOf(im.id);
      idIndex < 0 ? this.courseLessonTagListIdArr.push(im.id) : this.courseLessonTagListIdArr.splice(idIndex, 1);
      this.clearList();
    },
    clearList() {
      this.current = 1;
      this.pageSize = 20;
      this.courseList = [];
      this.noThenData = false;
      this.isReachBottom = false;
      this.isLoadAllData = false;
      this.getDisease();
    },
    initData() {
      this.getCourseCate();
    },
    async getDiseaseTagList() {
      this.showTags = false;
      try {
        let params = {
          current: this.current,
          pageSize: this.pageSize,
          category_id: this.category_id
        };
        /* 关键字搜索 */
        this.searchWords && (params["filter[name]"] = this.searchWords);
        if (this.courseLessonTagListIdArr.length) {
          params["tag_ids"] = this.courseLessonTagListIdArr.join(",");
        }
        let moniStr = `${document.title}:筛选-`;
        for (const key in params) {
          moniStr += `${key}=${params[key]}-`;
        }
        moniStr += `category_id=${this.category_id}`;
        (0, _monitor.monitorClick)(moniStr);
        let res = await this.$api.getDiseaseTagList(params);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCourseLessonTagList请求失败：" + res.errmsg)
          return;
        }
        res.forEach(im => {
          im.check = false;
        });
        this.courseLessonTagList = (0, _index.quchong)([...this.courseLessonTagList, ...res]);
        this.showTags = true;
        setTimeout(() => {
          (0, _index.handleTagCss)(this.$refs.courseTag, this.$refs.courseTagInner, this.$refs.courseTagInnerItem);
        }, 0);
      } catch (error) {
        console.log(error, '==>> error');
      }
    },
    async getDisease() {
      this.loadFinal = false;
      try {
        let params = {
          current: this.current,
          pageSize: this.pageSize,
          category_id: this.category_id,
          need_veeva: this.need_veeva
        };
        if (this.courseLessonTagListIdArr.length) {
          params["tag_ids"] = this.courseLessonTagListIdArr.join(",");
        }
        /* 关键字搜索 */
        this.searchWords && (params["filter[name]"] = this.searchWords);
        let res = await this.$api.getDisease(params, this.category_id);
        this.isReachBottom = false;
        this.loadFinal = true;

        // this.$store.commit("SET_LOADINGPOP", false)

        if (!this.isFirstRequest) {
          setTimeout(() => {
            this.showDrawer = false;
          }, 500);
        }
        this.isFirstRequest = false;
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getMyFaver请求失败：" + res.errmsg)
          return;
        }
        if ((res === null || res === void 0 ? void 0 : res.data.length) < params.pageSize) {
          this.isLoadAllData = true;
        }
        const finalData = (0, _index.limitData)({
          interfaceData: res,
          baseData: this.courseList,
          limit: params
        });
        this.courseList = finalData.baseData;
        finalData.currentAdd && this.current++ && (this.noThenData = false);
        !finalData.currentAdd && (this.noThenData = true);
      } catch (error) {
        console.log(error, '==>> error');
      }
    },
    async getCourseCate() {
      try {
        /* 获取全部分类 */
        let res = await this.$api.getCourseCate(1);
        // this.$store.commit("SET_LOADINGPOP", false)

        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getCourseCate(0)失败" + res.errmsg)
          return;
        }
        this.courseClass = res;
        const calcIndex = this.courseClass.findIndex(im => im.id == Number(this.urlQuery.id));
        this.courseClassUseType = calcIndex > -1 ? calcIndex : null;
        this.getData();
      } catch (error) {}
    },
    async getData() {
      var _this$courseClass$thi4;
      const firstClass = this.courseClass[this.courseClassUse[0]];
      const secClass = (_this$courseClass$thi4 = this.courseClass[this.courseClassUse[0]]) === null || _this$courseClass$thi4 === void 0 || (_this$courseClass$thi4 = _this$courseClass$thi4.children) === null || _this$courseClass$thi4 === void 0 ? void 0 : _this$courseClass$thi4[this.courseClassUse[1]];
    }
  }
});