"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$dataBase, _vm$dataBase2, _vm$dataBase3, _vm$dataBase4;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {}, [_c("div", {
    staticClass: "commentAreaListItem flex",
    class: [_vm.isNonePadding ? "nonePadding" : "", _vm.isNoneMargin ? "noneMargin" : ""]
  }, [_c("div", {
    staticClass: "commentAreaListItemLeft"
  }, [_c("Picture", {
    attrs: {
      src: _vm.handleShowUserInfo().avatar || _vm.$DEFAULTAVATAR
    }
  })], 1), _c("div", {
    staticClass: "commentAreaListItemRight"
  }, [_c("div", {
    staticClass: "commentAreaListItemRightTop flex align-center justify-between"
  }, [_c("div", {
    staticClass: "commentAreaListItemRightTopLeft"
  }, [_vm._v(_vm._s(_vm.handleShowUserInfo().title || "暂无信息"))]), _c("div", {
    staticClass: "commentAreaListItemRightTopRight flex align-center",
    on: {
      click: _vm.handleZan
    }
  }, [!((_vm$dataBase = _vm.dataBase) !== null && _vm$dataBase !== void 0 && _vm$dataBase.have_zan) ? _c("img", {
    attrs: {
      src: require("img/common/giveNull.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("img/common/give.png"),
      alt: ""
    }
  }), _vm._v(" " + _vm._s(((_vm$dataBase2 = _vm.dataBase) === null || _vm$dataBase2 === void 0 ? void 0 : _vm$dataBase2.zan) || 0) + " ")])]), _c("div", {
    staticClass: "commentAreaListItemRightContent flex align-end justify-between"
  }, [_c("div", {
    staticClass: "commentAreaListItemRightContentWords"
  }, [_vm._v(_vm._s(((_vm$dataBase3 = _vm.dataBase) === null || _vm$dataBase3 === void 0 ? void 0 : _vm$dataBase3[_vm.isAnswerComponent ? "answer_check_content" : "comment"]) || ""))])]), _c("div", {
    staticClass: "commentAreaListItemRightContentTime"
  }, [_vm._v(_vm._s(_vm.$SPLITDATE((_vm$dataBase4 = _vm.dataBase) === null || _vm$dataBase4 === void 0 ? void 0 : _vm$dataBase4.created_at)))])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;