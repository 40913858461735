"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$callUs, _vm$callUs2;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("transition", {
    attrs: {
      name: "popFull",
      appear: ""
    }
  }, [_vm.showPop ? _c("div", {
    staticClass: "popFull",
    style: {
      background: _vm.background
    },
    on: {
      click: _vm.maskHandle
    }
  }, [_c("div", {
    staticClass: "popFullInner",
    class: _vm.transparent && "transparent",
    on: {
      click: function ($event) {
        $event.stopPropagation();
      }
    }
  }, [!_vm.transparent ? _c("img", {
    staticClass: "popClose",
    attrs: {
      src: require("img/common/popClose.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.popFullClose.apply(null, arguments);
      }
    }
  }) : _vm._e(), _vm.showType == "words" ? _c("div", {
    staticClass: "popFullInnerContent popFullInnerContent-word"
  }, [_vm._v(" " + _vm._s(_vm.showCallUs ? _vm.showCallUs && _vm.userInfo.veeva ? _vm.tipWords : "抱歉！您暂无法查看该内容" : _vm.tipWords) + " ")]) : _vm._e(), _vm.showType == "slot" ? _c("div", {
    staticClass: "popFullInnerContent popFullInnerContent-slot"
  }, [_vm._t("default")], 2) : _vm._e(), _vm.showCallUs ? _c("div", {
    staticClass: "popFullInnerContent-ewmIcon"
  }, [(_vm$callUs = _vm.callUs) !== null && _vm$callUs !== void 0 && _vm$callUs.qrcode && _vm.userInfo.veeva ? _c("div", {
    staticClass: "ewmIcon"
  }, [_c("img", {
    attrs: {
      src: ((_vm$callUs2 = _vm.callUs) === null || _vm$callUs2 === void 0 ? void 0 : _vm$callUs2.qrcode) || _vm.$DEFAULTAVATAR,
      alt: ""
    }
  })]) : _vm._e()]) : _vm._e()])]) : _vm._e()])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;