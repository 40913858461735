"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueVideoPlayer = require("vue-video-player");
require("video.js/dist/video-js.css");
// import 'videojs-contrib-hls'
var _default = exports.default = {
  components: {
    videoPlayer: _vueVideoPlayer.videoPlayer
  },
  name: 'customVideo',
  props: {
    src: {
      type: String,
      default: '请传入提示路径'
    },
    listDetail: {
      type: Object,
      default: () => {}
    },
    scale: {
      type: Array,
      default: () => []
    },
    videoName: {
      tyoe: String,
      default: ''
    },
    isPlayNoPaused: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isReadyFlag: true,
      isPlay: false,
      showPop: false,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        // 可选的播放速度
        autoplay: false,
        // 如果为true,浏览器准备好时开始回放。
        muted: false,
        // 默认情况下将会消除任何音频。
        loop: false,
        // 是否视频一结束就重新开始。
        preload: 'auto',
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9',
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true,
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'video/mp4',
          // 类型
          // src: require("../../assets/img/ceshi.mp4")
          // src: this.src

          src: ''

          // src: "https://gore-minisite.oss-cn-beijing.aliyuncs.com/minisite/20230111/rc-upload-1673449505771-27.mp4"
          // url地址
        }],
        // poster: require("../../assets/img/logo.png"), // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          // 当前时间和持续时间的分隔符
          durationDisplay: true,
          // 显示持续时间
          remainingTimeDisplay: true,
          // 是否显示剩余时间功能
          fullscreenToggle: true,
          // 是否显示全屏按钮

          currentTimeDisplay: true,
          // 当前时间
          volumeControl: false,
          // 声音控制键
          playToggle: true,
          // 暂停和播放键
          progressControl: true // 进度条
        },
        playtimes: '15'
      }
    };
  },
  watch: {
    src() {
      this.src && (this.playerOptions['sources'][0]['src'] = this.src);
    }
  },
  filter: {},
  created() {
    this.scale.length && (this.playerOptions['aspectRatio'] = `${this.scale[0]}:${this.scale[1]}`);
    this.src && (this.playerOptions['sources'][0]['src'] = this.src);
  },
  mounted() {
    this.listener();
  },
  computed: {},
  beforeDestroy() {
    this.listenerRemove();
  },
  destroyed() {
    this.listenerRemove();
  },
  methods: {
    listenerRemove() {
      window.removeEventListener('onorientationchange');
    },
    listener() {
      window.addEventListener('onorientationchange' in window ? 'orientationchange' : 'resize', async function () {
        if (window.orientation === 180 || window.orientation === 0) {
          // alert('竖屏状态！');
        }
        if (window.orientation === 90 || window.orientation === -90) {
          // alert('横屏状态！');
          // this.$refs.VideoPlayer.player.requestFullscreen()
          /* const video = document.querySelector("video")
                  let res = await video.webkitEnterFullscreen() */
        }
      }, false);
    },
    onPlayerEnd(player) {
      var _this$$parent, _player$cache_;
      this.isPlay = false;
      (_this$$parent = this.$parent) === null || _this$$parent === void 0 || _this$$parent.pauseSetBrowse(Math.ceil((_player$cache_ = player.cache_) === null || _player$cache_ === void 0 ? void 0 : _player$cache_.duration) || 0, true);
    },
    onPlayerReady(player) {
      console.log(player, '==> ppppp');

      // 监听 canplay 当浏览器可以开始播放音频/视频时触发。
      player.on('canplaythrough', () => {
        var _this$listDetail;
        player.off('canplaythrough');
        // 现在可以安全地设置 currentTime，因为视频已经足够缓冲
        if (((_this$listDetail = this.listDetail) === null || _this$listDetail === void 0 ? void 0 : _this$listDetail.learn_time) >= player.cache_.duration) {
          player.currentTime(0);
        } else {
          var _this$listDetail2;
          player.currentTime(((_this$listDetail2 = this.listDetail) === null || _this$listDetail2 === void 0 ? void 0 : _this$listDetail2.learn_time) || 0);
        }
      });
    },
    resetUrl() {
      /* this.$nextTick(() => {
                console.log(this.$refs.VideoPlayer,'==>? this.$refs.VideoPlayer.player');
                this.$refs.VideoPlayer.player.currentTime(this.listDetail?.learn_time || 0)
            }) */
    },
    activitvResetVideo() {
      console.log('activitvResetVideoactivitvResetVideoactivitvResetVideo===activitvResetVideo');
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          var _this$listDetail3;
          this.$refs.VideoPlayer.player.pause();
          this.onpauseFn();
          this.$refs.VideoPlayer.player.currentTime(((_this$listDetail3 = this.listDetail) === null || _this$listDetail3 === void 0 ? void 0 : _this$listDetail3.learn_time) || 0);
          resolve();
        });
      });
    },
    onplayFn() {
      this.isPlay = true;
      if (!this.isPlayNoPaused) {
        var _this$$parent2;
        (_this$$parent2 = this.$parent) === null || _this$$parent2 === void 0 || _this$$parent2.pauseSetBrowse();
      }
      this.$parent.setBrowse();
      this.$forceUpdate();
      return;
    },
    onpauseFn() {
      var _this$$parent3;
      this.isPlay = false;
      (_this$$parent3 = this.$parent) === null || _this$$parent3 === void 0 || _this$$parent3.pauseSetBrowse();

      // this.isShowBtn = 0
    },
    open() {
      this.showPop = true;
    },
    close() {
      this.popFullClose();
    },
    maskHandle() {
      if (this.mask) {
        this.popFullClose();
      }
    },
    popFullClose() {
      this.showPop = false;
      this.$emit('cancle');
    }
  }
};