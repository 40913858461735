"use strict";

var _interopRequireDefault = require("C:/work/code/RADAcademy/bayer-rad-academy-shadowcloud-academy-html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/loading/style");
var _loading = _interopRequireDefault(require("vant/es/loading"));
var _default = exports.default = {
  components: {
    vantLoading: _loading.default
  },
  props: {
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tipsWords: "加载中...",
      timer: null,
      time: 0,
      loadError: false
    };
  },
  watch: {
    loadingShow() {
      if (this.loadingShow) {
        this.timer = setInterval(() => {
          this.time++;
          if (this.time >= 5) {
            this.tipsWords = "加载超时，点击刷新重试；或继续等待。";
            this.loadError = true;
            this.time = 0;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      if (!this.loadingShow) {
        this.clearErrorHandle();
      }
    }
  },
  filter: {},
  created() {},
  mounted() {},
  computed: {
    loadingShow() {
      return this.$store.getters.loadingShow;
    },
    tologin() {
      return this.$store.getters.tologin;
    }
  },
  methods: {
    errorHandle(type) {
      if (this.loadError) {
        // alert("需要重新加载")
        this.$store.commit("SET_LOADINGPOP", false);
        this.clearErrorHandle(type);
      }
    },
    clearErrorHandle(type) {
      this.loadError = false;
      this.time = 0;
      this.tipsWords = "加载中...";
      clearInterval(this.timer);
      this.timer = null;
      type == "again" && this.$GO(0);
    }
    /* open() {
      this.showPop = true
    },
    close(){
      this.popFullClose()
    },
    maskHandle() {
      if (this.mask) {
        this.popFullClose()
      }
    },
    popFullClose() {
      this.showPop = false
      this.$emit("cancle")
    } */
  }
};