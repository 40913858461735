"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = _interopRequireDefault(require("utils/http"));
var _default = exports.default = {
  /* 活动-列表 */
  getCampainList(params) {
    return (0, _http.default)({
      url: `/api/platform/campain/campain`,
      method: 'get',
      params
    });
  },
  /* 活动-列表 */
  getCampainListAuth(params, id) {
    return (0, _http.default)({
      url: `/api/platform/campain/campain/${id}`,
      method: 'get',
      params
    });
  },
  /* 活动-是否展示活动页 */
  getIsShowIntroduceAuth(params, id, type) {
    return (0, _http.default)({
      url: `/api/platform/content/desc/status/${id}/${type}`,
      method: 'get',
      params
    });
  },
  /* 活动-更新展示活动页 */

  putShowIntroduceAuth(params, id, type) {
    return (0, _http.default)({
      url: `/api/platform/content/desc/status/${id}/${type}`,
      method: 'put',
      params
    });
  },
  /* 活动-模块列表 */
  getCampainSectionList(params) {
    return (0, _http.default)({
      url: `/api/platform/campain/campain-section`,
      method: 'get',
      params
    });
  },
  /* 活动-模块详情 */
  getCampainSectionDetail(params, id) {
    return (0, _http.default)({
      url: `/api/platform/campain/campain-section/${id}`,
      method: 'get',
      params
    });
  },
  /* 活动-模块详情 */
  getCampainSectionDetailAuth(params, id) {
    return (0, _http.default)({
      url: `/api/platform/campain/campain-section/section/${id}`,
      method: 'get',
      params
    });
  },
  /* 活动-模块-内容管理 */
  getCampainContent(params) {
    return (0, _http.default)({
      url: `/api/platform/campain/campain-content`,
      method: 'get',
      params
    });
  },
  /* 活动-模块-内容管理 */
  getCampainContentId(params, id) {
    return (0, _http.default)({
      url: `/api/platform/campain/campain-content/${id}`,
      method: 'get',
      params
    });
  },
  /* 活动-三级权限 */
  getCheckPermissions(params, type, id) {
    return (0, _http.default)({
      url: `/api/platform/campain/check/permissions/${type}/${id}`,
      method: 'get',
      params
    });
  },
  // 获取2023年终h5数据
  get2023Data(params) {
    return (0, _http.default)({
      url: `/api/platform/year/h5/data/2023`,
      method: 'get',
      params
    });
  },
  //2023h5埋点
  setViewLog(params) {
    return (0, _http.default)({
      url: `/api/platform/year/h5/data/2023/view/log`,
      method: 'post',
      data: params
    });
  },
  //发放学术能量
  setFinishedNum(params) {
    return (0, _http.default)({
      url: `/api/platform/year/h5/data/2023/finished`,
      method: 'post',
      data: params
    });
  }
};